import {
  BILL_GET_LIST,
  BILL_GET_LIST_SUCCESS,
  BILL_GET_LIST_ERROR,
  BILL_GET_ONE,
  BILL_GET_ONE_SUCCESS,
  BILL_GET_ONE_ERROR,

  BILL_GET_BY_FILTER,
  BILL_GET_BY_FILTER_SUCCESS,
  BILL_GET_BY_FILTER_ERROR,

  BILL_ADD_ONE,
  BILL_ADD_ONE_SUCCESS,
  BILL_ADD_ONE_ERROR,
  BILL_EDIT_ONE,
  BILL_EDIT_ONE_SUCCESS,
  BILL_EDIT_ONE_ERROR,
  BILL_PRINT,
  BILL_PRINT_SUCCESS,
  BILL_PRINT_ERROR,

  BILL_DELETE_ONE,
  BILL_DELETE_ONE_SUCCESS,
  BILL_DELETE_ONE_ERROR,

  BILLS_DELETE_MANY,
  BILLS_DELETE_MANY_SUCCESS,
  BILLS_DELETE_MANY_ERROR,
} from '../../actions';

// GET LIST
export const getBillsList = (payload) => ({
  type: BILL_GET_LIST,
  payload
});
export const getBillsListSuccess = (payload) => ({
  type: BILL_GET_LIST_SUCCESS,
  payload
});
export const getBillsListError = (payload) => ({
  type: BILL_GET_LIST_ERROR,
  payload
});




// GET ONE
export const getOneBill = (payload) => ({
  type: BILL_GET_ONE,
  payload
});
export const getOneBillSuccess = (payload) => ({
  type: BILL_GET_ONE_SUCCESS,
  payload
});
export const getOneBillError = (payload) => ({
  type: BILL_GET_ONE_ERROR,
  payload
});

// GET bY Filter
export const getBillsByFilter = (payload) => ({
  type: BILL_GET_BY_FILTER,
  payload
});
export const getBillsByFilterSuccess = (payload) => ({
  type: BILL_GET_BY_FILTER_SUCCESS,
  payload
});
export const getBillsByFilterError = (payload) => ({
  type: BILL_GET_BY_FILTER_ERROR,
  payload
});

// ADD ONE
export const addBill = (payload) => ({
  type: BILL_ADD_ONE,
  payload
});
export const addBillSuccess = (payload) => ({
  type: BILL_ADD_ONE_SUCCESS,
  payload
});
export const addBillError = (payload) => ({
  type: BILL_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editBill = (payload) => ({
  type: BILL_EDIT_ONE,
  payload
});
export const editBillSuccess = (payload) => ({
  type: BILL_EDIT_ONE_SUCCESS,
  payload
});
export const editBillError = (payload) => ({
  type: BILL_EDIT_ONE_ERROR,
  payload
});



// PRINT 
export const printBills = (payload) => ({
  type: BILL_PRINT,
  payload
});
export const printBillSuccess = (payload) => ({
  type: BILL_PRINT_SUCCESS,
  payload
});
export const printBillError = (payload) => ({
  type: BILL_PRINT_ERROR,
  payload
});



// DELETE ONE
export const deleteOneBill = (payload) => ({
  type: BILL_DELETE_ONE,
  payload
});
export const deleteOneBillSuccess = (payload) => ({
  type: BILL_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneBillError = (payload) => ({
  type: BILL_DELETE_ONE_ERROR,
  payload
});



// DELETE MANY
export const deleteManyBills = (payload) => ({
  type: BILLS_DELETE_MANY,
  payload
});
export const deleteManyBillsSuccess = (payload) => ({
  type: BILLS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyBillsError = (payload) => ({
  type: BILLS_DELETE_MANY_ERROR,
  payload
});