import {
  EXAMMARKSNOTES_GET_BY_FILTERS,
  EXAMMARKSNOTES_GET_BY_FILTERS_SUCCESS,
  EXAMMARKSNOTES_GET_BY_FILTERS_ERROR,

  EXAMMARKSNOTES_GET_BY_FILTER,
  EXAMMARKSNOTES_GET_BY_FILTER_SUCCESS,
  EXAMMARKSNOTES_GET_BY_FILTER_ERROR,


  EXAMMARKSNOTES_ADD_ONE,
  EXAMMARKSNOTES_ADD_ONE_SUCCESS,
  EXAMMARKSNOTES_ADD_ONE_ERROR,

  EXAMMARKSNOTES_EDIT_ONE,
  EXAMMARKSNOTES_EDIT_ONE_SUCCESS,
  EXAMMARKSNOTES_EDIT_ONE_ERROR,

  EXAMMARKSNOTES_DELETE_ONE,
  EXAMMARKSNOTES_DELETE_ONE_SUCCESS,
  EXAMMARKSNOTES_DELETE_ONE_ERROR,

  EXAMMARKSNOTES_GET_ONE,
  EXAMMARKSNOTES_GET_ONE_SUCCESS,
  EXAMMARKSNOTES_GET_ONE_ERROR,
} from '../actions';

// GET EXam MARK NOTE BY FILTERS FOR STUDENT

export const getExamMarksNotesByFilters = (payload) => ({
  type: EXAMMARKSNOTES_GET_BY_FILTERS,
  payload
});
export const getExamMarksNotesByFiltersSuccess = (payload) => ({
  type: EXAMMARKSNOTES_GET_BY_FILTERS_SUCCESS,
  payload
});
export const getExamMarksNotesByFiltersError = (payload) => ({
  type: EXAMMARKSNOTES_GET_BY_FILTERS_ERROR,
  payload
});

//GET EXAM NOTE BY FILTER FOR TEACHER
export const getExamMarksNotesByFilter = (payload) => ({
  type: EXAMMARKSNOTES_GET_BY_FILTER,
  payload
});
export const getExamMarksNotesByFilterSuccess = (payload) => ({
  type: EXAMMARKSNOTES_GET_BY_FILTER_SUCCESS,
  payload
});
export const getExamMarksNotesByFilterError = (payload) => ({
  type: EXAMMARKSNOTES_GET_BY_FILTER_ERROR,
  payload
});
// GET One
export const getOneExamMarksNotes = (payload) => ({
  type: EXAMMARKSNOTES_GET_ONE,
  payload
});
export const getOneExamMarksNotesSuccess = (payload) => ({
  type: EXAMMARKSNOTES_GET_ONE_SUCCESS,
  payload
});
export const getOneExamMarksNotesError = (payload) => ({
  type: EXAMMARKSNOTES_GET_ONE_ERROR,
  payload
});

// ADD ONE
export const addExamMarksNotes = (payload) => ({
  type: EXAMMARKSNOTES_ADD_ONE,
  payload
});
export const addExamMarksNotesSuccess = (payload) => ({
  type: EXAMMARKSNOTES_ADD_ONE_SUCCESS,
  payload
});
export const addExamMarksNotesError = (payload) => ({
  type: EXAMMARKSNOTES_ADD_ONE_ERROR,
  payload
});

// EDIT ONE
export const editOneExamMarksNotes = (payload) => ({
  type: EXAMMARKSNOTES_EDIT_ONE,
  payload
});
export const editOneExamMarksNotesSuccess = (payload) => ({
  type: EXAMMARKSNOTES_EDIT_ONE_SUCCESS,
  payload
});
export const editOneExamMarksNotesError = (payload) => ({
  type: EXAMMARKSNOTES_EDIT_ONE_ERROR,
  payload
});

// DELETE ONE
export const deleteOneExamMarksNotes = (payload) => ({
  type: EXAMMARKSNOTES_DELETE_ONE,
  payload
});
export const deleteOneExamMarksNotesSuccess = (payload) => ({
  type: EXAMMARKSNOTES_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneExamMarksNotesError = (payload) => ({
  type: EXAMMARKSNOTES_DELETE_ONE_ERROR,
  payload
});