import {
  INVITATION_GET_LIST,
  INVITATION_GET_LIST_SUCCESS,
  INVITATION_GET_LIST_ERROR,

  INVITATION_GET_LIST_BY_PARTNER,

  INVITATION_ACCEPT,

  INVITATION_GET_IDS,
  INVITATION_GET_IDS_SUCCESS,
  INVITATION_GET_IDS_ERROR,

  INVITATION_GET_ONE,
  INVITATION_GET_ONE_SUCCESS,
  INVITATION_GET_ONE_ERROR,

  INVITATION_ADD_ONE,
  INVITATION_ADD_ONE_SUCCESS,
  INVITATION_ADD_ONE_ERROR,

  INVITATION_EDIT_ONE,
  INVITATION_EDIT_ONE_SUCCESS,
  INVITATION_EDIT_ONE_ERROR,

  INVITATION_DELETE_ONE,
  INVITATION_DELETE_ONE_SUCCESS,
  INVITATION_DELETE_ONE_ERROR,
  INVITATION_GET_LIST_BY_PARTNER_SUCCESS,
  INVITATION_GET_LIST_BY_PARTNER_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'
const INIT_STATE = {
  invitations: [],
  partner_invitations: [],
  invitations_ids: [],
  count: 0,
  invitation: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

  switch (action.type) {
    case INVITATION_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case INVITATION_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        invitations: action.payload.invitations || [],
        count: action.payload.count || 0
      };
    case INVITATION_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    // case INVITATION_GET_LIST_BY_PARTNER:
    //   return {
    //     ...state, loading: true, error: '',
    //     count: 0
    //   };

    case INVITATION_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case INVITATION_GET_LIST_BY_PARTNER_SUCCESS:
      return {
        ...state, loading: false,
        partner_invitations: action.payload.invitations || [],
        count: action.payload.count || 0
      };
    case INVITATION_GET_LIST_BY_PARTNER_ERROR:
      return { ...state, loading: false, error: action.payload.message };




    // GET EVENT INVITATIONS IDS (all)
    case INVITATION_GET_IDS:
      return {
        ...state, loading: true, error: ''
      };
    case INVITATION_GET_IDS_SUCCESS:
      return {
        ...state, loading: false,
        invitations_ids: action.payload.invitations_ids || [],
      };
    case INVITATION_GET_IDS_ERROR:
      return { ...state, loading: false, error: action.payload.message };






    // GET ONE
    case INVITATION_GET_ONE:
      return { ...state, loading: true, error: '' };
    case INVITATION_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        invitation: action.payload.invitation
      };
    case INVITATION_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









    // ADD ONE
    case INVITATION_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case INVITATION_ADD_ONE_SUCCESS:
      if (!action.payload.is_list)
        NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        invitations: [action.payload.invitation, ...state.invitations]
      };
    case INVITATION_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // EDIT ONE
    case INVITATION_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case INVITATION_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        invitation: action.payload.invitation
      };
    case INVITATION_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




    // ACCEPT ONE
    case INVITATION_ACCEPT:
      return { ...state, loading: true, error: '' };


    // DELETE ONE
    case INVITATION_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case INVITATION_DELETE_ONE_SUCCESS:
      const invitations = state.invitations
      if (action.payload.index !== null && action.payload.index !== undefined)
        invitations.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        invitations
      };
    case INVITATION_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    default: return { ...state };
  }
}
