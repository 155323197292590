import {
  MEALTYPES_GET_LIST,
  MEALTYPES_GET_LIST_SUCCESS,
  MEALTYPES_GET_LIST_ERROR,

  MEALTYPE_GET_ONE,
  MEALTYPE_GET_ONE_SUCCESS,
  MEALTYPE_GET_ONE_ERROR,

  MEALTYPES_GET_BY_FILTER,
  MEALTYPES_GET_BY_FILTER_SUCCESS,
  MEALTYPES_GET_BY_FILTER_ERROR,

  MEALTYPE_ADD_ONE,
  MEALTYPE_ADD_ONE_SUCCESS,
  MEALTYPE_ADD_ONE_ERROR,

  MEALTYPE_EDIT_ONE,
  MEALTYPE_EDIT_ONE_SUCCESS,
  MEALTYPE_EDIT_ONE_ERROR,

  MEALTYPE_DELETE_ONE,
  MEALTYPE_DELETE_ONE_SUCCESS,
  MEALTYPE_DELETE_ONE_ERROR,

  MEALTYPES_DELETE_MANY,
  MEALTYPES_DELETE_MANY_SUCCESS,
  MEALTYPES_DELETE_MANY_ERROR
} from '../actions';

// GET LIST
export const getMealTypesList = (payload) => ({
  type: MEALTYPES_GET_LIST,
  payload
});
export const getMealTypesListSuccess = (payload) => ({
  type: MEALTYPES_GET_LIST_SUCCESS,
  payload
});
export const getMealTypesListError = (payload) => ({
  type: MEALTYPES_GET_LIST_ERROR,
  payload
});


// GET ONE
export const getOneMealType = (payload) => ({
  type: MEALTYPE_GET_ONE,
  payload
});
export const getOneMealTypeSuccess = (payload) => ({
  type: MEALTYPE_GET_ONE_SUCCESS,
  payload
});
export const getOneMealTypeError = (payload) => ({
  type: MEALTYPE_GET_ONE_ERROR,
  payload
});


// GET bY Filter
export const getMealTypesByFilter = (payload) => ({
  type: MEALTYPES_GET_BY_FILTER,
  payload
});
export const getMealTypesByFilterSuccess = (payload) => ({
  type: MEALTYPES_GET_BY_FILTER_SUCCESS,
  payload
});
export const getMealTypesByFilterError = (payload) => ({
  type: MEALTYPES_GET_BY_FILTER_ERROR,
  payload
});

// ADD ONE
export const addMealType = (payload) => ({
  type: MEALTYPE_ADD_ONE,
  payload
});
export const addMealTypeSuccess = (payload) => ({
  type: MEALTYPE_ADD_ONE_SUCCESS,
  payload
});
export const addMealTypeError = (payload) => ({
  type: MEALTYPE_ADD_ONE_ERROR,
  payload
});


// EDIT ONE
export const editMealType = (payload) => ({
  type: MEALTYPE_EDIT_ONE,
  payload
});
export const editMealTypeSuccess = (payload) => ({
  type: MEALTYPE_EDIT_ONE_SUCCESS,
  payload
});
export const editMealTypeError = (payload) => ({
  type: MEALTYPE_EDIT_ONE_ERROR,
  payload
});


// DELETE ONE
export const deleteOneMealType = (payload) => ({
  type: MEALTYPE_DELETE_ONE,
  payload
});
export const deleteOneMealTypeSuccess = (payload) => ({
  type: MEALTYPE_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneMealTypeError = (payload) => ({
  type: MEALTYPE_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyMealTypes = (payload) => ({
  type: MEALTYPES_DELETE_MANY,
  payload
});
export const deleteManyMealTypesSuccess = (payload) => ({
  type: MEALTYPES_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyMealTypesError = (payload) => ({
  type: MEALTYPES_DELETE_MANY_ERROR,
  payload
});