import {
  TIMETABLE_GET_LIST,
  TIMETABLE_GET_LIST_SUCCESS,
  TIMETABLE_GET_LIST_ERROR,

  TIMETABLE_GET_ONE,
  TIMETABLE_GET_ONE_SUCCESS,
  TIMETABLE_GET_ONE_ERROR,

  TIMETABLE_GET_BY_FILTER,
  TIMETABLE_GET_BY_FILTER_SUCCESS,
  TIMETABLE_GET_BY_FILTER_ERROR,

  TIMETABLE_ADD_ONE,
  TIMETABLE_ADD_ONE_SUCCESS,
  TIMETABLE_ADD_ONE_ERROR,

  TIMETABLE_EDIT_ONE,
  TIMETABLE_EDIT_ONE_SUCCESS,
  TIMETABLE_EDIT_ONE_ERROR,

  TIMETABLE_DELETE_ONE,
  TIMETABLE_DELETE_ONE_SUCCESS,
  TIMETABLE_DELETE_ONE_ERROR,

  TIMETABLES_DELETE_MANY,
  TIMETABLES_DELETE_MANY_SUCCESS,
  TIMETABLES_DELETE_MANY_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg';

const INIT_STATE = {
  timetables: [],
  count: 0,
  timetable: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

  switch (action.type) {
    case TIMETABLE_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case TIMETABLE_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        timetables: action.payload.timetables || [],
        count: action.payload.count || 0
      };
    case TIMETABLE_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






    // GET ONE
    case TIMETABLE_GET_ONE:
      return { ...state, loading: true, error: '' };
    case TIMETABLE_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        timetable: action.payload.timetable
      };
    case TIMETABLE_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };





    // GET BY FILTER
    case TIMETABLE_GET_BY_FILTER:

      return { ...state, loading: true, error: '' };
    case TIMETABLE_GET_BY_FILTER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        timetables: action.payload.timetables || [],
      };
    case TIMETABLE_GET_BY_FILTER_ERROR:

      return { ...state, loading: false, error: action.payload.message };



    // ADD ONE
    case TIMETABLE_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case TIMETABLE_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        timetables: [action.payload.timetable, ...state.timetables]
      };
    case TIMETABLE_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // EDIT ONE
    case TIMETABLE_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case TIMETABLE_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        timetable: action.payload.timetable
      };
    case TIMETABLE_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // DELETE ONE
    case TIMETABLE_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case TIMETABLE_DELETE_ONE_SUCCESS:
      const timetables = state.timetables
      if (action.payload.index !== null && action.payload.index !== undefined)
        timetables.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        timetables
      };
    case TIMETABLE_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    // DELETE MANY
    case TIMETABLES_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case TIMETABLES_DELETE_MANY_SUCCESS:
      const timetables_list = state.timetables


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_timetable = -1
          for (let j = 0; j < timetables_list.length; j++) {
            if (timetables_list[j]._id === action.payload.data.ids[i]) {
              index_timetable = j; break;
            }
          }
          if (index_timetable > -1) timetables_list.splice(index_timetable, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        timetables: timetables_list
      };
    case TIMETABLES_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
    default: return { ...state };
  }
}