import {
    REQUEST_MANAGER_GET_LIST,
    REQUEST_MANAGER_GET_LIST_ERROR,
    REQUEST_MANAGER_GET_LIST_SUCCESS,

    REQUEST_MANAGER_GET_ITEM,
    REQUEST_MANAGER_GET_ITEM_ERROR,
    REQUEST_MANAGER_GET_ITEM_SUCCESS,

    REQUEST_MANAGER_ADD,
    REQUEST_MANAGER_ADD_SUCCESS,
    REQUEST_MANAGER_ADD_ERROR,

    REQUEST_MANAGER_EDIT_ONE,
    REQUEST_MANAGER_EDIT_ONE_SUCCESS,
    REQUEST_MANAGER_EDIT_ONE_ERROR,

    REQUEST_MANAGER_DELETE_ONE,
    REQUEST_MANAGER_DELETE_ONE_SUCCESS,
    REQUEST_MANAGER_DELETE_ONE_ERROR
} from './actionTypes'

import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'

const INIT_STATE = {
    requests: [],
    count: 0,
    request: null,
    error: '',
    loading: false
};


export default (state = INIT_STATE, action) => {
    const messages = notificationMessages()
  
    switch (action.type) {
      case REQUEST_MANAGER_GET_LIST:
        return {
          ...state, loading: true,
          error: ''
        };
      case REQUEST_MANAGER_GET_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          requests: action.payload.requests,
          count: action.payload.count
        };
      case REQUEST_MANAGER_GET_LIST_ERROR:
        return { ...state, loading: false, error: action.payload };

     //GET ONE
      case REQUEST_MANAGER_GET_ITEM:
        return { ...state, request: null, loading: true, error: '' };
  
      case REQUEST_MANAGER_GET_ITEM_SUCCESS:
        return { ...state, loading: false, request: action.payload.request };
  
      case REQUEST_MANAGER_GET_ITEM_ERROR:
        return { ...state, loading: false, request: null, error: action.payload.message };
 
 
    //EDIT ONE
      case REQUEST_MANAGER_EDIT_ONE:
        return { ...state, request: null, loading: true, error: '' };

      case REQUEST_MANAGER_EDIT_ONE_SUCCESS:
        NotificationManager.success(
            "Demande Validée", "Success",
            3000, null, null, ''
          );
        return { ...state, loading: false, request: action.payload.request };

      case REQUEST_MANAGER_EDIT_ONE_ERROR:
        return { ...state, loading: false, request: null, error: action.payload.message };
  

      //ADD ONE
      case REQUEST_MANAGER_ADD:
        return {
          ...state, error: '',
          loading: true
        };
      case REQUEST_MANAGER_ADD_SUCCESS:
        NotificationManager.success(
          "Request sent", "Success",
          3000, null, null, ''
        );
        return {
          ...state, error: '', loading: false,
          request: action.payload.request
        };
      case REQUEST_MANAGER_ADD_ERROR:
        NotificationManager.warning(
          (action.payload.error) ? action.payload.error.message : 'unknown error', "Error",
          3000, null, null, ''
        );
        return { ...state, loading: false, error: (action.payload.error) ? action.payload.error.message : 'unknown error' };
  

      // DELETE ONE
      case REQUEST_MANAGER_DELETE_ONE:
        return { ...state, loading: true, error: '' };
      case REQUEST_MANAGER_DELETE_ONE_SUCCESS:
        const requests = state.requests
        if (action.payload.index !== null && action.payload.index !== undefined)
        requests.splice(action.payload.index, 1)
  
        return {
          ...state, loading: false, error: '',
          requests
        };
      case REQUEST_MANAGER_DELETE_ONE_ERROR:
        NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };
  
  
  
      default: return { ...state };
  
    }
}  