import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
    REQUEST_MANAGER_GET_LIST, REQUEST_MANAGER_ADD, REQUEST_MANAGER_GET_ITEM, REQUEST_MANAGER_EDIT_ONE, REQUEST_MANAGER_DELETE_ONE
  } from "./actionTypes";
  


  import {
    getRequestsManagerListSuccess,
    getRequestsManagerListError,
  
    addRequestManagerSuccess,
    addRequestManagerError,

    getRequestManagerSuccess,
    getRequestManagerError,

    editRequestManagerSuccess,
    editRequestManagerError,

    deleteOneRequestManagerSuccess,
    deleteOneRequestManagerError
  } from "./actions";


//GET LIST
const getRequestsManagerListRequest = async (payload) => {
    return await axios.get(api.requestManager + `/list/${payload.offset}/${payload.limit}`)
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
  };

function* getRequestsManagerList({ payload }) {
    try {
      const ret = yield call(getRequestsManagerListRequest, payload);
  
      if (ret && ret.status === 200)
        yield put(getRequestsManagerListSuccess({ requests: ret.data.requests || [], count: ret.data.count || 0 }));
  
      else yield put(getRequestsManagerListError((ret.data) ? ret.data.msg : "Network Error"));
  
    } catch (error) {
      yield put(getRequestsManagerListError(error));
    }
  }
  
  
  
//ADD ONE
const addRequestManagerRequest = async (payload) => {
    // const data = payload.data;
    // if (payload.user_id) data['user_id'] = payload.user_id;
  
    return await axios.post(api.requestManager, payload.data)
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
  };
function* addRequestManager({ payload }) {
    try {
      const ret = yield call(addRequestManagerRequest, payload);
  
      if (ret && ret.status == 201) yield put(addRequestManagerSuccess({ request: ret.data.requestManager }));
  
      else yield put(addRequestManagerError({
        error: { message: (ret.data) ? ret.data.msg : "Network Error" }
      }));
    } catch (error) {
      yield put(addRequestManagerError({ error }));
    }
}


//GET ONE
const getRequestManagerRequest = async (payload) => {
    return await axios.get(api.requestManager + `/get-one/${payload._id}`)
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
  };
  function* getRequestManager({ payload }) {
    try {
      const ret = yield call(getRequestManagerRequest, payload);
  
      if (ret && ret.status === 200)
        yield put(getRequestManagerSuccess({ request: ret.data.request }));
  
      else yield put(getRequestManagerError({ msg: (ret.data) ? ret.data.msg : "Network Error" }));
  
    } catch (error) {
      yield put(getRequestManagerError({ error }));
    }
  }
  
 
  

//EDIT ONE

const editRequestManagerRequest = async (payload) => {
    return await axios.patch(api.requestManager + `/edit-one/${payload._id}`, payload.data)
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
};
  
function* editRequestManager({ payload }) {
    try {
      const ret = yield call(editRequestManagerRequest, payload);
  
      if (ret && ret.status === 204) {
        const { history } = payload;
  
        if (history) history.push('/admin/request-managers')
        yield put(editRequestManagerSuccess({ request: ret.data.request }));
      }
  
  
      else yield put(editRequestManagerError({ msg: (ret.data) ? ret.data.msg : "Network Error" }));
  
    } catch (error) {
      yield put(editRequestManagerError({ error }));
    }
}
  

// DELETE ONE
const deleteOneRequestManagerRequest = async (payload) => {
    return await axios.delete(`${api.requestManager}/delete-one/${payload._id}`)
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneRequestManager({ payload }) {
    try {
      const ret = yield call(deleteOneRequestManagerRequest, payload);
  
      console.log('RET', ret);
  
  
      if (ret && ret.status === 202) {
        const { history, index } = payload;
  
        if (history) history.push('/admin/request-managers')
        yield put(deleteOneRequestManagerSuccess({ index }));
      }
      else yield put(deleteOneRequestManagerError({ message: (ret.data) ? ret.data.msg : "Network Error" }))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneRequestManagerError({ error, message: (error) ? error.message : '' }));
    }
}
  
  

/////// WATCHERS
export function* watchGetList() {
    yield takeEvery(REQUEST_MANAGER_GET_LIST, getRequestsManagerList);
}
  
export function* wathcAddItem() {
    yield takeEvery(REQUEST_MANAGER_ADD, addRequestManager);
}


export function* watchGetItem() {
    yield takeEvery(REQUEST_MANAGER_GET_ITEM, getRequestManager);
}

export function* wathcEditItem() {
    yield takeEvery(REQUEST_MANAGER_EDIT_ONE, editRequestManager);
}
  
export function* watchDeleteOne() {
    yield takeEvery(REQUEST_MANAGER_DELETE_ONE, deleteOneRequestManager);
}

export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(wathcAddItem),
      fork(watchGetItem),
      fork(wathcEditItem),
      fork(watchDeleteOne)
    ]);
  }
  