import {
  GET_PARTNER_CONTACTS_LIST,
  GET_PARTNER_CONTACTS_LIST_SUCCESS,
  GET_PARTNER_CONTACTS_LIST_ERROR,
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';



const INIT_STATE = {
  contacts: [],
  teachers: [],
  students: [],
  partnerInstitution: null,
  contact_error: '',
  contact_loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PARTNER_CONTACTS_LIST:
      return {
        ...state, contact_loading: true, contact_error: '',
        count: 0,
        contacts: [],
        teachers: [],
        students: [],
      };
    case GET_PARTNER_CONTACTS_LIST_SUCCESS:

      return {

        ...state, contact_loading: false,
        contacts: action.payload.contacts || [],
        teachers: action.payload.teachers || [],
        students: action.payload.students || [],
        partnerInstitution: action.payload.partnerInstitution,
        count: action.payload.count || 0
      };
    case GET_PARTNER_CONTACTS_LIST_ERROR:
      return { ...state, contact_loading: false, contact_error: action.payload.message };




    default: return { ...state };
  }
}
