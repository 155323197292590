// const { default: axiosApi } = require("helpers/axiosConfig")

import { api } from "../constants/defaultValues";
import axiosApi from "../helpers/axiosConfig";

const UserSrv = {

  // ================= EDIT  PROFILE
  editUser: (payload) => {

    return new Promise((resolve, reject) => {
      return axiosApi.patch(api.users + `/edit-one/${payload._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })

  },
  editPasswordUser: (payload) => {

    return new Promise((resolve, reject) => {
      return axiosApi.patch(api.users + `/edit-one/password/${payload._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })

  },
  // ================= FORGET PASSWORD  PROFILE
  forgetPassword: (payload) => {

    return new Promise((resolve, reject) => {
      return axiosApi.post(api.users + `/forgot-password`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })

  },
  verifCode: (payload) => {

    return new Promise((resolve, reject) => {
      return axiosApi.post(api.users + `/reset-password/verify-code`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  resetPassword: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(api.users + `/reset-password`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  }
}

export default UserSrv