import {
  COURSE_PROGRESS_GET_LIST,
  COURSE_PROGRESS_GET_LIST_SUCCESS,
  COURSE_PROGRESS_GET_LIST_ERROR,



  COURSE_PROGRESS_GET_ONE,
  COURSE_PROGRESS_GET_ONE_SUCCESS,
  COURSE_PROGRESS_GET_ONE_ERROR,

 

  COURSE_PROGRESS_EDIT_ONE,
  COURSE_PROGRESS_EDIT_ONE_SUCCESS,
  COURSE_PROGRESS_EDIT_ONE_ERROR,


} from '../actions';
import { api } from "../../constants/defaultValues";
import axios from "axios";
// GET LIST
export const getCoursesProgessList = (payload) => ({
  type: COURSE_PROGRESS_GET_LIST,
  payload
});

export const getCoursesProgessListSuccess = (payload) => ({
  type: COURSE_PROGRESS_GET_LIST_SUCCESS,
  payload
});
export const getCoursesProgessListError = (payload) => ({
  type: COURSE_PROGRESS_GET_LIST_ERROR,
  payload
});







// GET ONE
// export const getOneCourseProgess = (payload) => ({
//   type: COURSE_PROGRESS_GET_ONE,
//   payload
// });
// export const getOneCourseProgessSuccess = (payload) => ({
//   type: COURSE_PROGRESS_GET_ONE_SUCCESS,
//   payload
// });
// export const getOneCourseProgessError = (payload) => ({
//   type: COURSE_PROGRESS_GET_ONE_ERROR,
//   payload
// });


export const getOneCourseProgess = (payload, callback, callbackError) => {
  return (dispatch) => {
    dispatch({
      type: COURSE_PROGRESS_GET_ONE,
    });

    axios.get(api.courses + `/file-progress/${payload?._id}?partner=${payload?.partner}&course=${payload?.course}`)
      .then((response) => {
        if (response && response?.status === 200 && response.data) {
          const resp = {
            fileProgress: response.data.fileProgress
          }
          dispatch({
            type: COURSE_PROGRESS_GET_ONE_SUCCESS,
            payload: resp,
          });
          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: (response.data ? response.data.msg : "Network Error") });
        }
      })
      .catch(e => {
        dispatch({
          type: COURSE_PROGRESS_GET_ONE_ERROR,
          payload: { error: e }
        });
        if (callbackError) callbackError(e.data ? e.data?.msg : "Network Error");
      });
  };
};


// EDIT ONE
export const editCourseProgess = (payload) => ({
  type: COURSE_PROGRESS_EDIT_ONE,
  payload
});
export const editCourseProgessSuccess = (payload) => ({
  type: COURSE_PROGRESS_EDIT_ONE_SUCCESS,
  payload
});
export const editCourseProgessError = (payload) => ({
  type: COURSE_PROGRESS_EDIT_ONE_ERROR,
  payload
});

