import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
    NOTIFICATION_GET_LIST, NOTIFICATION_GET_ONE,
} from './actionTypes'

import {
    getNotificationsListSuccess,
    getNotificationsListError,
  
    getOneNotificationSuccess,
    getOneNotificationError,
  
} from "./actions";

//GET LIST
const getNotificationsListRequest = async (payload) => {
    return await axios.get(api.notifications + `/list/${payload.offset}/${payload.limit}`, {
      headers: { Authorization: payload.token },
    })
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
  };
function* getNotificationsList({ payload }) {
    try {
      const ret = yield call(getNotificationsListRequest, payload);
  
      if (ret && ret.status === 200)
        yield put(getNotificationsListSuccess({ notifications: ret.data.notifications || [], count: ret.data.count || 0 }));
  
      else yield put(getNotificationsListError((ret.data) ? ret.data.msg : "Network Error"));
  
    } catch (error) {
      yield put(getNotificationsListError(error));
    }
}
  


//GET ONE
const getNotificationRequest = async (payload) => {
    return await axios.get(api.notifications + `/get-one/${payload._id}`)
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
  };
function* getNotification({ payload }) {
    try {
      const ret = yield call(getNotificationRequest, payload);
  
      if (ret && ret.status === 200)
        yield put(getOneNotificationSuccess({ notification: ret.data.notification }));
  
      else yield put(getOneNotificationError({ msg: (ret.data) ? ret.data.msg : "Network Error" }));
  
    } catch (error) {
      yield put(getOneNotificationError({ error }));
    }
}
  
//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(NOTIFICATION_GET_LIST, getNotificationsList);
  }


export function* watchGetItem() {
    yield takeEvery(NOTIFICATION_GET_ONE, getNotification);
}

export default function* rootSaga() {
    yield all([
      fork(watchGetList), fork(watchGetItem)])
}

  