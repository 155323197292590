import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  GROUP_GET_LIST, GROUP_SUBSCRIBE, GROUP_GET_PARTNER_NETWORK, GROUP_ADD_ONE, GROUP_GET_ONE, GROUP_EDIT_ONE, GROUP_DELETE_ONE, GROUP_GET_JOINED, GROUP_GET_MY_LIST, GROUP_GET_MY_LIST_SUCCESS, GROUP_GET_MY_LIST_ERROR
} from "../actions";

import {
  subscribeGroupSuccess,
  subscribeGroupError,

  getGroupsListSuccess,
  getGroupsListError,

  getMyGroupsListSuccess,
  getMyGroupsListError,

  getOneGroupSuccess,
  getOneGroupError,

  addGroupSuccess,
  addGroupError,

  editGroupSuccess,
  editGroupError,
  deleteOneGroupSuccess,
  deleteOneGroupError,
} from "./actions";


//GET LIST
const getGroupsListRequest = async (payload) => {
  return axios.get(api.groups + `/list/${payload.inst_id ? payload.inst_id + '/' : ''}${payload.offset}/${payload.limit}?searchInput=${payload.searchInput || ''}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getGroupsList({ payload }) {
  try {
    const ret = yield call(getGroupsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getGroupsListSuccess({ groups: ret.data.groups || [], count: ret.data.count || 0 }));

    else yield put(getGroupsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getGroupsListError({ error, message: (error) ? error.message : '' }));
  }
}
//GET MY LIST
const getMyGroupsListRequest = async (payload) => {
  const { admin, institution } = payload;
  return axios.get(api.groups + `/me/${payload.offset}/${payload.limit}?admin=${admin || ''}&institution=${institution || ''}&searchInput=${payload.searchInput || ''}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getMyGroupsList({ payload }) {
  try {
    const ret = yield call(getMyGroupsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getMyGroupsListSuccess({ groups: ret.data.groups || [], count: ret.data.count || 0 }));

    else yield put(getMyGroupsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getGroupsListError({ error, message: (error) ? error.message : '' }));
  }
}



//PARTNER NETWORK GROUPS
const getGroupsListPartnerRequest = async (payload) => {

  return await axios.get(api.groups + `/partner-network/${payload.partner_id}/${payload.offset}/${payload.limit}?searchInput=${payload.searchInput || ''}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getGroupsListPartner({ payload }) {
  try {
    const ret = yield call(getGroupsListPartnerRequest, payload);

    if (ret && ret.status === 200)
      yield put(getGroupsListSuccess({ groups: ret.data.groups || [], count: ret.data.count || 0 }));

    else yield put(getGroupsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getGroupsListError({ error, message: (error) ? error.message : '' }));
  }
}
//GET JOINED GROUPS
const getJoinedGroupsRequest = async (payload) => {
  return axios.get(api.groups + `/joined/${payload.partner_id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getJoinedGroups({ payload }) {
  try {
    const ret = yield call(getJoinedGroupsRequest, payload);

    if (ret && ret.status === 200)
      yield put(getGroupsListSuccess({ groups: ret.data.groups || [], count: ret.data.count || 0 }));

    else yield put(getGroupsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getGroupsListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOneGroupsRequest = async (payload) => {
  return axios.get(api.groups + `/get-one/${payload._id}?partner=${payload.partner}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneGroup({ payload }) {
  try {
    const ret = yield call(getOneGroupsRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneGroupSuccess({ group: ret.data.group }));

    else yield put(getOneGroupError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneGroupError({ error, message: (error) ? error.message : '' }));
  }
}



//SUBSCRIBE
const subscribeGroupRequest = async (payload) => {
  return axios.post(api.groups + `/subscribe/${payload.group_id}`, {
    partner: payload.partner
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* subscribeGroup({ payload }) {
  try {
    const ret = yield call(subscribeGroupRequest, payload);

    if (ret && ret.status === 200) {
      const groups = payload.groups
      const group = ret.data.group
      if (groups && groups[payload.index]) groups[payload.index] = group
      yield put(subscribeGroupSuccess({ index: payload.index, groups, group }));
    }

    else yield put(subscribeGroupError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(subscribeGroupError({ error, message: (error) ? error.message : '' }));
  }
}




//ADD ONE
const addGroupRequest = async (payload) => {
  const data = payload.data

  const frm = new FormData();
  frm.append("name", data.name)
  frm.append("cover", data.cover)
  frm.append("theme", data.theme)
  frm.append("admin", data.admin)
  frm.append("description", data.description)
  frm.append("rules", data.rules)
  frm.append("isPublic", data.isPublic)
  frm.append("allowAddSubscribers", data.allowAddSubscribers)

  return axios.post(api.groups, frm, {
    headers: {
      Authorization: payload.token, 'Content-Type': 'multipart/form-data',
    }
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addGroup({ payload }) {
  try {
    const ret = yield call(addGroupRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;
      history && history.goBack();
      yield put(addGroupSuccess({ group: ret.data.group }));
    }
    else yield put(addGroupError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addGroupError({ error, message: (error) ? error.message : '' }));
  }
}

//EDIT ONE
const editOneGroupRequest = async (payload) => {
  const data = payload.data
  if (data.cover) {
    const frm = new FormData();
    payload.name && frm.append("name", data.name)
    payload.cover && frm.append("cover", data.cover)
    payload.theme && frm.append("theme", data.theme)
    payload.description && frm.append("description", data.description)
    payload.rules && frm.append("rules", data.rules)
    payload.isPublic && frm.append("isPublic", data.isPublic)
    payload.allowAddSubscribers && frm.append("allowAddSubscribers", data.allowAddSubscribers)

  }
  const frm = {
    subscriber: data.subscriber
  }

  return axios.patch(`${api.groups}/edit-one/${payload._id}?partner=${data.partner}`, frm)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneGroup({ payload }) {
  try {
    const ret = yield call(editOneGroupRequest, payload);

    if (ret && ret.status === 200) {
      const { history, pathname } = payload;
      if (history) history.replace(pathname);
      yield put(editGroupSuccess({ group: ret.data.group }));
    }
    else yield put(editGroupError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editGroupError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneGroupRequest = async (payload) => {
  return axios.delete(`${api.groups}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneGroup({ payload }) {
  try {
    const ret = yield call(deleteOneGroupRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/my-institutions/groups')
      yield put(deleteOneGroupSuccess({ index }));
    }
    else yield put(deleteOneGroupError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneGroupError({ error, message: (error) ? error.message : '' }));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(GROUP_GET_LIST, getGroupsList);
}
export function* watchGetMyList() {
  yield takeEvery(GROUP_GET_MY_LIST, getMyGroupsList);
}

export function* watchSubscribe() {
  yield takeEvery(GROUP_SUBSCRIBE, subscribeGroup);
}

export function* watchGetListPartner() {
  yield takeEvery(GROUP_GET_PARTNER_NETWORK, getGroupsListPartner);
}
export function* watchGetJoinedGroups() {
  yield takeEvery(GROUP_GET_JOINED, getJoinedGroups);
}

export function* watchGetOne() {
  yield takeEvery(GROUP_GET_ONE, getOneGroup);
}

export function* watchEditOne() {
  yield takeEvery(GROUP_EDIT_ONE, editOneGroup);
}

export function* watchAddOne() {
  yield takeEvery(GROUP_ADD_ONE, addGroup);
}

export function* watchDeleteOne() {
  yield takeEvery(GROUP_DELETE_ONE, deleteOneGroup);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetMyList),
    fork(watchSubscribe),
    fork(watchGetListPartner),
    fork(watchGetJoinedGroups),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
