import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  STUDENT_GET_LIST, STUDENT_ADD_ONE, STUDENT_GET_ONE, STUDENT_GET_BY_FILTER, STUDENT_EDIT_ONE, STUDENT_DELETE_ONE, GET_STUDENT_ASSIGNMENTS,
  STUDENT_ASSIGNMENT, STUDENTS_DELETE_MANY, SCHOOL_STUDENTS_GET_LIST
} from "../actions";

import {
  getStudentsListSuccess,
  getStudentsListError,

  getOneStudentSuccess,
  getOneStudentError,

  getStudentByFilterSuccess,
  getStudentByFilterError,

  addStudentSuccess,
  addStudentError,

  editStudentSuccess,
  editStudentError,
  deleteOneStudentSuccess,
  deleteOneStudentError,

  getStudentAssignmentSuccess,
  getStudentAssignmentError,

  studentAssignmentSuccess,
  studentAssignmentError,

  deleteManyStudentsSuccess,
  deleteManyStudentsError,
  getSchoolStudentsListSuccess,
  getSchoolStudentsListError,


} from "./actions";


//GET LIST
const getStudentsListRequest = async (payload) => {
  return await axios.get(api.students + `/list/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getStudentsList({ payload }) {
  try {
    const ret = yield call(getStudentsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getStudentsListSuccess({ students: ret.data.students || [], count: ret.data.count || 0 }));

    else yield put(getStudentsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getStudentsListError({ error, message: (error) ? error.message : '' }));
  }
}

//GET STUDENT ASSIGNMENTS
const getStudentAssignmentsRequest = async (payload) => {
  return await axios.get(api.assignments + `/student-assignments/${payload.student_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getStudentAssignments({ payload }) {
  try {
    const ret = yield call(getStudentAssignmentsRequest, payload);

    if (ret && ret.status === 200)
      yield put(getStudentAssignmentSuccess({ student_assignments: ret.data.assignments || [], count: ret.data.count || 0 }));

    else yield put(getStudentAssignmentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getStudentAssignmentError({ error, message: (error) ? error.message : '' }));
  }
}

// STUDENT ASSIGNMENT
const studentAssignmentRequest = async (payload) => {
  return await axios.post(api.assignments, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* studentAssignment({ payload }) {
  try {
    const ret = yield call(studentAssignmentRequest, payload);
    if (ret && ret.status === 201)
      yield put(studentAssignmentSuccess({ student_assignment: ret.data.student_assignment }));

    else yield put(studentAssignmentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(studentAssignmentError({ error, message: (error) ? error.message : '' }));
  }
}

//GET SCHOOL STUDENTS LIST
const getSchoolStudentsListRequest = async (payload) => {
  return axios.get(api.students + `/list/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* getSchoolStudentsList({ payload }) {
  try {
    const ret = yield call(getSchoolStudentsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getSchoolStudentsListSuccess({ schoolStudents: ret.data.students || [], count: ret.data.count || 0, clear: payload.clear }));

    else yield put(getSchoolStudentsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getSchoolStudentsListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOneStudentsRequest = async (payload) => {
  return await axios.get(api.students + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneStudent({ payload }) {
  try {
    const ret = yield call(getOneStudentsRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneStudentSuccess({ student: ret.data.student }));

    else yield put(getOneStudentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneStudentError({ error, message: (error) ? error.message : '' }));
  }
}

//GET STUDENT BY FILTER
const getStudentByFilterRequest = async (payload) => {
  return await axios.post(api.students + `/filters/${payload.inst_id}/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getStudentByFilter({ payload }) {

  try {
    const ret = yield call(getStudentByFilterRequest, payload);


    if (ret && ret.status === 200)
      yield put(getStudentByFilterSuccess({ students: ret.data.students, count: ret.data.count }));

    else yield put(getStudentByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getStudentByFilterError({ error, message: (error) ? error.message : '' }));
  }
}


//ADD ONE
const addStudentRequest = async (payload) => {
  return await axios.post(api.students, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addStudent({ payload }) {
  try {
    const ret = yield call(addStudentRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/schooling/students')
      yield put(addStudentSuccess({ student: ret.data.student }));
    }
    else yield put(addStudentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addStudentError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneStudentRequest = async (payload) => {
  return await axios.patch(`${api.students}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneStudent({ payload }) {
  try {
    const ret = yield call(editOneStudentRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/schooling/students')
      yield put(editStudentSuccess({ student: ret.data.student }));
    }
    else yield put(editStudentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editStudentError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneStudentRequest = async (payload) => {
  return await axios.delete(`${api.students}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneStudent({ payload }) {
  try {
    const ret = yield call(deleteOneStudentRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/schooling/students')
      yield put(deleteOneStudentSuccess({ index }));
    }
    else yield put(deleteOneStudentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneStudentError({ error, message: (error) ? error.message : '' }));
  }
}

const deleteManyStudentsRequest = async (payload) => {
  return await axios.delete(`${api.students}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyStudents({ payload }) {
  try {
    const ret = yield call(deleteManyStudentsRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/app/schooling/students')
      yield put(deleteManyStudentsSuccess({ data }));
    }
    else yield put(deleteManyStudentsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyStudentsError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(STUDENT_GET_LIST, getStudentsList);
}

export function* watchGetStudentAssignments() {
  yield takeEvery(GET_STUDENT_ASSIGNMENTS, getStudentAssignments);
}

export function* watchStudentAssignment() {
  yield takeEvery(STUDENT_ASSIGNMENT, studentAssignment);
}

export function* watchGetSchoolStudentsList() {
  yield takeEvery(SCHOOL_STUDENTS_GET_LIST, getSchoolStudentsList);
}

export function* watchGetOne() {
  yield takeEvery(STUDENT_GET_ONE, getOneStudent);
}
export function* watchGetFilter() {
  yield takeEvery(STUDENT_GET_BY_FILTER, getStudentByFilter);
}
export function* watchEditOne() {
  yield takeEvery(STUDENT_EDIT_ONE, editOneStudent);
}

export function* watchAddOne() {
  yield takeEvery(STUDENT_ADD_ONE, addStudent);
}

export function* watchDeleteOne() {
  yield takeEvery(STUDENT_DELETE_ONE, deleteOneStudent);
}
export function* watchDeleteMany() {
  yield takeEvery(STUDENTS_DELETE_MANY, deleteManyStudents);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetFilter),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchGetStudentAssignments),
    fork(watchStudentAssignment),
    fork(watchDeleteMany),
    fork(watchGetSchoolStudentsList),

  ]);
}
