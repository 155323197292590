import EducationLevelSrv from '../../services/EducationLevelSrv';
import {
  EDUCATIONLEVEL_GET_LIST,
  EDUCATIONLEVEL_GET_LIST_SUCCESS,
  EDUCATIONLEVEL_GET_LIST_ERROR,

  EDUCATIONLEVEL_GET_ONE,
  EDUCATIONLEVEL_GET_ONE_SUCCESS,
  EDUCATIONLEVEL_GET_ONE_ERROR,

  EDUCATIONLEVEL_GET_BY_FILTER,
  EDUCATIONLEVEL_GET_BY_FILTER_SUCCESS,
  EDUCATIONLEVEL_GET_BY_FILTER_ERROR,

  EDUCATIONLEVEL_ADD_ONE,
  EDUCATIONLEVEL_ADD_ONE_SUCCESS,
  EDUCATIONLEVEL_ADD_ONE_ERROR,

  EDUCATIONLEVEL_EDIT_ONE,
  EDUCATIONLEVEL_EDIT_ONE_SUCCESS,
  EDUCATIONLEVEL_EDIT_ONE_ERROR,

  EDUCATIONLEVEL_DELETE_ONE,
  EDUCATIONLEVEL_DELETE_ONE_SUCCESS,
  EDUCATIONLEVEL_DELETE_ONE_ERROR,

  EDUCATIONLEVELS_DELETE_MANY,
  EDUCATIONLEVELS_DELETE_MANY_SUCCESS,
  EDUCATIONLEVELS_DELETE_MANY_ERROR

} from '../actions';

// GET LIST
export const getEducationLevelsList = (payload) => ({
  type: EDUCATIONLEVEL_GET_LIST,
  payload
});
export const getEducationLevelsListSuccess = (payload) => ({
  type: EDUCATIONLEVEL_GET_LIST_SUCCESS,
  payload
});
export const getEducationLevelsListError = (payload) => ({
  type: EDUCATIONLEVEL_GET_LIST_ERROR,
  payload
});







// GET LIST
export const getOneEducationLevel = (payload) => ({
  type: EDUCATIONLEVEL_GET_ONE,
  payload
});
export const getOneEducationLevelSuccess = (payload) => ({
  type: EDUCATIONLEVEL_GET_ONE_SUCCESS,
  payload
});
export const getOneEducationLevelError = (payload) => ({
  type: EDUCATIONLEVEL_GET_ONE_ERROR,
  payload
});



// GET BY FILTER

export const getEducationLevelsByFilter = (payload) => ({
  type: EDUCATIONLEVEL_GET_BY_FILTER,
  payload
});
export const getEducationLevelsByFilterSuccess = (payload) => ({
  type: EDUCATIONLEVEL_GET_BY_FILTER_SUCCESS,
  payload
});
export const getEducationLevelsByFilterError = (payload) => ({
  type: EDUCATIONLEVEL_GET_BY_FILTER_ERROR,
  payload
});
export const getEducationLevelsByFilterCB = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: EDUCATIONLEVEL_GET_BY_FILTER,
    });

    EducationLevelSrv.getList(payload)
      .then((response) => {
        if (response && response?.data) {
          const resp = {
            education_levels: response?.data?.education_levels,
            count: response.data.count
          }

          dispatch({
            type: EDUCATIONLEVEL_GET_BY_FILTER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: EDUCATIONLEVEL_GET_BY_FILTER_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};





// ADD ONE
export const addEducationLevel = (payload) => ({
  type: EDUCATIONLEVEL_ADD_ONE,
  payload
});
export const addEducationLevelSuccess = (payload) => ({
  type: EDUCATIONLEVEL_ADD_ONE_SUCCESS,
  payload
});
export const addEducationLevelError = (payload) => ({
  type: EDUCATIONLEVEL_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editEducationLevel = (payload) => ({
  type: EDUCATIONLEVEL_EDIT_ONE,
  payload
});
export const editEducationLevelSuccess = (payload) => ({
  type: EDUCATIONLEVEL_EDIT_ONE_SUCCESS,
  payload
});
export const editEducationLevelError = (payload) => ({
  type: EDUCATIONLEVEL_EDIT_ONE_ERROR,
  payload
});




// DELELE ONE
export const deleteOneEducationLevel = (payload) => ({
  type: EDUCATIONLEVEL_DELETE_ONE,
  payload
});
export const deleteOneEducationLevelSuccess = (payload) => ({
  type: EDUCATIONLEVEL_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneEducationLevelError = (payload) => ({
  type: EDUCATIONLEVEL_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyEducationLevels = (payload) => ({
  type: EDUCATIONLEVELS_DELETE_MANY,
  payload
});
export const deleteManyEducationLevelsSuccess = (payload) => ({
  type: EDUCATIONLEVELS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyEducationLevelsError = (payload) => ({
  type: EDUCATIONLEVELS_DELETE_MANY_ERROR,
  payload
});