import {
  EXAMNOTES_GET_LIST,
  EXAMNOTES_GET_LIST_SUCCESS,
  EXAMNOTES_GET_LIST_ERROR,

  EXAMNOTES_GET_ONE,
  EXAMNOTES_GET_ONE_SUCCESS,
  EXAMNOTES_GET_ONE_ERROR,

  EXAMNOTES_GET_BY_FILTER,
  EXAMNOTES_GET_BY_FILTER_SUCCESS,
  EXAMNOTES_GET_BY_FILTER_ERROR,

  EXAMNOTES_ADD_ONE,
  EXAMNOTES_ADD_ONE_SUCCESS,
  EXAMNOTES_ADD_ONE_ERROR,

  EXAMNOTES_EDIT_ONE,
  EXAMNOTES_EDIT_ONE_SUCCESS,
  EXAMNOTES_EDIT_ONE_ERROR,

  EXAMNOTES_DELETE_ONE,
  EXAMNOTES_DELETE_ONE_SUCCESS,
  EXAMNOTES_DELETE_ONE_ERROR,

  EXAMTYPES_GET_BY_FILTER_SUBJECT,
  EXAMTYPES_GET_BY_FILTER_SUBJECT_SUCCESS,
  EXAMTYPES_GET_BY_FILTER_SUBJECT_ERROR,

  EXAMNOTES_DELETE_MANY,
  EXAMNOTES_DELETE_MANY_SUCCESS,
  EXAMNOTES_DELETE_MANY_ERROR,


} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'


const INIT_STATE = {
  exam_notes: [],
  count: 0,
  exam_note: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

  switch (action.type) {

    //GET LIST
    case EXAMNOTES_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case EXAMNOTES_GET_LIST_SUCCESS:

      return {
        ...state, loading: false,
        exam_notes: action.payload.exam_notes,
        count: action.payload.count || 0

      };

    case EXAMNOTES_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };




    // GET ONE
    case EXAMNOTES_GET_ONE:
      return { ...state, loading: true, error: '' };
    case EXAMNOTES_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        exam_note: action.payload.exam_note
      };
    case EXAMNOTES_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET BY FILTER
    case EXAMNOTES_GET_BY_FILTER:

      return { ...state, loading: true, error: '' };
    case EXAMNOTES_GET_BY_FILTER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        exam_notes: action.payload.exam_notes || [], count: action.payload.count || 0
      };
    case EXAMNOTES_GET_BY_FILTER_ERROR:

      return { ...state, loading: false, error: action.payload.message };
    // GET BY FILTER SUBJECT
    case EXAMTYPES_GET_BY_FILTER_SUBJECT:
      return { ...state, loading: true, error: '' }
    case EXAMTYPES_GET_BY_FILTER_SUBJECT_SUCCESS:
      return {
        ...state, loading: false, error: '',
        exam_types: action.payload.exam_types || [],
      };
    case EXAMTYPES_GET_BY_FILTER_SUBJECT_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    // ADD ONE
    case EXAMNOTES_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case EXAMNOTES_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');

      return {
        ...state, loading: false,
        exam_notes: [action.payload.exam_note, ...state.exam_notes]
      };
    case EXAMNOTES_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



    // EDIT ONE
    case EXAMNOTES_EDIT_ONE:
      return { ...state, loading: true, edit_sucess: false, error: '' };
    case EXAMNOTES_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, edit_sucess: true, error: '',
        exam_note: action.payload.exam_note
      };
    case EXAMNOTES_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, edit_sucess: false, error: action.payload.message };


    // DELETE ONE
    case EXAMNOTES_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case EXAMNOTES_DELETE_ONE_SUCCESS:
      const exam_notes = state.exam_notes
      if (action.payload.index !== null && action.payload.index !== undefined)
        exam_notes.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        exam_notes
      };
    case EXAMNOTES_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // DELETE MANY
    case EXAMNOTES_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case EXAMNOTES_DELETE_MANY_SUCCESS:
      const exam_notes_list = state.exam_notes


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_exam_note = -1
          for (let j = 0; j < exam_notes_list.length; j++) {
            if (exam_notes_list[j]._id === action.payload.data.ids[i]) {
              index_exam_note = j; break;
            }
          }
          if (index_exam_note > -1) exam_notes_list.splice(index_exam_note, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        exam_notes: exam_notes_list
      };
    case EXAMNOTES_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
    default: return { ...state };

  }
}