// const { default: axiosApi } = require("helpers/axiosConfig")

import { api } from "../constants/defaultValues";
import axiosApi from "../helpers/axiosConfig";
import { filtersLink } from "../helpers/Utils";

const ExamSrv = {
  getList: (payload) => {
    return new Promise((resolve, reject) => {
      const new_filters = []
      try {
        for (const key in payload.filter) {
          if (Object.hasOwnProperty.call(payload.filter, key) && !(key === "name" && payload.filter[key] === "")) {
            // new_filters[key] = payload.filter[key]
            new_filters.push({ [key]: payload.filter[key] })
          }
        }
      } catch (error) { }

      return axiosApi.post(
        `${api.exam_types}/filter/${payload.inst_id}/${payload.offset}/${payload.limit}?${filtersLink(payload?.params || {})}`,
        new_filters
      )
        .then(resp => { resolve(resp); })
        .catch(e => reject(e))
    })
  },
}

export default ExamSrv