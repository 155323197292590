import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
    SIGNALS_GET_LIST, SIGNALS_GET_ITEM, SIGNALS_DELETE_ONE, SIGNALS_ADD
  } from "./actionTypes";
  


  import {
    getSignalSuccess,
    getSignalError,
  
    getSignalsListSuccess,
    getSignalsListError,

    addSignalSuccess,
    addSignalError,

    deleteOneSignalSuccess,
    deleteOneSignalError
  } from "./actions";


//GET LIST
const getSignalsListRequest = async (payload) => {
    return await axios.get(api.signals + `/list/${payload.offset}/${payload.limit}`,{headers: { Authorization: payload.token }})
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
  };

function* getSignalsList({ payload }) {
    try {
      const ret = yield call(getSignalsListRequest, payload);
  
      if (ret && ret.status === 200)
        yield put(getSignalsListSuccess({ signals: ret.data.signals || [], count: ret.data.count || 0 }));
  
      else yield put(getSignalsListError((ret.data) ? ret.data.msg : "Network Error"));
  
    } catch (error) {
      yield put(getSignalsListError(error));
    }
  }
  
  


//GET ONE
const getSignalRequest = async (payload) => {
    return await axios.get(api.signals + `/get-one/${payload._id}`,{headers: { Authorization: payload.token }})
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
  };
  function* getSignal({ payload }) {
    try {
      const ret = yield call(getSignalRequest, payload);
  
      if (ret && ret.status === 200)
        yield put(getSignalSuccess({ signal: ret.data.signal }));
  
      else yield put(getSignalError({ msg: (ret.data) ? ret.data.msg : "Network Error" }));
  
    } catch (error) {
      yield put(getSignalError({ error }));
    }
  }
 

//ADD ONE
const addSignalRequest = async (payload) => {
    return await axios.post(api.signals, payload.data,{headers: { Authorization: payload.token }})
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
  };
function* addSignal({ payload }) {
    try {
      const ret = yield call(addSignalRequest, payload);
  
      if (ret && ret.status == 201) yield put(addSignalSuccess({ signal: ret.data.signal }));
  
      else yield put(addSignalError({
        error: { message: (ret.data) ? ret.data.msg : "Network Error" }
      }));
    } catch (error) {
      yield put(addSignalError({ error }));
    }
}
  


// DELETE ONE
const deleteOneSignalRequest = async (payload) => {
    return await axios.delete(`${api.signals}/delete-one/${payload._id}`,{headers: { Authorization: payload.token }})
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneSignal({ payload }) {
    try {
      const ret = yield call(deleteOneSignalRequest, payload);
  
      console.log('RET', ret);
  
  
      if (ret && ret.status === 202) {
        const { history, index } = payload;
  
        if (history) history.push('/admin/signals')
        yield put(deleteOneSignalSuccess({ index }));
      }
      else yield put(deleteOneSignalError({ message: (ret.data) ? ret.data.msg : "Network Error" }))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneSignalError({ error, message: (error) ? error.message : '' }));
    }
}
  
  

/////// WATCHERS
export function* watchGetList() {
    yield takeEvery(SIGNALS_GET_LIST, getSignalsList);
}

export function* wathcAddItem() {
    yield takeEvery(SIGNALS_ADD, addSignal);
}

export function* watchGetItem() {
    yield takeEvery(SIGNALS_GET_ITEM, getSignal);
}

export function* watchDeleteOne() {
    yield takeEvery(SIGNALS_DELETE_ONE, deleteOneSignal);
}

export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(wathcAddItem),
      fork(watchGetItem),
      fork(watchDeleteOne)
    ]);
  }
  