import {
  PARTNER_SELECT_ONE,
  POST_PARTNER_SELECT_ONE,
  PARTNER_CHANGE_SECTION,

  PARTNER_GET_USER_PARTNER_LIST,
  PARTNER_GET_USER_PARTNER_LIST_SUCCESS,
  PARTNER_GET_USER_PARTNER_LIST_ERROR,

  PARTNER_GET_LIST,
  PARTNER_GET_LIST_SUCCESS,
  PARTNER_GET_LIST_ERROR,

  PARTNER_GET_ONE,
  PARTNER_GET_ONE_SUCCESS,
  PARTNER_GET_ONE_ERROR,

  PARTNER_ADD_ONE,
  PARTNER_ADD_ONE_SUCCESS,
  PARTNER_ADD_ONE_ERROR,

  PARTNER_EDIT_ONE,
  PARTNER_EDIT_ONE_SUCCESS,
  PARTNER_EDIT_ONE_ERROR,

  PARTNER_DELETE_ONE,
  PARTNER_DELETE_ONE_SUCCESS,
  PARTNER_DELETE_ONE_ERROR,
  NO_PARTNER_SELECTED,

  GET_PARTNER_NETWORK,
  GET_PARTNER_NETWORK_SUCCESS,
  GET_PARTNER_NETWORK_ERROR

} from './actionTypes';

import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'
import { findAndReplace } from '../../helpers/Utils';
import { LOGOUT_USER } from '../auth/actionTypes';

let partner = null;
let post_partner = null;
let partner_inst = null;
let default_partner = null

try {

  partner = JSON.parse(localStorage.getItem('partner'))
  post_partner = JSON.parse(localStorage.getItem('post_partner'))
  partner_inst = JSON.parse(localStorage.getItem('partner_inst'))
  default_partner = JSON.parse(localStorage.getItem('default_partner'))

} catch (error) {
  partner = null;
}


const INIT_STATE = {
  partners: [],
  count: 0,
  filteredPartners: [],
  filteredPartnersCount: 0,
  partner,
  partner_inst,
  post_partner,
  default_partner,
  network:null,
  loading_network:false,
  error_network:'',
  error: '',
  loading: false,
  section: { name: '' }
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

  switch (action.type) {
    // GET USER PARTNERS LIST
    case PARTNER_GET_USER_PARTNER_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case PARTNER_GET_USER_PARTNER_LIST_SUCCESS:
      try {
        // console.log("PARTNER_GET_USER_PARTNER_LIST_SUCCESS : ",action.payload);

        let sorted_partners = action.payload.partners || [];
        sorted_partners.sort((a, b) => {
          let keyA = a.partnertype,
            keyB = b.partnertype;
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });
        for (const partner of sorted_partners) {
          if (partner.partnertype === 'Parent' && partner.students.length > 0) {
            sorted_partners = [...sorted_partners, ...partner.students]
          }
        }

        let data = {
          ...state, loading: false,
          partners: [...sorted_partners],
          count: action.payload.count || 0,
          post_partner:null,
          default_partner : sorted_partners.find(item => item.partnertype == "defaultPartner" ) || action.payload.partners[0]
        };

        // if (state.partner && JSON.stringify(state.partner) !== '{}') {
        //   data = {
        //     ...data,
        //     partner: sorted_partners.find(item => String(item._id) === String(state.partner._id)),
        //     post_partner: post_partner || sorted_partners.find(item => String(item._id) === String(state.partner._id))
        //   }
        // }
        localStorage.setItem("default_partner", JSON.stringify(data.default_partner))
        return data;
      } catch (error) {

      }
    case PARTNER_GET_USER_PARTNER_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    // GET LIST
    case PARTNER_GET_LIST:
      return {
        ...state, loading: true, error: '',
      };
    case PARTNER_GET_LIST_SUCCESS:
      // console.log("PARTNER_GET_LIST_SUCCESS : ",action.payload);
      const partner_list = action.payload.partners.filter(item  => item._id !== default_partner._id )
      return {
        ...state, loading: false,
        filteredPartners: action.payload.offset === 0 ? partner_list : [...state.filteredPartners, ...partner_list],
        filteredPartnersCount: action.payload.count || 0,
      };
    case PARTNER_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    // GET NETWORK
    case GET_PARTNER_NETWORK:
      return {
        ...state, loading_network: true, error_network: '',
      };
    case GET_PARTNER_NETWORK_SUCCESS:
      return {
        ...state, loading_network: false,network:action.payload.network
      };
    case GET_PARTNER_NETWORK_ERROR:
      return { ...state, loading_network: false, error_network: action.payload.message };

    // GET ONE
    case PARTNER_GET_ONE:
      return { ...state, loading: true, error: '' };
    case PARTNER_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        partner: action.payload.partner
      };
    case PARTNER_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    //SELECT PARTNER (to be global var)
    case PARTNER_SELECT_ONE:
      if (!action.payload) {
        localStorage.removeItem('partner')
        localStorage.removeItem('post_partner')
      }
      else {
        localStorage.setItem("partner", JSON.stringify(action.payload))
        localStorage.setItem("partner_inst", action.payload.institution ? JSON.stringify(action.payload.institution) : null)
      }
      return {
        ...state, partner: action.payload,
        //  post_partner: action.payload, 
         partner_inst: action.payload?.institution
      };

    case NO_PARTNER_SELECTED:
      return {
        ...state,
        partner: state.partners[0],
        post_partner: state.partners[0],
      }


    //SELECT POST PARTNER (to be global var)
    case POST_PARTNER_SELECT_ONE:

      if (!action.payload) localStorage.removeItem('post_partner')
      else {
        localStorage.setItem("post_partner", JSON.stringify(action.payload))
      }
      return {
        ...state, post_partner: action.payload
      };


    //CHANGE PARTNER SECTION (LIKE TIMETABLE)
    case PARTNER_CHANGE_SECTION:
      if (!action.payload) return { ...state, section: { name: '' } };
      return { ...state, section: action.payload };


    // ADD ONE
    case PARTNER_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case PARTNER_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        partners: [action.payload.partner, ...state.partners]
      };
    case PARTNER_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // EDIT ONE
    case PARTNER_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case PARTNER_EDIT_ONE_SUCCESS:
      localStorage.removeItem('partner')
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      localStorage.setItem("partner", JSON.stringify(action.payload.partner))
      const newPartners = findAndReplace(state.partners, action.payload.partner)
      return {
        ...state, loading: false, error: '',
        partner: action.payload.partner,
        partners: newPartners
      };
    case PARTNER_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // DELETE ONE
    case PARTNER_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case PARTNER_DELETE_ONE_SUCCESS:
      const partners = state.partners
      if (action.payload.index !== null && action.payload.index !== undefined)
        partners.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        partners
      };
    case PARTNER_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
    case LOGOUT_USER:
      return {
        ...state,
        partners: [],
        count: 0,
        partner: null,
        partner_inst: null,
        post_partner: null,
        default_partner: null
      }
    default: return { ...state };
  }
}
