import {
  JOB_GET_LIST,
  JOB_GET_LIST_SUCCESS,
  JOB_GET_LIST_ERROR,

  JOB_GET_PARTNER_NETWORK,

  JOB_SUBSCRIBE,
  JOB_SUBSCRIBE_SUCCESS,
  JOB_SUBSCRIBE_ERROR,

  JOB_GET_ONE,
  JOB_GET_ONE_SUCCESS,
  JOB_GET_ONE_ERROR,

  JOB_ADD_ONE,
  JOB_ADD_ONE_SUCCESS,
  JOB_ADD_ONE_ERROR,

  JOB_EDIT_ONE,
  JOB_EDIT_ONE_SUCCESS,
  JOB_EDIT_ONE_ERROR,

  JOB_DELETE_ONE,
  JOB_DELETE_ONE_SUCCESS,
  JOB_DELETE_ONE_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'

const INIT_STATE = {
  jobs: [],
  count: 0,
  job: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

  switch (action.type) {
    case JOB_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case JOB_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        jobs: action.payload.jobs || [],
        count: action.payload.count || 0
      };
    case JOB_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };




    case JOB_GET_PARTNER_NETWORK:
      return {
        ...state, loading: true, error: '',
        count: 0
      };




    // GET ONE
    case JOB_GET_ONE:
      return { ...state, loading: true, error: '' };
    case JOB_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        job: action.payload.job
      };
    case JOB_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









    // ADD ONE
    case JOB_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case JOB_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');

      return {
        ...state, loading: false,
        jobs: [action.payload.job, ...state.jobs]
      };
    case JOB_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // EDIT ONE
    case JOB_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case JOB_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');

      const new_jobs = state.jobs
      const job = action.payload.job
      for (let i = 0; i < new_jobs.length; i++) {
        if (new_jobs[i]._id === job._id) {
          new_jobs[i] = job; break;
        }
      }

      return { ...state, loading: false, error: '', job, jobs: new_jobs };
    case JOB_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    // TOGGLE SUBSCRIBE
    case JOB_SUBSCRIBE:
      return { ...state, loading: true, error: '' };
    case JOB_SUBSCRIBE_SUCCESS:
      // NotificationManager.success("Subscribe", "Success",3000, null, null, '');
      const obj = { job: action.payload.job }
      if (action.payload.jobs) obj['jobs'] = action.payload.jobs
      return {
        ...state, loading: false, error: '',
        ...obj
      };
    case JOB_SUBSCRIBE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    // DELETE ONE
    case JOB_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case JOB_DELETE_ONE_SUCCESS:
      const jobs = state.jobs
      if (action.payload.index !== null && action.payload.index !== undefined)
        jobs.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        jobs
      };
    case JOB_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    default: return { ...state };
  }
}
