import {
  EVENT_GET_LIST,
  EVENT_GET_LIST_SUCCESS,
  EVENT_GET_LIST_ERROR,

  EVENT_GET_LIVE,
  EVENT_GET_LIVE_ERROR,
  EVENT_GET_LIVE_SUCCESS,

  EVENT_GET_PARTNER_NETWORK,

  EVENT_SUBSCRIBE,
  EVENT_SUBSCRIBE_SUCCESS,
  EVENT_SUBSCRIBE_ERROR,

  SUBSCRIPTION_ACCEPT,
  SUBSCRIPTION_ACCEPT_ERROR,
  SUBSCRIPTION_ACCEPT_SUCCESS,

  EVENT_GET_ONE,
  EVENT_GET_ONE_SUCCESS,
  EVENT_GET_ONE_ERROR,

  EVENT_ADD_ONE,
  EVENT_ADD_ONE_SUCCESS,
  EVENT_ADD_ONE_ERROR,

  EVENT_EDIT_ONE,
  EVENT_EDIT_ONE_SUCCESS,
  EVENT_EDIT_ONE_ERROR,

  EVENT_DELETE_ONE,
  EVENT_DELETE_ONE_SUCCESS,
  EVENT_DELETE_ONE_ERROR,
  EVENT_GET_SUBSCRIBED,
  EVENT_GET_MY_LIST,
  EVENT_GET_MY_LIST_SUCCESS,
  EVENT_GET_MY_LIST_ERROR
} from '../actions';

// GET LIST
export const getEventsList = (payload) => ({
  type: EVENT_GET_LIST,
  payload
});
export const getEventsListSuccess = (payload) => ({
  type: EVENT_GET_LIST_SUCCESS,
  payload
});
export const getEventsListError = (payload) => ({
  type: EVENT_GET_LIST_ERROR,
  payload
});


export const getMyEvents = (payload) => ({
  type: EVENT_GET_MY_LIST,
  payload
});
export const getMyEventsListSuccess = (payload) => ({
  type: EVENT_GET_MY_LIST_SUCCESS,
  payload
});
export const getMyEventsListError = (payload) => ({
  type: EVENT_GET_MY_LIST_ERROR,
  payload
});



// GET LIST
export const getEventsListPartner = (payload) => ({
  type: EVENT_GET_PARTNER_NETWORK,
  payload
});

export const getSubscribedEvents = (payload) => ({
  type: EVENT_GET_SUBSCRIBED,
  payload
});


// GET LIVE
export const getLiveEvents = (payload) => ({
  type: EVENT_GET_LIVE,
  payload
});
export const getLiveEventsSuccess = (payload) => ({
  type: EVENT_GET_LIVE_SUCCESS,
  payload
});
export const getLiveEventsError = (payload) => ({
  type: EVENT_GET_LIVE_ERROR,
  payload
});







// GET LIST
export const getOneEvent = (payload) => ({
  type: EVENT_GET_ONE,
  payload
});
export const getOneEventSuccess = (payload) => ({
  type: EVENT_GET_ONE_SUCCESS,
  payload
});
export const getOneEventError = (payload) => ({
  type: EVENT_GET_ONE_ERROR,
  payload
});


// TOGGLE SUBSCRIBE
export const subscribeEvent = (payload) => ({
  type: EVENT_SUBSCRIBE,
  payload
});
export const subscribeEventSuccess = (payload) => ({
  type: EVENT_SUBSCRIBE_SUCCESS,
  payload
});
export const subscribeEventError = (payload) => ({
  type: EVENT_SUBSCRIBE_ERROR,
  payload
});

// ACCEPT SUBSCRIPTION 

export const acceptSubscription = (payload) => ({
  type: SUBSCRIPTION_ACCEPT,
  payload
});
export const acceptSubscriptionSuccess = (payload) => ({
  type: SUBSCRIPTION_ACCEPT_SUCCESS,
  payload
});
export const acceptSubscriptionError = (payload) => ({
  type: SUBSCRIPTION_ACCEPT_ERROR,
  payload
});





// ADD ONE
export const addEvent = (payload) => ({
  type: EVENT_ADD_ONE,
  payload
});
export const addEventSuccess = (payload) => ({
  type: EVENT_ADD_ONE_SUCCESS,
  payload
});
export const addEventError = (payload) => ({
  type: EVENT_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editEvent = (payload) => ({
  type: EVENT_EDIT_ONE,
  payload
});
export const editEventSuccess = (payload) => ({
  type: EVENT_EDIT_ONE_SUCCESS,
  payload
});
export const editEventError = (payload) => ({
  type: EVENT_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneEvent = (payload) => ({
  type: EVENT_DELETE_ONE,
  payload
});
export const deleteOneEventSuccess = (payload) => ({
  type: EVENT_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneEventError = (payload) => ({
  type: EVENT_DELETE_ONE_ERROR,
  payload
});