import {
  CONFIGS_LEVEL_GET_LIST,
  CONFIGS_LEVEL_GET_LIST_SUCCESS,
  CONFIGS_LEVEL_GET_LIST_ERROR,

  CONFIGS_LEVEL_GET_ONE,
  CONFIGS_LEVEL_GET_ONE_SUCCESS,
  CONFIGS_LEVEL_GET_ONE_ERROR,

  CONFIGS_LEVEL_ADD_ONE,
  CONFIGS_LEVEL_ADD_ONE_SUCCESS,
  CONFIGS_LEVEL_ADD_ONE_ERROR,

  CONFIGS_LEVEL_EDIT_ONE,
  CONFIGS_LEVEL_EDIT_ONE_SUCCESS,
  CONFIGS_LEVEL_EDIT_ONE_ERROR,

  CONFIGS_LEVEL_DELETE_ONE,
  CONFIGS_LEVEL_DELETE_ONE_SUCCESS,
  CONFIGS_LEVEL_DELETE_ONE_ERROR,

} from '../../actions';
import { NotificationManager } from '../../../components/common/react-notifications';
import notificationMessages from '../../../lang/locales/notificationMsg';

const INIT_STATE = {
  levels: [],
  count: 0,
  level: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

  switch (action.type) {

    case CONFIGS_LEVEL_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case CONFIGS_LEVEL_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        levels: action.payload.levels || [],
        count: action.payload.count || 0
      };
    case CONFIGS_LEVEL_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET ONE
    case CONFIGS_LEVEL_GET_ONE:
      return { ...state, loading: true, error: '', class: null };
    case CONFIGS_LEVEL_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        level: action.payload.level
      };
    case CONFIGS_LEVEL_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // ADD ONE
    case CONFIGS_LEVEL_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case CONFIGS_LEVEL_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        levels: [action.payload.level, ...state.levels]
      };
    case CONFIGS_LEVEL_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




    // EDIT ONE
    case CONFIGS_LEVEL_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case CONFIGS_LEVEL_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        level: action.payload.level
      };
    case CONFIGS_LEVEL_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // DELETE ONE
    case CONFIGS_LEVEL_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case CONFIGS_LEVEL_DELETE_ONE_SUCCESS:
      const levels = state.levels
      if (action.payload.index !== null && action.payload.index !== undefined)
        levels.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        levels
      };
    case CONFIGS_LEVEL_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    default: return { ...state };
  }
} 