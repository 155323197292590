
export const PARTNER_SELECT_ONE = "PARTNER_SELECT_ONE";
export const NO_PARTNER_SELECTED = "NO_PARTNER_SELECTED";
export const POST_PARTNER_SELECT_ONE = "POST_PARTNER_SELECT_ONE";
export const PARTNER_CHANGE_SECTION = "PARTNER_CHANGE_SECTION";

export const PARTNER_GET_USER_PARTNER_LIST = "PARTNER_GET_USER_PARTNER_LIST";
export const PARTNER_GET_USER_PARTNER_LIST_SUCCESS = "PARTNER_GET_USER_PARTNER_LIST_SUCCESS";
export const PARTNER_GET_USER_PARTNER_LIST_ERROR = "PARTNER_GET_USER_PARTNER_LIST_ERROR";

export const GET_USER_CONTACTS_LIST = "GET_USER_CONTACTS_LIST";
export const GET_USER_CONTACTS_LIST_SUCCESS = "GET_USER_CONTACTS_LIST_SUCCESS";
export const GET_USER_CONTACTS_LIST_ERROR = "GET_USER_CONTACTS_LIST_ERROR";



export const PARTNER_GET_LIST = "PARTNER_GET_LIST";
export const PARTNER_GET_LIST_SUCCESS = "PARTNER_GET_LIST_SUCCESS";
export const PARTNER_GET_LIST_ERROR = "PARTNER_GET_LIST_ERROR";

export const PARTNER_GET_ONE = "PARTNER_GET_ONE";
export const PARTNER_GET_ONE_SUCCESS = "PARTNER_GET_ONE_SUCCESS";
export const PARTNER_GET_ONE_ERROR = "PARTNER_GET_ONE_ERROR";

export const PARTNER_ADD_ONE = "PARTNER_ADD_ONE";
export const PARTNER_ADD_ONE_SUCCESS = "PARTNER_ADD_ONE_SUCCESS";
export const PARTNER_ADD_ONE_ERROR = "PARTNER_ADD_ONE_ERROR";

export const PARTNER_EDIT_ONE = "PARTNER_EDIT_ONE";
export const PARTNER_EDIT_ONE_SUCCESS = "PARTNER_EDIT_ONE_SUCCESS";
export const PARTNER_EDIT_ONE_ERROR = "PARTNER_EDIT_ONE_ERROR";

export const PARTNER_DELETE_ONE = "PARTNER_DELETE_ONE";
export const PARTNER_DELETE_ONE_SUCCESS = "PARTNER_DELETE_ONE_SUCCESS";
export const PARTNER_DELETE_ONE_ERROR = "PARTNER_DELETE_ONE_ERROR";



export const GET_PARTNER_NETWORK = "GET_PARTNER_NETWORK";
export const GET_PARTNER_NETWORK_SUCCESS = "GET_PARTNER_NETWORK_SUCCESS";
export const GET_PARTNER_NETWORK_ERROR = "GET_PARTNER_NETWORK_ERROR";

export const PARTNER_NETWORK_ADD_MEMBER = "PARTNER_NETWORK_ADD_MEMBER";
export const PARTNER_NETWORK_ADD_MEMBER_SUCCESS = "PARTNER_NETWORK_ADD_MEMBER_SUCCESS";
export const PARTNER_NETWORK_ADD_MEMBER_ERROR = "PARTNER_NETWORK_ADD_MEMBER_ERROR";

export const PARTNER_NETWORK_ACCEPT_MEMBER = "PARTNER_NETWORK_ACCEPT_MEMBER";
export const PARTNER_NETWORK_ACCEPT_MEMBER_SUCCESS = "PARTNER_NETWORK_ACCEPT_MEMBER_SUCCESS";
export const PARTNER_NETWORK_ACCEPT_MEMBER_ERROR = "PARTNER_NETWORK_ACCEPT_MEMBER_ERROR";