import {
  PARENTS_SCHOOL_GET_LIST,
  PARENTS_SCHOOL_GET_LIST_SUCCESS,
  PARENTS_SCHOOL_GET_LIST_ERROR,

  PARENTS_SCHOOL_GET_LIST_BY_FILTER,
  PARENTS_SCHOOL_GET_LIST_BY_FILTER_SUCCESS,
  PARENTS_SCHOOL_GET_LIST_BY_FILTER_ERROR,


  PARENTS_SCHOOL_GET_ONE,
  PARENTS_SCHOOL_GET_ONE_SUCCESS,
  PARENTS_SCHOOL_GET_ONE_ERROR,

  PARENTS_SCHOOL_ADD_ONE,
  PARENTS_SCHOOL_ADD_ONE_SUCCESS,
  PARENTS_SCHOOL_ADD_ONE_ERROR,

  PARENTS_SCHOOL_EDIT_ONE,
  PARENTS_SCHOOL_EDIT_ONE_SUCCESS,
  PARENTS_SCHOOL_EDIT_ONE_ERROR,

  PARENTS_SCHOOL_DELETE_ONE,
  PARENTS_SCHOOL_DELETE_ONE_SUCCESS,
  PARENTS_SCHOOL_DELETE_ONE_ERROR,

  PARENTS_SCHOOL_DELETE_MANY,
  PARENTS_SCHOOL_DELETE_MANY_SUCCESS,
  PARENTS_SCHOOL_DELETE_MANY_ERROR,
} from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg';


const INIT_STATE = {
  parentsSchoolList: [],
  count: 0,
  parentsSchool: null,
  error: '',
  loading: false
};
export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
  switch (action.type) {

    //GET LIST
    case PARENTS_SCHOOL_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case PARENTS_SCHOOL_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        parentsSchoolList: action.payload.parentsSchoolList || [],
        count: action.payload.count || 0
      };
    case PARENTS_SCHOOL_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    //GET LIST BY FILTER
    case PARENTS_SCHOOL_GET_LIST_BY_FILTER:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case PARENTS_SCHOOL_GET_LIST_BY_FILTER_SUCCESS:
      return {
        ...state, loading: false,
        parentsSchoolList: action.payload.parentsSchoolList || [],
        count: action.payload.count || 0
      };
    case PARENTS_SCHOOL_GET_LIST_BY_FILTER_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET ONE
    case PARENTS_SCHOOL_GET_ONE:
      return { ...state, loading: true, error: '', class: null };
    case PARENTS_SCHOOL_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        parentsSchool: action.payload.parentsSchool
      };
    case PARENTS_SCHOOL_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    // ADD ONE
    case PARENTS_SCHOOL_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case PARENTS_SCHOOL_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        parentsSchoolList: [action.payload.parentsSchool, ...state.parentsSchoolList]
      };
    case PARENTS_SCHOOL_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // EDIT ONE
    case PARENTS_SCHOOL_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case PARENTS_SCHOOL_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        parentsSchool: action.payload.parentsSchool
      };
    case PARENTS_SCHOOL_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



    // DELETE ONE
    case PARENTS_SCHOOL_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case PARENTS_SCHOOL_DELETE_ONE_SUCCESS:
      const parentsSchoolList = state.parentsSchoolList
      if (action.payload.index !== null && action.payload.index !== undefined)
        parentsSchoolList.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        parentsSchoolList
      };
    case PARENTS_SCHOOL_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    // DELETE MANY
    case PARENTS_SCHOOL_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case PARENTS_SCHOOL_DELETE_MANY_SUCCESS:
      const parents_school_list = state.parentsSchoolList


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_parent_school = -1
          for (let j = 0; j < parents_school_list.length; j++) {
            if (parents_school_list[j]._id === action.payload.data.ids[i]) {
              index_parent_school = j; break;
            }
          }
          if (index_parent_school > -1) parents_school_list.splice(index_parent_school, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        parentsSchoolList: parents_school_list
      };
    case PARENTS_SCHOOL_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    default: return { ...state };
  }
}