export const SIGNALS_GET_LIST = "SIGNALS_GET_LIST";
export const SIGNALS_GET_LIST_SUCCESS = "SIGNALS_GET_LIST_SUCCESS";
export const SIGNALS_GET_LIST_ERROR = "SIGNALS_GET_LIST_ERROR";

export const SIGNALS_GET_ITEM = "SIGNALS_GET_ITEM";
export const SIGNALS_GET_ITEM_SUCCESS = "SIGNALS_GET_ITEM_SUCCESS";
export const SIGNALS_GET_ITEM_ERROR = "SIGNALS_GET_ITEM_ERROR";

export const SIGNALS_ADD = "SIGNALS_ADD";
export const SIGNALS_ADD_SUCCESS = "SIGNALS_ADD_SUCCESS";
export const SIGNALS_ADD_ERROR = "SIGNALS_ADD_ERROR";

export const SIGNALS_DELETE_ONE = "SIGNALS_DELETE_ONE";
export const SIGNALS_DELETE_ONE_SUCCESS = "SIGNALS_DELETE_ONE_SUCCESS";
export const SIGNALS_DELETE_ONE_ERROR = "SIGNALS_DELETE_ONE_ERROR";
