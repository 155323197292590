import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../../constants/defaultValues";
import axios from "axios";

import {
  BILL_GET_LIST, BILL_ADD_ONE, BILL_GET_ONE, BILL_EDIT_ONE, BILL_DELETE_ONE, BILL_PRINT, BILLS_DELETE_MANY, BILL_GET_BY_FILTER
} from "../../actions";

import {
  getBillsListSuccess,
  getBillsListError,

  getOneBillSuccess,
  getOneBillError,

  addBillSuccess,
  addBillError,

  editBillSuccess,
  editBillError,

  printBillSuccess,
  printBillError,


  deleteOneBillSuccess,
  deleteOneBillError,

  deleteManyBillsSuccess,
  deleteManyBillsError,

  getBillsByFilterSuccess,
  getBillsByFilterError
} from "./actions";


//GET LIST
const getBillsListRequest = async (payload) => {
  return await axios.get(api.bills + `/list/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* getBillsList({ payload }) {
  try {
    const ret = yield call(getBillsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getBillsListSuccess({ bills: ret.data.bills || [], count: ret.data.count || 0 }));

    else yield put(getBillsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getBillsListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOneBillRequest = async (payload) => {
  return await axios.get(api.bills + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneBill({ payload }) {
  try {
    const ret = yield call(getOneBillRequest, payload);

    if (ret && ret.status === 200) yield put(getOneBillSuccess({ bill: ret.data.bill }));
    else yield put(getOneBillError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(getOneBillError({ error, message: (error) ? error.message : '' }));
  }
}

//GET LIST BY FILTER

const getBillsByFilterRequest = async (payload) => {
  return await axios.post(api.bills + `/filters/${payload.inst_id}/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* getBillsByFilter({ payload }) {
  try {
    const ret = yield call(getBillsByFilterRequest, payload);

    if (ret && ret.status === 200)
      yield put(getBillsByFilterSuccess({ bills: ret.data.bills || [], count: ret.data.count || 0 }));

    else yield put(getBillsByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getBillsByFilterError({ error, message: (error) ? error.message : '' }));
  }
}
//ADD ONE
const addBillRequest = async (payload) => {

  return await axios.post(api.bills, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addBill({ payload }) {
  //console.log('saga add bill', payload);

  try {
    const ret = yield call(addBillRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/billing/bills')
      yield put(addBillSuccess({ bill: ret.data.bill }));
    }
    else yield put(addBillError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(addBillError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneBillRequest = async (payload) => {
  return await axios.patch(`${api.bills}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneBill({ payload }) {
  try {
    const ret = yield call(editOneBillRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/billing/bills')
      yield put(editBillSuccess({ bill: ret.data.bill }));
    }
    else yield put(editBillError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(editBillError({ error, message: (error) ? error.message : '' }));
  }
}

//PRINT BILL
const printBillRequest = async (payload) => {

  return await axios.post(`${api.bills}/print-bill/`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* printBill({ payload }) {
  try {
    const ret = yield call(printBillRequest, payload);
    //console.log('ret',ret);


    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/billing/bills')
      yield put(printBillSuccess({ path: ret.data.path }));
    }
    else yield put(printBillError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(printBillError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneBillRequest = async (payload) => {
  return await axios.delete(`${api.bills}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneBill({ payload }) {
  //console.log('saga delete bill', payload);
  try {
    const ret = yield call(deleteOneBillRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/billing/bills')
      yield put(deleteOneBillSuccess({ index }));
    }
    else yield put(deleteOneBillError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(deleteOneBillError({ error, message: (error) ? error.message : '' }));
  }
}


//DELETE MANY

const deleteManyBillsRequest = async (payload) => {
  return await axios.delete(`${api.bills}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyBills({ payload }) {
  try {
    const ret = yield call(deleteManyBillsRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/app/billing/bills')
      yield put(deleteManyBillsSuccess({ data }));
    }
    else yield put(deleteManyBillsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyBillsError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(BILL_GET_LIST, getBillsList);
}

export function* watchGetOne() {
  yield takeEvery(BILL_GET_ONE, getOneBill);
}
export function* watchGetByFilter() {
  yield takeEvery(BILL_GET_BY_FILTER, getBillsByFilter);
}
export function* watchEditOne() {
  yield takeEvery(BILL_EDIT_ONE, editOneBill);
}

export function* watchPrint() {
  yield takeEvery(BILL_PRINT, printBill);
}

export function* watchAddOne() {
  yield takeEvery(BILL_ADD_ONE, addBill);
}

export function* watchDeleteOne() {
  yield takeEvery(BILL_DELETE_ONE, deleteOneBill);
}
export function* watchDeleteMany() {
  yield takeEvery(BILLS_DELETE_MANY, deleteManyBills);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetByFilter),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchPrint),
    fork(watchDeleteMany)
  ]);
}
