import {
  EXAMRESULTS_GET_LIST,
  EXAMRESULTS_GET_LIST_SUCCESS,
  EXAMRESULTS_GET_LIST_ERROR,

  EXAMRESULTS_GET_ONE,
  EXAMRESULTS_GET_ONE_SUCCESS,
  EXAMRESULTS_GET_ONE_ERROR,

  EXAMRESULTS_GET_BY_FILTER,
  EXAMRESULTS_GET_BY_FILTER_SUCCESS,
  EXAMRESULTS_GET_BY_FILTER_ERROR,
  EXAMRESULTS_ADD_ONE,
  EXAMRESULTS_ADD_ONE_SUCCESS,
  EXAMRESULTS_ADD_ONE_ERROR,

  EXAMRESULTS_EDIT_ONE,
  EXAMRESULTS_EDIT_ONE_SUCCESS,
  EXAMRESULTS_EDIT_ONE_ERROR,

  EXAMRESULTS_DELETE_ONE,
  EXAMRESULTS_DELETE_ONE_SUCCESS,
  EXAMRESULTS_DELETE_ONE_ERROR,

  EXAMRESULTS_DELETE_MANY,
  EXAMRESULTS_DELETE_MANY_SUCCESS,
  EXAMRESULTS_DELETE_MANY_ERROR,
} from '../actions';

// GET LIST
export const getExamResultsList = (payload) => ({
  type: EXAMRESULTS_GET_LIST,
  payload
});
export const getExamResultsListSuccess = (payload) => ({
  type: EXAMRESULTS_GET_LIST_SUCCESS,
  payload
});
export const getExamResultsListError = (payload) => ({
  type: EXAMRESULTS_GET_LIST_ERROR,
  payload
});

// GET One
export const getOneExamResults = (payload) => ({
  type: EXAMRESULTS_GET_ONE,
  payload
});
export const getOneExamResultsSuccess = (payload) => ({
  type: EXAMRESULTS_GET_ONE_SUCCESS,
  payload
});
export const getOneExamResultsError = (payload) => ({
  type: EXAMRESULTS_GET_ONE_ERROR,
  payload
});
// // GET EXam RESULTS BY FILTER
export const getExamResultsByFilter = (payload) => ({
  type: EXAMRESULTS_GET_BY_FILTER,
  payload
});
export const getExamResultsByFilterSuccess = (payload) => ({
  type: EXAMRESULTS_GET_BY_FILTER_SUCCESS,
  payload
});
export const getExamResultsByFilterError = (payload) => ({
  type: EXAMRESULTS_GET_BY_FILTER_ERROR,
  payload
});
// ADD ONE
export const addExamResults = (payload) => ({
  type: EXAMRESULTS_ADD_ONE,
  payload
});
export const addExamResultsSuccess = (payload) => ({
  type: EXAMRESULTS_ADD_ONE_SUCCESS,
  payload
});
export const addExamResultsError = (payload) => ({
  type: EXAMRESULTS_ADD_ONE_ERROR,
  payload
});

// EDIT ONE
export const editOneExamResults = (payload) => ({
  type: EXAMRESULTS_EDIT_ONE,
  payload
});
export const editOneExamResultsSuccess = (payload) => ({
  type: EXAMRESULTS_EDIT_ONE_SUCCESS,
  payload
});
export const editOneExamResultsError = (payload) => ({
  type: EXAMRESULTS_EDIT_ONE_ERROR,
  payload
});

// DELETE ONE
export const deleteOneExamResults = (payload) => ({
  type: EXAMRESULTS_DELETE_ONE,
  payload
});
export const deleteOneExamResultsSuccess = (payload) => ({
  type: EXAMRESULTS_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneExamResultsError = (payload) => ({
  type: EXAMRESULTS_DELETE_ONE_ERROR,
  payload
});


// DELETE MANY
export const deleteManyExamResults = (payload) => ({
  type: EXAMRESULTS_DELETE_MANY,
  payload
});
export const deleteManyExamResultsSuccess = (payload) => ({
  type: EXAMRESULTS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyExamResultsError = (payload) => ({
  type: EXAMRESULTS_DELETE_MANY_ERROR,
  payload
});