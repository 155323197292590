import {
    NOTIFICATION_GET_LIST,
    NOTIFICATION_GET_LIST_ERROR,
    NOTIFICATION_GET_LIST_SUCCESS,
    NOTIFICATION_GET_ONE,
    NOTIFICATION_GET_ONE_ERROR,
    NOTIFICATION_GET_ONE_SUCCESS
} from './actionTypes'


const INIT_STATE = {
    notifications: [],
    notification:null,
    count: 0,
    error: '',
    loading: false
};

export default (state = INIT_STATE, action) => {
  
    switch (action.type) {

    // GET LIST NOTIFICATIONS
      case NOTIFICATION_GET_LIST:
        return {
          ...state, loading: true, success_add: '',
          error: ''
        };
      case NOTIFICATION_GET_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          notifications: action.payload.notifications,
          count: action.payload.count
        };
      case NOTIFICATION_GET_LIST_ERROR:
        return { ...state, loading: false, error: action.payload };

    
        //GET ONE

      case NOTIFICATION_GET_ONE:
        return { ...state, notification: null, loading: true, error: '' };

      case NOTIFICATION_GET_ONE_SUCCESS:
        return { ...state, loading: false, notification: action.payload.notification };

      case NOTIFICATION_GET_ONE_ERROR:
        return { ...state, loading: false, notification: null, error: action.payload.message };

        default: return { ...state };
    }
}