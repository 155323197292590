import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  TEACHER_COMMENTS_GET_LIST, TEACHER_COMMENT_GET_ONE, TEACHER_COMMENT_ADD_ONE, TEACHER_COMMENT_EDIT_ONE, TEACHER_COMMENT_DELETE_ONE, TEACHER_COMMENTS_GET_BY_FILTER,
  TEACHER_COMMENTS_DELETE_MANY

} from "../actions";

import {
  getTeacherCommentsListSuccess,
  getTeacherCommentsListError,

  getTeacherCommentsByFilterSuccess,
  getTeacherCommentsByFilterError,

  getOneTeacherCommentSuccess,
  getOneTeacherCommentError,


  addTeacherCommentSuccess,
  addTeacherCommentError,

  editOneTeacherCommentSuccess,
  editOneTeacherCommentError,

  deleteOneTeacherCommentSuccess,
  deleteOneTeacherCommentError,

  deleteManyTeacherCommentsSuccess,
  deleteManyTeacherCommentsError,

} from "./actions";

//GET LIST
const getTeacherCommentsListRequest = async (payload) => {
  return await axios.get(`${api.teacher_comments}/list/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getTeacherCommentsList({ payload }) {
  try {
    const ret = yield call(getTeacherCommentsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getTeacherCommentsListSuccess({ teacher_comments: ret.data.teacher_comments || [], count: ret.data.count || 0 }));

    else yield put(getTeacherCommentsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getTeacherCommentsListError({ error, message: (error) ? error.message : '' }));
  }
}
const getTeacherCommentsByFilterRequest = async (payload) => {
  return await axios.post(api.teacher_comments + `/filters/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getTeacherCommentsByFilter({ payload }) {
  try {
    const ret = yield call(getTeacherCommentsByFilterRequest, payload);


    if (ret && ret.status === 200)
      yield put(getTeacherCommentsByFilterSuccess({ teacher_comments: ret.data.teacher_comments, count: ret.data.count }));

    else yield put(getTeacherCommentsByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getTeacherCommentsByFilterError({ error, message: (error) ? error.message : '' }));
  }
}


//GET ONE
const getOneTeacherCommentRequest = async (payload) => {
  return await axios.get(api.teacher_comments + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneTeacherComment({ payload }) {
  try {
    const ret = yield call(getOneTeacherCommentRequest, payload);

    if (ret && ret.status === 200) yield put(getOneTeacherCommentSuccess({ teacher_comment: ret.data.teacher_comment }));

    else yield put(getOneTeacherCommentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(getOneTeacherCommentError({ error, message: (error) ? error.message : '' }));
  }
}
//ADD ONE
const addTeacherCommentRequest = async (payload) => {
  return await axios.post(api.teacher_comments, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addTeacherComment({ payload }) {
  try {
    const ret = yield call(addTeacherCommentRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/exams/elementary-school-exams/teacher-comments')
      yield put(addTeacherCommentSuccess({ teacher_comment: ret.data.teacher_comment }));
    }
    else yield put(addTeacherCommentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addTeacherCommentError({ error, message: (error) ? error.message : '' }));
  }
}
//EDIT ONE
const editOneTeacherCommentRequest = async (payload) => {
  return await axios.patch(`${api.teacher_comments}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneTeacherComment({ payload }) {
  try {
    const ret = yield call(editOneTeacherCommentRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/exams/elementary-school-exams/teacher-comments')
      yield put(editOneTeacherCommentSuccess({ teacher_comment: ret.data.teacher_comment }));
    }
    else yield put(editOneTeacherCommentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editOneTeacherCommentError({ error, message: (error) ? error.message : '' }));
  }
}

// DELETE ONE
const deleteOneTeacherCommentRequest = async (payload) => {
  return await axios.delete(`${api.teacher_comments}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneTeacherComment({ payload }) {
  try {
    const ret = yield call(deleteOneTeacherCommentRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/exams/teacher-comments')
      yield put(deleteOneTeacherCommentSuccess({ index }));
    }
    else yield put(deleteOneTeacherCommentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneTeacherCommentError({ error, message: (error) ? error.message : '' }));
  }
}

//DELETE MANY
const deleteManyTeacherCommentsRequest = async (payload) => {
  return await axios.delete(`${api.teacher_comments}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyTeacherComments({ payload }) {
  try {
    const ret = yield call(deleteManyTeacherCommentsRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/app/exams/teacher-comments')
      yield put(deleteManyTeacherCommentsSuccess({ data }));
    }
    else yield put(deleteManyTeacherCommentsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyTeacherCommentsError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(TEACHER_COMMENTS_GET_LIST, getTeacherCommentsList);
}

export function* watchGetFilter() {
  yield takeEvery(TEACHER_COMMENTS_GET_BY_FILTER, getTeacherCommentsByFilter);
}
export function* watchGetOne() {
  yield takeEvery(TEACHER_COMMENT_GET_ONE, getOneTeacherComment);
}


export function* watchEditOne() {
  yield takeEvery(TEACHER_COMMENT_EDIT_ONE, editOneTeacherComment);
}

export function* watchAddOne() {
  yield takeEvery(TEACHER_COMMENT_ADD_ONE, addTeacherComment);
}

export function* watchDeleteOne() {
  yield takeEvery(TEACHER_COMMENT_DELETE_ONE, deleteOneTeacherComment);
}
export function* watchDeleteMany() {
  yield takeEvery(TEACHER_COMMENTS_DELETE_MANY, deleteManyTeacherComments);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetFilter),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)


  ]);
}