import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  CLASS_GET_LIST, CLASS_ADD_ONE, CLASS_GET_ONE, CLASS_EDIT_ONE, CLASS_DELETE_ONE, CLASS_GET_BY_FILTER, CLASS_GET_BY_TEACHER, CLASSES_DELETE_MANY
} from "../actions";

import {
  getClassesListSuccess,
  getClassesListError,

  getOneClassSuccess,
  getOneClassError,

  getClassesByFilterSuccess,
  getClassesByFilterError,

  addClassSuccess,
  addClassError,

  editClassSuccess,
  editClassError,
  deleteOneClassSuccess,
  deleteOneClassError,

  deleteManyClassesSuccess,
  deleteManyClassesError
} from "./actions";


//GET LIST
const getClassesListRequest = async (payload) => {
  return await axios.get(api.classes + `/list/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getClassesList({ payload }) {
  try {
    const ret = yield call(getClassesListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getClassesListSuccess({ classes: ret.data.classes || [], count: ret.data.count || 0 }));

    else yield put(getClassesListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getClassesListError({ error, message: (error) ? error.message : '' }));
  }
}


//GET LIST BY TEACHER
const getClassesByTeacherRequest = async (payload) => {
  return await axios.post(api.classes + `/teacher/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getClassesByTeacher({ payload }) {
  try {
    const ret = yield call(getClassesByTeacherRequest, payload);

    if (ret && ret.status === 200)
      yield put(getClassesListSuccess({ classes: ret.data.classes || [], count: ret.data.count || 0 }));

    else yield put(getClassesListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getClassesListError({ error, message: (error) ? error.message : '' }));
  }
}


//GET ONE
const getOneClassesRequest = async (payload) => {
  return await axios.get(api.classes + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneClass({ payload }) {
  try {
    const ret = yield call(getOneClassesRequest, payload);

    if (ret && ret.status === 200) yield put(getOneClassSuccess({ class: ret.data.one_class }));
    else yield put(getOneClassError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(getOneClassError({ error, message: (error) ? error.message : '' }));
  }
}



//GET CLASS BY FILTER
const getClassesbyFilterRequest = async (payload) => {
  return await axios.post(api.classes + `/filters/${payload.inst_id}/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getClassByFilter({ payload }) {
  try {
    const ret = yield call(getClassesbyFilterRequest, payload);
    if (ret && ret.status === 200)
      yield put(getClassesByFilterSuccess({ classes: ret.data.classes, count: ret.data.count || 0 }));

    else yield put(getClassesByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getClassesByFilterError({ error, message: (error) ? error.message : '' }));
  }
}



//ADD ONE
const addClassRequest = async (payload) => {
  return await axios.post(api.classes, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addClass({ payload }) {
  try {
    const ret = yield call(addClassRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/my-institutions/classes')
      yield put(addClassSuccess({ class: ret.data.class }));
    }
    else yield put(addClassError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addClassError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneClassRequest = async (payload) => {
  return await axios.patch(`${api.classes}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneClass({ payload }) {
  try {
    const ret = yield call(editOneClassRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/my-institutions/classes')
      yield put(editClassSuccess({ class: ret.data.class }));
    }
    else yield put(editClassError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editClassError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneClassRequest = async (payload) => {
  return await axios.delete(`${api.classes}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneClass({ payload }) {
  try {
    const ret = yield call(deleteOneClassRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/my-institutions/classes')
      yield put(deleteOneClassSuccess({ index }));
    }
    else yield put(deleteOneClassError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneClassError({ error, message: (error) ? error.message : '' }));
  }
}
//DELETE MANY

const deleteManyClassesRequest = async (payload) => {
  return await axios.delete(`${api.classes}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyClasses({ payload }) {
  try {
    const ret = yield call(deleteManyClassesRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/app/my-institutions/classes')
      yield put(deleteManyClassesSuccess({ data }));
    }
    else yield put(deleteManyClassesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyClassesError({ error, message: (error) ? error.message : '' }));
  }
}

//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(CLASS_GET_LIST, getClassesList);
}

export function* watchGetByTeacher() {
  yield takeEvery(CLASS_GET_BY_TEACHER, getClassesByTeacher);
}

export function* watchGetFilter() {
  yield takeEvery(CLASS_GET_BY_FILTER, getClassByFilter);
}

export function* watchGetOne() {
  yield takeEvery(CLASS_GET_ONE, getOneClass);
}

export function* watchEditOne() {
  yield takeEvery(CLASS_EDIT_ONE, editOneClass);
}

export function* watchAddOne() {
  yield takeEvery(CLASS_ADD_ONE, addClass);
}

export function* watchDeleteOne() {
  yield takeEvery(CLASS_DELETE_ONE, deleteOneClass);
}
export function* watchDeleteMany() {
  yield takeEvery(CLASSES_DELETE_MANY, deleteManyClasses);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetByTeacher),
    fork(watchGetFilter),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)
  ]);
}
