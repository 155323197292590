import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  SUBJECT_GET_LIST,
  SUBJECT_ADD_ONE,
  SUBJECT_GET_ONE,
  SUBJECT_GET_BY_FILTER,
  SUBJECT_EDIT_ONE,
  SUBJECT_DELETE_ONE,
  SUBJECTS_DELETE_MANY,
} from "../actions";

import {
  getSubjectsListSuccess,
  getSubjectsListError,
  getOneSubjectSuccess,
  getOneSubjectError,
  getSubjectByFilterSuccess,
  getSubjectByFilterError,
  addSubjectSuccess,
  addSubjectError,
  editSubjectSuccess,
  editSubjectError,
  deleteOneSubjectSuccess,
  deleteOneSubjectError,
  deleteManySubjectsSuccess,
  deleteManySubjectsError,
} from "./actions";

//GET LIST
const getSubjectsListRequest = async (payload) => {
  return await axios
    .get(
      `${api.subjects}/list/${payload.inst_id}/${payload.offset}/${payload.limit}`
    )
    .then((resp) => resp)
    .catch((error) => {
      return error && error.response ? error.response : error;
    });
};
function* getSubjectsList({ payload }) {
  try {
    const ret = yield call(getSubjectsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(
        getSubjectsListSuccess({
          subjects: ret.data.subjects || [],
          count: ret.data.count || 0,
        })
      );
    else
      yield put(
        getSubjectsListError({
          message: ret.data ? ret.data.msg : "Network Error",
        })
      );
  } catch (error) {
    yield put(
      getSubjectsListError({ error, message: error ? error.message : "" })
    );
  }
}

//GET ONE
const getOneSubjectsRequest = async (payload) => {
  return await axios
    .get(`${api.subjects}/get-one/${payload._id}`)
    .then((resp) => resp)
    .catch((error) => {
      return error && error.response ? error.response : error;
    });
};
function* getOneSubject({ payload }) {
  try {
    const ret = yield call(getOneSubjectsRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneSubjectSuccess({ subject: ret.data.subject }));
    else
      yield put(
        getOneSubjectError({
          message: ret.data ? ret.data.msg : "Network Error",
        })
      );
  } catch (error) {
    yield put(
      getOneSubjectError({ error, message: error ? error.message : "" })
    );
  }
}

//GET BY FILTER
const getSubjectByFilterRequest = async (payload) => {
  return await axios
    .post(
      `${api.subjects}/${payload?.inst_id}/filter/${payload?.offset}/${payload?.limit}`,
      payload?.filter,
      { headers: { Authorization: payload?.token } }
    )
    .then((resp) => resp)
    .catch((error) => {
      return error && error.response ? error.response : error;
    });
};
function* getSubjectByFilter({ payload }) {
  try {
    const ret = yield call(getSubjectByFilterRequest, payload);
    if (ret && ret.status === 200)
      yield put(
        getSubjectByFilterSuccess({
          subjects: ret.data.subjects || [],
          count: ret.data.count || 0,
        })
      );
    else
      yield put(
        getSubjectByFilterError({
          message: ret.data ? ret.data.msg : "Network Error",
        })
      );
  } catch (error) {
    console.log("SAGA SUBJECT ERROR", error);
    yield put(
      getSubjectByFilterError({ error, message: error ? error.message : "" })
    );
  }
}

//ADD ONE
const addSubjectRequest = async (payload) => {
  return await axios
    .post(api.subjects, payload.data)
    .then((resp) => resp)
    .catch((error) => {
      return error && error.response ? error.response : error;
    });
};
function* addSubject({ payload }) {
  try {
    const ret = yield call(addSubjectRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push("/app/my-institutions/subjects");
      yield put(addSubjectSuccess({ subject: ret.data.subject }));
    } else
      yield put(
        addSubjectError({ message: ret.data ? ret.data.msg : "Network Error" })
      );
  } catch (error) {
    console.log("saga err", error);
    yield put(addSubjectError({ error, message: error ? error.message : "" }));
  }
}

//EDIT ONE
const editOneSubjectRequest = async (payload) => {
  return await axios
    .patch(`${api.subjects}/edit-one/${payload._id}`, payload.data)
    .then((resp) => resp)
    .catch((error) => {
      return error && error.response ? error.response : error;
    });
};
function* editOneSubject({ payload }) {
  try {
    const ret = yield call(editOneSubjectRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push("/app/my-institutions/subjects");
      yield put(editSubjectSuccess({ subject: ret.data.subject }));
    } else
      yield put(
        editSubjectError({ message: ret.data ? ret.data.msg : "Network Error" })
      );
  } catch (error) {
    console.log("saga err", error);
    yield put(editSubjectError({ error, message: error ? error.message : "" }));
  }
}

// DELETE ONE
const deleteOneSubjectRequest = async (payload) => {
  return await axios
    .delete(`${api.subjects}/delete-one/${payload._id}`)
    .then((resp) => resp)
    .catch((error) => {
      return error && error.response ? error.response : error;
    });
};
function* deleteOneSubject({ payload }) {
  try {
    const ret = yield call(deleteOneSubjectRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push("/app/my-institutions/subjects");
      yield put(deleteOneSubjectSuccess({ index }));
    } else
      yield put(
        deleteOneSubjectError({
          message: ret.data ? ret.data.msg : "Network Error",
        })
      );
  } catch (error) {
    console.log("saga err", error);
    yield put(
      deleteOneSubjectError({ error, message: error ? error.message : "" })
    );
  }
}
//DELETE MANY

const deleteManySubjectsRequest = async (payload) => {
  return await axios
    .delete(`${api.subjects}/delete-many`, {
      headers: { Authorization: payload.token },
      data: payload.data,
    })
    .then((resp) => resp)
    .catch((error) => {
      return error && error.response ? error.response : error;
    });
};
function* deleteManySubjects({ payload }) {
  try {
    const ret = yield call(deleteManySubjectsRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push("/app/my-institutions/subjects");
      yield put(deleteManySubjectsSuccess({ data }));
    } else
      yield put(
        deleteManySubjectsError({
          message: ret.data ? ret.data.msg : "Network Error",
        })
      );
  } catch (error) {
    console.log("saga err", error);
    yield put(
      deleteManySubjectsError({ error, message: error ? error.message : "" })
    );
  }
}

//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(SUBJECT_GET_LIST, getSubjectsList);
}

export function* watchGetOne() {
  yield takeEvery(SUBJECT_GET_ONE, getOneSubject);
}

export function* watchGetByFilter() {
  yield takeEvery(SUBJECT_GET_BY_FILTER, getSubjectByFilter);
}

export function* watchEditOne() {
  yield takeEvery(SUBJECT_EDIT_ONE, editOneSubject);
}

export function* watchAddOne() {
  yield takeEvery(SUBJECT_ADD_ONE, addSubject);
}

export function* watchDeleteOne() {
  yield takeEvery(SUBJECT_DELETE_ONE, deleteOneSubject);
}
export function* watchDeleteMany() {
  yield takeEvery(SUBJECTS_DELETE_MANY, deleteManySubjects);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetByFilter),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany),
  ]);
}
