import ExamSessionSrv from '../../services/ExamSessionSrv';
import {
  EXAMSESSIONS_GET_LIST,
  EXAMSESSIONS_GET_LIST_SUCCESS,
  EXAMSESSIONS_GET_LIST_ERROR,

  EXAMSESSIONS_GET_ONE,
  EXAMSESSIONS_GET_ONE_SUCCESS,
  EXAMSESSIONS_GET_ONE_ERROR,

  EXAMSESSIONS_GET_BY_FILTER,
  EXAMSESSIONS_GET_BY_FILTER_SUCCESS,
  EXAMSESSIONS_GET_BY_FILTER_ERROR,

  EXAMSESSIONS_ADD_ONE,
  EXAMSESSIONS_ADD_ONE_SUCCESS,
  EXAMSESSIONS_ADD_ONE_ERROR,

  EXAMSESSIONS_EDIT_ONE,
  EXAMSESSIONS_EDIT_ONE_SUCCESS,
  EXAMSESSIONS_EDIT_ONE_ERROR,

  EXAMSESSIONS_DELETE_ONE,
  EXAMSESSIONS_DELETE_ONE_SUCCESS,
  EXAMSESSIONS_DELETE_ONE_ERROR,

  EXAMSESSIONS_DELETE_MANY,
  EXAMSESSIONS_DELETE_MANY_SUCCESS,
  EXAMSESSIONS_DELETE_MANY_ERROR,
} from '../actions';

// GET LIST
export const getExamSessionsList = (payload) => ({
  type: EXAMSESSIONS_GET_LIST,
  payload
});
export const getExamSessionsListSuccess = (payload) => ({
  type: EXAMSESSIONS_GET_LIST_SUCCESS,
  payload
});
export const getExamSessionsListError = (payload) => ({
  type: EXAMSESSIONS_GET_LIST_ERROR,
  payload
});

// GET One
export const getOneExamSessions = (payload) => ({
  type: EXAMSESSIONS_GET_ONE,
  payload
});
export const getOneExamSessionsSuccess = (payload) => ({
  type: EXAMSESSIONS_GET_ONE_SUCCESS,
  payload
});
export const getOneExamSessionsError = (payload) => ({
  type: EXAMSESSIONS_GET_ONE_ERROR,
  payload
});


// GET EXAM SESSIONS BY FILTER
export const getExamSessionsByFilter = (payload) => ({
  type: EXAMSESSIONS_GET_BY_FILTER,
  payload
});
export const getExamSessionsByFilterSuccess = (payload) => ({
  type: EXAMSESSIONS_GET_BY_FILTER_SUCCESS,
  payload
});
export const getExamSessionsByFilterError = (payload) => ({
  type: EXAMSESSIONS_GET_BY_FILTER_ERROR,
  payload
});
export const getExamSessionsByFilterCB = (payload, callback, callbackError) => {
  return (dispatch) => {
    dispatch({
      type: EXAMSESSIONS_GET_BY_FILTER,
    });

    ExamSessionSrv.getList(payload)
      .then((response) => {
        if (response && response?.data) {
          const resp = {
            exam_sessions: response?.data?.exam_sessions,
            count: response.data.count
          }

          dispatch({
            type: EXAMSESSIONS_GET_BY_FILTER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: EXAMSESSIONS_GET_BY_FILTER_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// ADD ONE
export const addExamSessions = (payload) => ({
  type: EXAMSESSIONS_ADD_ONE,
  payload
});
export const addExamSessionsSuccess = (payload) => ({
  type: EXAMSESSIONS_ADD_ONE_SUCCESS,
  payload
});
export const addExamSessionsError = (payload) => ({
  type: EXAMSESSIONS_ADD_ONE_ERROR,
  payload
});


// EDIT ONE
export const editOneExamSessions = (payload) => ({
  type: EXAMSESSIONS_EDIT_ONE,
  payload
});
export const editOneExamSessionsSuccess = (payload) => ({
  type: EXAMSESSIONS_EDIT_ONE_SUCCESS,
  payload
});
export const editOneExamSessionsError = (payload) => ({
  type: EXAMSESSIONS_EDIT_ONE_ERROR,
  payload
});


// DELETE ONE
export const deleteOneExamSessions = (payload) => ({
  type: EXAMSESSIONS_DELETE_ONE,
  payload
});
export const deleteOneExamSessionsSuccess = (payload) => ({
  type: EXAMSESSIONS_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneExamSessionsError = (payload) => ({
  type: EXAMSESSIONS_DELETE_ONE_ERROR,
  payload
});


// DELETE MANY
export const deleteManyExamSessions = (payload) => ({
  type: EXAMSESSIONS_DELETE_MANY,
  payload
});
export const deleteManyExamSessionsSuccess = (payload) => ({
  type: EXAMSESSIONS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyExamSessionsError = (payload) => ({
  type: EXAMSESSIONS_DELETE_MANY_ERROR,
  payload
});