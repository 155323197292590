import {
  SKILL_CONFIG_GET_LIST,
  SKILL_CONFIG_GET_LIST_SUCCESS,
  SKILL_CONFIG_GET_LIST_ERROR,
  SKILL_CONFIG_GET_ONE,
  SKILL_CONFIG_GET_ONE_SUCCESS,
  SKILL_CONFIG_GET_ONE_ERROR,
  SKILL_CONFIG_ADD_ONE,
  SKILL_CONFIG_ADD_ONE_SUCCESS,
  SKILL_CONFIG_ADD_ONE_ERROR,
  SKILL_CONFIG_EDIT_ONE,
  SKILL_CONFIG_EDIT_ONE_SUCCESS,
  SKILL_CONFIG_EDIT_ONE_ERROR,
  SKILL_CONFIG_DELETE_ONE,
  SKILL_CONFIG_DELETE_ONE_SUCCESS,
  SKILL_CONFIG_DELETE_ONE_ERROR
} from '../../actions';
import { NotificationManager } from '../../../components/common/react-notifications';
import notificationMessages from '../../../lang/locales/notificationMsg';

const INIT_STATE = {
  skills: [],
  count: 0,
  skill: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

  switch (action.type) {
    case SKILL_CONFIG_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case SKILL_CONFIG_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        skills: action.payload.skills || [],
        count: action.payload.count || 0
      };
    case SKILL_CONFIG_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






    // GET ONE
    case SKILL_CONFIG_GET_ONE:
      return { ...state, loading: true, error: '' };
    case SKILL_CONFIG_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        skill: action.payload.skill
      };
    case SKILL_CONFIG_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









    // ADD ONE
    case SKILL_CONFIG_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case SKILL_CONFIG_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        skills: [action.payload.skill, ...state.skills]
      };
    case SKILL_CONFIG_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // EDIT ONE
    case SKILL_CONFIG_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case SKILL_CONFIG_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        skill: action.payload.skill
      };
    case SKILL_CONFIG_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // DELETE ONE
    case SKILL_CONFIG_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case SKILL_CONFIG_DELETE_ONE_SUCCESS:
      const skills = state.skills
      if (action.payload.index !== null && action.payload.index !== undefined)
        skills.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        skills
      };
    case SKILL_CONFIG_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    default: return { ...state };
  }
}
