import axios from "axios";
import { api } from "./constants/defaultValues";

const configAxios = (token = null) => {
	
	axios.defaults.headers.common['Authorization'] = (!token || token === "") ? localStorage.getItem("authToken") : token;
	axios.defaults.headers.post['Content-Type'] = 'application/json';
	axios.defaults.baseURL = api.root;
	axios.defaults.withCredentials = true;
	// axios.interceptors.request.use((config) => {
	// 	console.log('config headers', config);

	// 	return config;
	// }, (error) => {
	// 	console.error("interceptors error", error);
	// });


	axios.interceptors.response.use(
		response => response,
		error => {
			// if (error?.response?.status === 401){
			//   localStorage.removeItem("authUser")
			//   localStorage.removeItem("authToken")

			//   window.location.href = '/'
			// }

			return Promise.reject(error)
		}
	)
}
export default configAxios;