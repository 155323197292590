import SessionSrv from '../../services/SessionSrv';
import {
  SESSION_GET_LIST,
  SESSION_GET_LIST_SUCCESS,
  SESSION_GET_LIST_ERROR,

  SESSION_GET_ONE,
  SESSION_GET_ONE_SUCCESS,
  SESSION_GET_ONE_ERROR,

  SESSION_GET_BY_FILTER,
  SESSION_GET_BY_FILTER_SUCCESS,
  SESSION_GET_BY_FILTER_ERROR,

  SESSION_ADD_ONE,
  SESSION_ADD_ONE_SUCCESS,
  SESSION_ADD_ONE_ERROR,

  SESSION_EDIT_ONE,
  SESSION_EDIT_ONE_SUCCESS,
  SESSION_EDIT_ONE_ERROR,

  SESSION_DELETE_ONE,
  SESSION_DELETE_ONE_SUCCESS,
  SESSION_DELETE_ONE_ERROR


} from '../actions';

// GET LIST
export const getSessionsList = (payload) => ({
  type: SESSION_GET_LIST,
  payload
});
export const getSessionsListSuccess = (payload) => ({
  type: SESSION_GET_LIST_SUCCESS,
  payload
});
export const getSessionsListError = (payload) => ({
  type: SESSION_GET_LIST_ERROR,
  payload
});


// GET ONE
export const getOneSession = (payload) => ({
  type: SESSION_GET_ONE,
  payload
});
export const getOneSessionSuccess = (payload) => ({
  type: SESSION_GET_ONE_SUCCESS,
  payload
});
export const getOneSessionError = (payload) => ({
  type: SESSION_GET_ONE_ERROR,
  payload
});


// GET CLASS BY FILTER

export const getSessionByFilter = (payload) => ({
  type: SESSION_GET_BY_FILTER,
  payload
});
export const getSessionByFilterSuccess = (payload) => ({
  type: SESSION_GET_BY_FILTER_SUCCESS,
  payload
});
export const getSessionByFilterError = (payload) => ({
  type: SESSION_GET_BY_FILTER_ERROR,
  payload
});

export const getSessionByFilterCB = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: SESSION_GET_BY_FILTER,
    });

    SessionSrv.getList(payload)
      .then((response) => {
        if (response && response?.data) {
          const resp = {
            sessions: response?.data?.sessions,
            count: response.data.count
          }

          dispatch({
            type: SESSION_GET_BY_FILTER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: SESSION_GET_BY_FILTER_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// ADD ONE
export const addSession = (payload) => ({
  type: SESSION_ADD_ONE,
  payload
});
export const addSessionSuccess = (payload) => ({
  type: SESSION_ADD_ONE_SUCCESS,
  payload
});
export const addSessionError = (payload) => ({
  type: SESSION_ADD_ONE_ERROR,
  payload
});

// EDIT ONE
export const editOneSession = (payload) => ({
  type: SESSION_EDIT_ONE,
  payload
});
export const editOneSessionSuccess = (payload) => ({
  type: SESSION_EDIT_ONE_SUCCESS,
  payload
});
export const editOneSessionError = (payload) => ({
  type: SESSION_EDIT_ONE_ERROR,
  payload
});

// DELETE ONE
export const deleteOneSession = (payload) => ({
  type: SESSION_DELETE_ONE,
  payload
});
export const deleteOneSessionSuccess = (payload) => ({
  type: SESSION_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneSessionError = (payload) => ({
  type: SESSION_DELETE_ONE_ERROR,
  payload
});