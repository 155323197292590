import {
  ADD_QUESTION,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_ERROR,

  GET_LIST_QUESTIONS,
  GET_LIST_QUESTIONS_SUCCESS,
  GET_LIST_QUESTIONS_ERROR,

  DELETE_QUESTION,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_ERROR,

  EDIT_QUESTION,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_ERROR,
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'

const INIT_STATE = {
  quizzes: [],
  count: 0,
  quize: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

  switch (action.type) {
    case ADD_QUESTION:
      return { ...state, loading: true, error: '' };
    case ADD_QUESTION_SUCCESS:

      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      const quiz = action.payload.quiz
      const newList = state.quizzes?.push(quiz)
      return { ...state, loading: false, error: '', quiz, quizzes: newList };
    case ADD_QUESTION_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


      // EDIT ONE
    case EDIT_QUESTION:
      return { ...state, loading: true, error: '' };
    case EDIT_QUESTION_SUCCESS:
      const questions = state.quizzes
      if (action.payload.index !== null && action.payload.index !== undefined)
      questions[action.payload.index] = action.payload.question
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        quizzes: questions
      };
    case EDIT_QUESTION_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




    // DELETE ONE
    case DELETE_QUESTION:
      return { ...state, loading: true, error: '' };
    case DELETE_QUESTION_SUCCESS:
      const new_quizzes = state.quizzes
      if (action.payload.index !== null && action.payload.index !== undefined) {
        new_quizzes.splice(action.payload.index, 1)
      }

      return {
        ...state, loading: false, error: '',
        quizzes: new_quizzes
      };
    case DELETE_QUESTION_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    //GET LIST
      case GET_LIST_QUESTIONS:
        return {
          ...state, loading: true, error: '',
          count: 0
        };
      case GET_LIST_QUESTIONS_SUCCESS:
        const old = state.quizzes
        return {
          ...state, loading: false,
          quizzes: action.payload.quizzes ? [...action.payload.quizzes] : [...old, ...action.payload.quizzes || []],
          count: action.payload.count || 0
        };
      case GET_LIST_QUESTIONS_ERROR:
        return { ...state, loading: false, error: action.payload.message };

    default: return { ...state };
  }
}
