import {
    REQUEST_MANAGER_GET_LIST,
    REQUEST_MANAGER_GET_LIST_ERROR,
    REQUEST_MANAGER_GET_LIST_SUCCESS,

    REQUEST_MANAGER_GET_ITEM,
    REQUEST_MANAGER_GET_ITEM_ERROR,
    REQUEST_MANAGER_GET_ITEM_SUCCESS,

    REQUEST_MANAGER_ADD,
    REQUEST_MANAGER_ADD_SUCCESS,
    REQUEST_MANAGER_ADD_ERROR,

    REQUEST_MANAGER_EDIT_ONE,
    REQUEST_MANAGER_EDIT_ONE_SUCCESS,
    REQUEST_MANAGER_EDIT_ONE_ERROR,

    REQUEST_MANAGER_DELETE_ONE,
    REQUEST_MANAGER_DELETE_ONE_SUCCESS,
    REQUEST_MANAGER_DELETE_ONE_ERROR
} from './actionTypes'


// GET ONE
export const getRequestManager = (payload) => ({
    type: REQUEST_MANAGER_GET_ITEM,
    payload
  });
  export const getRequestManagerSuccess = (payload) => ({
    type: REQUEST_MANAGER_GET_ITEM_SUCCESS,
    payload
  });
  export const getRequestManagerError = (payload) => ({
    type: REQUEST_MANAGER_GET_ITEM_ERROR,
    payload
});
  

// GET LIST
export const getRequestsManagerList = (payload) => ({
    type: REQUEST_MANAGER_GET_LIST,
    payload
  });
  export const getRequestsManagerListSuccess = (items) => ({
    type: REQUEST_MANAGER_GET_LIST_SUCCESS,
    payload: items
  });
  export const getRequestsManagerListError = (error) => ({
    type: REQUEST_MANAGER_GET_LIST_ERROR,
    payload: error
});
  

//ADD ONE
export const addRequestManager = (payload) => ({
    type: REQUEST_MANAGER_ADD,
    payload
  })
  export const addRequestManagerSuccess = (items) => ({
    type: REQUEST_MANAGER_ADD_SUCCESS,
    payload: items
  })
  export const addRequestManagerError = (error) => ({
    type: REQUEST_MANAGER_ADD_ERROR,
    payload: error
});
  


// EDIT ONE
export const editRequestManager = (payload) => ({
    type: REQUEST_MANAGER_EDIT_ONE,
    payload
  });
  export const editRequestManagerSuccess = (payload) => ({
    type: REQUEST_MANAGER_EDIT_ONE_SUCCESS,
    payload
  });
  export const editRequestManagerError = (payload) => ({
    type: REQUEST_MANAGER_EDIT_ONE_ERROR,
    payload
});


// DELETE ONE
export const deleteOneRequestManager = (payload) => ({
    type: REQUEST_MANAGER_DELETE_ONE,
    payload
  });
  export const deleteOneRequestManagerSuccess = (payload) => ({
    type: REQUEST_MANAGER_DELETE_ONE_SUCCESS,
    payload
  });
  export const deleteOneRequestManagerError = (payload) => ({
    type: REQUEST_MANAGER_DELETE_ONE_ERROR,
    payload
  });
  