import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  COURSE_PROGRESS_GET_LIST,
  COURSE_PROGRESS_GET_ONE,
  COURSE_PROGRESS_EDIT_ONE,

} from "../actions";

import {
  getCoursesProgessListSuccess,
  getCoursesProgessListError,

  getOneCourseProgessSuccess,
  getOneCourseProgessError,

  editCourseProgessSuccess,
  editCourseProgessError,

} from "./actions";


//GET LIST
const getCoursesProgessListRequest = async (payload) => {
  if (payload.payload?.course)
    return await axios.get(api.courses + `/progress?course=${payload.payload?.course}&partner=${payload.payload?.partner}`)
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
};
function* getCoursesProgessList(payload) {
  try {
    const ret = yield call(getCoursesProgessListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getCoursesProgessListSuccess({ filesProgress: ret.data.filesProgress || [], count: ret.data.count || 0 }));

    else yield put(getCoursesProgessListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getCoursesProgessListError({ error, message: (error) ? error.message : '' }));
  }
}





// //GET ONE
// const getOneCoursesProgessRequest = async (payload) => {
//   return await axios.get(api.courses + `/file-progress/${payload._id}`)
//     .then(resp => resp)
//     .catch(error => { return (error && error.response) ? error.response : error })
// };
// function* getOneCourseProgess({ payload }) {
//   try {
//     const ret = yield call(getOneCoursesProgessRequest, payload);
//     if (ret && ret.status === 200)
//       yield put(getOneCourseProgessSuccess({ fileProgress: ret.data.fileProgress }));

//     else yield put(getOneCourseProgessError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

//   } catch (error) {
//     yield put(getOneCourseProgessError({ error, message: (error) ? error.message : '' }));
//   }
// }


//EDIT ONE
const editOneCourseProgessRequest = async (payload) => {
 if(payload?.fileId) return await axios.patch(`${api.courses}/edit-progress/${payload.fileId}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneCourseProgess({ payload }) {
  try {
    const ret = yield call(editOneCourseProgessRequest, payload);

    if (ret && ret.status === 200) {
      // const { history } = payload;

      // if (history) history.push('/teacher/courses')
      yield put(editCourseProgessSuccess({ fileProgress: ret.data.fileProgress }));
    }
    else yield put(editCourseProgessError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editCourseProgessError({ error, message: (error) ? error.message : '' }));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(COURSE_PROGRESS_GET_LIST, getCoursesProgessList);
}

// export function* watchGetOne() {
//   yield takeEvery(COURSE_PROGRESS_GET_ONE, getOneCourseProgess);
// }

export function* watchEditOne() {
  yield takeEvery(COURSE_PROGRESS_EDIT_ONE, editOneCourseProgess);
}



export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    // fork(watchGetOne),
    fork(watchEditOne),
  ]);
}
