import axios from "axios"
import { api } from "../constants/defaultValues"

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API,
})


axiosApi.interceptors.response.use(
  response => response,
  error => {
    // if (error?.response?.status === 401){
    //   localStorage.removeItem("authUser")
    //   localStorage.removeItem("authToken")

    //   window.location.href = '/'
    // }

    return Promise.reject(error)
  }
)

export function axiosGlobalConfigs(token) {
  // console.log();
  axiosApi.defaults.headers.common['Authorization'] = (!token || token === "") ? localStorage.getItem("authToken") : token;
  axiosApi.defaults.headers.post['Content-Type'] = 'application/json';
	axios.defaults.withCredentials = true;

  axiosApi.defaults.baseURL = api.root
}

export default axiosApi;