import {

	MENU_SET_CLASSNAMES,
	MENU_CONTAINER_ADD_CLASSNAME,
	MENU_CLICK_MOBILE_MENU,
	MENU_CHANGE_DEFAULT_CLASSES,
	MENU_CHANGE_HAS_SUB_ITEM_STATUS
} from '../actions';

import { defaultMenuType, subHiddenBreakpoint, menuHiddenBreakpoint } from '../../constants/defaultValues'

let selectedMenu = null

try {
	selectedMenu = JSON.parse(localStorage.getItem('selected_menu'))
  } catch (error) {
	selectedMenu = null;
}

const INIT_STATE = {
	containerClassnames: defaultMenuType,
	subHiddenBreakpoint,
	menuHiddenBreakpoint,
	menuClickCount: 0,
	selectedMenu ,
	selectedMenuHasSubItems: defaultMenuType === "menu-default" //if you use menu-sub-hidden as default menu type, set value of this variable to false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case MENU_CHANGE_HAS_SUB_ITEM_STATUS:
			return Object.assign({}, state, {
				selectedMenuHasSubItems: action.payload
			})

		case MENU_SET_CLASSNAMES:
			return Object.assign({}, state, {
				containerClassnames: action.payload.containerClassnames,
				menuClickCount: action.payload.menuClickCount
			})

		case MENU_CLICK_MOBILE_MENU:
			return Object.assign({}, state, {
				containerClassnames: action.payload.containerClassnames,
				menuClickCount: action.payload.menuClickCount
			})

		case MENU_CONTAINER_ADD_CLASSNAME:
			return Object.assign({}, state, {
				containerClassnames: action.payload
			})

		case MENU_CHANGE_DEFAULT_CLASSES:
			return Object.assign({}, state, {
				containerClassnames: action.payload
			})
		
		case "MENU_SELECT_ONE":
			if (!action.payload) localStorage.removeItem('selected_menu')
			else {
				localStorage.setItem("selected_menu", JSON.stringify(action.payload))
			}
			return {
				...state, selectedMenu: action.payload,
			};
		case "LOGOUT_USER":
			localStorage.removeItem('selected_menu')
			return {
				...state, selectedMenu: null,
			};


		default: return { ...state };
	}
}
