import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  PARTNER_GET_USER_PARTNER_LIST,
  PARTNER_GET_LIST, PARTNER_ADD_ONE, PARTNER_GET_ONE, PARTNER_EDIT_ONE, PARTNER_DELETE_ONE , GET_PARTNER_NETWORK
} from "./actionTypes";

import {
  getUserPartnersListSuccess,
  getUserPartnersListError,

  getPartnersListSuccess,
  getPartnersListError,

  getOnePartnerSuccess,
  getOnePartnerError,

  addPartnerSuccess,
  addPartnerError,

  editPartnerSuccess,
  editPartnerError,

  deleteOnePartnerSuccess,
  deleteOnePartnerError,

  getPartnerNetworkSuccess,
  getPartnerNetworkError

} from "./actions";


// GET USER PARTNERS
const getUserPartnersListRequest = async (payload) => {
  return axios.get(api.users + `/${payload.user_id}/partners`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getUserPartnersList({ payload }) {
  try {
    const ret = yield call(getUserPartnersListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getUserPartnersListSuccess({ partners: ret.data.partners || [], count: ret.data.count || 0 }));

    else {
      if (ret.status === 401) {
        // localStorage.clear();
        //   window.location.href = "/"
      }
      else
        yield put(getUserPartnersListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))
    }

  } catch (error) {
    yield put(getUserPartnersListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET LIST
const getPartnersListRequest = async (payload) => {
  return axios.get(api.partners + `/list${payload.offset === undefined ? '' : '/' + payload.offset}${payload.limit === undefined ? '' : '/' + payload.limit}?searchInput=${payload.searchInput || ''}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* getPartnersList({ payload }) {
  try {
    const ret = yield call(getPartnersListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getPartnersListSuccess({ partners: ret.data.partners || [], count: ret.data.count || 0, offset: payload.offset }));

    else yield put(getPartnersListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getPartnersListError({ error, message: (error) ? error.message : '' }));
  }
}

//GET ONE
const getOnePartnersRequest = async (payload) => {
  return axios.get(api.partners + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOnePartner({ payload }) {
  try {
    const ret = yield call(getOnePartnersRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOnePartnerSuccess({ partner: ret.data.partner }));

    else yield put(getOnePartnerError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOnePartnerError({ error, message: (error) ? error.message : '' }));
  }
}


//ADD ONE
const addPartnerRequest = async (payload) => {
  return axios.post(api.partners, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addPartner({ payload }) {
  try {
    const ret = yield call(addPartnerRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/my-institutions/partners')
      yield put(addPartnerSuccess({ partner: ret.data.partner }));
    }
    else yield put(addPartnerError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addPartnerError({ error, message: (error) ? error.message : '' }));
  }
}

//EDIT ONE
const editOnePartnerRequest = async (payload) => {

  return axios.patch(`${api.partners}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOnePartner({ payload,action }) {
  try {
    const ret = yield call(editOnePartnerRequest, payload);

    if (ret && ret.status === 200) {
      const { history, user_id } = payload;

      if (history) history.push('/app/my-institutions/partners')
      yield put(editPartnerSuccess({ partner: ret.data.partner }));
    }
    else yield put(editPartnerError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editPartnerError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOnePartnerRequest = async (payload) => {
  return axios.delete(`${api.partners}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOnePartner({ payload }) {
  try {
    const ret = yield call(deleteOnePartnerRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/my-institutions/partners')
      yield put(deleteOnePartnerSuccess({ index }));
    }
    else yield put(deleteOnePartnerError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOnePartnerError({ error, message: (error) ? error.message : '' }));
  }
}



// GET NETWORK
const getPartnerNetworkRequest = async (payload) => {
  return axios.get(`${api.network}/get-one/${payload.partner_id}`,{ headers: { Authorization: payload.token }} )
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getPartnerNetwork({ payload }) {
  try {
    const ret = yield call(getPartnerNetworkRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;
      // console.log("GET NETWORK : ",ret);
      if (history) history.push('/network')
      yield put(getPartnerNetworkSuccess({ network : ret.data.network }));
    }
    else yield put(getPartnerNetworkError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(getPartnerNetworkError({ error, message: (error) ? error.message : '' }));
  }
}


//////// WATCHERS
export function* watchGetListUser() {
  yield takeEvery(PARTNER_GET_USER_PARTNER_LIST, getUserPartnersList);
}

export function* watchGetList() {
  yield takeEvery(PARTNER_GET_LIST, getPartnersList);
}

export function* watchGetOne() {
  yield takeEvery(PARTNER_GET_ONE, getOnePartner);
}

export function* watchEditOne() {
  yield takeEvery(PARTNER_EDIT_ONE, editOnePartner);
}

export function* watchAddOne() {
  yield takeEvery(PARTNER_ADD_ONE, addPartner);
}

export function* watchDeleteOne() {
  yield takeEvery(PARTNER_DELETE_ONE, deleteOnePartner);
}

export function* watchGetNetwork() {
  yield takeEvery(GET_PARTNER_NETWORK, getPartnerNetwork);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetListUser),
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchGetNetwork)
  ]);
}
