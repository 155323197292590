import SubjectSrv from '../../services/SubjectSrv';
import {
  SUBJECT_GET_LIST,
  SUBJECT_GET_LIST_SUCCESS,
  SUBJECT_GET_LIST_ERROR,

  SUBJECT_GET_ONE,
  SUBJECT_GET_ONE_SUCCESS,
  SUBJECT_GET_ONE_ERROR,

  SUBJECT_GET_BY_FILTER,
  SUBJECT_GET_BY_FILTER_SUCCESS,
  SUBJECT_GET_BY_FILTER_ERROR,

  SUBJECT_ADD_ONE,
  SUBJECT_ADD_ONE_SUCCESS,
  SUBJECT_ADD_ONE_ERROR,

  SUBJECT_EDIT_ONE,
  SUBJECT_EDIT_ONE_SUCCESS,
  SUBJECT_EDIT_ONE_ERROR,

  SUBJECT_DELETE_ONE,
  SUBJECT_DELETE_ONE_SUCCESS,
  SUBJECT_DELETE_ONE_ERROR,


  SUBJECTS_DELETE_MANY,
  SUBJECTS_DELETE_MANY_SUCCESS,
  SUBJECTS_DELETE_MANY_ERROR,
} from '../actions';

// GET LIST
export const getSubjectsList = (payload) => ({
  type: SUBJECT_GET_LIST,
  payload
});
export const getSubjectsListSuccess = (payload) => ({
  type: SUBJECT_GET_LIST_SUCCESS,
  payload
});
export const getSubjectsListError = (payload) => ({
  type: SUBJECT_GET_LIST_ERROR,
  payload
});







// GET LIST
export const getOneSubject = (payload) => ({
  type: SUBJECT_GET_ONE,
  payload
});
export const getOneSubjectSuccess = (payload) => ({
  type: SUBJECT_GET_ONE_SUCCESS,
  payload
});
export const getOneSubjectError = (payload) => ({
  type: SUBJECT_GET_ONE_ERROR,
  payload
});



// GET BY FILTER
export const getSubjectByFilter = (payload) => ({
  type: SUBJECT_GET_BY_FILTER,
  payload
});
export const getSubjectByFilterSuccess = (payload) => ({
  type: SUBJECT_GET_BY_FILTER_SUCCESS,
  payload
});
export const getSubjectByFilterError = (payload) => ({
  type: SUBJECT_GET_BY_FILTER_ERROR,
  payload
});
export const getSubjectByFilterCB = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: SUBJECT_GET_BY_FILTER,
    });

    SubjectSrv.getList(payload)
      .then((response) => {
        if (response && response?.data) {
          const resp = {
            subjects: response?.data?.subjects,
            count: response.data.count
          }

          dispatch({
            type: SUBJECT_GET_BY_FILTER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: SUBJECT_GET_BY_FILTER_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};






// ADD ONE
export const addSubject = (payload) => ({
  type: SUBJECT_ADD_ONE,
  payload
});
export const addSubjectSuccess = (payload) => ({
  type: SUBJECT_ADD_ONE_SUCCESS,
  payload
});
export const addSubjectError = (payload) => ({
  type: SUBJECT_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editSubject = (payload) => ({
  type: SUBJECT_EDIT_ONE,
  payload
});
export const editSubjectSuccess = (payload) => ({
  type: SUBJECT_EDIT_ONE_SUCCESS,
  payload
});
export const editSubjectError = (payload) => ({
  type: SUBJECT_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneSubject = (payload) => ({
  type: SUBJECT_DELETE_ONE,
  payload
});
export const deleteOneSubjectSuccess = (payload) => ({
  type: SUBJECT_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneSubjectError = (payload) => ({
  type: SUBJECT_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManySubjects = (payload) => ({
  type: SUBJECTS_DELETE_MANY,
  payload
});
export const deleteManySubjectsSuccess = (payload) => ({
  type: SUBJECTS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManySubjectsError = (payload) => ({
  type: SUBJECTS_DELETE_MANY_ERROR,
  payload
});