/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export * from "./auth/actions"
export * from "./institution/actions"

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS";
export const MENU_SELECT_ONE = "MENU_SELECT_ONE";

/********************************************************************************************************************************/
export const SCOOLYEAR_GET_LIST = "SCOOLYEAR_GET_LIST";
export const SCOOLYEAR_GET_LIST_SUCCESS = "SCOOLYEAR_GET_LIST_SUCCESS";
export const SCOOLYEAR_GET_LIST_ERROR = "SCOOLYEAR_GET_LIST_ERROR";

export const SCOOLYEAR_GET_ONE = "SCOOLYEAR_GET_ONE";
export const SCOOLYEAR_GET_ONE_SUCCESS = "SCOOLYEAR_GET_ONE_SUCCESS";
export const SCOOLYEAR_GET_ONE_ERROR = "SCOOLYEAR_GET_ONE_ERROR";

export const SCOOLYEAR_GET_BY_FILTER = "SCOOLYEAR_GET_BY_FILTER";
export const SCOOLYEAR_GET_BY_FILTER_SUCCESS = "SCOOLYEAR_GET_BY_FILTER_SUCCESS";
export const SCOOLYEAR_GET_BY_FILTER_ERROR = "SCOOLYEAR_GET_BY_FILTER_ERROR";

export const SCOOLYEAR_ADD_ONE = "SCOOLYEAR_ADD_ONE";
export const SCOOLYEAR_ADD_ONE_SUCCESS = "SCOOLYEAR_ADD_ONE_SUCCESS";
export const SCOOLYEAR_ADD_ONE_ERROR = "SCOOLYEAR_ADD_ONE_ERROR";

export const SCOOLYEAR_EDIT_ONE = "SCOOLYEAR_EDIT_ONE";
export const SCOOLYEAR_EDIT_ONE_SUCCESS = "SCOOLYEAR_EDIT_ONE_SUCCESS";
export const SCOOLYEAR_EDIT_ONE_ERROR = "SCOOLYEAR_EDIT_ONE_ERROR";

export const SCHOOLYEAR_DELETE_ONE = "SCHOOLYEAR_DELETE_ONE";
export const SCHOOLYEAR_DELETE_ONE_SUCCESS = "SCHOOLYEAR_DELETE_ONE_SUCCESS";
export const SCHOOLYEAR_DELETE_ONE_ERROR = "SCHOOLYEAR_DELETE_ONE_ERROR";

export const SCHOOLYEARS_DELETE_MANY = "SCHOOLYEARS_DELETE_MANY";
export const SCHOOLYEARS_DELETE_MANY_SUCCESS = "SCHOOLYEARS_DELETE_MANY_SUCCESS";
export const SCHOOLYEARS_DELETE_MANY_ERROR = "SCHOOLYEARS_DELETE_MANY_ERROR";
/********************************************************************************************************************************/


export const PERIODTYPE_GET_LIST = "PERIODTYPE_GET_LIST";
export const PERIODTYPE_GET_LIST_SUCCESS = "PERIODTYPE_GET_LIST_SUCCESS";
export const PERIODTYPE_GET_LIST_ERROR = "PERIODTYPE_GET_LIST_ERROR";

export const PERIODTYPE_GET_LIST_FILTER = "PERIODTYPE_GET_LIST_FILTER";
export const PERIODTYPE_GET_LIST_FILTER_SUCCESS = "PERIODTYPE_GET_LIST_FILTER_SUCCESS";
export const PERIODTYPE_GET_LIST_FILTER_ERROR = "PERIODTYPE_GET_LIST_FILTER_ERROR";

export const PERIODTYPE_GET_ONE = "PERIODTYPE_GET_ONE";
export const PERIODTYPE_GET_ONE_SUCCESS = "PERIODTYPE_GET_ONE_SUCCESS";
export const PERIODTYPE_GET_ONE_ERROR = "PERIODTYPE_GET_ONE_ERROR";

export const PERIODTYPE_GET_ACTUEL = "PERIODTYPE_GET_ACTUEL";
export const PERIODTYPE_GET_ACTUEL_SUCCESS = "PERIODTYPE_GET_ACTUEL_SUCCESS";
export const PERIODTYPE_GET_ACTUEL_ERROR = "PERIODTYPE_GET_ACTUEL_ERROR";

export const PERIODTYPE_ADD_ONE = "PERIODTYPE_ADD_ONE";
export const PERIODTYPE_ADD_ONE_SUCCESS = "PERIODTYPE_ADD_ONE_SUCCESS";
export const PERIODTYPE_ADD_ONE_ERROR = "PERIODTYPE_ADD_ONE_ERROR";

export const PERIODTYPE_EDIT_ONE = "PERIODTYPE_EDIT_ONE";
export const PERIODTYPE_EDIT_ONE_SUCCESS = "PERIODTYPE_EDIT_ONE_SUCCESS";
export const PERIODTYPE_EDIT_ONE_ERROR = "PERIODTYPE_EDIT_ONE_ERROR";

export const PERIODTYPE_DELETE_ONE = "PERIODTYPE_DELETE_ONE";
export const PERIODTYPE_DELETE_ONE_SUCCESS = "PERIODTYPE_DELETE_ONE_SUCCESS";
export const PERIODTYPE_DELETE_ONE_ERROR = "PERIODTYPE_DELETE_ONE_ERROR";

export const PERIODTYPE_SELECT_PERIOD = "PERIODTYPE_SELECT_PERIOD";

export const PERIODTYPES_DELETE_MANY = "PERIODTYPES_DELETE_MANY";
export const PERIODTYPES_DELETE_MANY_SUCCESS = "PERIODTYPES_DELETE_MANY_SUCCESS";
export const PERIODTYPES_DELETE_MANY_ERROR = "PERIODTYPES_DELETE_MANY_ERROR"
/********************************************************************************************************************************/



export const EDUCATIONLEVEL_GET_LIST = "EDUCATIONLEVEL_GET_LIST";
export const EDUCATIONLEVEL_GET_LIST_SUCCESS = "EDUCATIONLEVEL_GET_LIST_SUCCESS";
export const EDUCATIONLEVEL_GET_LIST_ERROR = "EDUCATIONLEVEL_GET_LIST_ERROR";

export const EDUCATIONLEVEL_GET_ONE = "EDUCATIONLEVEL_GET_ONE";
export const EDUCATIONLEVEL_GET_ONE_SUCCESS = "EDUCATIONLEVEL_GET_ONE_SUCCESS";
export const EDUCATIONLEVEL_GET_ONE_ERROR = "EDUCATIONLEVEL_GET_ONE_ERROR";

export const EDUCATIONLEVEL_GET_BY_FILTER = "EDUCATIONLEVEL_GET_BY_FILTER";
export const EDUCATIONLEVEL_GET_BY_FILTER_SUCCESS = "EDUCATIONLEVEL_GET_BY_FILTER_SUCCESS";
export const EDUCATIONLEVEL_GET_BY_FILTER_ERROR = "EDUCATIONLEVEL_GET_BY_FILTER_ERROR";

export const EDUCATIONLEVEL_ADD_ONE = "EDUCATIONLEVEL_ADD_ONE";
export const EDUCATIONLEVEL_ADD_ONE_SUCCESS = "EDUCATIONLEVEL_ADD_ONE_SUCCESS";
export const EDUCATIONLEVEL_ADD_ONE_ERROR = "EDUCATIONLEVEL_ADD_ONE_ERROR";

export const EDUCATIONLEVEL_EDIT_ONE = "EDUCATIONLEVEL_EDIT_ONE";
export const EDUCATIONLEVEL_EDIT_ONE_SUCCESS = "EDUCATIONLEVEL_EDIT_ONE_SUCCESS";
export const EDUCATIONLEVEL_EDIT_ONE_ERROR = "EDUCATIONLEVEL_EDIT_ONE_ERROR";

export const EDUCATIONLEVEL_DELETE_ONE = "EDUCATIONLEVEL_DELETE_ONE";
export const EDUCATIONLEVEL_DELETE_ONE_SUCCESS = "EDUCATIONLEVEL_DELETE_ONE_SUCCESS";
export const EDUCATIONLEVEL_DELETE_ONE_ERROR = "EDUCATIONLEVEL_DELETE_ONE_ERROR";

export const EDUCATIONLEVELS_DELETE_MANY = "EDUCATIONLEVELS_DELETE_MANY";
export const EDUCATIONLEVELS_DELETE_MANY_SUCCESS = "EDUCATIONLEVELS_DELETE_MANY_SUCCESS";
export const EDUCATIONLEVELS_DELETE_MANY_ERROR = "EDUCATIONLEVELS_DELETE_MANY_ERROR";
/********************************************************************************************************************************/


export const SCHOOLLEVEL_GET_LIST = "SCHOOLLEVEL_GET_LIST";
export const SCHOOLLEVEL_GET_LIST_SUCCESS = "SCHOOLLEVEL_GET_LIST_SUCCESS";
export const SCHOOLLEVEL_GET_LIST_ERROR = "SCHOOLLEVEL_GET_LIST_ERROR";

export const SCHOOLLEVEL_GET_ONE = "SCHOOLLEVEL_GET_ONE";
export const SCHOOLLEVEL_GET_ONE_SUCCESS = "SCHOOLLEVEL_GET_ONE_SUCCESS";
export const SCHOOLLEVEL_GET_ONE_ERROR = "SCHOOLLEVEL_GET_ONE_ERROR";

export const SCHOOLLEVEL_GET_BY_FILTER = "SCHOOLLEVEL_GET_BY_FILTER";
export const SCHOOLLEVEL_GET_BY_FILTER_SUCCESS = "SCHOOLLEVEL_GET_BY_FILTER_SUCCESS";
export const SCHOOLLEVEL_GET_BY_FILTER_ERROR = "SCHOOLLEVEL_GET_BY_FILTER_ERROR";

export const SCHOOLLEVEL_ADD_ONE = "SCHOOLLEVEL_ADD_ONE";
export const SCHOOLLEVEL_ADD_ONE_SUCCESS = "SCHOOLLEVEL_ADD_ONE_SUCCESS";
export const SCHOOLLEVEL_ADD_ONE_ERROR = "SCHOOLLEVEL_ADD_ONE_ERROR";

export const SCHOOLLEVEL_EDIT_ONE = "SCHOOLLEVEL_EDIT_ONE";
export const SCHOOLLEVEL_EDIT_ONE_SUCCESS = "SCHOOLLEVEL_EDIT_ONE_SUCCESS";
export const SCHOOLLEVEL_EDIT_ONE_ERROR = "SCHOOLLEVEL_EDIT_ONE_ERROR";

export const SCHOOLLEVEL_DELETE_ONE = "SCHOOLLEVEL_DELETE_ONE";
export const SCHOOLLEVEL_DELETE_ONE_SUCCESS = "SCHOOLLEVEL_DELETE_ONE_SUCCESS";
export const SCHOOLLEVEL_DELETE_ONE_ERROR = "SCHOOLLEVEL_DELETE_ONE_ERROR";


export const SCHOOLLEVELS_DELETE_MANY = "SCHOOLLEVELS_DELETE_MANY";
export const SCHOOLLEVELS_DELETE_MANY_SUCCESS = "SCHOOLLEVELS_DELETE_MANY_SUCCESS";
export const SCHOOLLEVELS_DELETE_MANY_ERROR = "SCHOOLLEVELS_DELETE_MANY_ERROR";
/********************************************************************************************************************************/


export const DIPLOMA_GET_LIST = "DIPLOMA_GET_LIST";
export const DIPLOMA_GET_LIST_SUCCESS = "DIPLOMA_GET_LIST_SUCCESS";
export const DIPLOMA_GET_LIST_ERROR = "DIPLOMA_GET_LIST_ERROR";

export const DIPLOMA_GET_ONE = "DIPLOMA_GET_ONE";
export const DIPLOMA_GET_ONE_SUCCESS = "DIPLOMA_GET_ONE_SUCCESS";
export const DIPLOMA_GET_ONE_ERROR = "DIPLOMA_GET_ONE_ERROR";

export const DIPLOMA_GET_BY_FILTER = "DIPLOMA_GET_BY_FILTER";
export const DIPLOMA_GET_BY_FILTER_SUCCESS = "DIPLOMA_GET_BY_FILTER_SUCCESS";
export const DIPLOMA_GET_BY_FILTER_ERROR = "DIPLOMA_GET_BY_FILTER_ERROR";

export const DIPLOMA_ADD_ONE = "DIPLOMA_ADD_ONE";
export const DIPLOMA_ADD_ONE_SUCCESS = "DIPLOMA_ADD_ONE_SUCCESS";
export const DIPLOMA_ADD_ONE_ERROR = "DIPLOMA_ADD_ONE_ERROR";

export const DIPLOMA_EDIT_ONE = "DIPLOMA_EDIT_ONE";
export const DIPLOMA_EDIT_ONE_SUCCESS = "DIPLOMA_EDIT_ONE_SUCCESS";
export const DIPLOMA_EDIT_ONE_ERROR = "DIPLOMA_EDIT_ONE_ERROR";

export const DIPLOMA_DELETE_ONE = "DIPLOMA_DELETE_ONE";
export const DIPLOMA_DELETE_ONE_SUCCESS = "DIPLOMA_DELETE_ONE_SUCCESS";
export const DIPLOMA_DELETE_ONE_ERROR = "DIPLOMA_DELETE_ONE_ERROR";


export const DIPLOMAS_DELETE_MANY = "DIPLOMAS_DELETE_MANY";
export const DIPLOMAS_DELETE_MANY_SUCCESS = "DIPLOMAS_DELETE_MANY_SUCCESS";
export const DIPLOMAS_DELETE_MANY_ERROR = "DIPLOMAS_DELETE_MANY_ERROR";
/********************************************************************************************************************************/


export const SPECIALITY_GET_LIST = "SPECIALITY_GET_LIST";
export const SPECIALITY_GET_LIST_SUCCESS = "SPECIALITY_GET_LIST_SUCCESS";
export const SPECIALITY_GET_LIST_ERROR = "SPECIALITY_GET_LIST_ERROR";

export const SPECIALITY_GET_ONE = "SPECIALITY_GET_ONE";
export const SPECIALITY_GET_ONE_SUCCESS = "SPECIALITY_GET_ONE_SUCCESS";
export const SPECIALITY_GET_ONE_ERROR = "SPECIALITY_GET_ONE_ERROR";

export const SPECIALITY_GET_BY_FILTER = "SPECIALITY_GET_BY_FILTER";
export const SPECIALITY_GET_BY_FILTER_SUCCESS = "SPECIALITY_GET_BY_FILTER_SUCCESS";
export const SPECIALITY_GET_BY_FILTER_ERROR = "SPECIALITY_GET_BY_FILTER_ERROR";

export const SPECIALITY_ADD_ONE = "SPECIALITY_ADD_ONE";
export const SPECIALITY_ADD_ONE_SUCCESS = "SPECIALITY_ADD_ONE_SUCCESS";
export const SPECIALITY_ADD_ONE_ERROR = "SPECIALITY_ADD_ONE_ERROR";

export const SPECIALITY_EDIT_ONE = "SPECIALITY_EDIT_ONE";
export const SPECIALITY_EDIT_ONE_SUCCESS = "SPECIALITY_EDIT_ONE_SUCCESS";
export const SPECIALITY_EDIT_ONE_ERROR = "SPECIALITY_EDIT_ONE_ERROR";

export const SPECIALITY_DELETE_ONE = "SPECIALITY_DELETE_ONE";
export const SPECIALITY_DELETE_ONE_SUCCESS = "SPECIALITY_DELETE_ONE_SUCCESS";
export const SPECIALITY_DELETE_ONE_ERROR = "SPECIALITY_DELETE_ONE_ERROR";

export const SPECIALITIES_DELETE_MANY = "SPECIALITIES_DELETE_MANY";
export const SPECIALITIES_DELETE_MANY_SUCCESS = "SPECIALITIES_DELETE_MANY_SUCCESS";
export const SPECIALITIES_DELETE_MANY_ERROR = "SPECIALITIES_DELETE_MANY_ERROR"


/********************************************************************************************************************************/


export const SUBJECT_GET_LIST = "SUBJECT_GET_LIST";
export const SUBJECT_GET_LIST_SUCCESS = "SUBJECT_GET_LIST_SUCCESS";
export const SUBJECT_GET_LIST_ERROR = "SUBJECT_GET_LIST_ERROR";

export const SUBJECT_GET_ONE = "SUBJECT_GET_ONE";
export const SUBJECT_GET_ONE_SUCCESS = "SUBJECT_GET_ONE_SUCCESS";
export const SUBJECT_GET_ONE_ERROR = "SUBJECT_GET_ONE_ERROR";

export const SUBJECT_GET_BY_FILTER = "SUBJECT_GET_BY_FILTER";
export const SUBJECT_GET_BY_FILTER_SUCCESS = "SUBJECT_GET_BY_FILTER_SUCCESS";
export const SUBJECT_GET_BY_FILTER_ERROR = "SUBJECT_GET_BY_FILTER_ERROR";

export const SUBJECT_ADD_ONE = "SUBJECT_ADD_ONE";
export const SUBJECT_ADD_ONE_SUCCESS = "SUBJECT_ADD_ONE_SUCCESS";
export const SUBJECT_ADD_ONE_ERROR = "SUBJECT_ADD_ONE_ERROR";

export const SUBJECT_EDIT_ONE = "SUBJECT_EDIT_ONE";
export const SUBJECT_EDIT_ONE_SUCCESS = "SUBJECT_EDIT_ONE_SUCCESS";
export const SUBJECT_EDIT_ONE_ERROR = "SUBJECT_EDIT_ONE_ERROR";

export const SUBJECT_DELETE_ONE = "SUBJECT_DELETE_ONE";
export const SUBJECT_DELETE_ONE_SUCCESS = "SUBJECT_DELETE_ONE_SUCCESS";
export const SUBJECT_DELETE_ONE_ERROR = "SUBJECT_DELETE_ONE_ERROR";


export const SUBJECTS_DELETE_MANY = "SUBJECTS_DELETE_MANY";
export const SUBJECTS_DELETE_MANY_SUCCESS = "SUBJECTS_DELETE_MANY_SUCCESS";
export const SUBJECTS_DELETE_MANY_ERROR = "SUBJECTS_DELETE_MANY_ERROR";
/********************************************************************************************************************************/

// export const PARTNER_SELECT_ONE = "PARTNER_SELECT_ONE";
// export const POST_PARTNER_SELECT_ONE = "POST_PARTNER_SELECT_ONE";
// export const PARTNER_CHANGE_SECTION = "PARTNER_CHANGE_SECTION";

export const PARTNER_GET_USER_PARTNER_LIST = "PARTNER_GET_USER_PARTNER_LIST";
export const PARTNER_GET_USER_PARTNER_LIST_SUCCESS = "PARTNER_GET_USER_PARTNER_LIST_SUCCESS";
export const PARTNER_GET_USER_PARTNER_LIST_ERROR = "PARTNER_GET_USER_PARTNER_LIST_ERROR";

export const GET_PARTNER_CONTACTS_LIST = "GET_PARTNER_CONTACTS_LIST";
export const GET_PARTNER_CONTACTS_LIST_SUCCESS = "GET_PARTNER_CONTACTS_LIST_SUCCESS";
export const GET_PARTNER_CONTACTS_LIST_ERROR = "GET_PARTNER_CONTACTS_LIST_ERROR";



export const TEACHER_GET_LIST = "TEACHER_GET_LIST";
export const TEACHER_GET_LIST_SUCCESS = "TEACHER_GET_LIST_SUCCESS";
export const TEACHER_GET_LIST_ERROR = "TEACHER_GET_LIST_ERROR";

export const SCHOOL_TEACHER_GET_LIST = "SCHOOL_TEACHER_GET_LIST";
export const SCHOOL_TEACHER_GET_LIST_SUCCESS = "SCHOOL_TEACHER_GET_LIST_SUCCESS";
export const SCHOOL_TEACHER_GET_LIST_ERROR = "SCHOOL_TEACHER_GET_LIST_ERROR";

export const SCHOOL_STUDENTS_GET_LIST = "SCHOOL_STUDENTS_GET_LIST";
export const SCHOOL_STUDENTS_GET_LIST_SUCCESS = "SCHOOL_STUDENTS_GET_LIST_SUCCESS";
export const SCHOOL_STUDENTS_GET_LIST_ERROR = "SCHOOL_STUDENTS_GET_LIST_ERROR";

export const TEACHER_GET_LIST_BY_FILTER = "TEACHER_GET_LIST_BY_FILTER";
export const TEACHER_GET_LIST_BY_FILTER_SUCCESS = "TEACHER_GET_LIST_BY_FILTER_SUCCESS";
export const TEACHER_GET_LIST_BY_FILTER_ERROR = "TEACHER_GET_LIST_BY_FILTER_ERROR";

export const TEACHER_GET_LIST_PRIVATE = "TEACHER_GET_LIST_PRIVATE";
export const TEACHER_GET_LIST_PRIVATE_SUCCESS = "TEACHER_GET_LIST_PRIVATE_SUCCESS";
export const TEACHER_GET_LIST_PRIVATE_ERROR = "TEACHER_GET_LIST_PRIVATE_ERROR";


export const TEACHER_GET_ONE = "TEACHER_GET_ONE";
export const TEACHER_GET_ONE_SUCCESS = "TEACHER_GET_ONE_SUCCESS";
export const TEACHER_GET_ONE_ERROR = "TEACHER_GET_ONE_ERROR";

export const TEACHER_ADD_ONE = "TEACHER_ADD_ONE";
export const TEACHER_ADD_ONE_SUCCESS = "TEACHER_ADD_ONE_SUCCESS";
export const TEACHER_ADD_ONE_ERROR = "TEACHER_ADD_ONE_ERROR";

export const TEACHER_ADD_ONE_PRIVATE = "TEACHER_ADD_ONE_PRIVATE";
export const TEACHER_ADD_ONE_PRIVATE_SUCCESS = "TEACHER_ADD_ONE_PRIVATE_SUCCESS";
export const TEACHER_ADD_ONE_PRIVATE_ERROR = "TEACHER_ADD_ONE_PRIVATE_ERROR";

export const TEACHER_EDIT_ONE = "TEACHER_EDIT_ONE";
export const TEACHER_EDIT_ONE_SUCCESS = "TEACHER_EDIT_ONE_SUCCESS";
export const TEACHER_EDIT_ONE_ERROR = "TEACHER_EDIT_ONE_ERROR";

export const TEACHER_DELETE_ONE = "TEACHER_DELETE_ONE";
export const TEACHER_DELETE_ONE_SUCCESS = "TEACHER_DELETE_ONE_SUCCESS";
export const TEACHER_DELETE_ONE_ERROR = "TEACHER_DELETE_ONE_ERROR";


export const TEACHERS_DELETE_MANY = "TEACHERS_DELETE_MANY";
export const TEACHERS_DELETE_MANY_SUCCESS = "TEACHERS_DELETE_MANY_SUCCESS";
export const TEACHERS_DELETE_MANY_ERROR = "TEACHERS_DELETE_MANY_ERROR";

export const TEACHER_SUBSCRIBE = "TEACHER_SUBSCRIBE";
export const TEACHER_SUBSCRIBE_SUCCESS = "TEACHER_SUBSCRIBE_SUCCESS";
export const TEACHER_SUBSCRIBE_ERROR = "TEACHER_SUBSCRIBE_ERROR";

export const  TEACHER_RECOMMAND="TEACHER_RECOMMAND";
export const  TEACHER_RECOMMAND_SUCCESS="TEACHER_RECOMMAND_SUCCESS";
export const  TEACHER_RECOMMAND_ERROR="TEACHER_RECOMMAND_ERROR";

/********************************************************************************************************************************/


export const STUDENT_GET_LIST = "STUDENT_GET_LIST";
export const STUDENT_GET_LIST_SUCCESS = "STUDENT_GET_LIST_SUCCESS";
export const STUDENT_GET_LIST_ERROR = "STUDENT_GET_LIST_ERROR";

export const STUDENT_GET_ONE = "STUDENT_GET_ONE";
export const STUDENT_GET_ONE_SUCCESS = "STUDENT_GET_ONE_SUCCESS";
export const STUDENT_GET_ONE_ERROR = "STUDENT_GET_ONE_ERROR";


export const STUDENT_GET_BY_FILTER = "STUDENT_GET_BY_FILTER";
export const STUDENT_GET_BY_FILTER_SUCCESS = "STUDENT_GET_BY_FILTER_SUCCESS";
export const STUDENT_GET_BY_FILTER_ERROR = "STUDENT_GET_BY_FILTER_ERROR";

export const STUDENT_ADD_ONE = "STUDENT_ADD_ONE";
export const STUDENT_ADD_ONE_SUCCESS = "STUDENT_ADD_ONE_SUCCESS";
export const STUDENT_ADD_ONE_ERROR = "STUDENT_ADD_ONE_ERROR";

export const STUDENT_EDIT_ONE = "STUDENT_EDIT_ONE";
export const STUDENT_EDIT_ONE_SUCCESS = "STUDENT_EDIT_ONE_SUCCESS";
export const STUDENT_EDIT_ONE_ERROR = "STUDENT_EDIT_ONE_ERROR";

export const STUDENT_DELETE_ONE = "STUDENT_DELETE_ONE";
export const STUDENT_DELETE_ONE_SUCCESS = "STUDENT_DELETE_ONE_SUCCESS";
export const STUDENT_DELETE_ONE_ERROR = "STUDENT_DELETE_ONE_ERROR";

export const GET_STUDENT_ASSIGNMENTS = "GET_STUDENT_ASSIGNMENTS";
export const GET_STUDENT_ASSIGNMENTS_SUCCESS = "GET_STUDENT_ASSIGNMENTS_SUCCESS";
export const GET_STUDENT_ASSIGNMENTS_ERROR = "GET_STUDENT_ASSIGNMENTS_ERROR";

export const STUDENT_ASSIGNMENT = "STUDENT_ASSIGNMENT";
export const STUDENT_ASSIGNMENT_SUCCESS = "STUDENT_ASSIGNMENT_SUCCESS";
export const STUDENT_ASSIGNMENT_ERROR = "STUDENT_ASSIGNMENT_ERROR";

export const STUDENTS_DELETE_MANY = "STUDENTS_DELETE_MANY";
export const STUDENTS_DELETE_MANY_SUCCESS = "STUDENTS_DELETE_MANY_SUCCESS";
export const STUDENTS_DELETE_MANY_ERROR = "STUDENTS_DELETE_MANY_ERROR";
/********************************************************************************************************************************/

export const PARENT_GET_LIST = "PARENT_GET_LIST";
export const PARENT_GET_LIST_SUCCESS = "PARENT_GET_LIST_SUCCESS";
export const PARENT_GET_LIST_ERROR = "PARENT_GET_LIST_ERROR";

export const PARENT_GET_LIST_BY_FILTER = "PARENT_GET_LIST_BY_FILTER";
export const PARENT_GET_LIST_BY_FILTER_SUCCESS = "PARENT_GET_LIST_BY_FILTER_SUCCESS";
export const PARENT_GET_LIST_BY_FILTER_ERROR = "PARENT_GET_LIST_BY_FILTER_ERROR";

export const PARENT_GET_ONE = "PARENT_GET_ONE";
export const PARENT_GET_ONE_SUCCESS = "PARENT_GET_ONE_SUCCESS";
export const PARENT_GET_ONE_ERROR = "PARENT_GET_ONE_ERROR";

export const PARENT_ADD_ONE = "PARENT_ADD_ONE";
export const PARENT_ADD_ONE_SUCCESS = "PARENT_ADD_ONE_SUCCESS";
export const PARENT_ADD_ONE_ERROR = "PARENT_ADD_ONE_ERROR";

export const PARENT_EDIT_ONE = "PARENT_EDIT_ONE";
export const PARENT_EDIT_ONE_SUCCESS = "PARENT_EDIT_ONE_SUCCESS";
export const PARENT_EDIT_ONE_ERROR = "PARENT_EDIT_ONE_ERROR";

export const PARENT_DELETE_ONE = "PARENT_DELETE_ONE";
export const PARENT_DELETE_ONE_SUCCESS = "PARENT_DELETE_ONE_SUCCESS";
export const PARENT_DELETE_ONE_ERROR = "PARENT_DELETE_ONE_ERROR";

export const PARENTS_DELETE_MANY = "PARENTS_DELETE_MANY";
export const PARENTS_DELETE_MANY_SUCCESS = "PARENTS_DELETE_MANY_SUCCESS";
export const PARENTS_DELETE_MANY_ERROR = "PARENTS_DELETE_MANY_ERROR";
/********************************************************************************************************************************/


export const SKILL_GET_LIST = "SKILL_GET_LIST";
export const SKILL_GET_LIST_SUCCESS = "SKILL_GET_LIST_SUCCESS";
export const SKILL_GET_LIST_ERROR = "SKILL_GET_LIST_ERROR";

export const SKILL_GET_ONE = "SKILL_GET_ONE";
export const SKILL_GET_ONE_SUCCESS = "SKILL_GET_ONE_SUCCESS";
export const SKILL_GET_ONE_ERROR = "SKILL_GET_ONE_ERROR";

export const SKILL_ADD_ONE = "SKILL_ADD_ONE";
export const SKILL_ADD_ONE_SUCCESS = "SKILL_ADD_ONE_SUCCESS";
export const SKILL_ADD_ONE_ERROR = "SKILL_ADD_ONE_ERROR";

export const SKILL_EDIT_ONE = "SKILL_EDIT_ONE";
export const SKILL_EDIT_ONE_SUCCESS = "SKILL_EDIT_ONE_SUCCESS";
export const SKILL_EDIT_ONE_ERROR = "SKILL_EDIT_ONE_ERROR";

export const SKILL_DELETE_ONE = "SKILL_DELETE_ONE";
export const SKILL_DELETE_ONE_SUCCESS = "SKILL_DELETE_ONE_SUCCESS";
export const SKILL_DELETE_ONE_ERROR = "SKILL_DELETE_ONE_ERROR";
/********************************************************************************************************************************/


export const CLASS_GET_LIST = "CLASS_GET_LIST";
export const CLASS_GET_LIST_SUCCESS = "CLASS_GET_LIST_SUCCESS";
export const CLASS_GET_LIST_ERROR = "CLASS_GET_LIST_ERROR";

export const CLASS_GET_ONE = "CLASS_GET_ONE";
export const CLASS_GET_ONE_SUCCESS = "CLASS_GET_ONE_SUCCESS";
export const CLASS_GET_ONE_ERROR = "CLASS_GET_ONE_ERROR";

export const CLASS_GET_BY_FILTER = "CLASS_GET_BY_FILTER";
export const CLASS_GET_BY_FILTER_SUCCESS = "CLASS_GET_BY_FILTER_SUCCESS";
export const CLASS_GET_BY_FILTER_ERROR = "CLASS_GET_BY_FILTER_ERROR";

export const CLASS_ADD_ONE = "CLASS_ADD_ONE";
export const CLASS_ADD_ONE_SUCCESS = "CLASS_ADD_ONE_SUCCESS";
export const CLASS_ADD_ONE_ERROR = "CLASS_ADD_ONE_ERROR";

export const CLASS_EDIT_ONE = "CLASS_EDIT_ONE";
export const CLASS_EDIT_ONE_SUCCESS = "CLASS_EDIT_ONE_SUCCESS";
export const CLASS_EDIT_ONE_ERROR = "CLASS_EDIT_ONE_ERROR";

export const CLASS_DELETE_ONE = "CLASS_DELETE_ONE";
export const CLASS_DELETE_ONE_SUCCESS = "CLASS_DELETE_ONE_SUCCESS";
export const CLASS_DELETE_ONE_ERROR = "CLASS_DELETE_ONE_ERROR";

export const CLASS_GET_BY_TEACHER = "CLASS_GET_BY_TEACHER";

export const CLASSES_DELETE_MANY = "CLASSES_DELETE_MANY";
export const CLASSES_DELETE_MANY_SUCCESS = "CLASSES_DELETE_MANY_SUCCESS";
export const CLASSES_DELETE_MANY_ERROR = "CLASSES_DELETE_MANY_ERROR";


/********************************************************************************************************************************/


export const MODULE_GET_LIST = "MODULE_GET_LIST";
export const MODULE_GET_LIST_SUCCESS = "MODULE_GET_LIST_SUCCESS";
export const MODULE_GET_LIST_ERROR = "MODULE_GET_LIST_ERROR";

export const MODULE_GET_ONE = "MODULE_GET_ONE";
export const MODULE_GET_ONE_SUCCESS = "MODULE_GET_ONE_SUCCESS";
export const MODULE_GET_ONE_ERROR = "MODULE_GET_ONE_ERROR";

export const MODULE_GET_BY_FILTER = "MODULE_GET_BY_FILTER";
export const MODULE_GET_BY_FILTER_SUCCESS = "MODULE_GET_BY_FILTER_SUCCESS";
export const MODULE_GET_BY_FILTER_ERROR = "MODULE_GET_BY_FILTER_ERROR";

export const MODULE_ADD_ONE = "MODULE_ADD_ONE";
export const MODULE_ADD_ONE_SUCCESS = "MODULE_ADD_ONE_SUCCESS";
export const MODULE_ADD_ONE_ERROR = "MODULE_ADD_ONE_ERROR";

export const MODULE_EDIT_ONE = "MODULE_EDIT_ONE";
export const MODULE_EDIT_ONE_SUCCESS = "MODULE_EDIT_ONE_SUCCESS";
export const MODULE_EDIT_ONE_ERROR = "MODULE_EDIT_ONE_ERROR";

export const MODULE_DELETE_ONE = "MODULE_DELETE_ONE";
export const MODULE_DELETE_ONE_SUCCESS = "MODULE_DELETE_ONE_SUCCESS";
export const MODULE_DELETE_ONE_ERROR = "MODULE_DELETE_ONE_ERROR";


export const MODULES_DELETE_MANY = "MODULES_DELETE_MANY";
export const MODULES_DELETE_MANY_SUCCESS = "MODULES_DELETE_MANY_SUCCESS";
export const MODULES_DELETE_MANY_ERROR = "MODULES_DELETE_MANY_ERROR"
/********************************************************************************************************************************/


export const ROOM_GET_LIST = "ROOM_GET_LIST";
export const ROOM_GET_LIST_SUCCESS = "ROOM_GET_LIST_SUCCESS";
export const ROOM_GET_LIST_ERROR = "ROOM_GET_LIST_ERROR";

export const ROOM_GET_ONE = "ROOM_GET_ONE";
export const ROOM_GET_ONE_SUCCESS = "ROOM_GET_ONE_SUCCESS";
export const ROOM_GET_ONE_ERROR = "ROOM_GET_ONE_ERROR";

export const ROOM_GET_BY_FILTER = "ROOM_GET_BY_FILTER";
export const ROOM_GET_BY_FILTER_SUCCESS = "ROOM_GET_BY_FILTER_SUCCESS";
export const ROOM_GET_BY_FILTER_ERROR = "ROOM_GET_BY_FILTER_ERROR";

export const ROOM_ADD_ONE = "ROOM_ADD_ONE";
export const ROOM_ADD_ONE_SUCCESS = "ROOM_ADD_ONE_SUCCESS";
export const ROOM_ADD_ONE_ERROR = "ROOM_ADD_ONE_ERROR";

export const ROOM_EDIT_ONE = "ROOM_EDIT_ONE";
export const ROOM_EDIT_ONE_SUCCESS = "ROOM_EDIT_ONE_SUCCESS";
export const ROOM_EDIT_ONE_ERROR = "ROOM_EDIT_ONE_ERROR";

export const ROOM_DELETE_ONE = "ROOM_DELETE_ONE";
export const ROOM_DELETE_ONE_SUCCESS = "ROOM_DELETE_ONE_SUCCESS";
export const ROOM_DELETE_ONE_ERROR = "ROOM_DELETE_ONE_ERROR";


export const ROOMS_DELETE_MANY = "ROOMS_DELETE_MANY";
export const ROOMS_DELETE_MANY_SUCCESS = "ROOMS_DELETE_MANY_SUCCESS";
export const ROOMS_DELETE_MANY_ERROR = "ROOMS_DELETE_MANY_ERROR";
/********************************************************************************************************************************/


export const TIMETABLE_GET_LIST = "TIMETABLE_GET_LIST";
export const TIMETABLE_GET_LIST_SUCCESS = "TIMETABLE_GET_LIST_SUCCESS";
export const TIMETABLE_GET_LIST_ERROR = "TIMETABLE_GET_LIST_ERROR";

export const TIMETABLE_GET_ONE = "TIMETABLE_GET_ONE";
export const TIMETABLE_GET_ONE_SUCCESS = "TIMETABLE_GET_ONE_SUCCESS";
export const TIMETABLE_GET_ONE_ERROR = "TIMETABLE_GET_ONE_ERROR";

export const TIMETABLE_GET_BY_FILTER = "TIMETABLE_GET_BY_FILTER";
export const TIMETABLE_GET_BY_FILTER_SUCCESS = "TIMETABLE_GET_BY_FILTER_SUCCESS";
export const TIMETABLE_GET_BY_FILTER_ERROR = "TIMETABLE_GET_BY_FILTER_ERROR";

export const TIMETABLE_ADD_ONE = "TIMETABLE_ADD_ONE";
export const TIMETABLE_ADD_ONE_SUCCESS = "TIMETABLE_ADD_ONE_SUCCESS";
export const TIMETABLE_ADD_ONE_ERROR = "TIMETABLE_ADD_ONE_ERROR";

export const TIMETABLE_EDIT_ONE = "TIMETABLE_EDIT_ONE";
export const TIMETABLE_EDIT_ONE_SUCCESS = "TIMETABLE_EDIT_ONE_SUCCESS";
export const TIMETABLE_EDIT_ONE_ERROR = "TIMETABLE_EDIT_ONE_ERROR";

export const TIMETABLE_DELETE_ONE = "TIMETABLE_DELETE_ONE";
export const TIMETABLE_DELETE_ONE_SUCCESS = "TIMETABLE_DELETE_ONE_SUCCESS";
export const TIMETABLE_DELETE_ONE_ERROR = "TIMETABLE_DELETE_ONE_ERROR";

export const TIMETABLES_DELETE_MANY = "TIMETABLES_DELETE_MANY";
export const TIMETABLES_DELETE_MANY_SUCCESS = "TIMETABLES_DELETE_MANY_SUCCESS";
export const TIMETABLES_DELETE_MANY_ERROR = "TIMETABLES_DELETE_MANY_ERROR";


/********************************************************************************************************************************/


export const HOMEWORK_GET_LIST = "HOMEWORK_GET_LIST";
export const HOMEWORK_GET_LIST_SUCCESS = "HOMEWORK_GET_LIST_SUCCESS";
export const HOMEWORK_GET_LIST_ERROR = "HOMEWORK_GET_LIST_ERROR";

export const HOMEWORK_GET_ONE = "HOMEWORK_GET_ONE";
export const HOMEWORK_GET_ONE_SUCCESS = "HOMEWORK_GET_ONE_SUCCESS";
export const HOMEWORK_GET_ONE_ERROR = "HOMEWORK_GET_ONE_ERROR";

export const HOMEWORK_ADD_ONE = "HOMEWORK_ADD_ONE";
export const HOMEWORK_ADD_ONE_SUCCESS = "HOMEWORK_ADD_ONE_SUCCESS";
export const HOMEWORK_ADD_ONE_ERROR = "HOMEWORK_ADD_ONE_ERROR";


export const HOMEWORKS_GET_BY_FILTER = "HOMEWORKS_GET_BY_FILTER";
export const HOMEWORKS_GET_BY_FILTER_SUCCESS = "HOMEWORKS_GET_BY_FILTER_SUCCESS";
export const HOMEWORKS_GET_BY_FILTER_ERROR = "HOMEWORKS_GET_BY_FILTER_ERROR";

export const HOMEWORK_EDIT_ONE = "HOMEWORK_EDIT_ONE";
export const HOMEWORK_EDIT_ONE_SUCCESS = "HOMEWORK_EDIT_ONE_SUCCESS";
export const HOMEWORK_EDIT_ONE_ERROR = "HOMEWORK_EDIT_ONE_ERROR";

export const HOMEWORK_DELETE_ONE = "HOMEWORK_DELETE_ONE";
export const HOMEWORK_DELETE_ONE_SUCCESS = "HOMEWORK_DELETE_ONE_SUCCESS";
export const HOMEWORK_DELETE_ONE_ERROR = "HOMEWORK_DELETE_ONE_ERROR";

export const HOMEWORKS_DELETE_MANY = "HOMEWORKS_DELETE_MANY";
export const HOMEWORKS_DELETE_MANY_SUCCESS = "HOMEWORKS_DELETE_MANY_SUCCESS";
export const HOMEWORKS_DELETE_MANY_ERROR = "HOMEWORKS_DELETE_MANY_ERROR"
/**************************************************************************************************/
export const SANCTION_GET_LIST = "SANCTION_GET_LIST";
export const SANCTION_GET_LIST_SUCCESS = "SANCTION_GET_LIST_SUCCESS";
export const SANCTION_GET_LIST_ERROR = "SANCTION_GET_LIST_ERROR";


export const SANCTION_GET_ONE = "SANCTION_GET_ONE";
export const SANCTION_GET_ONE_SUCCESS = "SANCTION_GET_ONE_SUCCESS";
export const SANCTION_GET_ONE_ERROR = "SANCTION_GET_ONE_ERROR";

export const SANCTIONS_GET_BY_FILTER = "SANCTIONS_GET_BY_FILTER";
export const SANCTIONS_GET_BY_FILTER_SUCCESS = "SANCTIONS_GET_BY_FILTER_SUCCESS";
export const SANCTIONS_GET_BY_FILTER_ERROR = "SANCTIONS_GET_BY_FILTER_ERROR";

export const SANCTION_ADD_ONE = "SANCTION_ADD_ONE";
export const SANCTION_ADD_ONE_SUCCESS = "SANCTION_ADD_ONE_SUCCESS";
export const SANCTION_ADD_ONE_ERROR = "SANCTION_ADD_ONE_ERROR";

export const SANCTION_EDIT_ONE = "SANCTION_EDIT_ONE";
export const SANCTION_EDIT_ONE_SUCCESS = "SANCTION_EDIT_ONE_SUCCESS";
export const SANCTION_EDIT_ONE_ERROR = "SANCTION_EDIT_ONE_ERROR";

export const SANCTION_DELETE_ONE = "SANCTION_DELETE_ONE";
export const SANCTION_DELETE_ONE_SUCCESS = "SANCTION_DELETE_ONE_SUCCESS";
export const SANCTION_DELETE_ONE_ERROR = "SANCTION_DELETE_ONE_ERROR";

export const SANCTIONS_DELETE_MANY = "SANCTIONS_DELETE_MANY";
export const SANCTIONS_DELETE_MANY_SUCCESS = "SANCTIONS_DELETE_MANY_SUCCESS";
export const SANCTIONS_DELETE_MANY_ERROR = "SANCTIONS_DELETE_MANY_ERROR";
/**************************************************************************************************/

export const SANCTION_TYPES_GET_LIST = "SANCTION_TYPES_GET_LIST";
export const SANCTION_TYPES_GET_LIST_SUCCESS = "SANCTION_TYPES_GET_LIST_SUCCESS";
export const SANCTION_TYPES_GET_LIST_ERROR = "SANCTION_TYPES_GET_LIST_ERROR";

export const SANCTION_TYPES_GET_ONE = "SANCTION_TYPES_GET_ONE";
export const SANCTION_TYPES_GET_ONE_SUCCESS = "SANCTION_TYPES_GET_ONE_SUCCESS";
export const SANCTION_TYPES_GET_ONE_ERROR = "SANCTION_TYPES_GET_ONE_ERROR";

export const SANCTION_TYPES_GET_BY_FILTER = "SANCTION_TYPES_GET_BY_FILTER";
export const SANCTION_TYPES_GET_BY_FILTER_SUCCESS = "SANCTION_TYPES_GET_BY_FILTER_SUCCESS";
export const SANCTION_TYPES_GET_BY_FILTER_ERROR = "SANCTION_TYPES_GET_BY_FILTER_ERROR";

export const SANCTION_TYPES_ADD_ONE = "SANCTION_TYPES_ADD_ONE";
export const SANCTION_TYPES_ADD_ONE_SUCCESS = "SANCTION_TYPES_ADD_ONE_SUCCESS";
export const SANCTION_TYPES_ADD_ONE_ERROR = "SANCTION_TYPES_ADD_ONE_ERROR";

export const SANCTION_TYPES_EDIT_ONE = "SANCTION_TYPES_EDIT_ONE";
export const SANCTION_TYPES_EDIT_ONE_SUCCESS = "SANCTION_TYPES_EDIT_ONE_SUCCESS";
export const SANCTION_TYPES_EDIT_ONE_ERROR = "SANCTION_TYPES_EDIT_ONE_ERROR";


export const SANCTION_TYPES_DELETE_ONE = "SANCTION_TYPES_DELETE_ONE";
export const SANCTION_TYPES_DELETE_ONE_SUCCESS = "SANCTION_TYPES_DELETE_ONE_SUCCESS";
export const SANCTION_TYPES_DELETE_ONE_ERROR = "SANCTION_TYPES_DELETE_ONE_ERROR";

export const SANCTION_TYPES_DELETE_MANY = "SANCTION_TYPES_DELETE_MANY";
export const SANCTION_TYPES_DELETE_MANY_SUCCESS = "SANCTION_TYPES_DELETE_MANY_SUCCESS";
export const SANCTION_TYPES_DELETE_MANY_ERROR = "SANCTION_TYPES_DELETE_MANY_ERROR";

/**************************************************************************************************/
export const EXEMPTION_GET_LIST = "EXEMPTION_GET_LIST";
export const EXEMPTION_GET_LIST_SUCCESS = "EXEMPTION_GET_LIST_SUCCESS";
export const EXEMPTION_GET_LIST_ERROR = "EXEMPTION_GET_LIST_ERROR";


export const EXEMPTION_GET_ONE = "EXEMPTION_GET_ONE";
export const EXEMPTION_GET_ONE_SUCCESS = "EXEMPTION_GET_ONE_SUCCESS";
export const EXEMPTION_GET_ONE_ERROR = "EXEMPTION_GET_ONE_ERROR";


export const EXEMPTION_ADD_ONE = "EXEMPTION_ADD_ONE";
export const EXEMPTION_ADD_ONE_SUCCESS = "EXEMPTION_ADD_ONE_SUCCESS";
export const EXEMPTION_ADD_ONE_ERROR = "EXEMPTION_ADD_ONE_ERROR";

export const EXEMPTION_GET_BY_FILTER = "EXEMPTION_GET_BY_FILTER";
export const EXEMPTION_GET_BY_FILTER_SUCCESS = "EXEMPTION_GET_BY_FILTER_SUCCESS";
export const EXEMPTION_GET_BY_FILTER_ERROR = "EXEMPTION_GET_BY_FILTER_ERROR";

export const EXEMPTION_EDIT_ONE = "EXEMPTION_EDIT_ONE";
export const EXEMPTION_EDIT_ONE_SUCCESS = "EXEMPTION_EDIT_ONE_SUCCESS";
export const EXEMPTION_EDIT_ONE_ERROR = "EXEMPTION_EDIT_ONE_ERROR";

export const EXEMPTION_DELETE_ONE = "EXEMPTION_DELETE_ONE";
export const EXEMPTION_DELETE_ONE_SUCCESS = "EXEMPTION_DELETE_ONE_SUCCESS";
export const EXEMPTION_DELETE_ONE_ERROR = "EXEMPTION_DELETE_ONE_ERROR";

export const EXEMPTIONS_DELETE_MANY = "EXEMPTIONS_DELETE_MANY";
export const EXEMPTIONS_DELETE_MANY_SUCCESS = "EXEMPTIONS_DELETE_MANY_SUCCESS";
export const EXEMPTIONS_DELETE_MANY_ERROR = "EXEMPTIONS_DELETE_MANY_ERROR";
/**************************************************************************************************/
export const ABSENCE_GET_LIST = "ABSENCE_GET_LIST";
export const ABSENCE_GET_LIST_SUCCESS = "ABSENCE_GET_LIST_SUCCESS";
export const ABSENCE_GET_LIST_ERROR = "ABSENCE_GET_LIST_ERROR";


export const ABSENCE_GET_ONE = "ABSENCE_GET_ONE";
export const ABSENCE_GET_ONE_SUCCESS = "ABSENCE_GET_ONE_SUCCESS";
export const ABSENCE_GET_ONE_ERROR = "ABSENCE_GET_ONE_ERROR";

export const ABSENCE_GET_BY_FILTER = "ABSENCE_GET_BY_FILTER";
export const ABSENCE_GET_BY_FILTER_SUCCESS = "ABSENCE_GET_BY_FILTER_SUCCESS";
export const ABSENCE_GET_BY_FILTER_ERROR = "ABSENCE_GET_BY_FILTER_ERROR";

export const ABSENCE_GET_BY_STUDENT = "ABSENCE_GET_BY_STUDENT";
export const ABSENCE_GET_BY_STUDENT_SUCCESS = "ABSENCE_GET_BY_STUDENT_SUCCESS";
export const ABSENCE_GET_BY_STUDENT_ERROR = "ABSENCE_GET_BY_STUDENT_ERROR";

export const ABSENCE_ADD_ONE = "ABSENCE_ADD_ONE";
export const ABSENCE_ADD_ONE_SUCCESS = "ABSENCE_ADD_ONE_SUCCESS";
export const ABSENCE_ADD_ONE_ERROR = "ABSENCE_ADD_ONE_ERROR";


export const ABSENCE_EDIT_ONE = "ABSENCE_EDIT_ONE";
export const ABSENCE_EDIT_ONE_SUCCESS = "ABSENCE_EDIT_ONE_SUCCESS";
export const ABSENCE_EDIT_ONE_ERROR = "ABSENCE_EDIT_ONE_ERROR";

export const ABSENCE_DELETE_ONE = "ABSENCE_DELETE_ONE";
export const ABSENCE_DELETE_ONE_SUCCESS = "ABSENCE_DELETE_ONE_SUCCESS";
export const ABSENCE_DELETE_ONE_ERROR = "ABSENCE_DELETE_ONE_ERROR";


export const ABSENCES_DELETE_MANY = "ABSENCES_DELETE_MANY";
export const ABSENCES_DELETE_MANY_SUCCESS = "ABSENCES_DELETE_MANY_SUCCESS";
export const ABSENCES_DELETE_MANY_ERROR = "ABSENCES_DELETE_MANY_ERROR";
/**************************************************************************************************/
export const SESSION_GET_LIST = "SESSION_GET_LIST";
export const SESSION_GET_LIST_SUCCESS = "SESSION_GET_LIST_SUCCESS";
export const SESSION_GET_LIST_ERROR = "SESSION_GET_LIST_ERROR";


export const SESSION_GET_ONE = "SESSION_GET_ONE";
export const SESSION_GET_ONE_SUCCESS = "SESSION_GET_ONE_SUCCESS";
export const SESSION_GET_ONE_ERROR = "SESSION_GET_ONE_ERROR";

export const SESSION_GET_BY_FILTER = "SESSION_GET_BY_FILTER";
export const SESSION_GET_BY_FILTER_SUCCESS = "SESSION_GET_BY_FILTER_SUCCESS";
export const SESSION_GET_BY_FILTER_ERROR = "SESSION_GET_BY_FILTER_ERROR";

export const SESSION_ADD_ONE = "SESSION_ADD_ONE";
export const SESSION_ADD_ONE_SUCCESS = "SESSION_ADD_ONE_SUCCESS";
export const SESSION_ADD_ONE_ERROR = "SESSION_ADD_ONE_ERROR";


export const SESSION_EDIT_ONE = "SESSION_EDIT_ONE";
export const SESSION_EDIT_ONE_SUCCESS = "SESSION_EDIT_ONE_SUCCESS";
export const SESSION_EDIT_ONE_ERROR = "SESSION_EDIT_ONE_ERROR";

export const SESSION_DELETE_ONE = "SESSION_DELETE_ONE";
export const SESSION_DELETE_ONE_SUCCESS = "SESSION_DELETE_ONE_SUCCESS";
export const SESSION_DELETE_ONE_ERROR = "SESSION_DELETE_ONE_ERROR";

/********************Billing***************************************/
export const TAX_GET_LIST = "TAX_GET_LIST";
export const TAX_GET_LIST_SUCCESS = "TAX_GET_LIST_SUCCESS";
export const TAX_GET_LIST_ERROR = "TAX_GET_LIST_ERROR";
export const TAX_GET_ONE = "TAX_GET_ONE";
export const TAX_GET_ONE_SUCCESS = "TAX_GET_ONE_SUCCESS";
export const TAX_GET_ONE_ERROR = "TAX_GET_ONE_ERROR";

export const TAX_GET_BY_FILTER = "TAX_GET_BY_FILTER";
export const TAX_GET_BY_FILTER_SUCCESS = "TAX_GET_BY_FILTER_SUCCESS";
export const TAX_GET_BY_FILTER_ERROR = "TAX_GET_BY_FILTER_ERROR";

export const TAX_ADD_ONE = "TAX_ADD_ONE";
export const TAX_ADD_ONE_SUCCESS = "TAX_ADD_ONE_SUCCESS";
export const TAX_ADD_ONE_ERROR = "TAX_ADD_ONE_ERROR";
export const TAX_EDIT_ONE = "TAX_EDIT_ONE";
export const TAX_EDIT_ONE_SUCCESS = "TAX_EDIT_ONE_SUCCESS";
export const TAX_EDIT_ONE_ERROR = "TAX_EDIT_ONE_ERROR";
export const TAX_DELETE_ONE = "TAX_DELETE_ONE";
export const TAX_DELETE_ONE_SUCCESS = "TAX_DELETE_ONE_SUCCESS";
export const TAX_DELETE_ONE_ERROR = "TAX_DELETE_ONE_ERROR";

export const TAXES_DELETE_MANY = "TAXES_DELETE_MANY";
export const TAXES_DELETE_MANY_SUCCESS = "TAXES_DELETE_MANY_SUCCESS";
export const TAXES_DELETE_MANY_ERROR = "TAXES_DELETE_MANY_ERROR";

/***************************************************************** */
export const FEE_GET_LIST = "FEE_GET_LIST";
export const FEE_GET_LIST_SUCCESS = "FEE_GET_LIST_SUCCESS";
export const FEE_GET_LIST_ERROR = "FEE_GET_LIST_ERROR";
export const FEE_GET_ONE = "FEE_GET_ONE";
export const FEE_GET_ONE_SUCCESS = "FEE_GET_ONE_SUCCESS";
export const FEE_GET_ONE_ERROR = "FEE_GET_ONE_ERROR";

export const FEE_GET_BY_FILTER = "FEE_GET_BY_FILTER";
export const FEE_GET_BY_FILTER_SUCCESS = "FEE_GET_BY_FILTER_SUCCESS";
export const FEE_GET_BY_FILTER_ERROR = "FEE_GET_BY_FILTER_ERROR";


export const FEE_ADD_ONE = "FEE_ADD_ONE";
export const FEE_ADD_ONE_SUCCESS = "FEE_ADD_ONE_SUCCESS";
export const FEE_ADD_ONE_ERROR = "FEE_ADD_ONE_ERROR";
export const FEE_EDIT_ONE = "FEE_EDIT_ONE";
export const FEE_EDIT_ONE_SUCCESS = "FEE_EDIT_ONE_SUCCESS";
export const FEE_EDIT_ONE_ERROR = "FEE_EDIT_ONE_ERROR";
export const FEE_DELETE_ONE = "FEE_DELETE_ONE";
export const FEE_DELETE_ONE_SUCCESS = "FEE_DELETE_ONE_SUCCESS";
export const FEE_DELETE_ONE_ERROR = "FEE_DELETE_ONE_ERROR";

export const FEES_DELETE_MANY = "FEES_DELETE_MANY";
export const FEES_DELETE_MANY_SUCCESS = "FEES_DELETE_MANY_SUCCESS";
export const FEES_DELETE_MANY_ERROR = "FEES_DELETE_MANY_ERROR"

////************************************************************** */
export const FEE_COMPOSITION_GET_LIST = "FEE_COMPOSITION_GET_LIST";
export const FEE_COMPOSITION_GET_LIST_SUCCESS = "FEE_COMPOSITION_GET_LIST_SUCCESS";
export const FEE_COMPOSITION_GET_LIST_ERROR = "FEE_COMPOSITION_GET_LIST_ERROR";
export const FEE_COMPOSITION_GET_ONE = "FEE_COMPOSITION_GET_ONE";
export const FEE_COMPOSITION_GET_ONE_SUCCESS = "FEE_COMPOSITION_GET_ONE_SUCCESS";
export const FEE_COMPOSITION_GET_ONE_ERROR = "FEE_COMPOSITION_GET_ONE_ERROR";
export const FEE_COMPOSITION_ADD_ONE = "FEE_COMPOSITION_ADD_ONE";
export const FEE_COMPOSITION_ADD_ONE_SUCCESS = "FEE_COMPOSITION_ADD_ONE_SUCCESS";
export const FEE_COMPOSITION_ADD_ONE_ERROR = "FEE_COMPOSITION_ADD_ONE_ERROR";
export const FEE_COMPOSITION_EDIT_ONE = "FEE_COMPOSITION_EDIT_ONE";
export const FEE_COMPOSITION_EDIT_ONE_SUCCESS = "FEE_COMPOSITION_EDIT_ONE_SUCCESS";
export const FEE_COMPOSITION_EDIT_ONE_ERROR = "FEE_COMPOSITION_EDIT_ONE_ERROR";
export const FEE_COMPOSITION_DELETE_ONE = "FEE_COMPOSITION_DELETE_ONE";
export const FEE_COMPOSITION_DELETE_ONE_SUCCESS = "FEE_COMPOSITION_DELETE_ONE_SUCCESS";
export const FEE_COMPOSITION_DELETE_ONE_ERROR = "FEE_COMPOSITION_DELETE_ONE_ERROR";

/************************************************************************************** */
export const PAYMENT_GET_LIST = "PAYMENT_GET_LIST";
export const PAYMENT_GET_LIST_SUCCESS = "PAYMENT_GET_LIST_SUCCESS";
export const PAYMENT_GET_LIST_ERROR = "PAYMENT_GET_LIST_ERROR";

export const PAYMENT_GET_ONE = "PAYMENT_GET_ONE";
export const PAYMENT_GET_ONE_SUCCESS = "PAYMENT_GET_ONE_SUCCESS";
export const PAYMENT_GET_ONE_ERROR = "PAYMENT_GET_ONE_ERROR";

export const PAYMENT_ADD_ONE = "PAYMENT_ADD_ONE";
export const PAYMENT_ADD_ONE_SUCCESS = "PAYMENT_ADD_ONE_SUCCESS";

export const PAYMENT_ADD_ONE_ERROR = "PAYMENT_ADD_ONE_ERROR";
export const PAYMENT_EDIT_ONE = "PAYMENT_EDIT_ONE";
export const PAYMENT_EDIT_ONE_SUCCESS = "PAYMENT_EDIT_ONE_SUCCESS";

export const PAYMENT_EDIT_ONE_ERROR = "PAYMENT_EDIT_ONE_ERROR";
export const PAYMENT_DELETE_ONE = "PAYMENT_DELETE_ONE";
export const PAYMENT_DELETE_ONE_SUCCESS = "PAYMENT_DELETE_ONE_SUCCESS";
export const PAYMENT_DELETE_ONE_ERROR = "PAYMENT_DELETE_ONE_ERROR";

export const PAYMENT_PRINT = "PAYMENT_PRINT";
export const PAYMENT_PRINT_SUCCESS = "PAYMENT_PRINT_SUCCESS";
export const PAYMENT_PRINT_ERROR = "PAYMENT_PRINT_ERROR";

export const PAYMENT_CHARGE = "PAYMENT_CHARGE";
export const PAYMENT_CHARGE_SUCCESS = "PAYMENT_CHARGE_SUCCESS";
export const PAYMENT_CHARGE_ERROR = "PAYMENT_CHARGE_ERROR";

export const PAYMENT_GET_LIST_BY_FILTER = "PAYMENT_GET_LIST_BY_FILTER";
export const PAYMENT_GET_LIST_BY_FILTER_SUCCESS = "PAYMENT_GET_LIST_BY_FILTER_SUCCESS";
export const PAYMENT_GET_LIST_BY_FILTER_ERROR = "PAYMENT_GET_LIST_BY_FILTER_ERROR";

export const PAYMENTS_DELETE_MANY = "PAYMENTS_DELETE_MANY";
export const PAYMENTS_DELETE_MANY_SUCCESS = "PAYMENTS_DELETE_MANY_SUCCESS";
export const PAYMENTS_DELETE_MANY_ERROR = "PAYMENTS_DELETE_MANY_ERROR";

//********************************************************************* */
export const BILL_GET_LIST = "BILL_GET_LIST";
export const BILL_GET_LIST_SUCCESS = "BILL_GET_LIST_SUCCESS";
export const BILL_GET_LIST_ERROR = "BILL_GET_LIST_ERROR";
export const BILL_GET_ONE = "BILL_GET_ONE";
export const BILL_GET_ONE_SUCCESS = "BILL_GET_ONE_SUCCESS";
export const BILL_GET_ONE_ERROR = "BILL_GET_ONE_ERROR";
export const BILL_ADD_ONE = "BILL_ADD_ONE";
export const BILL_ADD_ONE_SUCCESS = "BILL_ADD_ONE_SUCCESS";
export const BILL_ADD_ONE_ERROR = "BILL_ADD_ONE_ERROR";
export const BILL_EDIT_ONE = "BILL_EDIT_ONE";
export const BILL_EDIT_ONE_SUCCESS = "BILL_EDIT_ONE_SUCCESS";
export const BILL_EDIT_ONE_ERROR = "BILL_EDIT_ONE_ERROR";
export const BILL_DELETE_ONE = "BILL_DELETE_ONE";
export const BILL_DELETE_ONE_SUCCESS = "BILL_DELETE_ONE_SUCCESS";
export const BILL_DELETE_ONE_ERROR = "BILL_DELETE_ONE_ERROR";
export const BILL_PRINT = "BILL_PRINT";
export const BILL_PRINT_SUCCESS = "BILL_PRINT_SUCCESS";
export const BILL_PRINT_ERROR = "BILL_PRINT_ERROR";

export const BILL_GET_BY_FILTER = "BILL_GET_BY_FILTER";
export const BILL_GET_BY_FILTER_SUCCESS = "BILL_GET_BY_FILTER_SUCCESS";
export const BILL_GET_BY_FILTER_ERROR = "BILL_GET_BY_FILTER_ERROR";

export const BILLS_DELETE_MANY = "BILLS_DELETE_MANY";
export const BILLS_DELETE_MANY_SUCCESS = "BILLS_DELETE_MANY_SUCCESS";
export const BILLS_DELETE_MANY_ERROR = "BILLS_DELETE_MANY_ERROR"
/******************************************************************/

export const EXAMTYPES_GET_LIST = "EXAMTYPES_GET_LIST";
export const EXAMTYPES_GET_LIST_SUCCESS = "EXAMTYPES_GET_LIST_SUCCESS";
export const EXAMTYPES_GET_LIST_ERROR = "EXAMTYPES_GET_LIST_ERROR";

export const EXAMTYPES_GET_ONE = "EXAMTYPES_GET_ONE";
export const EXAMTYPES_GET_ONE_SUCCESS = "EXAMTYPES_GET_ONE_SUCCESS";
export const EXAMTYPES_GET_ONE_ERROR = "EXAMTYPES_GET_ONE_ERROR";


export const EXAMTYPES_ADD_ONE = "EXAMTYPES_ADD_ONE";
export const EXAMTYPES_ADD_ONE_SUCCESS = "EXAMTYPES_ADD_ONE_SUCCESS";
export const EXAMTYPES_ADD_ONE_ERROR = "EXAMTYPES_ADD_ONE_ERROR";

export const EXAMTYPES_GET_BY_FILTER = "EXAMTYPES_GET_BY_FILTER";
export const EXAMTYPES_GET_BY_FILTER_SUCCESS = "EXAMTYPES_GET_BY_FILTER_SUCCESS";
export const EXAMTYPES_GET_BY_FILTER_ERROR = "EXAMTYPES_GET_BY_FILTER_ERROR";

export const EXAMTYPES_EDIT_ONE = "EXAMTYPES_EDIT_ONE";
export const EXAMTYPES_EDIT_ONE_SUCCESS = "EXAMTYPES_EDIT_ONE_SUCCESS";
export const EXAMTYPES_EDIT_ONE_ERROR = "EXAMTYPES_EDIT_ONE_ERROR";

export const EXAMTYPES_DELETE_ONE = "EXAMTYPES_DELETE_ONE";
export const EXAMTYPES_DELETE_ONE_SUCCESS = "EXAMTYPES_DELETE_ONE_SUCCESS";
export const EXAMTYPES_DELETE_ONE_ERROR = "EXAMTYPES_DELETE_ONE_ERROR";

export const EXAMTYPES_DELETE_MANY = "EXAMTYPES_DELETE_MANY";
export const EXAMTYPES_DELETE_MANY_SUCCESS = "EXAMTYPES_DELETE_MANY_SUCCESS";
export const EXAMTYPES_DELETE_MANY_ERROR = "EXAMTYPES_DELETE_MANY_ERROR";
/**************************************************************************************************/
export const EXAMPERIODS_GET_LIST = "EXAMPERIODS_GET_LIST";
export const EXAMPERIODS_GET_LIST_SUCCESS = "EXAMPERIODS_GET_LIST_SUCCESS";
export const EXAMPERIODS_GET_LIST_ERROR = "EXAMPERIODS_GET_LIST_ERROR";


export const EXAMPERIODS_GET_ONE = "EXAMPERIODS_GET_ONE";
export const EXAMPERIODS_GET_ONE_SUCCESS = "EXAMPERIODS_GET_ONE_SUCCESS";
export const EXAMPERIODS_GET_ONE_ERROR = "EXAMPERIODS_GET_ONE_ERROR";

export const EXAMPERIODS_GET_BY_FILTER = "EXAMPERIODS_GET_BY_FILTER";
export const EXAMPERIODS_GET_BY_FILTER_SUCCESS = "EXAMPERIODS_GET_BY_FILTER_SUCCESS";
export const EXAMPERIODS_GET_BY_FILTER_ERROR = "EXAMPERIODS_GET_BY_FILTER_ERROR";

export const EXAMPERIODS_ADD_ONE = "EXAMPERIODS_ADD_ONE";
export const EXAMPERIODS_ADD_ONE_SUCCESS = "EXAMPERIODS_ADD_ONE_SUCCESS";
export const EXAMPERIODS_ADD_ONE_ERROR = "EXAMPERIODS_ADD_ONE_ERROR";


export const EXAMPERIODS_EDIT_ONE = "EXAMPERIODS_EDIT_ONE";
export const EXAMPERIODS_EDIT_ONE_SUCCESS = "EXAMPERIODS_EDIT_ONE_SUCCESS";
export const EXAMPERIODS_EDIT_ONE_ERROR = "EXAMPERIODS_EDIT_ONE_ERROR";


export const EXAMPERIODS_DELETE_ONE = "EXAMPERIODS_DELETE_ONE";
export const EXAMPERIODS_DELETE_ONE_SUCCESS = "EXAMPERIODS_DELETE_ONE_SUCCESS";
export const EXAMPERIODS_DELETE_ONE_ERROR = "EXAMPERIODS_DELETE_ONE_ERROR";


export const EXAMPERIODS_DELETE_MANY = "EXAMPERIODS_DELETE_MANY";
export const EXAMPERIODS_DELETE_MANY_SUCCESS = "EXAMPERIODS_DELETE_MANY_SUCCESS";
export const EXAMPERIODS_DELETE_MANY_ERROR = "EXAMPERIODS_DELETE_MANY_ERROR"

/**************************************************************************************************/
export const EXAMSESSIONS_GET_LIST = "EXAMSESSIONS_GET_LIST";
export const EXAMSESSIONS_GET_LIST_SUCCESS = "EXAMSESSIONS_GET_LIST_SUCCESS";
export const EXAMSESSIONS_GET_LIST_ERROR = "EXAMSESSIONS_GET_LIST_ERROR";


export const EXAMSESSIONS_GET_ONE = "EXAMSESSIONS_GET_ONE";
export const EXAMSESSIONS_GET_ONE_SUCCESS = "EXAMSESSIONS_GET_ONE_SUCCESS";
export const EXAMSESSIONS_GET_ONE_ERROR = "EXAMSESSIONS_GET_ONE_ERROR";


export const EXAMSESSIONS_GET_BY_FILTER = "EXAMSESSIONS_GET_BY_FILTER";
export const EXAMSESSIONS_GET_BY_FILTER_SUCCESS = "EXAMSESSIONS_GET_BY_FILTER_SUCCESS";
export const EXAMSESSIONS_GET_BY_FILTER_ERROR = "EXAMSESSIONS_GET_BY_FILTER_ERROR";



export const EXAMSESSIONS_ADD_ONE = "EXAMSESSIONS_ADD_ONE";
export const EXAMSESSIONS_ADD_ONE_SUCCESS = "EXAMSESSIONS_ADD_ONE_SUCCESS";
export const EXAMSESSIONS_ADD_ONE_ERROR = "EXAMSESSIONS_ADD_ONE_ERROR";

export const EXAMSESSIONS_EDIT_ONE = "EXAMSESSIONS_EDIT_ONE";
export const EXAMSESSIONS_EDIT_ONE_SUCCESS = "EXAMSESSIONS_EDIT_ONE_SUCCESS";
export const EXAMSESSIONS_EDIT_ONE_ERROR = "EXAMSESSIONS_EDIT_ONE_ERROR";

export const EXAMSESSIONS_DELETE_ONE = "EXAMSESSIONS_DELETE_ONE";
export const EXAMSESSIONS_DELETE_ONE_SUCCESS = "EXAMSESSIONS_DELETE_ONE_SUCCESS";
export const EXAMSESSIONS_DELETE_ONE_ERROR = "EXAMSESSIONS_DELETE_ONE_ERROR";

export const EXAMSESSIONS_DELETE_MANY = "EXAMSESSIONS_DELETE_MANY";
export const EXAMSESSIONS_DELETE_MANY_SUCCESS = "EXAMSESSIONS_DELETE_MANY_SUCCESS";
export const EXAMSESSIONS_DELETE_MANY_ERROR = "EXAMSESSIONS_DELETE_MANY_ERROR";
/**************************************************************************************************/
export const EXAMNOTES_GET_LIST = "EXAMNOTES_GET_LIST";
export const EXAMNOTES_GET_LIST_SUCCESS = "EXAMNOTES_GET_LIST_SUCCESS";
export const EXAMNOTES_GET_LIST_ERROR = "EXAMNOTES_GET_LIST_ERROR";

export const EXAMNOTES_GET_ONE = "EXAMNOTES_GET_ONE";
export const EXAMNOTES_GET_ONE_SUCCESS = "EXAMNOTES_GET_ONE_SUCCESS";
export const EXAMNOTES_GET_ONE_ERROR = "EXAMNOTES_GET_ONE_ERROR";


export const EXAMNOTES_ADD_ONE = "EXAMNOTES_ADD_ONE";
export const EXAMNOTES_ADD_ONE_SUCCESS = "EXAMNOTES_ADD_ONE_SUCCESS";
export const EXAMNOTES_ADD_ONE_ERROR = "EXAMNOTES_ADD_ONE_ERROR";

export const EXAMNOTES_EDIT_ONE = "EXAMNOTES_EDIT_ONE";
export const EXAMNOTES_EDIT_ONE_SUCCESS = "EXAMNOTES_EDIT_ONE_SUCCESS";
export const EXAMNOTES_EDIT_ONE_ERROR = "EXAMNOTES_EDIT_ONE_ERROR";


export const EXAMNOTES_DELETE_ONE = "EXAMNOTES_DELETE_ONE";
export const EXAMNOTES_DELETE_ONE_SUCCESS = "EXAMNOTES_DELETE_ONE_SUCCESS";
export const EXAMNOTES_DELETE_ONE_ERROR = "EXAMNOTES_DELETE_ONE_ERROR";

export const EXAMNOTES_GET_BY_FILTER = "EXAMNOTES_GET_BY_FILTER";
export const EXAMNOTES_GET_BY_FILTER_SUCCESS = "EXAMNOTES_GET_BY_FILTER_SUCCESS";
export const EXAMNOTES_GET_BY_FILTER_ERROR = "EXAMNOTES_GET_BY_FILTER_ERROR";

export const EXAMTYPES_GET_BY_FILTER_SUBJECT = "EXAMTYPES_GET_BY_FILTER_SUBJECT";
export const EXAMTYPES_GET_BY_FILTER_SUBJECT_SUCCESS = "EXAMTYPES_GET_BY_FILTER_SUBJECT_SUCCESS";
export const EXAMTYPES_GET_BY_FILTER_SUBJECT_ERROR = "EXAMTYPES_GET_BY_FILTER_SUBJECT_ERROR";

export const EXAMNOTES_DELETE_MANY = "EXAMNOTES_DELETE_MANY";
export const EXAMNOTES_DELETE_MANY_SUCCESS = "EXAMNOTES_DELETE_MANY_SUCCESS";
export const EXAMNOTES_DELETE_MANY_ERROR = "EXAMNOTES_DELETE_MANY_ERROR";


/**************************************************************************************************/
export const EXAMMODULES_GET_LIST = "EXAMMODULES_GET_LIST";
export const EXAMMODULES_GET_LIST_SUCCESS = "EXAMMODULES_GET_LIST_SUCCESS";
export const EXAMMODULES_GET_LIST_ERROR = "EXAMMODULES_GET_LIST_ERROR";

export const EXAMMODULES_GET_ONE = "EXAMMODULES_GET_ONE";
export const EXAMMODULES_GET_ONE_SUCCESS = "EXAMMODULES_GET_ONE_SUCCESS";
export const EXAMMODULES_GET_ONE_ERROR = "EXAMMODULES_GET_ONE_ERROR";

export const EXAMMODULES_GET_BY_FILTER = "EXAMMODULES_GET_BY_FILTER";
export const EXAMMODULES_GET_BY_FILTER_SUCCESS = "EXAMMODULES_GET_BY_FILTER_SUCCESS";
export const EXAMMODULES_GET_BY_FILTER_ERROR = "EXAMMODULES_GET_BY_FILTER_ERROR";

export const EXAMMODULES_ADD_ONE = "EXAMMODULES_ADD_ONE";
export const EXAMMODULES_ADD_ONE_SUCCESS = "EXAMMODULES_ADD_ONE_SUCCESS";
export const EXAMMODULES_ADD_ONE_ERROR = "EXAMMODULES_ADD_ONE_ERROR";

export const EXAMMODULES_EDIT_ONE = "EXAMMODULES_EDIT_ONE";
export const EXAMMODULES_EDIT_ONE_SUCCESS = "EXAMMODULES_EDIT_ONE_SUCCESS";
export const EXAMMODULES_EDIT_ONE_ERROR = "EXAMMODULES_EDIT_ONE_ERROR";

export const EXAMMODULES_DELETE_ONE = "EXAMMODULES_DELETE_ONE";
export const EXAMMODULES_DELETE_ONE_SUCCESS = "EXAMMODULES_DELETE_ONE_SUCCESS";
export const EXAMMODULES_DELETE_ONE_ERROR = "EXAMMODULES_DELETE_ONE_ERROR";

export const EXAMMODULES_DELETE_MANY = "EXAMMODULES_DELETE_MANY";
export const EXAMMODULES_DELETE_MANY_SUCCESS = "EXAMMODULES_DELETE_MANY_SUCCESS";
export const EXAMMODULES_DELETE_MANY_ERROR = "EXAMMODULES_DELETE_MANY_ERROR";
/**************************************************************************************************/
export const EXAMRESULTS_GET_LIST = "EXAMRESULTS_GET_LIST";
export const EXAMRESULTS_GET_LIST_SUCCESS = "EXAMRESULTS_GET_LIST_SUCCESS";
export const EXAMRESULTS_GET_LIST_ERROR = "EXAMRESULTS_GET_LIST_ERROR";

export const EXAMRESULTS_GET_ONE = "EXAMRESULTS_GET_ONE"
export const EXAMRESULTS_GET_ONE_SUCCESS = "EXAMRESULTS_GET_ONE_SUCCESS";
export const EXAMRESULTS_GET_ONE_ERROR = "EXAMRESULTS_GET_ONE_ERROR";

export const EXAMRESULTS_GET_BY_FILTER = "EXAMRESULTS_GET_BY_FILTER";
export const EXAMRESULTS_GET_BY_FILTER_SUCCESS = "EXAMRESULTS_GET_BY_FILTER_SUCCESS";
export const EXAMRESULTS_GET_BY_FILTER_ERROR = "EXAMRESULTS_GET_BY_FILTER_ERROR";

export const EXAMRESULTS_ADD_ONE = "EXAMRESULTS_ADD_ONE";
export const EXAMRESULTS_ADD_ONE_SUCCESS = "EXAMRESULTS_ADD_ONE_SUCCESS";
export const EXAMRESULTS_ADD_ONE_ERROR = "EXAMRESULTS_ADD_ONE_ERROR";

export const EXAMRESULTS_EDIT_ONE = "EXAMRESULTS_EDIT_ONE";
export const EXAMRESULTS_EDIT_ONE_SUCCESS = "EXAMRESULTS_EDIT_ONE_SUCCESS";
export const EXAMRESULTS_EDIT_ONE_ERROR = "EXAMRESULTS_EDIT_ONE_ERROR";

export const EXAMRESULTS_DELETE_ONE = "EXAMRESULTS_DELETE_ONE";
export const EXAMRESULTS_DELETE_ONE_SUCCESS = "EXAMRESULTS_DELETE_ONE_SUCCESS";
export const EXAMRESULTS_DELETE_ONE_ERROR = "EXAMRESULTS_DELETE_ONE_ERROR";

export const EXAMRESULTS_DELETE_MANY = "EXAMRESULTS_DELETE_MANY";
export const EXAMRESULTS_DELETE_MANY_SUCCESS = "EXAMRESULTS_DELETE_MANY_SUCCESS";
export const EXAMRESULTS_DELETE_MANY_ERROR = "EXAMRESULTS_DELETE_MANY_ERROR";

/************************************************************************************************* */
export const EXAMS_GET_LIST = "EXAMS_GET_LIST";
export const EXAMS_GET_LIST_SUCCESS = "EXAMS_GET_LIST_SUCCESS";
export const EXAMS_GET_LIST_ERROR = "EXAMS_GET_LIST_ERROR";

export const EXAM_GET_ONE = "EXAM_GET_ONE";
export const EXAM_GET_ONE_SUCCESS = "EXAM_GET_ONE_SUCCESS";
export const EXAM_GET_ONE_ERROR = "EXAM_GET_ONE_ERROR";

export const EXAMS_GET_BY_FILTER = "EXAMS_GET_BY_FILTER";
export const EXAMS_GET_BY_FILTER_SUCCESS = "EXAMS_GET_BY_FILTER_SUCCESS";
export const EXAMS_GET_BY_FILTER_ERROR = "EXAMS_GET_BY_FILTER_ERROR";

export const EXAM_ADD_ONE = "EXAM_ADD_ONE";
export const EXAM_ADD_ONE_SUCCESS = "EXAM_ADD_ONE_SUCCESS";
export const EXAM_ADD_ONE_ERROR = "EXAM_ADD_ONE_ERROR";

export const EXAM_EDIT_ONE = "EXAM_EDIT_ONE";
export const EXAM_EDIT_ONE_SUCCESS = "EXAM_EDIT_ONE_SUCCESS";
export const EXAM_EDIT_ONE_ERROR = "EXAM_EDIT_ONE_ERROR";

export const EXAM_DELETE_ONE = "EXAM_DELETE_ONE";
export const EXAM_DELETE_ONE_SUCCESS = "EXAM_DELETE_ONE_SUCCESS";
export const EXAM_DELETE_ONE_ERROR = "EXAM_DELETE_ONE_ERROR";


export const EXAMS_DELETE_MANY = "EXAMS_DELETE_MANY";
export const EXAMS_DELETE_MANY_SUCCESS = "EXAMS_DELETE_MANY_SUCCESS";
export const EXAMS_DELETE_MANY_ERROR = "EXAMS_DELETE_MANY_ERROR"

/********* ***************************************************************************************/

export const EXAMMARKSNOTES_GET_BY_FILTERS = "EXAMMARKSNOTES_GET_BY_FILTERS";
export const EXAMMARKSNOTES_GET_BY_FILTERS_SUCCESS = "EXAMMARKSNOTES_GET_BY_FILTERS_SUCCESS";
export const EXAMMARKSNOTES_GET_BY_FILTERS_ERROR = "EXAMMARKSNOTES_GET_BY_FILTERS_ERROR";

export const EXAMMARKSNOTES_GET_BY_FILTER = "EXAMMARKSNOTES_GET_BY_FILTER";
export const EXAMMARKSNOTES_GET_BY_FILTER_SUCCESS = "EXAMMARKSNOTES_GET_BY_FILTER_SUCCESS";
export const EXAMMARKSNOTES_GET_BY_FILTER_ERROR = "EXAMMARKSNOTES_GET_BY_FILTER_ERROR";

export const EXAMMARKSNOTES_GET_ONE = "EXAMMARKSNOTES_GET_ONE";
export const EXAMMARKSNOTES_GET_ONE_SUCCESS = "EXAMMARKSNOTES_GET_ONE_SUCCESS";
export const EXAMMARKSNOTES_GET_ONE_ERROR = "EXAMMARKSNOTES_GET_ONE_ERROR";

export const EXAMMARKSNOTES_ADD_ONE = "EXAMMARKSNOTES_ADD_ONE";
export const EXAMMARKSNOTES_ADD_ONE_SUCCESS = "EXAMMARKSNOTES_ADD_ONE_SUCCESS";
export const EXAMMARKSNOTES_ADD_ONE_ERROR = "EXAMMARKSNOTES_ADD_ONE_ERROR";

export const EXAMMARKSNOTES_EDIT_ONE = "EXAMMARKSNOTES_EDIT_ONE";
export const EXAMMARKSNOTES_EDIT_ONE_SUCCESS = "EXAMMARKSNOTES_EDIT_ONE_SUCCESS";
export const EXAMMARKSNOTES_EDIT_ONE_ERROR = "EXAMMARKSNOTES_EDIT_ONE_ERROR";


export const EXAMMARKSNOTES_DELETE_ONE = "EXAMMARKSNOTES_DELETE_ONE";
export const EXAMMARKSNOTES_DELETE_ONE_SUCCESS = "EXAMMARKSNOTES_DELETE_ONE_SUCCESS";
export const EXAMMARKSNOTES_DELETE_ONE_ERROR = "EXAMMARKSNOTES_DELETE_ONE_ERROR";

/****************************************************************************************** */
export const TEACHER_COMMENTS_GET_LIST = "TEACHER_COMMENTS_GET_LIST";
export const TEACHER_COMMENTS_GET_LIST_SUCCESS = "TEACHER_COMMENTS_GET_LIST_SUCCESS";
export const TEACHER_COMMENTS_GET_LIST_ERROR = "TEACHER_COMMENTS_GET_LIST_ERROR";

export const TEACHER_COMMENT_GET_ONE = "TEACHER_COMMENT_GET_ONE";
export const TEACHER_COMMENT_GET_ONE_SUCCESS = "TEACHER_COMMENT_GET_ONE_SUCCESS";
export const TEACHER_COMMENT_GET_ONE_ERROR = "TEACHER_COMMENT_GET_ONE_ERROR";

export const TEACHER_COMMENTS_GET_BY_FILTER = "TEACHER_COMMENTS_GET_BY_FILTER";
export const TEACHER_COMMENTS_GET_BY_FILTER_SUCCESS = "TEACHER_COMMENTS_GET_BY_FILTER_SUCCESS";
export const TEACHER_COMMENTS_GET_BY_FILTER_ERROR = "TEACHER_COMMENTS_GET_BY_FILTER_ERROR";

export const TEACHER_COMMENT_ADD_ONE = "TEACHER_COMMENT_ADD_ONE";
export const TEACHER_COMMENT_ADD_ONE_SUCCESS = "TEACHER_COMMENT_ADD_ONE_SUCCESS";
export const TEACHER_COMMENT_ADD_ONE_ERROR = "TEACHER_COMMENT_ADD_ONE_ERROR";

export const TEACHER_COMMENT_EDIT_ONE = "TEACHER_COMMENT_EDIT_ONE";
export const TEACHER_COMMENT_EDIT_ONE_SUCCESS = "TEACHER_COMMENT_EDIT_ONE_SUCCESS";
export const TEACHER_COMMENT_EDIT_ONE_ERROR = "TEACHER_COMMENT_EDIT_ONE_ERROR";

export const TEACHER_COMMENT_DELETE_ONE = "TEACHER_COMMENT_DELETE_ONE";
export const TEACHER_COMMENT_DELETE_ONE_SUCCESS = "TEACHER_COMMENT_DELETE_ONE_SUCCESS";
export const TEACHER_COMMENT_DELETE_ONE_ERROR = "TEACHER_COMMENT_DELETE_ONE_ERROR";

export const TEACHER_COMMENTS_DELETE_MANY = "TEACHER_COMMENTS_DELETE_MANY";
export const TEACHER_COMMENTS_DELETE_MANY_SUCCESS = "TEACHER_COMMENTS_DELETE_MANY_SUCCESS";
export const TEACHER_COMMENTS_DELETE_MANY_ERROR = "TEACHER_COMMENTS_DELETE_MANY_ERROR";
/**************************************************************************************************/
export const APPOINTEMENTS_GET_LIST = "APPOINTEMENTS_GET_LIST";
export const APPOINTEMENTS_GET_LIST_SUCCESS = "APPOINTEMENTS_GET_LIST_SUCCESS";
export const APPOINTEMENTS_GET_LIST_ERROR = "APPOINTEMENTS_GET_LIST_ERROR";


export const APPOINTEMENT_GET_ONE = "APPOINTEMENT_GET_ONE";
export const APPOINTEMENT_GET_ONE_SUCCESS = "APPOINTEMENT_GET_ONE_SUCCESS";
export const APPOINTEMENT_GET_ONE_ERROR = "APPOINTEMENT_GET_ONE_ERROR";

export const APPOINTEMENTS_GET_BY_FILTER = "APPOINTEMENTS_GET_BY_FILTER";
export const APPOINTEMENTS_GET_BY_FILTER_SUCCESS = "APPOINTEMENTS_GET_BY_FILTER_SUCCESS";
export const APPOINTEMENTS_GET_BY_FILTER_ERROR = "APPOINTEMENTS_GET_BY_FILTER_ERROR";

export const APPOINTEMENT_ADD_ONE = "APPOINTEMENT_ADD_ONE";
export const APPOINTEMENT_ADD_ONE_SUCCESS = "APPOINTEMENT_ADD_ONE_SUCCESS";
export const APPOINTEMENT_ADD_ONE_ERROR = "APPOINTEMENT_ADD_ONE_ERROR";

export const APPOINTEMENT_EDIT_ONE = "APPOINTEMENT_EDIT_ONE";
export const APPOINTEMENT_EDIT_ONE_SUCCESS = "APPOINTEMENT_EDIT_ONE_SUCCESS";
export const APPOINTEMENT_EDIT_ONE_ERROR = "APPOINTEMENT_EDIT_ONE_ERROR";


export const APPOINTEMENT_DELETE_ONE = "APPOINTEMENT_DELETE_ONE";
export const APPOINTEMENT_DELETE_ONE_SUCCESS = "APPOINTEMENT_DELETE_ONE_SUCCESS";
export const APPOINTEMENT_DELETE_ONE_ERROR = "APPOINTEMENT_DELETE_ONE_ERROR";

export const APPOINTEMENTS_DELETE_MANY = "APPOINTEMENTS_DELETE_MANY";
export const APPOINTEMENTS_DELETE_MANY_SUCCESS = "APPOINTEMENTS_DELETE_MANY_SUCCESS";
export const APPOINTEMENTS_DELETE_MANY_ERROR = "APPOINTEMENTS_DELETE_MANY_ERROR"
/**************************************************************************************************/
export const MEALTYPES_GET_LIST = "MEALTYPES_GET_LIST";
export const MEALTYPES_GET_LIST_SUCCESS = "MEALTYPES_GET_LIST_SUCCESS";
export const MEALTYPES_GET_LIST_ERROR = "MEALTYPES_GET_LIST_ERROR";

export const MEALTYPE_GET_ONE = "MEALTYPE_GET_ONE";
export const MEALTYPE_GET_ONE_SUCCESS = "MEALTYPE_GET_ONE_SUCCESS";
export const MEALTYPE_GET_ONE_ERROR = "MEALTYPE_GET_ONE_ERROR";

export const MEALTYPES_GET_BY_FILTER = "MEALTYPES_GET_BY_FILTER";
export const MEALTYPES_GET_BY_FILTER_SUCCESS = "MEALTYPES_GET_BY_FILTER_SUCCESS";
export const MEALTYPES_GET_BY_FILTER_ERROR = "MEALTYPES_GET_BY_FILTER_ERROR";


export const MEALTYPE_ADD_ONE = "MEALTYPE_ADD_ONE";
export const MEALTYPE_ADD_ONE_SUCCESS = "MEALTYPE_ADD_ONE_SUCCESS";
export const MEALTYPE_ADD_ONE_ERROR = "MEALTYPE_ADD_ONE_ERROR";

export const MEALTYPE_EDIT_ONE = "MEALTYPE_EDIT_ONE";
export const MEALTYPE_EDIT_ONE_SUCCESS = "MEALTYPE_EDIT_ONE_SUCCESS";
export const MEALTYPE_EDIT_ONE_ERROR = "MEALTYPE_EDIT_ONE_ERROR";


export const MEALTYPE_DELETE_ONE = "MEALTYPE_DELETE_ONE";
export const MEALTYPE_DELETE_ONE_SUCCESS = "MEALTYPE_DELETE_ONE_SUCCESS";
export const MEALTYPE_DELETE_ONE_ERROR = "MEALTYPE_DELETE_ONE_ERROR";

export const MEALTYPES_DELETE_MANY = "MEALTYPES_DELETE_MANY";
export const MEALTYPES_DELETE_MANY_SUCCESS = "MEALTYPES_DELETE_MANY_SUCCESS";
export const MEALTYPES_DELETE_MANY_ERROR = "MEALTYPES_DELETE_MANY_ERROR";
/**************************************************************************************************/
export const MEALS_GET_LIST = "MEALS_GET_LIST";
export const MEALS_GET_LIST_SUCCESS = "MEALS_GET_LIST_SUCCESS";
export const MEALS_GET_LIST_ERROR = "MEALS_GET_LIST_ERROR";


export const MEAL_GET_ONE = "MEAL_GET_ONE";
export const MEAL_GET_ONE_SUCCESS = "MEAL_GET_ONE_SUCCESS";
export const MEAL_GET_ONE_ERROR = "MEAL_GET_ONE_ERROR";

export const MEALS_GET_BY_FILTER = "MEALS_GET_BY_FILTER";
export const MEALS_GET_BY_FILTER_SUCCESS = "MEALS_GET_BY_FILTER_SUCCESS";
export const MEALS_GET_BY_FILTER_ERROR = "MEALS_GET_BY_FILTER_ERROR";

export const MEAL_ADD_ONE = "MEAL_ADD_ONE";
export const MEAL_ADD_ONE_SUCCESS = "MEAL_ADD_ONE_SUCCESS";
export const MEAL_ADD_ONE_ERROR = "MEAL_ADD_ONE_ERROR";

export const MEAL_EDIT_ONE = "MEAL_EDIT_ONE";
export const MEAL_EDIT_ONE_SUCCESS = "MEAL_EDIT_ONE_SUCCESS";
export const MEAL_EDIT_ONE_ERROR = "MEAL_EDIT_ONE_ERROR";


export const MEAL_DELETE_ONE = "MEAL_DELETE_ONE";
export const MEAL_DELETE_ONE_SUCCESS = "MEAL_DELETE_ONE_SUCCESS";
export const MEAL_DELETE_ONE_ERROR = "MEAL_DELETE_ONE_ERROR";

export const MEALS_DELETE_MANY = "MEALS_DELETE_MANY";
export const MEALS_DELETE_MANY_SUCCESS = "MEALS_DELETE_MANY_SUCCESS";
export const MEALS_DELETE_MANY_ERROR = "MEALS_DELETE_MANY_ERROR";
/**************************************************************************************************/
export const CANTEENMENUS_GET_LIST = "CANTEENMENUS_GET_LIST";
export const CANTEENMENUS_GET_LIST_SUCCESS = "CANTEENMENUS_GET_LIST_SUCCESS";
export const CANTEENMENUS_GET_LIST_ERROR = "CANTEENMENUS_GET_LIST_ERROR";

export const CANTEEN_RESERVATIONS_GET_BY_DATE = "CANTEEN_RESERVATIONS_GET_BY_DATE";
export const CANTEEN_RESERVATIONS_GET_BY_DATE_SUCCESS = "CANTEEN_RESERVATIONS_GET_BY_DATE_SUCCESS";
export const CANTEEN_RESERVATIONS_GET_BY_DATE_ERROR = "CANTEEN_RESERVATIONS_GET_BY_DATE_ERROR";

export const CANTEENMENU_GET_ONE = "CANTEENMENU_GET_ONE";
export const CANTEENMENU_GET_ONE_SUCCESS = "CANTEENMENU_GET_ONE_SUCCESS";
export const CANTEENMENU_GET_ONE_ERROR = "CANTEENMENU_GET_ONE_ERROR";

export const CANTEENMENUS_GET_BY_FILTER = "CANTEENMENUS_GET_BY_FILTER";
export const CANTEENMENUS_GET_BY_FILTER_SUCCESS = "CANTEENMENUS_GET_BY_FILTER_SUCCESS";
export const CANTEENMENUS_GET_BY_FILTER_ERROR = "CANTEENMENUS_GET_BY_FILTER_ERROR"
export const CANTEENMENU_ADD_ONE = "CANTEENMENU_ADD_ONE";
export const CANTEENMENU_ADD_ONE_SUCCESS = "CANTEENMENU_ADD_ONE_SUCCESS";
export const CANTEENMENU_ADD_ONE_ERROR = "CANTEENMENU_ADD_ONE_ERROR";


export const CANTEENMENU_EDIT_ONE = "CANTEENMENU_EDIT_ONE";
export const CANTEENMENU_EDIT_ONE_SUCCESS = "CANTEENMENU_EDIT_ONE_SUCCESS";
export const CANTEENMENU_EDIT_ONE_ERROR = "CANTEENMENU_EDIT_ONE_ERROR";

export const CANTEENMENU_DELETE_ONE = "CANTEENMENU_DELETE_ONE";
export const CANTEENMENU_DELETE_ONE_SUCCESS = "CANTEENMENU_DELETE_ONE_SUCCESS";
export const CANTEENMENU_DELETE_ONE_ERROR = "CANTEENMENU_DELETE_ONE_ERROR";

export const CANTEENMENUS_DELETE_MANY = "CANTEENMENUS_DELETE_MANY";
export const CANTEENMENUS_DELETE_MANY_SUCCESS = "CANTEENMENUS_DELETE_MANY_SUCCESS";
export const CANTEENMENUS_DELETE_MANY_ERROR = "CANTEENMENUS_DELETE_MANY_ERROR";
/**************************************************************************************************/
export const RESERVATION_TYPES_GET_LIST = "RESERVATION_TYPES_GET_LIST";
export const RESERVATION_TYPES_GET_LIST_SUCCESS = "RESERVATION_TYPES_GET_LIST_SUCCESS";
export const RESERVATION_TYPES_GET_LIST_ERROR = "RESERVATION_TYPES_GET_LIST_ERROR";


export const RESERVATION_TYPE_GET_ONE = "RESERVATION_TYPE_GET_ONE";
export const RESERVATION_TYPE_GET_ONE_SUCCESS = "RESERVATION_TYPE_GET_ONE_SUCCESS";
export const RESERVATION_TYPE_GET_ONE_ERROR = "RESERVATION_TYPE_GET_ONE_ERROR";

export const RESERVATION_TYPES_GET_BY_FILTER = "RESERVATION_TYPES_GET_BY_FILTER";
export const RESERVATION_TYPES_GET_BY_FILTER_SUCCESS = "RESERVATION_TYPES_GET_BY_FILTER_SUCCESS";
export const RESERVATION_TYPES_GET_BY_FILTER_ERROR = "RESERVATION_TYPES_GET_BY_FILTER_ERROR";

export const RESERVATION_TYPE_ADD_ONE = "RESERVATION_TYPE_ADD_ONE";
export const RESERVATION_TYPE_ADD_ONE_SUCCESS = "RESERVATION_TYPE_ADD_ONE_SUCCESS";
export const RESERVATION_TYPE_ADD_ONE_ERROR = "RESERVATION_TYPE_ADD_ONE_ERROR";


export const RESERVATION_TYPE_EDIT_ONE = "RESERVATION_TYPE_EDIT_ONE";
export const RESERVATION_TYPE_EDIT_ONE_SUCCESS = "RESERVATION_TYPE_EDIT_ONE_SUCCESS";
export const RESERVATION_TYPE_EDIT_ONE_ERROR = "RESERVATION_TYPE_EDIT_ONE_ERROR";


export const RESERVATION_TYPE_DELETE_ONE = "RESERVATION_TYPE_DELETE_ONE";
export const RESERVATION_TYPE_DELETE_ONE_SUCCESS = "RESERVATION_TYPE_DELETE_ONE_SUCCESS";
export const RESERVATION_TYPE_DELETE_ONE_ERROR = "RESERVATION_TYPE_DELETE_ONE_ERROR";

export const RESERVATION_TYPES_DELETE_MANY = "RESERVATION_TYPES_DELETE_MANY";
export const RESERVATION_TYPES_DELETE_MANY_SUCCESS = "RESERVATION_TYPES_DELETE_MANY_SUCCESS";
export const RESERVATION_TYPES_DELETE_MANY_ERROR = "RESERVATION_TYPES_DELETE_MANY_ERROR";
/**************************************************************************************************/
export const CANTEEN_RESERVATIONS_GET_LIST = "CANTEEN_RESERVATIONS_GET_LIST";
export const CANTEEN_RESERVATIONS_GET_LIST_SUCCESS = "CANTEEN_RESERVATIONS_GET_LIST_SUCCESS";
export const CANTEEN_RESERVATIONS_GET_LIST_ERROR = "CANTEEN_RESERVATIONS_GET_LIST_ERROR";

export const CANTEEN_RESERVATION_GET_ONE = "CANTEEN_RESERVATION_GET_ONE";
export const CANTEEN_RESERVATION_GET_ONE_SUCCESS = "CANTEEN_RESERVATION_GET_ONE_SUCCESS";
export const CANTEEN_RESERVATION_GET_ONE_ERROR = "CANTEEN_RESERVATION_GET_ONE_ERROR";

export const CANTEEN_RESERVATIONS_GET_BY_FILTER = "CANTEEN_RESERVATIONS_GET_BY_FILTER";
export const CANTEEN_RESERVATIONS_GET_BY_FILTER_SUCCESS = "CANTEEN_RESERVATIONS_GET_BY_FILTER_SUCCESS";
export const CANTEEN_RESERVATIONS_GET_BY_FILTER_ERROR = "CANTEEN_RESERVATIONS_GET_BY_FILTER_ERROR";

export const CANTEEN_RESERVATION_ADD_ONE = "CANTEEN_RESERVATION_ADD_ONE";
export const CANTEEN_RESERVATION_ADD_ONE_SUCCESS = "CANTEEN_RESERVATION_ADD_ONE_SUCCESS";
export const CANTEEN_RESERVATION_ADD_ONE_ERROR = "CANTEEN_RESERVATION_ADD_ONE_ERROR";

export const CANTEEN_RESERVATION_EDIT_ONE = "CANTEEN_RESERVATION_EDIT_ONE";
export const CANTEEN_RESERVATION_EDIT_ONE_SUCCESS = "CANTEEN_RESERVATION_EDIT_ONE_SUCCESS";
export const CANTEEN_RESERVATION_EDIT_ONE_ERROR = "CANTEEN_RESERVATION_EDIT_ONE_ERROR";

export const CANTEEN_RESERVATION_DELETE_ONE = "CANTEEN_RESERVATION_DELETE_ONE";
export const CANTEEN_RESERVATION_DELETE_ONE_SUCCESS = "CANTEEN_RESERVATION_DELETE_ONE_SUCCESS";
export const CANTEEN_RESERVATION_DELETE_ONE_ERROR = "CANTEEN_RESERVATION_DELETE_ONE_ERROR";

export const CANTEEN_RESERVATIONS_DELETE_MANY = "CANTEEN_RESERVATIONS_DELETE_MANY";
export const CANTEEN_RESERVATIONS_DELETE_MANY_SUCCESS = "CANTEEN_RESERVATIONS_DELETE_MANY_SUCCESS";
export const CANTEEN_RESERVATIONS_DELETE_MANY_ERROR = "CANTEEN_RESERVATIONS_DELETE_MANY_ERROR"
/**************************************************************************************************/
export const POST_GET_LIST = "POST_GET_LIST";
export const POST_GET_LIST_SUCCESS = "POST_GET_LIST_SUCCESS";
export const POST_GET_LIST_ERROR = "POST_GET_LIST_ERROR";

export const POST_GET_ONE = "POST_GET_ONE";
export const POST_GET_ONE_SUCCESS = "POST_GET_ONE_SUCCESS";
export const POST_GET_ONE_ERROR = "POST_GET_ONE_ERROR";

export const POST_GET_BY_PARTNER = "POST_GET_BY_PARTNER";
export const POST_GET_BY_PARTNER_SUCCESS = "POST_GET_BY_PARTNER_SUCCESS";
export const POST_GET_BY_PARTNER_ERROR = "POST_GET_BY_PARTNER_ERROR";

export const POST_GET_BY_FILTER = "POST_GET_BY_FILTER";
export const POST_GET_BY_FILTER_SUCCESS = "POST_GET_BY_FILTER_SUCCESS";
export const POST_GET_BY_FILTER_ERROR = "POST_GET_BY_FILTER_ERROR";

export const POST_ADD_ONE = "POST_ADD_ONE";
export const POST_ADD_ONE_SUCCESS = "POST_ADD_ONE_SUCCESS";
export const POST_ADD_ONE_ERROR = "POST_ADD_ONE_ERROR";

export const POST_COMMENT = "POST_COMMENT";
export const POST_COMMENT_SUCCESS = "POST_COMMENT_SUCCESS";
export const POST_COMMENT_ERROR = "POST_COMMENT_ERROR";

export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";

export const POST_LIKE = "POST_LIKE";
export const POST_LIKE_SUCCESS = "POST_LIKE_SUCCESS";
export const POST_LIKE_ERROR = "POST_LIKE_ERROR";

export const POST_EDIT_ONE = "POST_EDIT_ONE";
export const POST_EDIT_ONE_SUCCESS = "POST_EDIT_ONE_SUCCESS";
export const POST_EDIT_ONE_ERROR = "POST_EDIT_ONE_ERROR";

export const POST_DELETE_ONE = "POST_DELETE_ONE";
export const POST_DELETE_ONE_SUCCESS = "POST_DELETE_ONE_SUCCESS";
export const POST_DELETE_ONE_ERROR = "POST_DELETE_ONE_ERROR";
/********************************************************************************************************************************/


export const EVENT_GET_LIST = "EVENT_GET_LIST";
export const EVENT_GET_LIST_SUCCESS = "EVENT_GET_LIST_SUCCESS";
export const EVENT_GET_LIST_ERROR = "EVENT_GET_LIST_ERROR";

export const EVENT_GET_LIVE = "EVENT_GET_LIVE";
export const EVENT_GET_LIVE_SUCCESS = "EVENT_GET_LIVE_SUCCESS";
export const EVENT_GET_LIVE_ERROR = "EVENT_GET_LIVE_ERROR";

export const EVENT_GET_MY_LIST = "EVENT_GET_MY_LIST";
export const EVENT_GET_MY_LIST_SUCCESS = "EVENT_GET_MY_LIST_SUCCESS";
export const EVENT_GET_MY_LIST_ERROR = "EVENT_GET_MY_LIST_ERROR";

export const EVENT_GET_PARTNER_NETWORK = "EVENT_GET_PARTNER_NETWORK";
export const EVENT_GET_SUBSCRIBED = "EVENT_GET_SUBSCRIBED";


export const EVENT_SUBSCRIBE = "EVENT_SUBSCRIBE";
export const EVENT_SUBSCRIBE_SUCCESS = "EVENT_SUBSCRIBE_SUCCESS";
export const EVENT_SUBSCRIBE_ERROR = "EVENT_SUBSCRIBE_ERROR";

export const SUBSCRIPTION_ACCEPT = "SUBSCRIPTION_ACCEPT";
export const SUBSCRIPTION_ACCEPT_SUCCESS = "SUBSCRIPTION_ACCEPT_SUCCESS";
export const SUBSCRIPTION_ACCEPT_ERROR = "SUBSCRIPTION_ACCEPT_ERROR";

export const EVENT_GET_ONE = "EVENT_GET_ONE";
export const EVENT_GET_ONE_SUCCESS = "EVENT_GET_ONE_SUCCESS";
export const EVENT_GET_ONE_ERROR = "EVENT_GET_ONE_ERROR";

export const EVENT_ADD_ONE = "EVENT_ADD_ONE";
export const EVENT_ADD_ONE_SUCCESS = "EVENT_ADD_ONE_SUCCESS";
export const EVENT_ADD_ONE_ERROR = "EVENT_ADD_ONE_ERROR";

export const EVENT_EDIT_ONE = "EVENT_EDIT_ONE";
export const EVENT_EDIT_ONE_SUCCESS = "EVENT_EDIT_ONE_SUCCESS";
export const EVENT_EDIT_ONE_ERROR = "EVENT_EDIT_ONE_ERROR";

export const EVENT_DELETE_ONE = "EVENT_DELETE_ONE";
export const EVENT_DELETE_ONE_SUCCESS = "EVENT_DELETE_ONE_SUCCESS";
export const EVENT_DELETE_ONE_ERROR = "EVENT_DELETE_ONE_ERROR";
/********************************************************************************************************************************/

export const INVITATION_GET_LIST = "INVITATION_GET_LIST";
export const INVITATION_GET_LIST_SUCCESS = "INVITATION_GET_LIST_SUCCESS";
export const INVITATION_GET_LIST_ERROR = "INVITATION_GET_LIST_ERROR";

export const INVITATION_GET_LIST_BY_PARTNER = "INVITATION_GET_LIST_BY_PARTNER";
export const INVITATION_GET_LIST_BY_PARTNER_SUCCESS = "INVITATION_GET_LIST_BY_PARTNER_SUCCESS";
export const INVITATION_GET_LIST_BY_PARTNER_ERROR = "INVITATION_GET_LIST_BY_PARTNER_ERROR";
export const INVITATION_ACCEPT = "INVITATION_ACCEPT";

export const INVITATION_GET_IDS = "INVITATION_GET_IDS";
export const INVITATION_GET_IDS_SUCCESS = "INVITATION_GET_IDS_SUCCESS";
export const INVITATION_GET_IDS_ERROR = "INVITATION_GET_IDS_ERROR";

export const JOB_GET_PARTNER_NETWORK = "JOB_GET_PARTNER_NETWORK";

export const JOB_SUBSCRIBE = "JOB_SUBSCRIBE";
export const JOB_SUBSCRIBE_SUCCESS = "JOB_SUBSCRIBE_SUCCESS";
export const JOB_SUBSCRIBE_ERROR = "JOB_SUBSCRIBE_ERROR";


export const INVITATION_GET_ONE = "INVITATION_GET_ONE";
export const INVITATION_GET_ONE_SUCCESS = "INVITATION_GET_ONE_SUCCESS";
export const INVITATION_GET_ONE_ERROR = "INVITATION_GET_ONE_ERROR";

export const INVITATION_ADD_ONE = "INVITATION_ADD_ONE";
export const INVITATION_ADD_ONE_SUCCESS = "INVITATION_ADD_ONE_SUCCESS";
export const INVITATION_ADD_ONE_ERROR = "INVITATION_ADD_ONE_ERROR";

export const INVITATION_EDIT_ONE = "INVITATION_EDIT_ONE";
export const INVITATION_EDIT_ONE_SUCCESS = "INVITATION_EDIT_ONE_SUCCESS";
export const INVITATION_EDIT_ONE_ERROR = "INVITATION_EDIT_ONE_ERROR";

export const INVITATION_DELETE_ONE = "INVITATION_DELETE_ONE";
export const INVITATION_DELETE_ONE_SUCCESS = "INVITATION_DELETE_ONE_SUCCESS";
export const INVITATION_DELETE_ONE_ERROR = "INVITATION_DELETE_ONE_ERROR";
/********************************************************************************************************************************/


export const JOB_GET_LIST = "JOB_GET_LIST";
export const JOB_GET_LIST_SUCCESS = "JOB_GET_LIST_SUCCESS";
export const JOB_GET_LIST_ERROR = "JOB_GET_LIST_ERROR";

export const JOB_GET_ONE = "JOB_GET_ONE";
export const JOB_GET_ONE_SUCCESS = "JOB_GET_ONE_SUCCESS";
export const JOB_GET_ONE_ERROR = "JOB_GET_ONE_ERROR";

export const JOB_ADD_ONE = "JOB_ADD_ONE";
export const JOB_ADD_ONE_SUCCESS = "JOB_ADD_ONE_SUCCESS";
export const JOB_ADD_ONE_ERROR = "JOB_ADD_ONE_ERROR";

export const JOB_EDIT_ONE = "JOB_EDIT_ONE";
export const JOB_EDIT_ONE_SUCCESS = "JOB_EDIT_ONE_SUCCESS";
export const JOB_EDIT_ONE_ERROR = "JOB_EDIT_ONE_ERROR";

export const JOB_DELETE_ONE = "JOB_DELETE_ONE";
export const JOB_DELETE_ONE_SUCCESS = "JOB_DELETE_ONE_SUCCESS";
export const JOB_DELETE_ONE_ERROR = "JOB_DELETE_ONE_ERROR";
/********************************************************************************************************************************/

export const TRAINING_GET_LIST = "TRAINING_GET_LIST";
export const TRAINING_GET_LIST_SUCCESS = "TRAINING_GET_LIST_SUCCESS";
export const TRAINING_GET_LIST_ERROR = "TRAINING_GET_LIST_ERROR";


export const TRAINING_GET_LIVE = "TRAINING_GET_LIVE";
export const TRAINING_GET_LIVE_SUCCESS = "TRAINING_GET_LIVE_SUCCESS";
export const TRAINING_GET_LIVE_ERROR = "TRAINING_GET_LIVE_ERROR";

export const TRAINING_GET_MY_LIST = "TRAINING_GET_MY_LIST";
export const TRAINING_GET_MY_LIST_SUCCESS = "TRAINING_GET_MY_LIST_SUCCESS";
export const TRAINING_GET_MY_LIST_ERROR = "TRAINING_GET_MY_LIST_ERROR";

export const TRAINING_GET_ONE = "TRAINING_GET_ONE";
export const TRAINING_GET_ONE_SUCCESS = "TRAINING_GET_ONE_SUCCESS";
export const TRAINING_GET_ONE_ERROR = "TRAINING_GET_ONE_ERROR";

export const TRAINING_ADD_ONE = "TRAINING_ADD_ONE";
export const TRAINING_ADD_ONE_SUCCESS = "TRAINING_ADD_ONE_SUCCESS";
export const TRAINING_ADD_ONE_ERROR = "TRAINING_ADD_ONE_ERROR";

export const TRAINING_EDIT_ONE = "TRAINING_EDIT_ONE";
export const TRAINING_EDIT_ONE_SUCCESS = "TRAINING_EDIT_ONE_SUCCESS";
export const TRAINING_EDIT_ONE_ERROR = "TRAINING_EDIT_ONE_ERROR";

export const TRAINING_DELETE_ONE = "TRAINING_DELETE_ONE";
export const TRAINING_DELETE_ONE_SUCCESS = "TRAINING_DELETE_ONE_SUCCESS";
export const TRAINING_DELETE_ONE_ERROR = "TRAINING_DELETE_ONE_ERROR";

export const TRAINING_GET_PARTNER_NETWORK = "TRAINING_GET_PARTNER_NETWORK";

export const TRAINING_SUBSCRIBE = "TRAINING_SUBSCRIBE";
export const TRAINING_SUBSCRIBE_SUCCESS = "TRAINING_SUBSCRIBE_SUCCESS";
export const TRAINING_SUBSCRIBE_ERROR = "TRAINING_SUBSCRIBE_ERROR";
/********************************************************************************************************************************/


export const COURSE_GET_LIST = "COURSE_GET_LIST";
export const COURSE_GET_LIST_SUCCESS = "COURSE_GET_LIST_SUCCESS";
export const COURSE_GET_LIST_ERROR = "COURSE_GET_LIST_ERROR";

export const COURSE_GET_ONE = "COURSE_GET_ONE";
export const COURSE_GET_ONE_SUCCESS = "COURSE_GET_ONE_SUCCESS";
export const COURSE_GET_ONE_ERROR = "COURSE_GET_ONE_ERROR";

export const COURSE_ADD_ONE = "COURSE_ADD_ONE";
export const COURSE_ADD_ONE_SUCCESS = "COURSE_ADD_ONE_SUCCESS";
export const COURSE_ADD_ONE_ERROR = "COURSE_ADD_ONE_ERROR";

export const COURSE_EDIT_ONE = "COURSE_EDIT_ONE";
export const COURSE_EDIT_ONE_SUCCESS = "COURSE_EDIT_ONE_SUCCESS";
export const COURSE_EDIT_ONE_ERROR = "COURSE_EDIT_ONE_ERROR";

export const COURSE_DELETE_ONE = "COURSE_DELETE_ONE";
export const COURSE_DELETE_ONE_SUCCESS = "COURSE_DELETE_ONE_SUCCESS";
export const COURSE_DELETE_ONE_ERROR = "COURSE_DELETE_ONE_ERROR";

export const COURSE_GET_PARTNER_NETWORK = "COURSE_GET_PARTNER_NETWORK";

export const COURSE_SUBSCRIBE = "COURSE_SUBSCRIBE";
export const COURSE_SUBSCRIBE_SUCCESS = "COURSE_SUBSCRIBE_SUCCESS";
export const COURSE_SUBSCRIBE_ERROR = "COURSE_SUBSCRIBE_ERROR";

/********************************************************************************************************************************/

export const COURSE_PROGRESS_GET_LIST = "COURSE_PROGRESS_GET_LIST";
export const COURSE_PROGRESS_GET_LIST_SUCCESS = "COURSE_PROGRESS_GET_LIST_SUCCESS";
export const COURSE_PROGRESS_GET_LIST_ERROR = "COURSE_PROGRESS_GET_LIST_ERROR";

export const COURSE_PROGRESS_GET_ONE = "COURSE_PROGRESS_GET_ONE";
export const COURSE_PROGRESS_GET_ONE_SUCCESS = "COURSE_PROGRESS_GET_ONE_SUCCESS";
export const COURSE_PROGRESS_GET_ONE_ERROR = "COURSE_PROGRESS_GET_ONE_ERROR";

export const COURSE_PROGRESS_EDIT_ONE = "COURSE_PROGRESS_EDIT_ONE";
export const COURSE_PROGRESS_EDIT_ONE_SUCCESS = "COURSE_PROGRESS_EDIT_ONE_SUCCESS";
export const COURSE_PROGRESS_EDIT_ONE_ERROR = "COURSE_PROGRESS_EDIT_ONE_ERROR";

export const LEARNINGS_GET_LIST = "LEARNINGS_GET_LIST";
export const LEARNINGS_GET_LIST_SUCCESS = "LEARNINGS_GET_LIST_SUCCESS";
export const LEARNINGS_GET_LIST_ERROR = "LEARNINGS_GET_LIST_ERROR";

/********************************************************************************************************************************/
export const CONFIGS_SUBJECT_GET_LIST = "CONFIGS_SUBJECT_GET_LIST";
export const CONFIGS_SUBJECT_LIST_SUCCESS = "CONFIGS_SUBJECT_LIST_SUCCESS";
export const CONFIGS_SUBJECT_LIST_ERROR = "CONFIGS_SUBJECT_LIST_ERROR";

export const CONFIGS_SUBJECT_GET_ONE = "CONFIGS_SUBJECT_GET_ONE";
export const CONFIGS_SUBJECT_GET_ONE_SUCCESS = "CONFIGS_SUBJECT_GET_ONE_SUCCESS";
export const CONFIGS_SUBJECT_GET_ONE_ERROR = "CONFIGS_SUBJECT_GET_ONE_ERROR";

export const CONFIGS_SUBJECT_ADD_ONE = "CONFIGS_SUBJECT_ADD_ONE";
export const CONFIGS_SUBJECT_ADD_ONE_SUCCESS = "CONFIGS_SUBJECT_ADD_ONE_SUCCESS";
export const CONFIGS_SUBJECT_ADD_ONE_ERROR = "CONFIGS_SUBJECT_ADD_ONE_ERROR";

export const CONFIGS_SUBJECT_EDIT_ONE = "CONFIGS_SUBJECT_EDIT_ONE";
export const CONFIGS_SUBJECT_EDIT_ONE_SUCCESS = "CONFIGS_SUBJECT_EDIT_ONE_SUCCESS";
export const CONFIGS_SUBJECT_EDIT_ONE_ERROR = "CONFIGS_SUBJECT_EDIT_ONE_ERROR";

export const CONFIGS_SUBJECT_DELETE_ONE = "CONFIGS_SUBJECT_DELETE_ONE";
export const CONFIGS_SUBJECT_DELETE_ONE_SUCCESS = "CONFIGS_SUBJECT_DELETE_ONE_SUCCESS";
export const CONFIGS_SUBJECT_DELETE_ONE_ERROR = "CONFIGS_SUBJECT_DELETE_ONE_ERROR";


/********************************************************************************************************************************/
export const CONFIGS_LEVEL_GET_LIST = "CONFIGS_LEVEL_GET_LIST";
export const CONFIGS_LEVEL_GET_LIST_SUCCESS = "CONFIGS_LEVEL_GET_LIST_SUCCESS";
export const CONFIGS_LEVEL_GET_LIST_ERROR = "CONFIGS_LEVEL_GET_LIST_ERROR";

export const CONFIGS_LEVEL_GET_ONE = "CONFIGS_LEVEL_GET_ONE";
export const CONFIGS_LEVEL_GET_ONE_SUCCESS = "CONFIGS_LEVEL_GET_ONE_SUCCESS";
export const CONFIGS_LEVEL_GET_ONE_ERROR = "CONFIGS_LEVEL_GET_ONE_ERROR";

export const CONFIGS_LEVEL_ADD_ONE = "CONFIGS_LEVEL_ADD_ONE";
export const CONFIGS_LEVEL_ADD_ONE_SUCCESS = "CONFIGS_LEVEL_ADD_ONE_SUCCESS";
export const CONFIGS_LEVEL_ADD_ONE_ERROR = "CONFIGS_LEVEL_ADD_ONE_ERROR";

export const CONFIGS_LEVEL_EDIT_ONE = "CONFIGS_LEVEL_EDIT_ONE";
export const CONFIGS_LEVEL_EDIT_ONE_SUCCESS = "CONFIGS_LEVEL_EDIT_ONE_SUCCESS";
export const CONFIGS_LEVEL_EDIT_ONE_ERROR = "CONFIGS_LEVEL_EDIT_ONE_ERROR";

export const CONFIGS_LEVEL_DELETE_ONE = "CONFIGS_LEVEL_DELETE_ONE";
export const CONFIGS_LEVEL_DELETE_ONE_SUCCESS = "CONFIGS_LEVEL_DELETE_ONE_SUCCESS";
export const CONFIGS_LEVEL_DELETE_ONE_ERROR = "CONFIGS_LEVEL_DELETE_ONE_ERROR";

/********************************************************************************************************************************/
export const CONFIGS_SCHOOL_LEVEL_GET_LIST = "CONFIGS_SCHOOL_LEVEL_GET_LIST";
export const CONFIGS_SCHOOL_LEVEL_GET_LIST_SUCCESS = "CONFIGS_SCHOOL_LEVEL_GET_LIST_SUCCESS";
export const CONFIGS_SCHOOL_LEVEL_GET_LIST_ERROR = "CONFIGS_SCHOOL_LEVEL_GET_LIST_ERROR";

export const CONFIGS_SCHOOL_LEVEL_GET_ONE = "CONFIGS_SCHOOL_LEVEL_GET_ONE";
export const CONFIGS_SCHOOL_LEVEL_GET_ONE_SUCCESS = "CONFIGS_SCHOOL_LEVEL_GET_ONE_SUCCESS";
export const CONFIGS_SCHOOL_LEVEL_GET_ONE_ERROR = "CONFIGS_SCHOOL_LEVEL_GET_ONE_ERROR";

export const CONFIGS_SCHOOL_LEVEL_ADD_ONE = "CONFIGS_SCHOOL_LEVEL_ADD_ONE";
export const CONFIGS_SCHOOL_LEVEL_ADD_ONE_SUCCESS = "CONFIGS_SCHOOL_LEVEL_ADD_ONE_SUCCESS";
export const CONFIGS_SCHOOL_LEVEL_ADD_ONE_ERROR = "CONFIGS_SCHOOL_LEVEL_ADD_ONE_ERROR";

export const CONFIGS_SCHOOL_LEVEL_EDIT_ONE = "CONFIGS_SCHOOL_LEVEL_EDIT_ONE";
export const CONFIGS_SCHOOL_LEVEL_EDIT_ONE_SUCCESS = "CONFIGS_SCHOOL_LEVEL_EDIT_ONE_SUCCESS";
export const CONFIGS_SCHOOL_LEVEL_EDIT_ONE_ERROR = "CONFIGS_SCHOOL_LEVEL_EDIT_ONE_ERROR";

export const CONFIGS_SCHOOL_LEVEL_DELETE_ONE = "CONFIGS_SCHOOL_LEVEL_DELETE_ONE";
export const CONFIGS_SCHOOL_LEVEL_DELETE_ONE_SUCCESS = "CONFIGS_SCHOOL_LEVEL_DELETE_ONE_SUCCESS";
export const CONFIGS_SCHOOL_LEVEL_DELETE_ONE_ERROR = "CONFIGS_SCHOOL_LEVEL_DELETE_ONE_ERROR";

/********************************************************************************************************************************/
export const TUTORING_GET_LIST = "TUTORING_GET_LIST";
export const TUTORING_GET_LIST_SUCCESS = "TUTORING_GET_LIST_SUCCESS";
export const TUTORING_GET_LIST_ERROR = "TUTORING_GET_LIST_ERROR";

export const TUTORING_GET_BY_TEACHER = "TUTORING_GET_BY_TEACHER";
export const TUTORING_GET_BY_TEACHER_SUCCESS = "TUTORING_GET_BY_TEACHER_SUCCESS";
export const TUTORING_GET_BY_TEACHER_ERROR = "TUTORING_GET_BY_TEACHER_ERROR";

export const TUTORING_GET_BY_INSTUTITION = "TUTORING_GET_BY_INSTUTITION";
export const TUTORING_GET_BY_INSTUTITION_SUCCESS = "TUTORING_GET_BY_INSTUTITION_SUCCESS";
export const TUTORING_GET_BY_INSTUTITION_ERROR = "TUTORING_GET_BY_INSTUTITION_ERROR";


export const TUTORING_GET_ONE = "TUTORING_GET_ONE";
export const TUTORING_GET_ONE_SUCCESS = "TUTORING_GET_ONE_SUCCESS";
export const TUTORING_GET_ONE_ERROR = "TUTORING_GET_ONE_ERROR";

export const TUTORING_ADD_ONE = "TUTORING_ADD_ONE";
export const TUTORING_ADD_ONE_SUCCESS = "TUTORING_ADD_ONE_SUCCESS";
export const TUTORING_ADD_ONE_ERROR = "TUTORING_ADD_ONE_ERROR";

export const TUTORING_EDIT_ONE = "TUTORING_EDIT_ONE";
export const TUTORING_EDIT_ONE_SUCCESS = "TUTORING_EDIT_ONE_SUCCESS";
export const TUTORING_EDIT_ONE_ERROR = "TUTORING_EDIT_ONE_ERROR";

export const TUTORING_DELETE_ONE = "TUTORING_DELETE_ONE";
export const TUTORING_DELETE_ONE_SUCCESS = "TUTORING_DELETE_ONE_SUCCESS";
export const TUTORING_DELETE_ONE_ERROR = "TUTORING_DELETE_ONE_ERROR";

export const TUTORING_SUBSCRIBE = "TUTORING_SUBSCRIBE";
export const TUTORING_SUBSCRIBE_SUCCESS = "TUTORING_SUBSCRIBE_SUCCESS";
export const TUTORING_SUBSCRIBE_ERROR = "TUTORING_SUBSCRIBE_ERROR";

export const TUTORING_ACCEPT = "TUTORING_ACCEPT";
export const TUTORING_ACCEPT_SUCCESS = "TUTORING_ACCEPT_SUCCESS";
export const TUTORING_ACCEPT_ERROR = "TUTORING_ACCEPT_ERROR";

export const TRAINING_ACCEPT = "TRAINING_ACCEPT";
export const TRAINING_ACCEPT_SUCCESS = "TRAINING_ACCEPT_SUCCESS";
export const TRAINING_ACCEPT_ERROR = "TRAINING_ACCEPT_ERROR";

export const TUTORING_UNSUBSCRIBE = "TUTORING_UNSUBSCRIBE";
export const TUTORING_UNSUBSCRIBE_SUCCESS = "TUTORING_UNSUBSCRIBE_SUCCESS";
export const TUTORING_UNSUBSCRIBE_ERROR = "TUTORING_UNSUBSCRIBE_ERROR";
/******************************************************    SMS      ***************************************************************/

export const SMS_HISTORY_GET = "SMS_HISTORY_GET";
export const SMS_HISTORY_GET_SUCCESS = "SMS_HISTORY_GET_SUCCESS";
export const SMS_HISTORY_GET_ERROR = "SMS_HISTORY_GET_ERROR";

export const ACCOUNTS_GET_LIST = "ACCOUNTS_GET_LIST";
export const ACCOUNTS_GET_LIST_SUCCESS = "ACCOUNTS_GET_LIST_SUCCESS";
export const ACCOUNTS_GET_LIST_ERROR = "ACCOUNTS_GET_LIST_ERROR";

export const ACCOUNT_GET_ONE = "ACCOUNT_GET_ONE";
export const ACCOUNT_GET_ONE_SUCCESS = "ACCOUNT_GET_ONE_SUCCESS";
export const ACCOUNT_GET_ONE_ERROR = "ACCOUNT_GET_ONE_ERROR";

export const ACCOUNT_ADD_ONE = "ACCOUNT_ADD_ONE";
export const ACCOUNT_ADD_ONE_SUCCESS = "ACCOUNT_ADD_ONE_SUCCESS";
export const ACCOUNT_ADD_ONE_ERROR = "ACCOUNT_ADD_ONE_ERROR";


export const ACCOUNT_EDIT_ONE = "ACCOUNT_EDIT_ONE";
export const ACCOUNT_EDIT_ONE_SUCCESS = "ACCOUNT_EDIT_ONE_SUCCESS";
export const ACCOUNT_EDIT_ONE_ERROR = "ACCOUNT_EDIT_ONE_ERROR"

export const ACCOUNT_DELETE_ONE = "ACCOUNT_DELETE_ONE";
export const ACCOUNT_DELETE_ONE_SUCCESS = "ACCOUNT_DELETE_ONE_SUCCESS";
export const ACCOUNT_DELETE_ONE_ERROR = "ACCOUNT_DELETE_ONE_ERROR";

export const SYNCHRONIZE_ACCOUNT = "SYNCHRONIZE_ACCOUNT";
export const SYNCHRONIZE_ACCOUNT_SUCCESS = "SYNCHRONIZE_ACCOUNT_SUCCESS";
export const SYNCHRONIZE_ACCOUNT_ERROR = "SYNCHRONIZE_ACCOUNT_ERROR";

export const SMS_GET_LIST = 'SMS_GET_LIST';
export const SMS_GET_LIST_SUCCESS = 'SMS_GET_LIST_SUCCESS';
export const SMS_GET_LIST_ERROR = 'SMS_GET_LIST_ERROR';


export const CHECK_ACCOUNT = "CHECK_ACCOUNT";
export const CHECK_ACCOUNT_SUCCESS = "CHECK_ACCOUNT_SUCCESS";
export const CHECK_ACCOUNT_ERROR = "CHECK_ACCOUNT_ERROR";


export const SEND_SMS = "SEND_SMS";
export const SEND_SMS_SUCCESS = "SEND_SMS_SUCCESS";
export const SEND_SMS_ERROR = "SEND_SMS_ERROR";
/********************************************************************************************************************************** */


export const GROUP_GET_LIST = "GROUP_GET_LIST";
export const GROUP_GET_LIST_SUCCESS = "GROUP_GET_LIST_SUCCESS";
export const GROUP_GET_LIST_ERROR = "GROUP_GET_LIST_ERROR";

export const GROUP_GET_MY_LIST = "GROUP_GET_MY_LIST";
export const GROUP_GET_MY_LIST_SUCCESS = "GROUP_GET_MY_LIST_SUCCESS";
export const GROUP_GET_MY_LIST_ERROR = "GROUP_GET_MY_LIST_ERROR";

export const GROUP_GET_PARTNER_NETWORK = "GROUP_GET_PARTNER_NETWORK";
export const GROUP_GET_JOINED = "GROUP_GET_JOINED";

export const GROUP_SUBSCRIBE = "GROUP_SUBSCRIBE";
export const GROUP_SUBSCRIBE_SUCCESS = "GROUP_SUBSCRIBE_SUCCESS";
export const GROUP_SUBSCRIBE_ERROR = "GROUP_SUBSCRIBE_ERROR";

export const GROUP_GET_ONE = "GROUP_GET_ONE";
export const GROUP_GET_ONE_SUCCESS = "GROUP_GET_ONE_SUCCESS";
export const GROUP_GET_ONE_ERROR = "GROUP_GET_ONE_ERROR";

export const GROUP_ADD_ONE = "GROUP_ADD_ONE";
export const GROUP_ADD_ONE_SUCCESS = "GROUP_ADD_ONE_SUCCESS";
export const GROUP_ADD_ONE_ERROR = "GROUP_ADD_ONE_ERROR";

export const GROUP_EDIT_ONE = "GROUP_EDIT_ONE";
export const GROUP_EDIT_ONE_SUCCESS = "GROUP_EDIT_ONE_SUCCESS";
export const GROUP_EDIT_ONE_ERROR = "GROUP_EDIT_ONE_ERROR";

export const GROUP_DELETE_ONE = "GROUP_DELETE_ONE";
export const GROUP_DELETE_ONE_SUCCESS = "GROUP_DELETE_ONE_SUCCESS";
export const GROUP_DELETE_ONE_ERROR = "GROUP_DELETE_ONE_ERROR";

/********************************************************************************************************************************/



export const PARENTS_SCHOOL_GET_LIST = "PARENTS_SCHOOL_GET_LIST";
export const PARENTS_SCHOOL_GET_LIST_SUCCESS = "PARENTS_SCHOOL_GET_LIST_SUCCESS";
export const PARENTS_SCHOOL_GET_LIST_ERROR = "PARENTS_SCHOOL_GET_LIST_ERROR";

export const PARENTS_SCHOOL_GET_LIST_BY_FILTER = "PARENTS_SCHOOL_GET_LIST_BY_FILTER";
export const PARENTS_SCHOOL_GET_LIST_BY_FILTER_SUCCESS = "PARENTS_SCHOOL_GET_LIST_BY_FILTER_SUCCESS";
export const PARENTS_SCHOOL_GET_LIST_BY_FILTER_ERROR = "PARENTS_SCHOOL_GET_LIST_BY_FILTER_ERROR";

export const PARENTS_SCHOOL_GET_ONE = "PARENTS_SCHOOL_GET_ONE";
export const PARENTS_SCHOOL_GET_ONE_SUCCESS = "PARENTS_SCHOOL_GET_ONE_SUCCESS";
export const PARENTS_SCHOOL_GET_ONE_ERROR = "PARENTS_SCHOOL_GET_ONE_ERROR";

export const PARENTS_SCHOOL_ADD_ONE = "PARENTS_SCHOOL_ADD_ONE";
export const PARENTS_SCHOOL_ADD_ONE_SUCCESS = "PARENTS_SCHOOL_ADD_ONE_SUCCESS";
export const PARENTS_SCHOOL_ADD_ONE_ERROR = "PARENTS_SCHOOL_ADD_ONE_ERROR";

export const PARENTS_SCHOOL_EDIT_ONE = "PARENTS_SCHOOL_EDIT_ONE";
export const PARENTS_SCHOOL_EDIT_ONE_SUCCESS = "PARENTS_SCHOOL_EDIT_ONE_SUCCESS";
export const PARENTS_SCHOOL_EDIT_ONE_ERROR = "PARENTS_SCHOOL_EDIT_ONE_ERROR";

export const PARENTS_SCHOOL_DELETE_ONE = "PARENTS_SCHOOL_DELETE_ONE";
export const PARENTS_SCHOOL_DELETE_ONE_SUCCESS = "PARENTS_SCHOOL_DELETE_ONE_SUCCESS";
export const PARENTS_SCHOOL_DELETE_ONE_ERROR = "PARENTS_SCHOOL_DELETE_ONE_ERROR";

export const PARENTS_SCHOOL_DELETE_MANY = "PARENTS_SCHOOL_DELETE_MANY";
export const PARENTS_SCHOOL_DELETE_MANY_SUCCESS = "PARENTS_SCHOOL_DELETE_MANY_SUCCESS";
export const PARENTS_SCHOOL_DELETE_MANY_ERROR = "PARENTS_SCHOOL_DELETE_MANY_ERROR";

////////////////////********************************************************************************************************* */

////////////***************************************************************************************************** */
export const CONTACT_MSG_GET_LIST = "CONTACT_MSG_GET_LIST";
export const CONTACT_MSG_GET_LIST_SUCCESS = "CONTACT_MSG_GET_LIST_SUCCESS";
export const CONTACT_MSG_GET_LIST_ERROR = "CONTACT_MSG_GET_LIST_ERROR";

export const CONTACT_MSG_GET_LIST_BY_FILTER = "CONTACT_MSG_GET_LIST_BY_FILTER";
export const CONTACT_MSG_GET_LIST_BY_FILTER_SUCCESS = "CONTACT_MSG_GET_LIST_BY_FILTER_SUCCESS";
export const CONTACT_MSG_GET_LIST_BY_FILTER_ERROR = "CONTACT_MSG_GET_LIST_BY_FILTER_ERROR";

export const CONTACT_MSG_GET_ONE = "CONTACT_MSG_GET_ONE";
export const CONTACT_MSG_GET_ONE_SUCCESS = "CONTACT_MSG_GET_ONE_SUCCESS";
export const CONTACT_MSG_GET_ONE_ERROR = "CONTACT_MSG_GET_ONE_ERROR";

export const CONTACT_MSG_ADD_ONE = "CONTACT_MSG_ADD_ONE";
export const CONTACT_MSG_ADD_ONE_SUCCESS = "CONTACT_MSG_ADD_ONE_SUCCESS";
export const CONTACT_MSG_ADD_ONE_ERROR = "CONTACT_MSG_ADD_ONE_ERROR";

export const CONTACT_MSG_EDIT_ONE = "CONTACT_MSG_EDIT_ONE";
export const CONTACT_MSG_EDIT_ONE_SUCCESS = "CONTACT_MSG_EDIT_ONE_SUCCESS";
export const CONTACT_MSG_EDIT_ONE_ERROR = "CONTACT_MSG_EDIT_ONE_ERROR";

export const CONTACT_MSG_DELETE_ONE = "CONTACT_MSG_DELETE_ONE";
export const CONTACT_MSG_DELETE_ONE_SUCCESS = "CONTACT_MSG_DELETE_ONE_SUCCESS";
export const CONTACT_MSG_DELETE_ONE_ERROR = "CONTACT_MSG_DELETE_ONE_ERROR";


export const CONTACT_MSGS_DELETE_MANY = "CONTACT_MSGS_DELETE_MANY"
export const CONTACT_MSGS_DELETE_MANY_SUCCESS = "CONTACT_MSGS_DELETE_MANY_SUCCESS";
export const CONTACT_MSGS_DELETE_MANY_ERROR = "CONTACT_MSGS_DELETE_MANY_ERROR";


export const GET_MY_CHATROOMS = "GET_MY_CHATROOMS";
export const GET_MY_CHATROOMS_SUCCESS = "GET_MY_CHATROOMS_SUCCESS";
export const GET_MY_CHATROOMS_ERROR = "GET_MY_CHATROOMS_ERROR";

export const GET_CHATROOM_CONVERSATION = "GET_CHATROOM_CONVERSATION";
export const GET_CHATROOM_CONVERSATION_SUCCESS = "GET_CHATROOM_CONVERSATION_SUCCESS";
export const GET_CHATROOM_CONVERSATION_ERROR = "GET_CHATROOM_CONVERSATION_ERROR";

export const CREATE_NEW_CHATROOM = "CREATE_NEW_CHATROOM";
export const CREATE_NEW_CHATROOM_SUCCESS = "CREATE_NEW_CHATROOM_SUCCESS";
export const CREATE_NEW_CHATROOM_ERROR = "CREATE_NEW_CHATROOM_ERROR";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";

export const ADD_QUESTION = "ADD_QUESTION";
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_ERROR = "ADD_QUESTION_ERROR";

export const GET_LIST_QUESTIONS = "GET_LIST_QUESTIONS";
export const GET_LIST_QUESTIONS_SUCCESS = "GET_LIST_QUESTIONS_SUCCESS";
export const GET_LIST_QUESTIONS_ERROR = "GET_LIST_QUESTIONS_ERROR";

export const DELETE_QUESTION = "DELETE_QUESTION";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_ERROR = "DELETE_QUESTION_ERROR";

export const EDIT_QUESTION = "EDIT_QUESTION";
export const EDIT_QUESTION_SUCCESS = "EDIT_QUESTION_SUCCESS";
export const EDIT_QUESTION_ERROR = "EDIT_QUESTION_ERROR";


//ADMIN CONFIGS
export const CLASS_CONFIG_GET_LIST = "CLASS_CONFIG_GET_LIST";
export const CLASS_CONFIG_GET_BY_TEACHER = "CLASS_CONFIG_GET_BY_TEACHER";

export const CLASS_CONFIG_GET_LIST_SUCCESS = "CLASS_CONFIG_GET_LIST_SUCCESS";
export const CLASS_CONFIG_GET_LIST_ERROR = "CLASS_CONFIG_GET_LIST_ERROR";

export const CLASS_CONFIG_GET_ONE = "CLASS_CONFIG_GET_ONE";
export const CLASS_CONFIG_GET_ONE_SUCCESS = "CLASS_CONFIG_GET_ONE_SUCCESS";
export const CLASS_CONFIG_GET_ONE_ERROR = "CLASS_CONFIG_GET_ONE_ERROR";

export const CLASS_CONFIG_GET_BY_FILTER = "CLASS_CONFIG_GET_BY_FILTER";
export const CLASS_CONFIG_GET_BY_FILTER_SUCCESS = "CLASS_CONFIG_GET_BY_FILTER_SUCCESS";
export const CLASS_CONFIG_GET_BY_FILTER_ERROR = "CLASS_CONFIG_GET_BY_FILTER_ERROR";

export const CLASS_CONFIG_ADD_ONE = "CLASS_CONFIG_ADD_ONE";
export const CLASS_CONFIG_ADD_ONE_SUCCESS = "CLASS_CONFIG_ADD_ONE_SUCCESS";
export const CLASS_CONFIG_ADD_ONE_ERROR = "CLASS_CONFIG_ADD_ONE_ERROR";

export const CLASS_CONFIG_EDIT_ONE = "CLASS_CONFIG_EDIT_ONE";
export const CLASS_CONFIG_EDIT_ONE_SUCCESS = "CLASS_CONFIG_EDIT_ONE_SUCCESS";
export const CLASS_CONFIG_EDIT_ONE_ERROR = "CLASS_CONFIG_EDIT_ONE_ERROR";

export const CLASS_CONFIG_DELETE_ONE = "CLASS_CONFIG_DELETE_ONE";
export const CLASS_CONFIG_DELETE_ONE_SUCCESS = "CLASS_CONFIG_DELETE_ONE_SUCCESS";
export const CLASS_CONFIG_DELETE_ONE_ERROR = "CLASS_CONFIG_DELETE_ONE_ERROR";

export const CLASSES_CONFIG_DELETE_MANY = "CLASSES_CONFIG_DELETE_MANY";
export const CLASSES_CONFIG_DELETE_MANY_SUCCESS = "CLASSES_CONFIG_DELETE_MANY_SUCCESS";
export const CLASSES_CONFIG_DELETE_MANY_ERROR = "CLASSES_CONFIG_DELETE_MANY_ERROR";

export const DIPLOMA_CONFIG_GET_LIST ="DIPLOMA_CONFIG_GET_LIST";
export const DIPLOMA_CONFIG_GET_LIST_SUCCESS="DIPLOMA_CONFIG_GET_LIST_SUCCESS";
export const DIPLOMA_CONFIG_GET_LIST_ERROR="DIPLOMA_CONFIG_GET_LIST_ERROR";
export const DIPLOMA_CONFIG_GET_ONE="DIPLOMA_CONFIG_GET_ONE";
export const DIPLOMA_CONFIG_GET_ONE_SUCCESS="DIPLOMA_CONFIG_GET_ONE_SUCCESS";
export const DIPLOMA_CONFIG_GET_ONE_ERROR="DIPLOMA_CONFIG_GET_ONE_ERROR";
export const DIPLOMA_CONFIG_GET_BY_FILTER="DIPLOMA_CONFIG_GET_BY_FILTER";
export const DIPLOMA_CONFIG_GET_BY_FILTER_SUCCESS="DIPLOMA_CONFIG_GET_BY_FILTER_SUCCESS";
export const DIPLOMA_CONFIG_GET_BY_FILTER_ERROR="DIPLOMA_CONFIG_GET_BY_FILTER_ERROR";
export const DIPLOMA_CONFIG_ADD_ONE="DIPLOMA_CONFIG_ADD_ONE";
export const DIPLOMA_CONFIG_ADD_ONE_SUCCESS="DIPLOMA_CONFIG_ADD_ONE_SUCCESS";
export const DIPLOMA_CONFIG_ADD_ONE_ERROR="DIPLOMA_CONFIG_ADD_ONE_ERROR";
export const DIPLOMA_CONFIG_EDIT_ONE="DIPLOMA_CONFIG_EDIT_ONE";
export const DIPLOMA_CONFIG_EDIT_ONE_SUCCESS="DIPLOMA_CONFIG_EDIT_ONE_SUCCESS";
export const DIPLOMA_CONFIG_EDIT_ONE_ERROR="DIPLOMA_CONFIG_EDIT_ONE_ERROR";
export const DIPLOMA_CONFIG_DELETE_ONE="DIPLOMA_CONFIG_DELETE_ONE";
export const DIPLOMA_CONFIG_DELETE_ONE_SUCCESS="DIPLOMA_CONFIG_DELETE_ONE_SUCCESS";
export const DIPLOMA_CONFIG_DELETE_ONE_ERROR="DIPLOMA_CONFIG_DELETE_ONE_ERROR";
export const DIPLOMAS_CONFIG_DELETE_MANY="DIPLOMAS_CONFIG_DELETE_MANY";
export const DIPLOMAS_CONFIG_DELETE_MANY_SUCCESS="DIPLOMAS_CONFIG_DELETE_MANY_SUCCESS";
export const DIPLOMAS_CONFIG_DELETE_MANY_ERROR="DIPLOMAS_CONFIG_DELETE_MANY_ERROR";

export const MODULE_CONFIG_GET_LIST  ="MODULE_CONFIG_GET_LIST";
export const MODULE_CONFIG_GET_LIST_SUCCESS ="MODULE_CONFIG_GET_LIST_SUCCESS";
export const MODULE_CONFIG_GET_LIST_ERROR ="MODULE_CONFIG_GET_LIST_ERROR";
export const MODULE_CONFIG_GET_ONE ="MODULE_CONFIG_GET_ONE";
export const MODULE_CONFIG_GET_ONE_SUCCESS ="MODULE_CONFIG_GET_ONE_SUCCESS";
export const MODULE_CONFIG_GET_ONE_ERROR ="MODULE_CONFIG_GET_ONE_ERROR";
export const MODULE_CONFIG_GET_BY_FILTER ="MODULE_CONFIG_GET_BY_FILTER";
export const MODULE_CONFIG_GET_BY_FILTER_SUCCESS ="MODULE_CONFIG_GET_BY_FILTER_SUCCESS";
export const MODULE_CONFIG_GET_BY_FILTER_ERROR ="MODULE_CONFIG_GET_BY_FILTER_ERROR";
export const MODULE_CONFIG_ADD_ONE ="MODULE_CONFIG_ADD_ONE";
export const MODULE_CONFIG_ADD_ONE_SUCCESS ="MODULE_CONFIG_ADD_ONE_SUCCESS";
export const MODULE_CONFIG_ADD_ONE_ERROR ="MODULE_CONFIG_ADD_ONE_ERROR";
export const MODULE_CONFIG_EDIT_ONE ="MODULE_CONFIG_EDIT_ONE";
export const MODULE_CONFIG_EDIT_ONE_SUCCESS ="MODULE_CONFIG_EDIT_ONE_SUCCESS";
export const MODULE_CONFIG_EDIT_ONE_ERROR ="MODULE_CONFIG_EDIT_ONE_ERROR";
export const MODULE_CONFIG_DELETE_ONE ="MODULE_CONFIG_DELETE_ONE";
export const MODULE_CONFIG_DELETE_ONE_SUCCESS ="MODULE_CONFIG_DELETE_ONE_SUCCESS";
export const MODULE_CONFIG_DELETE_ONE_ERROR ="MODULE_CONFIG_DELETE_ONE_ERROR";
export const MODULE_CONFIG_DELETE_MANY ="MODULE_CONFIG_DELETE_MANY";
export const MODULE_CONFIG_DELETE_MANY_SUCCESS = "MODULE_CONFIG_DELETE_MANY_SUCCESS";
export const MODULE_CONFIG_DELETE_MANY_ERROR = "MODULE_CONFIG_DELETE_MANY_ERROR";

export const PERIODTYPE_CONFIG_GET_LIST = "PERIODTYPE_CONFIG_GET_LIST";
export const PERIODTYPE_CONFIG_GET_LIST_SUCCESS = "PERIODTYPE_CONFIG_GET_LIST_SUCCESS";
export const PERIODTYPE_CONFIG_GET_LIST_ERROR = "PERIODTYPE_CONFIG_GET_LIST_ERROR"
export const PERIODTYPE_CONFIG_GET_LIST_FILTER = "PERIODTYPE_CONFIG_GET_LIST_FILTER";
export const PERIODTYPE_CONFIG_GET_LIST_FILTER_SUCCESS = "PERIODTYPE_CONFIG_GET_LIST_FILTER_SUCCESS";
export const PERIODTYPE_CONFIG_GET_LIST_FILTER_ERROR = "PERIODTYPE_CONFIG_GET_LIST_FILTER_ERROR";
export const PERIODTYPE_CONFIG_GET_ONE = "PERIODTYPE_CONFIG_GET_ONE";
export const PERIODTYPE_CONFIG_GET_ONE_SUCCESS = "PERIODTYPE_CONFIG_GET_ONE_SUCCESS";
export const PERIODTYPE_CONFIG_GET_ONE_ERROR = "PERIODTYPE_CONFIG_GET_ONE_ERROR";
export const PERIODTYPE_CONFIG_GET_ACTUEL = "PERIODTYPE_CONFIG_GET_ACTUEL";
export const PERIODTYPE_CONFIG_GET_ACTUEL_SUCCESS = "PERIODTYPE_CONFIG_GET_ACTUEL_SUCCESS";
export const PERIODTYPE_CONFIG_GET_ACTUEL_ERROR = "PERIODTYPE_CONFIG_GET_ACTUEL_ERROR";
export const PERIODTYPE_CONFIG_SELECT_PERIOD = "PERIODTYPE_CONFIG_SELECT_PERIOD";
export const PERIODTYPE_CONFIG_ADD_ONE = "PERIODTYPE_CONFIG_ADD_ONE";
export const PERIODTYPE_CONFIG_ADD_ONE_SUCCESS = "PERIODTYPE_CONFIG_ADD_ONE_SUCCESS";
export const PERIODTYPE_CONFIG_ADD_ONE_ERROR = "PERIODTYPE_CONFIG_ADD_ONE_ERROR";
export const PERIODTYPE_CONFIG_EDIT_ONE = "PERIODTYPE_CONFIG_EDIT_ONE";
export const PERIODTYPE_CONFIG_EDIT_ONE_SUCCESS = "PERIODTYPE_CONFIG_EDIT_ONE_SUCCESS";
export const PERIODTYPE_CONFIG_EDIT_ONE_ERROR = "PERIODTYPE_CONFIG_EDIT_ONE_ERROR";
export const PERIODTYPE_CONFIG_DELETE_ONE = "PERIODTYPE_CONFIG_DELETE_ONE";
export const PERIODTYPE_CONFIG_DELETE_ONE_SUCCESS = "PERIODTYPE_CONFIG_DELETE_ONE_SUCCESS";
export const PERIODTYPE_CONFIG_DELETE_ONE_ERROR = "PERIODTYPE_CONFIG_DELETE_ONE_ERROR";
export const PERIODTYPES_CONFIG_DELETE_MANY = "PERIODTYPES_CONFIG_DELETE_MANY";
export const PERIODTYPES_CONFIG_DELETE_MANY_SUCCESS = "PERIODTYPES_CONFIG_DELETE_MANY_SUCCESS";
export const PERIODTYPES_CONFIG_DELETE_MANY_ERROR = "PERIODTYPES_CONFIG_DELETE_MANY_ERROR";

export const SCHOOLYEAR_CONFIG_GET_LIST = "SCHOOLYEAR_CONFIG_GET_LIST";
export const SCHOOLYEAR_CONFIG_GET_LIST_SUCCESS = "SCHOOLYEAR_CONFIG_GET_LIST_SUCCESS";
export const SCHOOLYEAR_CONFIG_GET_LIST_ERROR = "SCHOOLYEAR_CONFIG_GET_LIST_ERROR";
export const SCHOOLYEAR_CONFIG_GET_ONE = "SCHOOLYEAR_CONFIG_GET_ONE";
export const SCHOOLYEAR_CONFIG_GET_ONE_SUCCESS = "SCHOOLYEAR_CONFIG_GET_ONE_SUCCESS";
export const SCHOOLYEAR_CONFIG_GET_ONE_ERROR = "SCHOOLYEAR_CONFIG_GET_ONE_ERROR";
export const SCHOOLYEAR_CONFIG_GET_BY_FILTER = "SCHOOLYEAR_CONFIG_GET_BY_FILTER";
export const SCHOOLYEAR_CONFIG_GET_BY_FILTER_SUCCESS = "SCHOOLYEAR_CONFIG_GET_BY_FILTER_SUCCESS";
export const SCHOOLYEAR_CONFIG_GET_BY_FILTER_ERROR = "SCHOOLYEAR_CONFIG_GET_BY_FILTER_ERROR";
export const SCHOOLYEAR_CONFIG_ADD_ONE = "SCHOOLYEAR_CONFIG_ADD_ONE";
export const SCHOOLYEAR_CONFIG_ADD_ONE_SUCCESS = "SCHOOLYEAR_CONFIG_ADD_ONE_SUCCESS";
export const SCHOOLYEAR_CONFIG_ADD_ONE_ERROR = "SCHOOLYEAR_CONFIG_ADD_ONE_ERROR";
export const SCHOOLYEAR_CONFIG_EDIT_ONE = "SCHOOLYEAR_CONFIG_EDIT_ONE";
export const SCHOOLYEAR_CONFIG_EDIT_ONE_SUCCESS = "SCHOOLYEAR_CONFIG_EDIT_ONE_SUCCESS";
export const SCHOOLYEAR_CONFIG_EDIT_ONE_ERROR = "SCHOOLYEAR_CONFIG_EDIT_ONE_ERROR";
export const SCHOOLYEAR_CONFIG_DELETE_ONE = "SCHOOLYEAR_CONFIG_DELETE_ONE";
export const SCHOOLYEAR_CONFIG_DELETE_ONE_SUCCESS = "SCHOOLYEAR_CONFIG_DELETE_ONE_SUCCESS";
export const SCHOOLYEAR_CONFIG_DELETE_ONE_ERROR = "SCHOOLYEAR_CONFIG_DELETE_ONE_ERROR";
export const SCHOOLYEAR_CONFIG_DELETE_MANY = "SCHOOLYEAR_CONFIG_DELETE_MANY";
export const SCHOOLYEAR_CONFIG_DELETE_MANY_SUCCESS = "SCHOOLYEAR_CONFIG_DELETE_MANY_SUCCESS";
export const SCHOOLYEAR_CONFIG_DELETE_MANY_ERROR = "SCHOOLYEAR_CONFIG_DELETE_MANY_ERROR";

export const SKILL_CONFIG_GET_LIST = "SKILL_CONFIG_GET_LIST";
export const SKILL_CONFIG_GET_LIST_SUCCESS = "SKILL_CONFIG_GET_LIST_SUCCESS";
export const SKILL_CONFIG_GET_LIST_ERROR = "SKILL_CONFIG_GET_LIST_ERROR";
export const SKILL_CONFIG_GET_ONE = "SKILL_CONFIG_GET_ONE";
export const SKILL_CONFIG_GET_ONE_SUCCESS = "SKILL_CONFIG_GET_ONE_SUCCESS";
export const SKILL_CONFIG_GET_ONE_ERROR = "SKILL_CONFIG_GET_ONE_ERROR";
export const SKILL_CONFIG_ADD_ONE = "SKILL_CONFIG_ADD_ONE";
export const SKILL_CONFIG_ADD_ONE_SUCCESS = "SKILL_CONFIG_ADD_ONE_SUCCESS";
export const SKILL_CONFIG_ADD_ONE_ERROR = "SKILL_CONFIG_ADD_ONE_ERROR";
export const SKILL_CONFIG_EDIT_ONE = "SKILL_CONFIG_EDIT_ONE";
export const SKILL_CONFIG_EDIT_ONE_SUCCES = "SKILL_CONFIG_EDIT_ONE_SUCCES";
export const SKILL_CONFIG_EDIT_ONE_ERROR = "SKILL_CONFIG_EDIT_ONE_ERROR";
export const SKILL_CONFIG_EDIT_ONE_SUCCESS = "SKILL_CONFIG_EDIT_ONE_SUCCESS";
export const SKILL_CONFIG_DELETE_ONE = "SKILL_CONFIG_DELETE_ONE";
export const SKILL_CONFIG_DELETE_ONE_SUCCESS = "SKILL_CONFIG_DELETE_ONE_SUCCESS";
export const SKILL_CONFIG_DELETE_ONE_ERROR = "SKILL_CONFIG_DELETE_ONE_ERROR"; 

export const SPECIALITY_CONFIG_GET_LIST = "SPECIALITY_CONFIG_GET_LIST";
export const SPECIALITY_CONFIG_GET_LIST_SUCCESS = "SPECIALITY_CONFIG_GET_LIST_SUCCESS";
export const SPECIALITY_CONFIG_GET_LIST_ERROR = "SPECIALITY_CONFIG_GET_LIST_ERROR";
export const SPECIALITY_CONFIG_GET_ONE = "SPECIALITY_CONFIG_GET_ONE";
export const SPECIALITY_CONFIG_GET_ONE_SUCCESS = "SPECIALITY_CONFIG_GET_ONE_SUCCESS";
export const SPECIALITY_CONFIG_GET_ONE_ERROR = "SPECIALITY_CONFIG_GET_ONE_ERROR";
export const SPECIALITY_CONFIG_GET_BY_FILTER = "SPECIALITY_CONFIG_GET_BY_FILTER";
export const SPECIALITY_CONFIG_GET_BY_FILTER_SUCCESS = "SPECIALITY_CONFIG_GET_BY_FILTER_SUCCESS";
export const SPECIALITY_CONFIG_GET_BY_FILTER_ERROR = "SPECIALITY_CONFIG_GET_BY_FILTER_ERROR";
export const SPECIALITY_CONFIG_ADD_ONE = "SPECIALITY_CONFIG_ADD_ONE";
export const SPECIALITY_CONFIG_ADD_ONE_SUCCESS = "SPECIALITY_CONFIG_ADD_ONE_SUCCESS";
export const SPECIALITY_CONFIG_ADD_ONE_ERROR = "SPECIALITY_CONFIG_ADD_ONE_ERROR";
export const SPECIALITY_CONFIG_EDIT_ONE = "SPECIALITY_CONFIG_EDIT_ONE";
export const SPECIALITY_CONFIG_EDIT_ONE_SUCCESS = "SPECIALITY_CONFIG_EDIT_ONE_SUCCESS";
export const SPECIALITY_CONFIG_EDIT_ONE_ERROR = "SPECIALITY_CONFIG_EDIT_ONE_ERROR";
export const SPECIALITY_CONFIG_DELETE_ONE = "SPECIALITY_CONFIG_DELETE_ONE";
export const SPECIALITY_CONFIG_DELETE_ONE_SUCCESS = "SPECIALITY_CONFIG_DELETE_ONE_SUCCESS";
export const SPECIALITY_CONFIG_DELETE_ONE_ERROR = "SPECIALITY_CONFIG_DELETE_ONE_ERROR";
export const SPECIALITY_CONFIG_DELETE_MANY = "SPECIALITY_CONFIG_DELETE_MANY";
export const SPECIALITY_CONFIG_DELETE_MANY_SUCCESS = "SPECIALITY_CONFIG_DELETE_MANY_SUCCESS";
export const SPECIALITY_CONFIG_DELETE_MANY_ERROR = "SPECIALITY_CONFIG_DELETE_MANY_ERROR";



/********************************************************************************************************************************* */
export * from "./quiz/actions";
export * from "./chat/actions";
export * from "./groups/actions";
export * from "./tutorings/actions";

export * from "./configs/education-levels/actions";
export * from './configs/school-levels/actions';
export * from "./configs/subjects/actions";
export * from "./configs/classes/actions";
export * from "./configs/diplomas/actions";
export * from "./configs/specialities/actions";

export * from "./configs/modules/actions";
export * from "./configs/period-types/actions";
export * from "./configs/school-years/actions";
export * from "./configs/skills/actions";

export * from "./courses/actions";
export * from "./coursesProgress/actions";
export * from "./trainings/actions";
export * from "./jobs/actions";
export * from "./invitations/actions";
export * from "./events/actions";
export * from "./canteen-reservations/actions";
export * from "./posts/actions";
export * from "./reservation-types/actions";
export * from "./canteen-menus/actions";
export * from "./meals/actions";
export * from "./meal-types/actions";
export * from "./appointements/actions";
export * from "./sms/actions";
export * from "./exam-results/actions";
export * from "./exam-mark-notes/actions";
export * from "./exam-modules/actions";
export * from "./exam-notes/actions";
export * from "./exam-sessions/actions";
export * from "./exam-periods/actions"
export * from "./exam-types/actions";
export * from "./exams/actions";
export * from "./teacher-comments/actions";
export * from "./sessions/actions";
export * from "./absences/actions";
export * from "./exemptions/actions";
export * from "./sanction-types/actions";
export * from "./sanctions/actions";
export * from "./homeworks/actions";
export * from "./timetables/actions";
export * from "./modules/actions";
export * from "./classes/actions";
export * from "./skills/actions";
export * from "./parents/actions";
export * from "./students/actions";
export * from "./teachers/actions";
export * from "./partners/actions";
export * from "./contacts/actions";
export * from "./subjects/actions";
export * from "./specialities/actions";
export * from "./rooms/actions";
export * from "./diplomas/actions";
export * from "./school-levels/actions";
export * from "./education-levels/actions";
export * from "./period-types/actions";
export * from "./school-years/actions";
export * from "./institution/actions";
export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./request-manager/actions";
/*billing*/
export * from "./billing.redux/taxes/actions";
export * from "./billing.redux/fees/actions";
export * from "./billing.redux/fee-compositions/actions";
export * from "./billing.redux/payments/actions";
export * from "./billing.redux/bills/actions";

/***** */
export * from "./parents-school/actions"
export * from "./contact-msg/actions"
export * from "./notifications/actions"
export * from './signals/actions'
