import {
  CANTEEN_RESERVATIONS_GET_LIST,
  CANTEEN_RESERVATIONS_GET_LIST_SUCCESS,
  CANTEEN_RESERVATIONS_GET_LIST_ERROR,

  CANTEEN_RESERVATIONS_GET_BY_DATE,
  CANTEEN_RESERVATIONS_GET_BY_DATE_SUCCESS,
  CANTEEN_RESERVATIONS_GET_BY_DATE_ERROR,

  CANTEEN_RESERVATION_GET_ONE,
  CANTEEN_RESERVATION_GET_ONE_SUCCESS,
  CANTEEN_RESERVATION_GET_ONE_ERROR,

  CANTEEN_RESERVATIONS_GET_BY_FILTER,
  CANTEEN_RESERVATIONS_GET_BY_FILTER_SUCCESS,
  CANTEEN_RESERVATIONS_GET_BY_FILTER_ERROR,

  CANTEEN_RESERVATION_ADD_ONE,
  CANTEEN_RESERVATION_ADD_ONE_SUCCESS,
  CANTEEN_RESERVATION_ADD_ONE_ERROR,

  CANTEEN_RESERVATION_EDIT_ONE,
  CANTEEN_RESERVATION_EDIT_ONE_SUCCESS,
  CANTEEN_RESERVATION_EDIT_ONE_ERROR,

  CANTEEN_RESERVATION_DELETE_ONE,
  CANTEEN_RESERVATION_DELETE_ONE_SUCCESS,
  CANTEEN_RESERVATION_DELETE_ONE_ERROR,

  CANTEEN_RESERVATIONS_DELETE_MANY,
  CANTEEN_RESERVATIONS_DELETE_MANY_SUCCESS,
  CANTEEN_RESERVATIONS_DELETE_MANY_ERROR,
} from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'

const INIT_STATE = {
  canteen_reservations: [],
  count: 0,
  canteen_reservation: null,
  canteen_reservations_counts: [],
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

  switch (action.type) {

    ///GET LIST
    case CANTEEN_RESERVATIONS_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case CANTEEN_RESERVATIONS_GET_LIST_SUCCESS:
      console.log("CANTEEN_RESERVATIONS_GET_LIST_SUCCESS : ", action.payload.count);
      return {
        ...state, loading: false,
        canteen_reservations: action.payload.canteen_reservations || [],
        count: action.payload.count
      };
    case CANTEEN_RESERVATIONS_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    ///GET BY DATE
    case CANTEEN_RESERVATIONS_GET_BY_DATE:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case CANTEEN_RESERVATIONS_GET_BY_DATE_SUCCESS:
      return {
        ...state, loading: false,
        canteen_reservations_counts: action.payload.canteen_reservations_counts || [],
        count: action.payload.count || 0
      };
    case CANTEEN_RESERVATIONS_GET_BY_DATE_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET ONE
    case CANTEEN_RESERVATION_GET_ONE:
      return { ...state, loading: true, error: '' };
    case CANTEEN_RESERVATION_GET_ONE_SUCCESS:

      return {
        ...state, loading: false, error: '',
        canteen_reservation: action.payload.canteen_reservation
      };
    case CANTEEN_RESERVATION_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET BY FILTER
    case CANTEEN_RESERVATIONS_GET_BY_FILTER:

      return { ...state, loading: true, error: '' };
    case CANTEEN_RESERVATIONS_GET_BY_FILTER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        canteen_reservations: action.payload.canteen_reservations || [],
        count: action.payload.count
      };
    case CANTEEN_RESERVATIONS_GET_BY_FILTER_ERROR:

      return { ...state, loading: false, error: action.payload.message };

    // ADD ONE
    case CANTEEN_RESERVATION_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case CANTEEN_RESERVATION_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        canteen_reservations: [action.payload.canteen_reservation, ...state.canteen_reservations]
      };
    case CANTEEN_RESERVATION_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    // EDIT ONE
    case CANTEEN_RESERVATION_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case CANTEEN_RESERVATION_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        canteen_reservation: action.payload.canteen_reservation
      };
    case CANTEEN_RESERVATION_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



    // DELETE ONE
    case CANTEEN_RESERVATION_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case CANTEEN_RESERVATION_DELETE_ONE_SUCCESS:
      const canteen_reservations = state.canteen_reservations
      if (action.payload.index !== null && action.payload.index !== undefined)
        canteen_reservations.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        canteen_reservations
      };
    case CANTEEN_RESERVATION_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    // DELETE MANY
    case CANTEEN_RESERVATIONS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case CANTEEN_RESERVATIONS_DELETE_MANY_SUCCESS:
      const canteen_reservations_list = state.canteen_reservations


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_canteen_reservation = -1
          for (let j = 0; j < canteen_reservations_list.length; j++) {
            if (canteen_reservations_list[j]._id === action.payload.data.ids[i]) {
              index_canteen_reservation = j; break;
            }
          }
          if (index_canteen_reservation > -1) canteen_reservations_list.splice(index_canteen_reservation, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        canteen_reservations: canteen_reservations_list
      };
    case CANTEEN_RESERVATIONS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    default: return { ...state };
  }
}


