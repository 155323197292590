import { GET_MY_CHATROOMS_SUCCESS } from "../actions";


const INIT_STATE = {
  newMessage: false
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MY_CHATROOMS_SUCCESS:
      const newMessage = !!(action?.payload?.chatRooms) && action?.payload?.chatRooms.length > 0 ? !action?.payload?.chatRooms[0]?.isRead : false;
      return { ...state, newMessage };

    default: return { ...state };
  }

}
