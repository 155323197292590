import ModuleSrv from '../../../services/ModuleSrv';
import {
  MODULE_CONFIG_GET_LIST,
  MODULE_CONFIG_GET_LIST_SUCCESS,
  MODULE_CONFIG_GET_LIST_ERROR,

  MODULE_CONFIG_GET_ONE,
  MODULE_CONFIG_GET_ONE_SUCCESS,
  MODULE_CONFIG_GET_ONE_ERROR,

  MODULE_CONFIG_GET_BY_FILTER,
  MODULE_CONFIG_GET_BY_FILTER_SUCCESS,
  MODULE_CONFIG_GET_BY_FILTER_ERROR,

  MODULE_CONFIG_ADD_ONE,
  MODULE_CONFIG_ADD_ONE_SUCCESS,
  MODULE_CONFIG_ADD_ONE_ERROR,

  MODULE_CONFIG_EDIT_ONE,
  MODULE_CONFIG_EDIT_ONE_SUCCESS,
  MODULE_CONFIG_EDIT_ONE_ERROR,

  MODULE_CONFIG_DELETE_ONE,
  MODULE_CONFIG_DELETE_ONE_SUCCESS,
  MODULE_CONFIG_DELETE_ONE_ERROR,

  MODULE_CONFIG_DELETE_MANY,
  MODULE_CONFIG_DELETE_MANY_SUCCESS,
  MODULE_CONFIG_DELETE_MANY_ERROR
} from '../../actions';

// GET LIST
export const getModulesConfigList = (payload) => ({
  type: MODULE_CONFIG_GET_LIST,
  payload
});
export const getModulesConfigListSuccess = (payload) => ({
  type: MODULE_CONFIG_GET_LIST_SUCCESS,
  payload
});
export const getModulesConfigListError = (payload) => ({
  type: MODULE_CONFIG_GET_LIST_ERROR,
  payload
});


// GET LIST
export const getOneModuleConfig = (payload) => ({
  type: MODULE_CONFIG_GET_ONE,
  payload
});
export const getOneModuleConfigSuccess = (payload) => ({
  type: MODULE_CONFIG_GET_ONE_SUCCESS,
  payload
});
export const getOneModuleConfigError = (payload) => ({
  type: MODULE_CONFIG_GET_ONE_ERROR,
  payload
});


// GET BY FILTER
export const getModulesConfigByFilter = (payload) => ({
  type: MODULE_CONFIG_GET_BY_FILTER,
  payload
});
export const getModulesConfigByFilterSuccess = (payload) => ({
  type: MODULE_CONFIG_GET_BY_FILTER_SUCCESS,
  payload
});
export const getModulesConfigByFilterError = (payload) => ({
  type: MODULE_CONFIG_GET_BY_FILTER_ERROR,
  payload
});
export const getModulesConfigByFilterCB = (payload, callback, callbackError) => {
  return (dispatch) => {
    dispatch({
      type: MODULE_CONFIG_GET_BY_FILTER,
    });

    ModuleSrv.getListConfig(payload)
      .then((response) => {
        if (response && response?.data) {
          const resp = {
            modules: response?.data?.modules,
            count: response.data.count
          }

          dispatch({
            type: MODULE_CONFIG_GET_BY_FILTER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: MODULE_CONFIG_GET_BY_FILTER_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};






// ADD ONE
export const addModuleConfig = (payload) => ({
  type: MODULE_CONFIG_ADD_ONE,
  payload
});
export const addModuleConfigSuccess = (payload) => ({
  type: MODULE_CONFIG_ADD_ONE_SUCCESS,
  payload
});
export const addModuleConfigrror = (payload) => ({
  type: MODULE_CONFIG_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editModuleConfig = (payload) => ({
  type: MODULE_CONFIG_EDIT_ONE,
  payload
});
export const editModuleConfigSuccess = (payload) => ({
  type: MODULE_CONFIG_EDIT_ONE_SUCCESS,
  payload
});
export const editModuleConfigError = (payload) => ({
  type: MODULE_CONFIG_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneModuleConfig = (payload) => ({
  type: MODULE_CONFIG_DELETE_ONE,
  payload
});
export const deleteOneModuleConfigSuccess = (payload) => ({
  type: MODULE_CONFIG_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneModuleConfigError = (payload) => ({
  type: MODULE_CONFIG_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyModulesConfig = (payload) => ({
  type: MODULE_CONFIG_DELETE_MANY,
  payload
});
export const deleteManyModulesConfigSuccess = (payload) => ({
  type: MODULE_CONFIG_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyModulesConfigError = (payload) => ({
  type: MODULE_CONFIG_DELETE_MANY_ERROR,
  payload
});