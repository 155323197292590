import {
  ADD_QUESTION,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_ERROR,

  GET_LIST_QUESTIONS,
  GET_LIST_QUESTIONS_SUCCESS,
  GET_LIST_QUESTIONS_ERROR,

  DELETE_QUESTION,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_ERROR,

  EDIT_QUESTION,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_ERROR,

} from '../actions';
import { api } from "../../constants/defaultValues";
import axios from "axios";
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg';
const messages = notificationMessages()


export const createQuestion = (payload, callback, callbackError) => {
  return (dispatch) => {
    dispatch({
      type: ADD_QUESTION,
    });

    axios.post(api.quiz + '/add-question', payload)
      .then((response) => {
        NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
        if (response && response?.status === 201 && response.data) {
          const resp = {
            quiz: response.data.quiz
          }
          dispatch({
            type: ADD_QUESTION_SUCCESS,
            payload: resp,
          });
          callback(resp);
        }
        else {
          // NotificationManager.warning(payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
          if (callbackError) callbackError({ error: (response.data ? response.data.msg : "Network Error") });
        }
      })
      .catch(e => {
        NotificationManager.warning(payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        dispatch({
          type: ADD_QUESTION_ERROR,
          payload: { error: e }
        });
        if (callbackError) callbackError(e.data ? e.data?.msg : "Network Error");
      });
  };
};
// GET LIST
export const getQuestionsList = (payload) => ({
  type: GET_LIST_QUESTIONS,
  payload
});
export const getQuestionsListSuccess = (payload) => ({
  type: GET_LIST_QUESTIONS_SUCCESS,
  payload
});
export const getQuestionsListError = (payload) => ({
  type: GET_LIST_QUESTIONS_ERROR,
  payload
});

//EDIT QUESTION
export const editQuestion = (payload) => ({
  type: EDIT_QUESTION,
  payload
});
export const editQuestionSuccess = (payload) => ({
  type: EDIT_QUESTION_SUCCESS,
  payload
});
export const editQuestionError = (payload) => ({
  type: EDIT_QUESTION_ERROR,
  payload
});

//DELETE QUESTION
export const deleteQuestion = (payload) => ({
  type: DELETE_QUESTION,
  payload
});
export const deleteQuestionSuccess = (payload) => ({
  type: DELETE_QUESTION_SUCCESS,
  payload
});
export const deleteQuestionError = (payload) => ({
  type: DELETE_QUESTION_ERROR,
  payload
});
