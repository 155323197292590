import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../../constants/defaultValues";
import axios from "axios";

import {
  MODULE_CONFIG_GET_LIST,
  MODULE_CONFIG_ADD_ONE,
  MODULE_CONFIG_GET_ONE,
  MODULE_CONFIG_EDIT_ONE,
  MODULE_CONFIG_DELETE_ONE,
  MODULE_CONFIG_DELETE_MANY,
  MODULE_CONFIG_GET_BY_FILTER
} from "../../actions";

import {
  getModulesConfigListSuccess,
  getModulesConfigListError,

  getOneModuleConfigSuccess,
  getOneModuleConfigError,

  addModuleConfigSuccess,
  addModuleConfigrror,

  getModulesConfigByFilterSuccess,
  getModulesConfigByFilterError,

  editModuleConfigSuccess,
  editModuleConfigError,
  deleteOneModuleConfigSuccess,
  deleteOneModuleConfigError,

  deleteManyModulesConfigSuccess,
  deleteManyModulesConfigError
} from "./actions";


//GET LIST
const getModulesListRequest = async (payload) => {
  return await axios.get(api.modules + `/list-admin/${payload.offset}/${payload.limit}`,{ headers: { Authorization: payload.token }})
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getModulesConfigList({ payload }) {
  try {
    const ret = yield call(getModulesListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getModulesConfigListSuccess({ modules: ret.data.modules || [], count: ret.data.count || 0 }));

    else yield put(getModulesConfigListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getModulesConfigListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOneModulesRequest = async (payload) => {
  return await axios.get(api.modules + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneModuleConfig({ payload }) {
  try {
    const ret = yield call(getOneModulesRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneModuleConfigSuccess({ module: ret.data.module }));

    else yield put(getOneModuleConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneModuleConfigError({ error, message: (error) ? error.message : '' }));
  }
}


//GET BY FILTER
const getModulesByFilterRequest = async (payload) => {
  return await axios.post(api.modules + `/filters/${payload?.inst_id}/${payload?.offset}/${payload?.limit}`, payload?.filter, { headers: { Authorization: payload?.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getModulesConfigByFilter({ payload }) {
  try {
    const ret = yield call(getModulesByFilterRequest, payload);


    if (ret && ret.status === 200) yield put(getModulesConfigByFilterSuccess({ modules: ret.data.modules || [], count: ret.data.count || 0 }));

    else yield put(getModulesConfigByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    // console.log('SAGA MODULES ERROR', error)
    yield put(getModulesConfigByFilterError({ error, message: (error) ? error.message : '' }));
  }
}

//ADD ONE
const addModuleRequest = async (payload) => {
  return await axios.post(api.modules, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addModuleConfig({ payload }) {
  try {
    const ret = yield call(addModuleRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/admin/settings/modules/list')
      yield put(addModuleConfigSuccess({ module: ret.data.module }));
    }
    else yield put(addModuleConfigrror({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addModuleConfigrror({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneModuleRequest = async (payload) => {
  return await axios.patch(`${api.modules}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneModule({ payload }) {
  try {
    const ret = yield call(editOneModuleRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/admin/settings/modules/list')
      yield put(editModuleConfigSuccess({ module: ret.data.module }));
    }
    else yield put(editModuleConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editModuleConfigError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneModuleRequest = async (payload) => {
  return await axios.delete(`${api.modules}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneModuleConfig({ payload }) {
  try {
    const ret = yield call(deleteOneModuleRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/admin/settings/modules/list')
      yield put(deleteOneModuleConfigSuccess({ index }));
    }
    else yield put(deleteOneModuleConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneModuleConfigError({ error, message: (error) ? error.message : '' }));
  }
}

//DELETE MANY

const deleteManyModulesRequest = async (payload) => {
  return await axios.delete(`${api.modules}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyModulesConfig({ payload }) {
  try {
    const ret = yield call(deleteManyModulesRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/admin/settings/modules/list')
      yield put(deleteManyModulesConfigSuccess({ data }));
    }
    else yield put(deleteManyModulesConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyModulesConfigError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(MODULE_CONFIG_GET_LIST, getModulesConfigList);
}

export function* watchGetOne() {
  yield takeEvery(MODULE_CONFIG_GET_ONE, getOneModuleConfig);
}

export function* watchEditOne() {
  yield takeEvery(MODULE_CONFIG_EDIT_ONE, editOneModule);
}

export function* watchAddOne() {
  yield takeEvery(MODULE_CONFIG_ADD_ONE, addModuleConfig);
}

export function* watchDeleteOne() {
  yield takeEvery(MODULE_CONFIG_DELETE_ONE, deleteOneModuleConfig);
}
export function* watchDeleteMany() {
  yield takeEvery(MODULE_CONFIG_DELETE_MANY, deleteManyModulesConfig);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)
  ]);
}
