export const REQUEST_MANAGER_GET_LIST = "REQUEST_MANAGER_GET_LIST";
export const REQUEST_MANAGER_GET_LIST_SUCCESS = "REQUEST_MANAGER_GET_LIST_SUCCESS";
export const REQUEST_MANAGER_GET_LIST_ERROR = "REQUEST_MANAGER_GET_LIST_ERROR";

export const REQUEST_MANAGER_GET_ITEM = "REQUEST_MANAGER_GET_ITEM";
export const REQUEST_MANAGER_GET_ITEM_SUCCESS = "REQUEST_MANAGER_GET_ITEM_SUCCESS";
export const REQUEST_MANAGER_GET_ITEM_ERROR = "REQUEST_MANAGER_GET_ITEM_ERROR";

export const REQUEST_MANAGER_ADD = "REQUEST_MANAGER_ADD";
export const REQUEST_MANAGER_ADD_SUCCESS = "REQUEST_MANAGER_ADD_SUCCESS";
export const REQUEST_MANAGER_ADD_ERROR = "REQUEST_MANAGER_ADD_ERROR";

export const REQUEST_MANAGER_DELETE_ONE = "REQUEST_MANAGER_DELETE_ONE";
export const REQUEST_MANAGER_DELETE_ONE_SUCCESS = "REQUEST_MANAGER_DELETE_ONE_SUCCESS";
export const REQUEST_MANAGER_DELETE_ONE_ERROR = "REQUEST_MANAGER_DELETE_ONE_ERROR";

export const REQUEST_MANAGER_EDIT_ONE = "REQUEST_MANAGER_EDIT_ONE";
export const REQUEST_MANAGER_EDIT_ONE_SUCCESS = "REQUEST_MANAGER_EDIT_ONE_SUCCESS";
export const REQUEST_MANAGER_EDIT_ONE_ERROR = "REQUEST_MANAGER_EDIT_ONE_ERROR";