import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../../constants/defaultValues";
import axios from "axios";

import {
  PAYMENT_GET_LIST, PAYMENT_ADD_ONE, PAYMENT_GET_ONE, PAYMENT_EDIT_ONE, PAYMENT_DELETE_ONE, PAYMENT_PRINT, PAYMENT_CHARGE, PAYMENTS_DELETE_MANY, PAYMENT_GET_LIST_BY_FILTER
} from "../../actions";

import {
  getPaymentsListSuccess,
  getPaymentsListError,

  getOnePaymentSuccess,
  getOnePaymentError,

  getPaymentByFilterSuccess,
  getPaymentsByFilterError,

  addPaymentSuccess,
  addPaymentError,

  editPaymentSuccess,
  editPaymentError,

  printPaymentSuccess,
  printPaymentError,

  chargePaymentSuccess,
  chargePaymentError,


  deleteOnePaymentSuccess,
  deleteOnePaymentError,

  deleteManyPaymentsSuccess,
  deleteManyPaymentsError,
} from "./actions";


//GET LIST
const getPaymentsListRequest = async (payload) => {
  return await axios.get(api.payments + `/list/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* getPaymentsList({ payload }) {
  try {
    const ret = yield call(getPaymentsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getPaymentsListSuccess({ payments: ret.data.payments || [], count: ret.data.count || 0 }));

    else yield put(getPaymentsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getPaymentsListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOnePaymentRequest = async (payload) => {
  return await axios.get(api.payments + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOnePayment({ payload }) {
  try {
    const ret = yield call(getOnePaymentRequest, payload);

    if (ret && ret.status === 200) yield put(getOnePaymentSuccess({ payment: ret.data.payment }));
    else yield put(getOnePaymentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(getOnePaymentError({ error, message: (error) ? error.message : '' }));
  }
}
//GET LIST BY FILTER

const getPaymentsByFilterRequest = async (payload) => {
  return await axios.post(api.payments + `/filters/${payload.inst_id}/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* getPaymentsByFilter({ payload }) {
  try {
    const ret = yield call(getPaymentsByFilterRequest, payload);
    console.log("getPaymentsByFilter : ", ret);

    if (ret && ret.status === 200)
      yield put(getPaymentByFilterSuccess({ payments: ret.data.paiments || [], count: ret.data.count || 0 }));

    else yield put(getPaymentsByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getPaymentsByFilterError({ error, message: (error) ? error.message : '' }));
  }
}

//ADD ONE
const addPaymentRequest = async (payload) => {

  return await axios.post(api.payments, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addPayment({ payload }) {
  //console.log('saga add payment', payload);

  try {
    const ret = yield call(addPaymentRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/billing/payments')
      yield put(addPaymentSuccess({ payment: ret.data.payment }));
    }
    else yield put(addPaymentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(addPaymentError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOnePaymentRequest = async (payload) => {
  return await axios.patch(`${api.payments}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOnePayment({ payload }) {
  try {
    const ret = yield call(editOnePaymentRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/billing/payments')
      yield put(editPaymentSuccess({ payment: ret.data.payment }));
    }
    else yield put(editPaymentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(editPaymentError({ error, message: (error) ? error.message : '' }));
  }
}

//PRINT PAYMENT
const printPaymentRequest = async (payload) => {

  return await axios.post(`${api.payments}/print-payment/`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* printPayment({ payload }) {
  try {
    const ret = yield call(printPaymentRequest, payload);
    //console.log('ret',ret);


    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/billing/payments')
      yield put(printPaymentSuccess({ path: ret.data.path }));
    }
    else yield put(printPaymentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(printPaymentError({ error, message: (error) ? error.message : '' }));
  }
}


//CHARGE PAYMENT
const chargePaymentRequest = async (payload) => {

  return await axios.post(`${api.payments}/charge-payment/`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* chargePayment({ payload }) {
  try {
    const ret = yield call(chargePaymentRequest, payload);
    //console.log('ret',ret);


    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/billing/payments')
      yield put(chargePaymentSuccess({ path: ret.data.path }));
    }
    else yield put(chargePaymentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(chargePaymentError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOnePaymentRequest = async (payload) => {
  return await axios.delete(`${api.payments}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOnePayment({ payload }) {
  //console.log('saga delete payment', payload);
  try {
    const ret = yield call(deleteOnePaymentRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/billing/payments')
      yield put(deleteOnePaymentSuccess({ index }));
    }
    else yield put(deleteOnePaymentError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(deleteOnePaymentError({ error, message: (error) ? error.message : '' }));
  }
}
//DELETE MANY

const deleteManyPaymentsRequest = async (payload) => {
  return await axios.delete(`${api.payments}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyPayments({ payload }) {
  try {
    const ret = yield call(deleteManyPaymentsRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/app/billing/payments')
      yield put(deleteManyPaymentsSuccess({ data }));
    }
    else yield put(deleteManyPaymentsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyPaymentsError({ error, message: (error) ? error.message : '' }));
  }
}

//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(PAYMENT_GET_LIST, getPaymentsList);
}

export function* watchGetOne() {
  yield takeEvery(PAYMENT_GET_ONE, getOnePayment);
}
export function* watchGetByFilter() {
  yield takeEvery(PAYMENT_GET_LIST_BY_FILTER, getPaymentsByFilter);
}
export function* watchEditOne() {
  yield takeEvery(PAYMENT_EDIT_ONE, editOnePayment);
}

export function* watchPrint() {
  yield takeEvery(PAYMENT_PRINT, printPayment);
}

export function* watchCharge() {
  yield takeEvery(PAYMENT_CHARGE, chargePayment);
}

export function* watchAddOne() {
  yield takeEvery(PAYMENT_ADD_ONE, addPayment);
}

export function* watchDeleteOne() {
  yield takeEvery(PAYMENT_DELETE_ONE, deleteOnePayment);
}

export function* watchDeleteMany() {
  yield takeEvery(PAYMENTS_DELETE_MANY, deleteManyPayments);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetByFilter),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchPrint),
    fork(watchCharge),
    fork(watchDeleteMany)
  ]);
}
