import StudentSrv from '../../services/StudentSrv';
import {
  STUDENT_GET_LIST,
  STUDENT_GET_LIST_SUCCESS,
  STUDENT_GET_LIST_ERROR,

  STUDENT_GET_ONE,
  STUDENT_GET_ONE_SUCCESS,
  STUDENT_GET_ONE_ERROR,

  STUDENT_GET_BY_FILTER,
  STUDENT_GET_BY_FILTER_SUCCESS,
  STUDENT_GET_BY_FILTER_ERROR,

  STUDENT_ADD_ONE,
  STUDENT_ADD_ONE_SUCCESS,
  STUDENT_ADD_ONE_ERROR,

  STUDENT_EDIT_ONE,
  STUDENT_EDIT_ONE_SUCCESS,
  STUDENT_EDIT_ONE_ERROR,

  STUDENT_DELETE_ONE,
  STUDENT_DELETE_ONE_SUCCESS,
  STUDENT_DELETE_ONE_ERROR,

  GET_STUDENT_ASSIGNMENTS,
  GET_STUDENT_ASSIGNMENTS_SUCCESS,
  GET_STUDENT_ASSIGNMENTS_ERROR,

  STUDENT_ASSIGNMENT,
  STUDENT_ASSIGNMENT_SUCCESS,
  STUDENT_ASSIGNMENT_ERROR,

  STUDENTS_DELETE_MANY,
  STUDENTS_DELETE_MANY_SUCCESS,
  STUDENTS_DELETE_MANY_ERROR,
  SCHOOL_STUDENTS_GET_LIST,
  SCHOOL_STUDENTS_GET_LIST_SUCCESS,
  SCHOOL_STUDENTS_GET_LIST_ERROR,
} from '../actions';

// GET LIST
export const getStudentsList = (payload) => ({
  type: STUDENT_GET_LIST,
  payload
});
export const getStudentsListSuccess = (payload) => ({
  type: STUDENT_GET_LIST_SUCCESS,
  payload
});
export const getStudentsListError = (payload) => ({
  type: STUDENT_GET_LIST_ERROR,
  payload
});



// GET STUDENT ASSIGNMENTS
export const getStudentAssignment = (payload) => ({
  type: GET_STUDENT_ASSIGNMENTS,
  payload
});
export const getStudentAssignmentSuccess = (payload) => ({
  type: GET_STUDENT_ASSIGNMENTS_SUCCESS,
  payload
});
export const getStudentAssignmentError = (payload) => ({
  type: GET_STUDENT_ASSIGNMENTS_ERROR,
  payload
});

// STUDENT ASSIGNMENT
export const studentAssignment = (payload) => ({
  type: STUDENT_ASSIGNMENT,
  payload
});
export const studentAssignmentSuccess = (payload) => ({
  type: STUDENT_ASSIGNMENT_SUCCESS,
  payload
});
export const studentAssignmentError = (payload) => ({
  type: STUDENT_ASSIGNMENT_ERROR,
  payload
});


// GET SCHOOL TEACHERS
export const getSchoolStudentsList = (payload) => ({
  type: SCHOOL_STUDENTS_GET_LIST,
  payload
});
export const getSchoolStudentsListSuccess = (payload) => ({
  type: SCHOOL_STUDENTS_GET_LIST_SUCCESS,
  payload
});
export const getSchoolStudentsListError = (payload) => ({
  type: SCHOOL_STUDENTS_GET_LIST_ERROR,
  payload
});

// GET LIST
export const getOneStudent = (payload) => ({
  type: STUDENT_GET_ONE,
  payload
});
export const getOneStudentSuccess = (payload) => ({
  type: STUDENT_GET_ONE_SUCCESS,
  payload
});
export const getOneStudentError = (payload) => ({
  type: STUDENT_GET_ONE_ERROR,
  payload
});

//GET BY FILTER

export const getStudentByFilter = (payload) => ({
  type: STUDENT_GET_BY_FILTER,
  payload
});
export const getStudentByFilterSuccess = (payload) => ({
  type: STUDENT_GET_BY_FILTER_SUCCESS,
  payload
});
export const getStudentByFilterError = (payload) => ({
  type: STUDENT_GET_BY_FILTER_ERROR,
  payload
});
export const getStudentByFilterCB = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: STUDENT_GET_BY_FILTER,
    });

    StudentSrv.getList(payload)
      .then((response) => {
        if (response && response?.data) {
          const resp = {
            students: response?.data?.students,
            count: response.data.count
          }

          dispatch({
            type: STUDENT_GET_BY_FILTER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: STUDENT_GET_BY_FILTER_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};






// ADD ONE
export const addStudent = (payload) => ({
  type: STUDENT_ADD_ONE,
  payload
});
export const addStudentSuccess = (payload) => ({
  type: STUDENT_ADD_ONE_SUCCESS,
  payload
});
export const addStudentError = (payload) => ({
  type: STUDENT_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editStudent = (payload) => ({
  type: STUDENT_EDIT_ONE,
  payload
});
export const editStudentSuccess = (payload) => ({
  type: STUDENT_EDIT_ONE_SUCCESS,
  payload
});
export const editStudentError = (payload) => ({
  type: STUDENT_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneStudent = (payload) => ({
  type: STUDENT_DELETE_ONE,
  payload
});
export const deleteOneStudentSuccess = (payload) => ({
  type: STUDENT_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneStudentError = (payload) => ({
  type: STUDENT_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyStudents = (payload) => ({
  type: STUDENTS_DELETE_MANY,
  payload
});
export const deleteManyStudentsSuccess = (payload) => ({
  type: STUDENTS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyStudentsError = (payload) => ({
  type: STUDENTS_DELETE_MANY_ERROR,
  payload
});