import {
    CONFIGS_SCHOOL_LEVEL_GET_LIST,
    CONFIGS_SCHOOL_LEVEL_GET_LIST_SUCCESS,
    CONFIGS_SCHOOL_LEVEL_GET_LIST_ERROR,
  
    CONFIGS_SCHOOL_LEVEL_GET_ONE,
    CONFIGS_SCHOOL_LEVEL_GET_ONE_SUCCESS,
    CONFIGS_SCHOOL_LEVEL_GET_ONE_ERROR,
  
    CONFIGS_SCHOOL_LEVEL_ADD_ONE,
    CONFIGS_SCHOOL_LEVEL_ADD_ONE_SUCCESS,
    CONFIGS_SCHOOL_LEVEL_ADD_ONE_ERROR,
  
    CONFIGS_SCHOOL_LEVEL_EDIT_ONE,
    CONFIGS_SCHOOL_LEVEL_EDIT_ONE_SUCCESS,
    CONFIGS_SCHOOL_LEVEL_EDIT_ONE_ERROR,
  
    CONFIGS_SCHOOL_LEVEL_DELETE_ONE,
    CONFIGS_SCHOOL_LEVEL_DELETE_ONE_SUCCESS,
    CONFIGS_SCHOOL_LEVEL_DELETE_ONE_ERROR,
  
  } from '../../actions';
  
  // GET LIST
  export const getConfigsSchoolLevelList = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_GET_LIST,
    payload
  });
  export const getConfigsSchoolLevelListSuccess = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_GET_LIST_SUCCESS,
    payload
  });
  export const getConfigsSchoolLevelListError = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_GET_LIST_ERROR,
    payload
  });
  
  
  // GET ONE
  export const getOneConfigsSchoollevel = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_GET_ONE,
    payload
  });
  export const getOneConfigsSchoollevelSuccess = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_GET_ONE_SUCCESS,
    payload
  });
  export const getOneConfigsSchoollevelError = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_GET_ONE_ERROR,
    payload
  });
  
  
  
  // ADD ONE
  export const addConfigsSchoolLevel = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_ADD_ONE,
    payload
  });
  export const addConfigsSchoolLevelSuccess = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_ADD_ONE_SUCCESS,
    payload
  });
  export const addConfigsSchoolLevelError = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_ADD_ONE_ERROR,
    payload
  });
  
  
  // EDIT ONE
  export const editOneConfigsSchoolLevel = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_EDIT_ONE,
    payload
  });
  export const editOneConfigsSchoolLevelSuccess = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_EDIT_ONE_SUCCESS,
    payload
  });
  export const editOneConfigsSchoolLevelError = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_EDIT_ONE_ERROR,
    payload
  });
  
  // DELETE ONE
  export const deleteOneConfigsSchoolLevel = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_DELETE_ONE,
    payload
  });
  export const deleteOneConfigsSchoolLevelSuccess = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_DELETE_ONE_SUCCESS,
    payload
  });
  export const deleteOneConfigsSchoolLevelError = (payload) => ({
    type: CONFIGS_SCHOOL_LEVEL_DELETE_ONE_ERROR,
    payload
  });