import {
  INVITATION_GET_LIST,
  INVITATION_GET_LIST_SUCCESS,
  INVITATION_GET_LIST_ERROR,

  INVITATION_GET_LIST_BY_PARTNER,

  INVITATION_ACCEPT,

  INVITATION_GET_IDS,
  INVITATION_GET_IDS_SUCCESS,
  INVITATION_GET_IDS_ERROR,

  INVITATION_GET_ONE,
  INVITATION_GET_ONE_SUCCESS,
  INVITATION_GET_ONE_ERROR,

  INVITATION_ADD_ONE,
  INVITATION_ADD_ONE_SUCCESS,
  INVITATION_ADD_ONE_ERROR,

  INVITATION_EDIT_ONE,
  INVITATION_EDIT_ONE_SUCCESS,
  INVITATION_EDIT_ONE_ERROR,

  INVITATION_DELETE_ONE,
  INVITATION_DELETE_ONE_SUCCESS,
  INVITATION_DELETE_ONE_ERROR,
  INVITATION_GET_LIST_BY_PARTNER_SUCCESS,
  INVITATION_GET_LIST_BY_PARTNER_ERROR
} from '../actions';

// GET LIST
export const getInvitationsList = (payload) => ({
  type: INVITATION_GET_LIST,
  payload
});
export const getInvitationsListSuccess = (payload) => ({
  type: INVITATION_GET_LIST_SUCCESS,
  payload
});
export const getInvitationsListError = (payload) => ({
  type: INVITATION_GET_LIST_ERROR,
  payload
});

export const getInvitationsListByPartner = (payload) => ({
  type: INVITATION_GET_LIST_BY_PARTNER,
  payload
});
export const getInvitationsListByPartnerSuccess = (payload) => ({
  type: INVITATION_GET_LIST_BY_PARTNER_SUCCESS,
  payload
});
export const getInvitationsListByPartnerError = (payload) => ({
  type: INVITATION_GET_LIST_BY_PARTNER_ERROR,
  payload
});

export const acceptInvitation = (payload) => ({
  type: INVITATION_ACCEPT,
  payload
});


// GET EVENT INVITATIONS IDS (all)
export const getInvitationsListIds = (payload) => ({
  type: INVITATION_GET_IDS,
  payload
});
export const getInvitationsListIdsSuccess = (payload) => ({
  type: INVITATION_GET_IDS_SUCCESS,
  payload
});
export const getInvitationsListIdsError = (payload) => ({
  type: INVITATION_GET_IDS_ERROR,
  payload
});






// GET LIST
export const getOneInvitation = (payload) => ({
  type: INVITATION_GET_ONE,
  payload
});
export const getOneInvitationSuccess = (payload) => ({
  type: INVITATION_GET_ONE_SUCCESS,
  payload
});
export const getOneInvitationError = (payload) => ({
  type: INVITATION_GET_ONE_ERROR,
  payload
});








// ADD ONE
export const addInvitation = (payload) => ({
  type: INVITATION_ADD_ONE,
  payload
});
export const addInvitationSuccess = (payload) => ({
  type: INVITATION_ADD_ONE_SUCCESS,
  payload
});
export const addInvitationError = (payload) => ({
  type: INVITATION_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editInvitation = (payload) => ({
  type: INVITATION_EDIT_ONE,
  payload
});
export const editInvitationSuccess = (payload) => ({
  type: INVITATION_EDIT_ONE_SUCCESS,
  payload
});
export const editInvitationError = (payload) => ({
  type: INVITATION_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneInvitation = (payload) => ({
  type: INVITATION_DELETE_ONE,
  payload
});
export const deleteOneInvitationSuccess = (payload) => ({
  type: INVITATION_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneInvitationError = (payload) => ({
  type: INVITATION_DELETE_ONE_ERROR,
  payload
});