import {
  CONFIGS_SUBJECT_GET_LIST,
  CONFIGS_SUBJECT_LIST_SUCCESS,
  CONFIGS_SUBJECT_LIST_ERROR,

  CONFIGS_SUBJECT_GET_ONE,
  CONFIGS_SUBJECT_GET_ONE_SUCCESS,
  CONFIGS_SUBJECT_GET_ONE_ERROR,

  CONFIGS_SUBJECT_ADD_ONE,
  CONFIGS_SUBJECT_ADD_ONE_SUCCESS,
  CONFIGS_SUBJECT_ADD_ONE_ERROR,

  CONFIGS_SUBJECT_EDIT_ONE,
  CONFIGS_SUBJECT_EDIT_ONE_SUCCESS,
  CONFIGS_SUBJECT_EDIT_ONE_ERROR,

  CONFIGS_SUBJECT_DELETE_ONE,
  CONFIGS_SUBJECT_DELETE_ONE_SUCCESS,
  CONFIGS_SUBJECT_DELETE_ONE_ERROR,

  SUBJECT_GET_BY_FILTER,
  SUBJECT_GET_BY_FILTER_SUCCESS,
  SUBJECT_GET_BY_FILTER_ERROR
} from '../../actions';

import { NotificationManager } from '../../../components/common/react-notifications';
import notificationMessages from '../../../lang/locales/notificationMsg'

const INIT_STATE = {
  subjects: [],
  count: 0,
  subject: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()


  switch (action.type) {

    case CONFIGS_SUBJECT_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case CONFIGS_SUBJECT_LIST_SUCCESS:
      //console.log("CONFIGS_SUBJECT_LIST_SUCCESS : ");
      return {
        ...state, loading: false,
        subjects: action.payload.subjects || [],
        count: action.payload.count || 0
      };
    case CONFIGS_SUBJECT_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET ONE
    case CONFIGS_SUBJECT_GET_ONE:
      return { ...state, loading: true, error: '', class: null };
    case CONFIGS_SUBJECT_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        subject: action.payload.subject
      };
    case CONFIGS_SUBJECT_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    // ADD ONE
    case CONFIGS_SUBJECT_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case CONFIGS_SUBJECT_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        subjects: [action.payload.subject, ...state.subjects]
      };
    case CONFIGS_SUBJECT_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // GET BY FILTER
    case SUBJECT_GET_BY_FILTER:
      return { ...state, loading: true, error: '' };
    case SUBJECT_GET_BY_FILTER_SUCCESS:

      if (action.payload.filter) {
        //console.log("SUBJECT_GET_BY_FILTER_SUCCESS : ",action.payload.filter);
        return {
          ...state, loading: false, error: '',
          subjects: action.payload.subjects || [],
        };
      }

      else return { ...state, loading: false, error: '' };

    case SUBJECT_GET_BY_FILTER_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // EDIT ONE
    case CONFIGS_SUBJECT_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case CONFIGS_SUBJECT_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        subject: action.payload.subject
      };
    case CONFIGS_SUBJECT_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



    // DELETE ONE
    case CONFIGS_SUBJECT_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case CONFIGS_SUBJECT_DELETE_ONE_SUCCESS:
      const subjects = state.subjects
      if (action.payload.index !== null && action.payload.index !== undefined)
        subjects.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        subjects
      };
    case CONFIGS_SUBJECT_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    default: return { ...state };
  }
} 