import {
  PAYMENT_GET_LIST,
  PAYMENT_GET_LIST_SUCCESS,
  PAYMENT_GET_LIST_ERROR,

  PAYMENT_GET_ONE,
  PAYMENT_GET_ONE_SUCCESS,
  PAYMENT_GET_ONE_ERROR,

  PAYMENT_GET_LIST_BY_FILTER,
  PAYMENT_GET_LIST_BY_FILTER_SUCCESS,
  PAYMENT_GET_LIST_BY_FILTER_ERROR,

  PAYMENT_ADD_ONE,
  PAYMENT_ADD_ONE_SUCCESS,
  PAYMENT_ADD_ONE_ERROR,
  PAYMENT_EDIT_ONE,
  PAYMENT_EDIT_ONE_SUCCESS,
  PAYMENT_EDIT_ONE_ERROR,
  PAYMENT_PRINT,
  PAYMENT_PRINT_SUCCESS,
  PAYMENT_PRINT_ERROR,
  PAYMENT_CHARGE,
  PAYMENT_CHARGE_SUCCESS,
  PAYMENT_CHARGE_ERROR,
  PAYMENT_DELETE_ONE,
  PAYMENT_DELETE_ONE_SUCCESS,
  PAYMENT_DELETE_ONE_ERROR,

  PAYMENTS_DELETE_MANY,
  PAYMENTS_DELETE_MANY_SUCCESS,
  PAYMENTS_DELETE_MANY_ERROR,
} from '../../actions';

// GET LIST
export const getPaymentsList = (payload) => ({
  type: PAYMENT_GET_LIST,
  payload
});
export const getPaymentsListSuccess = (payload) => ({
  type: PAYMENT_GET_LIST_SUCCESS,
  payload
});
export const getPaymentsListError = (payload) => ({
  type: PAYMENT_GET_LIST_ERROR,
  payload
});




// GET ONE
export const getOnePayment = (payload) => ({
  type: PAYMENT_GET_ONE,
  payload
});
export const getOnePaymentSuccess = (payload) => ({
  type: PAYMENT_GET_ONE_SUCCESS,
  payload
});
export const getOnePaymentError = (payload) => ({
  type: PAYMENT_GET_ONE_ERROR,
  payload
});
// GET bY Filter
export const getPaymentsByFilter = (payload) => ({
  type: PAYMENT_GET_LIST_BY_FILTER,
  payload
});
export const getPaymentByFilterSuccess = (payload) => ({
  type: PAYMENT_GET_LIST_BY_FILTER_SUCCESS,
  payload
});
export const getPaymentsByFilterError = (payload) => ({
  type: PAYMENT_GET_LIST_BY_FILTER_ERROR,
  payload
});


// ADD ONE
export const addPayment = (payload) => ({
  type: PAYMENT_ADD_ONE,
  payload
});
export const addPaymentSuccess = (payload) => ({
  type: PAYMENT_ADD_ONE_SUCCESS,
  payload
});
export const addPaymentError = (payload) => ({
  type: PAYMENT_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editPayment = (payload) => ({
  type: PAYMENT_EDIT_ONE,
  payload
});
export const editPaymentSuccess = (payload) => ({
  type: PAYMENT_EDIT_ONE_SUCCESS,
  payload
});
export const editPaymentError = (payload) => ({
  type: PAYMENT_EDIT_ONE_ERROR,
  payload
});



// PRINT 
export const printPayments = (payload) => ({
  type: PAYMENT_PRINT,
  payload
});
export const printPaymentSuccess = (payload) => ({
  type: PAYMENT_PRINT_SUCCESS,
  payload
});
export const printPaymentError = (payload) => ({
  type: PAYMENT_PRINT_ERROR,
  payload
});

// // PRINT PAYMENT STATUS
// export const printPaymentStatus = (payload) => ({
//   type: PAYMENT_STATUS_PRINT,
//   payload
// });
// export const printPaymentStatusSuccess = (payload) => ({
//   type: PAYMENT_STATUS_PRINT_SUCCESS,
//   payload
// });
// export const printPaymentStatusError = (payload) => ({
//   type: PAYMENT_STATUS_PRINT_ERROR,
//   payload
// });



//CHarge
export const chargePayment = (payload) => ({
  type: PAYMENT_CHARGE,
  payload
});
export const chargePaymentSuccess = (payload) => ({
  type: PAYMENT_CHARGE_SUCCESS,
  payload
});
export const chargePaymentError = (payload) => ({
  type: PAYMENT_CHARGE_ERROR,
  payload
});


// DELETE ONE
export const deleteOnePayment = (payload) => ({
  type: PAYMENT_DELETE_ONE,
  payload
});
export const deleteOnePaymentSuccess = (payload) => ({
  type: PAYMENT_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOnePaymentError = (payload) => ({
  type: PAYMENT_DELETE_ONE_ERROR,
  payload
});


// DELETE MANY
export const deleteManyPayments = (payload) => ({
  type: PAYMENTS_DELETE_MANY,
  payload
});
export const deleteManyPaymentsSuccess = (payload) => ({
  type: PAYMENTS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyPaymentsError = (payload) => ({
  type: PAYMENTS_DELETE_MANY_ERROR,
  payload
});