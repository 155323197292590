import {
  CONFIGS_LEVEL_GET_LIST,
  CONFIGS_LEVEL_GET_LIST_SUCCESS,
  CONFIGS_LEVEL_GET_LIST_ERROR,

  CONFIGS_LEVEL_GET_ONE,
  CONFIGS_LEVEL_GET_ONE_SUCCESS,
  CONFIGS_LEVEL_GET_ONE_ERROR,

  CONFIGS_LEVEL_ADD_ONE,
  CONFIGS_LEVEL_ADD_ONE_SUCCESS,
  CONFIGS_LEVEL_ADD_ONE_ERROR,

  CONFIGS_LEVEL_EDIT_ONE,
  CONFIGS_LEVEL_EDIT_ONE_SUCCESS,
  CONFIGS_LEVEL_EDIT_ONE_ERROR,

  CONFIGS_LEVEL_DELETE_ONE,
  CONFIGS_LEVEL_DELETE_ONE_SUCCESS,
  CONFIGS_LEVEL_DELETE_ONE_ERROR,

} from '../../actions';

// GET LIST
export const getConfigsLevelList = (payload) => ({
  type: CONFIGS_LEVEL_GET_LIST,
  payload
});
export const getConfigsLevelListSuccess = (payload) => ({
  type: CONFIGS_LEVEL_GET_LIST_SUCCESS,
  payload
});
export const getConfigsLevelListError = (payload) => ({
  type: CONFIGS_LEVEL_GET_LIST_ERROR,
  payload
});


// GET ONE
export const getOneConfigslevel = (payload) => ({
  type: CONFIGS_LEVEL_GET_ONE,
  payload
});
export const getOneConfigslevelSuccess = (payload) => ({
  type: CONFIGS_LEVEL_GET_ONE_SUCCESS,
  payload
});
export const getOneConfigslevelError = (payload) => ({
  type: CONFIGS_LEVEL_GET_ONE_ERROR,
  payload
});



// ADD ONE
export const addConfigsLevel = (payload) => ({
  type: CONFIGS_LEVEL_ADD_ONE,
  payload
});
export const addConfigsLevelSuccess = (payload) => ({
  type: CONFIGS_LEVEL_ADD_ONE_SUCCESS,
  payload
});
export const addConfigsLevelError = (payload) => ({
  type: CONFIGS_LEVEL_ADD_ONE_ERROR,
  payload
});


// EDIT ONE
export const editOneConfigsLevel = (payload) => ({
  type: CONFIGS_LEVEL_EDIT_ONE,
  payload
});
export const editOneConfigsLevelSuccess = (payload) => ({
  type: CONFIGS_LEVEL_EDIT_ONE_SUCCESS,
  payload
});
export const editOneConfigsLevelError = (payload) => ({
  type: CONFIGS_LEVEL_EDIT_ONE_ERROR,
  payload
});

// DELETE ONE
export const deleteOneConfigsLevel = (payload) => ({
  type: CONFIGS_LEVEL_DELETE_ONE,
  payload
});
export const deleteOneConfigsLevelSuccess = (payload) => ({
  type: CONFIGS_LEVEL_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneConfigsLevelError = (payload) => ({
  type: CONFIGS_LEVEL_DELETE_ONE_ERROR,
  payload
});