import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  HOMEWORK_GET_LIST, HOMEWORK_ADD_ONE, HOMEWORK_GET_ONE, HOMEWORKS_GET_BY_FILTER, HOMEWORK_EDIT_ONE, HOMEWORK_DELETE_ONE, HOMEWORKS_DELETE_MANY
} from "../actions";

import {
  getHomeworksListSuccess,
  getHomeworksListError,

  getOneHomeworkSuccess,
  getOneHomeworkError,

  addHomeworkSuccess,
  addHomeworkError,

  editHomeworkSuccess,
  editHomeworkError,

  getHomeworksByFilterSuccess,
  getHomeworksByFilterError,

  deleteOneHomeworkSuccess,
  deleteOneHomeworkError,

  deleteManyHomeworksSuccess,
  deleteManyHomeworksError
} from "./actions";
import { createFormData } from "../../helpers/Utils";


//GET LIST
const getHomeworksListRequest = async (payload) => {
  return await axios.get(api.homeworks + `/list/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getHomeworksList({ payload }) {
  try {
    const ret = yield call(getHomeworksListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getHomeworksListSuccess({ homeworks: ret.data.homeworks || [], count: ret.data.count || 0 }));

    else yield put(getHomeworksListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getHomeworksListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOneHomeworksRequest = async (payload) => {
  return await axios.get(api.homeworks + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneHomework({ payload }) {
  try {
    const ret = yield call(getOneHomeworksRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneHomeworkSuccess({ homework: ret.data.homework }));

    else yield put(getOneHomeworkError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneHomeworkError({ error, message: (error) ? error.message : '' }));
  }
}


//GET HOMEWORKS BY FILTER
const getHomeworksByFilterRequest = async (payload) => {
  return await axios.post(api.homeworks + `/filters/${payload.inst_id}/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getHomeworksByFilter({ payload }) {
  try {
    const ret = yield call(getHomeworksByFilterRequest, payload);


    if (ret && ret.status === 200)
      yield put(getHomeworksByFilterSuccess({ homeworks: ret.data.homeworks, count: ret.data.count || 0 }));

    else yield put(getHomeworksByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getHomeworksByFilterError({ error, message: (error) ? error.message : '' }));
  }
}

//ADD ONE
const addHomeworkRequest = async (payload) => {
  let frm = new FormData();


  // frm.append('name', payload.data.name)
  // frm.append('inst_id', payload.data.inst_id)

  // if(payload.data.attachments)
  // for (let i = 0; i < payload.data.attachments.length; i++) {
  //   frm.append('attachments', payload.data.attachments[i])
  // }

  //createFormData(frm, '',payload.data)
  for (let key in payload.data) {

    if (payload.data.attachments && key === 'attachments')

      for (let i = 0; i < payload.data.attachments.length; i++) {


        frm.append('attachments', payload.data.attachments[i])

      }
    if (key !== 'attachments')
      frm.append(key, payload.data[key])
  }

  //frm.append('inst_id', payload.data.inst_id)

  return await axios.post(api.homeworks, frm, {
    headers: { Authorization: payload.token },
    'content-type': 'multipart/form-data'
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addHomework({ payload }) {
  try {
    const ret = yield call(addHomeworkRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/schooling/homeworks')
      yield put(addHomeworkSuccess({ homework: ret.data.homework }));
    }
    else yield put(addHomeworkError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addHomeworkError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneHomeworkRequest = async (payload) => {
  let frm = new FormData();
  for (let key in payload.data) {

    if (payload.data.attachments && key === 'attachments')

      for (let i = 0; i < payload.data.attachments.length; i++) {


        frm.append('attachments', payload.data.attachments[i])

      }
    if (key !== 'attachments')
      frm.append(key, payload.data[key])
  }
  return await axios.patch(`${api.homeworks}/edit-one/${payload._id}`, frm, { headers: { Authorization: payload.token }, 'content-type': 'multipart/form-data' })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneHomework({ payload }) {
  try {
    const ret = yield call(editOneHomeworkRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/schooling/homeworks')
      yield put(editHomeworkSuccess({ homework: ret.data.homework }));
    }
    else yield put(editHomeworkError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editHomeworkError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneHomeworkRequest = async (payload) => {
  return await axios.delete(`${api.homeworks}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneHomework({ payload }) {
  try {
    const ret = yield call(deleteOneHomeworkRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/schooling/homeworks')
      yield put(deleteOneHomeworkSuccess({ index }));
    }
    else yield put(deleteOneHomeworkError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneHomeworkError({ error, message: (error) ? error.message : '' }));
  }
}
//DELETE MANY
const deleteManyHomeworksRequest = async (payload) => {
  return await axios.delete(`${api.homeworks}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyHomeworks({ payload }) {
  try {
    const ret = yield call(deleteManyHomeworksRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/app/schooling/homeworks')
      yield put(deleteManyHomeworksSuccess({ data }));
    }
    else yield put(deleteManyHomeworksError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyHomeworksError({ error, message: (error) ? error.message : '' }));
  }
}

//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(HOMEWORK_GET_LIST, getHomeworksList);
}

export function* watchGetOne() {
  yield takeEvery(HOMEWORK_GET_ONE, getOneHomework);
}
export function* watchGetFilter() {
  yield takeEvery(HOMEWORKS_GET_BY_FILTER, getHomeworksByFilter);
}

export function* watchEditOne() {
  yield takeEvery(HOMEWORK_EDIT_ONE, editOneHomework);
}

export function* watchAddOne() {
  yield takeEvery(HOMEWORK_ADD_ONE, addHomework);
}

export function* watchDeleteOne() {
  yield takeEvery(HOMEWORK_DELETE_ONE, deleteOneHomework);
}
export function* watchDeleteMany() {
  yield takeEvery(HOMEWORKS_DELETE_MANY, deleteManyHomeworks);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetFilter),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)

  ]);
}
