import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../../constants/defaultValues";
import axios from "axios";

import {
  FEE_GET_LIST, FEE_ADD_ONE, FEE_GET_ONE, FEE_EDIT_ONE, FEE_DELETE_ONE, FEES_DELETE_MANY, FEE_GET_BY_FILTER
} from "../../actions";

import {
  getFeesListSuccess,
  getFeesListError,

  getOneFeeSuccess,
  getOneFeeError,

  getFeesByFilterSuccess,
  getFeesByFilterError,

  addFeeSuccess,
  addFeeError,

  editFeeSuccess,
  editFeeError,

  deleteOneFeeSuccess,
  deleteOneFeeError,
  deleteManyFeesSuccess,
  deleteManyFeesError,
} from "./actions";


//GET LIST
const getFeesListRequest = async (payload) => {
  return await axios.get(api.fees + `/list/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* getFeesList({ payload }) {
  try {
    const ret = yield call(getFeesListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getFeesListSuccess({ fees: ret.data.fees || [], count: ret.data.count || 0 }));

    else yield put(getFeesListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getFeesListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOneFeeRequest = async (payload) => {
  return await axios.get(api.fees + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneFee({ payload }) {
  try {
    const ret = yield call(getOneFeeRequest, payload);

    if (ret && ret.status === 200) yield put(getOneFeeSuccess({ fee: ret.data.fee }));
    else yield put(getOneFeeError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(getOneFeeError({ error, message: (error) ? error.message : '' }));
  }
}

//GET LIST BY FILTER

const getFeesByFilterRequest = async (payload) => {
  return await axios.post(api.fees + `/filters/${payload.inst_id}/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* getFeesByFilter({ payload }) {
  try {
    const ret = yield call(getFeesByFilterRequest, payload);

    if (ret && ret.status === 200)
      yield put(getFeesByFilterSuccess({ fees: ret.data.fees || [], count: ret.data.count || 0 }));

    else yield put(getFeesByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getFeesByFilterError({ error, message: (error) ? error.message : '' }));
  }
}
//ADD ONE
const addFeeRequest = async (payload) => {

  return await axios.post(api.fees, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addFee({ payload }) {
  //console.log('saga add fee',payload);

  try {
    const ret = yield call(addFeeRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/billing/fees')
      yield put(addFeeSuccess({ fee: ret.data.fee }));
    }
    else yield put(addFeeError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(addFeeError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneFeeRequest = async (payload) => {
  return await axios.patch(`${api.fees}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneFee({ payload }) {
  try {
    const ret = yield call(editOneFeeRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/billing/fees')
      yield put(editFeeSuccess({ fee: ret.data.fee }));
    }
    else yield put(editFeeError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(editFeeError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneFeeRequest = async (payload) => {
  return await axios.delete(`${api.fees}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneFee({ payload }) {
  //console.log('saga delete fee',payload);
  try {
    const ret = yield call(deleteOneFeeRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/billing/fees')
      yield put(deleteOneFeeSuccess({ index }));
    }
    else yield put(deleteOneFeeError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(deleteOneFeeError({ error, message: (error) ? error.message : '' }));
  }
}


//DELETE MANY

const deleteManyFeesRequest = async (payload) => {
  return await axios.delete(`${api.fees}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyFees({ payload }) {
  try {
    const ret = yield call(deleteManyFeesRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/app/billing/fees')
      yield put(deleteManyFeesSuccess({ data }));
    }
    else yield put(deleteManyFeesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyFeesError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(FEE_GET_LIST, getFeesList);
}

export function* watchGetOne() {
  yield takeEvery(FEE_GET_ONE, getOneFee);
}
export function* watchGetListByFilter() {
  yield takeEvery(FEE_GET_BY_FILTER, getFeesByFilter);
}
export function* watchEditOne() {
  yield takeEvery(FEE_EDIT_ONE, editOneFee);
}

export function* watchAddOne() {
  yield takeEvery(FEE_ADD_ONE, addFee);
}

export function* watchDeleteOne() {
  yield takeEvery(FEE_DELETE_ONE, deleteOneFee);
}
export function* watchDeleteMany() {
  yield takeEvery(FEES_DELETE_MANY, deleteManyFees);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetListByFilter),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)
  ]);
}
