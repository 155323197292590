import { api } from "../constants/defaultValues";
import axiosApi from "../helpers/axiosConfig";

const chatServices = {
  getMyRooms: (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await axiosApi.get(
          `${api.chat}/rooms/partner/${payload.partner}/0/1000`);
        resolve(resp);
      } catch (e) {
        return reject(e);
      }
    })
  },
  getRoomConversation: (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { roomId, page, limit } = payload;
        const resp = await axiosApi.get(
          `${api.chat}/rooms/${roomId}/${page}/${limit}`);
        resolve(resp);
      } catch (e) {
        return reject(e);
      }
    })
  },
  initiateChatRoom: (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { chatInitiator, receiver } = payload;
        const resp = await axiosApi.post(
          `${api.chat}/rooms/initiate`, {
          chatInitiator, receiver
        });
        resolve(resp);
      } catch (e) {
        return reject(e);
      }
    })
  },
  sendMessage: (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { message, partner } = payload;
        const resp = await axiosApi.post(
          `${api.chat}/rooms/${payload.roomId}/message`, {
          message, partner
        });
        resolve(resp);
      } catch (e) {
        return reject(e);
      }
    })
  },
  markAsRead: (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { roomId, partner } = payload;
        const resp = await axiosApi.patch(
          `${api.chat}/rooms/${roomId}/mark-read`, {
          partner
        });
        resolve(resp);
      } catch (e) {
        return reject(e);
      }
    })
  },
}

export default chatServices