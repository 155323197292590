import {
  COURSE_PROGRESS_GET_LIST,
  COURSE_PROGRESS_GET_LIST_SUCCESS,
  COURSE_PROGRESS_GET_LIST_ERROR,



  COURSE_PROGRESS_GET_ONE,
  COURSE_PROGRESS_GET_ONE_SUCCESS,
  COURSE_PROGRESS_GET_ONE_ERROR,


  COURSE_PROGRESS_EDIT_ONE,
  COURSE_PROGRESS_EDIT_ONE_SUCCESS,
  COURSE_PROGRESS_EDIT_ONE_ERROR,

} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'

const INIT_STATE = {
  filesProgress: [],
  count: 0,
  fileProgress: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  // const messages = notificationMessages()

  switch (action.type) {
    case COURSE_PROGRESS_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case COURSE_PROGRESS_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        filesProgress: action.payload.filesProgress || [],
        count: action.payload.count || 0
      };
    case COURSE_PROGRESS_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET ONE
    case COURSE_PROGRESS_GET_ONE:
      return { ...state, loading: true, error: '' };
    case COURSE_PROGRESS_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        fileProgress: action.payload.fileProgress
      };
    case COURSE_PROGRESS_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };







    // EDIT ONE
    case COURSE_PROGRESS_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case COURSE_PROGRESS_EDIT_ONE_SUCCESS:
      // NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      const new_courses = state.filesProgress
      const course = action.payload.fileProgress
      for (let i = 0; i < new_courses.length; i++) {
        if (new_courses[i]._id === course._id) {
          new_courses[i] = course; break;
        }
      }

      return { ...state, loading: false, error: '', fileProgress:course, filesProgress: new_courses };
    case COURSE_PROGRESS_EDIT_ONE_ERROR:
      // NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




    default: return { ...state };
  }
}
