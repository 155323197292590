import {
  TIMETABLE_GET_LIST,
  TIMETABLE_GET_LIST_SUCCESS,
  TIMETABLE_GET_LIST_ERROR,

  TIMETABLE_GET_ONE,
  TIMETABLE_GET_ONE_SUCCESS,
  TIMETABLE_GET_ONE_ERROR,

  TIMETABLE_GET_BY_FILTER,
  TIMETABLE_GET_BY_FILTER_SUCCESS,
  TIMETABLE_GET_BY_FILTER_ERROR,

  TIMETABLE_ADD_ONE,
  TIMETABLE_ADD_ONE_SUCCESS,
  TIMETABLE_ADD_ONE_ERROR,

  TIMETABLE_EDIT_ONE,
  TIMETABLE_EDIT_ONE_SUCCESS,
  TIMETABLE_EDIT_ONE_ERROR,

  TIMETABLE_DELETE_ONE,
  TIMETABLE_DELETE_ONE_SUCCESS,
  TIMETABLE_DELETE_ONE_ERROR,

  TIMETABLES_DELETE_MANY,
  TIMETABLES_DELETE_MANY_SUCCESS,
  TIMETABLES_DELETE_MANY_ERROR
} from '../actions';

// GET LIST
export const getTimetablesList = (payload) => ({
  type: TIMETABLE_GET_LIST,
  payload
});
export const getTimetablesListSuccess = (payload) => ({
  type: TIMETABLE_GET_LIST_SUCCESS,
  payload
});
export const getTimetablesListError = (payload) => ({
  type: TIMETABLE_GET_LIST_ERROR,
  payload
});







// GET LIST
export const getOneTimetable = (payload) => ({
  type: TIMETABLE_GET_ONE,
  payload
});
export const getOneTimetableSuccess = (payload) => ({
  type: TIMETABLE_GET_ONE_SUCCESS,
  payload
});
export const getOneTimetableError = (payload) => ({
  type: TIMETABLE_GET_ONE_ERROR,
  payload
});



// GET TIMETABLE BY FILTER

export const getTimetableByFilter = (payload) => ({
  type: TIMETABLE_GET_BY_FILTER,
  payload
});
export const getTimetableByFilterSuccess = (payload) => ({
  type: TIMETABLE_GET_BY_FILTER_SUCCESS,
  payload
});
export const getTimetableByFilterError = (payload) => ({
  type: TIMETABLE_GET_BY_FILTER_ERROR,
  payload
});





// ADD ONE
export const addTimetable = (payload) => ({
  type: TIMETABLE_ADD_ONE,
  payload
});
export const addTimetableSuccess = (payload) => ({
  type: TIMETABLE_ADD_ONE_SUCCESS,
  payload
});
export const addTimetableError = (payload) => ({
  type: TIMETABLE_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editTimetable = (payload) => ({
  type: TIMETABLE_EDIT_ONE,
  payload
});
export const editTimetableSuccess = (payload) => ({
  type: TIMETABLE_EDIT_ONE_SUCCESS,
  payload
});
export const editTimetableError = (payload) => ({
  type: TIMETABLE_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneTimetable = (payload) => ({
  type: TIMETABLE_DELETE_ONE,
  payload
});
export const deleteOneTimetableSuccess = (payload) => ({
  type: TIMETABLE_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneTimetableError = (payload) => ({
  type: TIMETABLE_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyTimetables = (payload) => ({
  type: TIMETABLES_DELETE_MANY,
  payload
});
export const deleteManyTimetablesSuccess = (payload) => ({
  type: TIMETABLES_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyTimetablesError = (payload) => ({
  type: TIMETABLES_DELETE_MANY_ERROR,
  payload
});