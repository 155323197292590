import RoomSrv from '../../services/RoomSrv';
import {
  ROOM_GET_LIST,
  ROOM_GET_LIST_SUCCESS,
  ROOM_GET_LIST_ERROR,

  ROOM_GET_ONE,
  ROOM_GET_ONE_SUCCESS,
  ROOM_GET_ONE_ERROR,

  ROOM_GET_BY_FILTER,
  ROOM_GET_BY_FILTER_SUCCESS,
  ROOM_GET_BY_FILTER_ERROR,

  ROOM_ADD_ONE,
  ROOM_ADD_ONE_SUCCESS,
  ROOM_ADD_ONE_ERROR,

  ROOM_EDIT_ONE,
  ROOM_EDIT_ONE_SUCCESS,
  ROOM_EDIT_ONE_ERROR,

  ROOM_DELETE_ONE,
  ROOM_DELETE_ONE_SUCCESS,
  ROOM_DELETE_ONE_ERROR,

  ROOMS_DELETE_MANY,
  ROOMS_DELETE_MANY_SUCCESS,
  ROOMS_DELETE_MANY_ERROR
} from '../actions';

// GET LIST
export const getRoomsList = (payload) => ({
  type: ROOM_GET_LIST,
  payload
});
export const getRoomsListSuccess = (payload) => ({
  type: ROOM_GET_LIST_SUCCESS,
  payload
});
export const getRoomsListError = (payload) => ({
  type: ROOM_GET_LIST_ERROR,
  payload
});







// GET ONE
export const getOneRoom = (payload) => ({
  type: ROOM_GET_ONE,
  payload
});
export const getOneRoomSuccess = (payload) => ({
  type: ROOM_GET_ONE_SUCCESS,
  payload
});
export const getOneRoomError = (payload) => ({
  type: ROOM_GET_ONE_ERROR,
  payload
});

// GET  BY FILTER

export const getRoomsByFilters = (payload) => ({
  type: ROOM_GET_BY_FILTER,
  payload
});
export const getRoomsByFiltersSuccess = (payload) => ({
  type: ROOM_GET_BY_FILTER_SUCCESS,
  payload
});
export const getRoomsByFiltersError = (payload) => ({
  type: ROOM_GET_BY_FILTER_ERROR,
  payload
});
export const getRoomsByFiltersCB = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: ROOM_GET_BY_FILTER,
    });

    RoomSrv.getList(payload)
      .then((response) => {
        if (response && response?.data) {
          const resp = {
            rooms: response?.data?.rooms,
            count: response.data.count
          }

          dispatch({
            type: ROOM_GET_BY_FILTER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: ROOM_GET_BY_FILTER_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};






// ADD ONE
export const addRoom = (payload) => ({
  type: ROOM_ADD_ONE,
  payload
});
export const addRoomSuccess = (payload) => ({
  type: ROOM_ADD_ONE_SUCCESS,
  payload
});
export const addRoomError = (payload) => ({
  type: ROOM_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editRoom = (payload) => ({
  type: ROOM_EDIT_ONE,
  payload
});
export const editRoomSuccess = (payload) => ({
  type: ROOM_EDIT_ONE_SUCCESS,
  payload
});
export const editRoomError = (payload) => ({
  type: ROOM_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneRoom = (payload) => ({
  type: ROOM_DELETE_ONE,
  payload
});
export const deleteOneRoomSuccess = (payload) => ({
  type: ROOM_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneRoomError = (payload) => ({
  type: ROOM_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyRooms = (payload) => ({
  type: ROOMS_DELETE_MANY,
  payload
});
export const deleteManyRoomsSuccess = (payload) => ({
  type: ROOMS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyRoomsError = (payload) => ({
  type: ROOMS_DELETE_MANY_ERROR,
  payload
});