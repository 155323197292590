import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  TUTORING_GET_LIST,
  TUTORING_GET_BY_TEACHER,
  TUTORING_GET_ONE,
  TUTORING_ADD_ONE,
  TUTORING_EDIT_ONE,
  TUTORING_DELETE_ONE,
  TUTORING_SUBSCRIBE,
  TUTORING_ACCEPT,
  TUTORING_UNSUBSCRIBE,
  TUTORING_GET_BY_INSTUTITION
} from "../actions";

import {
  getTutoringsListSuccess,
  getTutoringsListError,

  getTutoringsByTeacherSuccess,
  getTutoringsByTeacherError,

  getOneTutoringSuccess,
  getOneTutoringError,

  addTutoringSuccess,
  addTutoringError,

  editOneTutoringSuccess,
  editOneTutoringError,

  deleteOneTutoringSuccess,
  deleteOneTutoringError,

  subscribeTutoringSuccess,
  subscribeTutoringError,

  acceptTutoringSuccess,
  acceptTutoringError,

  unsubscribeTutoringError,
  unsubscribeTutoringSuccess,

  getTutoringsByInstSuccess,
  getTutoringsByInstError

} from "./actions";



//GET LIST
const getTutoringsListRequest = async (payload) => {
  let filter = `?`;
  if (payload?.filter?.subject && payload?.filter?.subject !=='')filter+= `&subject=${payload?.filter?.subject}`
  if (payload?.filter?.level && payload?.filter?.level !=='')filter+= `&level=${payload?.filter?.level}`
  if (payload?.filter?.query && payload?.filter?.query !=='')filter+= `&query=${payload?.filter?.query}`
  if (payload?.filter?.city && payload?.filter?.city !=='')filter+= `&city=${payload?.filter?.city}`
  if (typeof payload.filter?.is_online === 'boolean') {
    filter = filter + `&is_online=${payload.filter?.is_online}`
  }
  if (typeof payload.filter?.is_presential === 'boolean') {
    filter = filter + `&is_presential=${payload.filter?.is_presential}`
  }
  if (typeof payload.filter?.is_hybrid === 'boolean') {
    filter = filter + `&is_hybrid=${payload.filter?.is_hybrid}`
  }
  
  return await axios.get(api.tutorings + `/list/${payload.offset}/${payload.limit}${filter}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getTutoringsList({ payload }) {
  try {
    const ret = yield call(getTutoringsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getTutoringsListSuccess({ tutorings: ret.data.tutorings || [], count: ret.data.count || 0 }));

    else yield put(getTutoringsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getTutoringsListError({ error, message: (error) ? error.message : '' }));
  }
}


//GET BY TEACHER
const getTutoringsByTeacherRequest = async (payload) => {
  return await axios.get(api.tutorings + `/list/${payload._id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getTutoringsByTeacher({ payload }) {
  try {
    const ret = yield call(getTutoringsByTeacherRequest, payload);


    if (ret && ret.status === 200)
      yield put(getTutoringsByTeacherSuccess({ tutorings: ret.data.tutorings }));

    else yield put(getTutoringsByTeacherError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getTutoringsByTeacherError({ error, message: (error) ? error.message : '' }));
  }
}


//GET BY Inst
const getTutoringsByInstutitionRequest = async (payload) => {
  return await axios.get(api.tutorings + `/list/institution/${payload._id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getTutoringsByInst({ payload }) {
  try {
    const ret = yield call(getTutoringsByInstutitionRequest, payload);


    if (ret && ret.status === 200)
      yield put(getTutoringsByInstSuccess({ tutorings: ret.data.tutorings }));

    else yield put(getTutoringsByInstError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getTutoringsByInstError({ error, message: (error) ? error.message : '' }));
  }
}


//GET ONE
const getOneTutoringRequest = async (payload) => {
  return await axios.get(api.tutorings + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneTutoring({ payload }) {
  try {
    const ret = yield call(getOneTutoringRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneTutoringSuccess({ tutoring: ret.data.tutoring }));

    else yield put(getOneTutoringError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneTutoringError({ error, message: (error) ? error.message : '' }));
  }
}


//ADD ONE
const addTutoringRequest = async (payload) => {
  return await axios.post(api.tutorings, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addTutoring({ payload }) {
  try {
    const ret = yield call(addTutoringRequest, payload);

    if (ret && ret.status === 201) {
      const { history, index } = payload;
      // history.push('/teachers-private/tutorings')
      yield put(addTutoringSuccess({ tutoring: ret.data.tutoring }));
    }
    else yield put(addTutoringError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addTutoringError({ error, message: (error) ? error.message : '' }));
  }
}


//EDIT ONE
const editOneTutoringRequest = async (payload) => {
  return await axios.patch(`${api.tutorings}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneTutoring({ payload }) {
  try {
    const ret = yield call(editOneTutoringRequest, payload);

    if (ret && ret.status === 200) {

      yield put(editOneTutoringSuccess({ tutoring: ret.data.tutoring }));
    }
    else yield put(editOneTutoringError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editOneTutoringError({ error, message: (error) ? error.message : '' }));
  }
}

// DELETE ONE
const deleteOneTutoringRequest = async (payload) => {
  return await axios.delete(`${api.tutorings}/delete-one/${payload._id}`,{
    headers: {
      Authorization: payload.token
    }
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneTutoring({ payload }) {
  try {
    const ret = yield call(deleteOneTutoringRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;


      yield put(deleteOneTutoringSuccess({ index }));
    }
    else yield put(deleteOneTutoringError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneTutoringError({ error, message: (error) ? error.message : '' }));
  }
}


//SUBSCRIBE
const subscribeTutoringRequest = async (payload) => {
  return await axios.post(api.tutorings + `/subscribe/${payload.tutoring_id}`, {
    partner: payload.partner
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* subscribeTutoring({ payload }) {
  try {
    const ret = yield call(subscribeTutoringRequest, payload);

    if (ret && ret.status === 200) {
      const tutorings = payload.tutorings
      const tutoring = ret.data.tutoring
      if (tutorings && tutorings[payload.index]) tutorings[payload.index] = tutoring
      yield put(subscribeTutoringSuccess({ index: payload.index, tutorings, tutoring }));
    }

    else yield put(subscribeTutoringError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(subscribeTutoringError({ error, message: (error) ? error.message : '' }));
  }
}

//UNSUBSCRIBE
const unsubscribeTutoringRequest = async (payload) => {
  return await axios.post(api.tutorings + `/unsubscribe/${payload._id}`,
    {
      partner: payload.partner
    })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* unsubscribeTutoring({ payload }) {
  try {
    const ret = yield call(unsubscribeTutoringRequest, payload);

    if (ret && ret.status === 200) {
      const tutorings = payload.tutorings
      const tutoring = ret.data.tutoring
      if (tutorings && tutorings[payload.index]) tutorings[payload.index] = tutoring
      yield put(unsubscribeTutoringSuccess({ index: payload.index, tutorings, tutoring }));

    }

    else yield put(unsubscribeTutoringError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(unsubscribeTutoringError({ error, message: (error) ? error.message : '' }));
  }
}

// ACCEPT ONE
const acceptTutoringRequest = async (payload) => {
  return await axios.post(`${api.tutorings}/change-status/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* acceptTutoring({ payload }) {
  try {
    const ret = yield call(acceptTutoringRequest, payload);

    if (ret && ret.status === 200) {

      yield put(acceptTutoringSuccess({ tutoring: ret.data.tutoring }));
    }
    else yield put(acceptTutoringError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(acceptTutoringError({ error, message: (error) ? error.message : '' }));
  }

}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(TUTORING_GET_LIST, getTutoringsList);
}

export function* watchGetByTeacher() {
  yield takeEvery(TUTORING_GET_BY_TEACHER, getTutoringsByTeacher);
}

export function* watchGetByInst() {
  yield takeEvery(TUTORING_GET_BY_INSTUTITION, getTutoringsByInst);
}

export function* watchGetOne() {
  yield takeEvery(TUTORING_GET_ONE, getOneTutoring);
}

export function* watchEditOne() {
  yield takeEvery(TUTORING_EDIT_ONE, editOneTutoring);
}

export function* watchAddOne() {
  yield takeEvery(TUTORING_ADD_ONE, addTutoring);
}

export function* watchAccept() {
  yield takeEvery(TUTORING_ACCEPT, acceptTutoring);
}

export function* watchDeleteOne() {
  yield takeEvery(TUTORING_DELETE_ONE, deleteOneTutoring);
}

export function* watchSubscribe() {
  yield takeEvery(TUTORING_SUBSCRIBE, subscribeTutoring);
}
export function* watchUnsubscribe() {
  yield takeEvery(TUTORING_UNSUBSCRIBE, unsubscribeTutoring);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetByTeacher),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchAccept),
    fork(watchDeleteOne),
    fork(watchSubscribe),
    fork(watchUnsubscribe),
    fork(watchGetByInst)
  ]);
}
