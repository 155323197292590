import {
  CANTEENMENUS_GET_LIST,
  CANTEENMENUS_GET_LIST_SUCCESS,
  CANTEENMENUS_GET_LIST_ERROR,

  CANTEENMENU_GET_ONE,
  CANTEENMENU_GET_ONE_SUCCESS,
  CANTEENMENU_GET_ONE_ERROR,

  CANTEENMENUS_GET_BY_FILTER,
  CANTEENMENUS_GET_BY_FILTER_SUCCESS,
  CANTEENMENUS_GET_BY_FILTER_ERROR,

  CANTEENMENU_ADD_ONE,
  CANTEENMENU_ADD_ONE_SUCCESS,
  CANTEENMENU_ADD_ONE_ERROR,

  CANTEENMENU_EDIT_ONE,
  CANTEENMENU_EDIT_ONE_SUCCESS,
  CANTEENMENU_EDIT_ONE_ERROR,

  CANTEENMENU_DELETE_ONE,
  CANTEENMENU_DELETE_ONE_SUCCESS,
  CANTEENMENU_DELETE_ONE_ERROR,

  CANTEENMENUS_DELETE_MANY,
  CANTEENMENUS_DELETE_MANY_SUCCESS,
  CANTEENMENUS_DELETE_MANY_ERROR
} from '../actions';

// GET LIST
export const getCanteenMenusList = (payload) => ({
  type: CANTEENMENUS_GET_LIST,
  payload
});
export const getCanteenMenusListSuccess = (payload) => ({
  type: CANTEENMENUS_GET_LIST_SUCCESS,
  payload
});
export const getCanteenMenusListError = (payload) => ({
  type: CANTEENMENUS_GET_LIST_ERROR,
  payload
});


// GET ONE
export const getOneCanteenMenu = (payload) => ({
  type: CANTEENMENU_GET_ONE,
  payload
});
export const getOneCanteenMenuSuccess = (payload) => ({
  type: CANTEENMENU_GET_ONE_SUCCESS,
  payload
});
export const getOneCanteenMenuError = (payload) => ({
  type: CANTEENMENU_GET_ONE_ERROR,
  payload
});

// GET bY Filter
export const getCanteenMenusByFilter = (payload) => ({
  type: CANTEENMENUS_GET_BY_FILTER,
  payload
});
export const getCanteenMenusByFilterSuccess = (payload) => ({
  type: CANTEENMENUS_GET_BY_FILTER_SUCCESS,
  payload
});
export const getCanteenMenusByFilterError = (payload) => ({
  type: CANTEENMENUS_GET_BY_FILTER_ERROR,
  payload
});
// ADD ONE
export const addCanteenMenu = (payload) => ({
  type: CANTEENMENU_ADD_ONE,
  payload
});
export const addCanteenMenuSuccess = (payload) => ({
  type: CANTEENMENU_ADD_ONE_SUCCESS,
  payload
});
export const addCanteenMenuError = (payload) => ({
  type: CANTEENMENU_ADD_ONE_ERROR,
  payload
});


// EDIT ONE
export const editCanteenMenu = (payload) => ({
  type: CANTEENMENU_EDIT_ONE,
  payload
});
export const editCanteenMenuSuccess = (payload) => ({
  type: CANTEENMENU_EDIT_ONE_SUCCESS,
  payload
});
export const editCanteenMenuError = (payload) => ({
  type: CANTEENMENU_EDIT_ONE_ERROR,
  payload
});

// DELETE ONE
export const deleteOneCanteenMenu = (payload) => ({
  type: CANTEENMENU_DELETE_ONE,
  payload
});
export const deleteOneCanteenMenuSuccess = (payload) => ({
  type: CANTEENMENU_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneCanteenMenuError = (payload) => ({
  type: CANTEENMENU_DELETE_ONE_ERROR,
  payload
});
// DELETE MANY
export const deleteManyCanteenMenus = (payload) => ({
  type: CANTEENMENUS_DELETE_MANY,
  payload
});
export const deleteManyCanteenMenusSuccess = (payload) => ({
  type: CANTEENMENUS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyCanteenMenusError = (payload) => ({
  type: CANTEENMENUS_DELETE_MANY_ERROR,
  payload
});