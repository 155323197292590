import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { api } from "../../constants/defaultValues";
import axios from "axios";
import {
  EXAMTYPES_GET_LIST, EXAMTYPES_GET_ONE, EXAMTYPES_GET_BY_FILTER, EXAMTYPES_ADD_ONE, EXAMTYPES_EDIT_ONE, EXAMTYPES_DELETE_ONE, EXAMTYPES_DELETE_MANY
} from "../actions";
import {
  getExamTypesListSuccess,
  getExamTypesListError,

  getOneExamTypesSuccess,
  getOneExamTypesError,

  getExamTypesByFilterSuccess,
  getExamTypesByFilterError,

  addExamTypesSuccess,
  addExamTypesError,

  editOneExamTypesSuccess,
  editOneExamTypesError,

  deleteOneExamTypesSuccess,
  deleteOneExamTypesError,

  deleteManyExamTypesSuccess,
  deleteManyExamTypesError,


} from "./actions";


//GET LIST
const getExamTypesListRequest = async (payload) => {
  return await axios.get(api.exam_types + `/list/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getExamTypesList({ payload }) {
  try {
    const ret = yield call(getExamTypesListRequest, payload);

    if (ret && ret.status === 200)

      yield put(getExamTypesListSuccess({ exam_types: ret.data.exam_types || [], count: ret.data.count || 0 }));


    else yield put(getExamTypesListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getExamTypesListError({ error, message: (error) ? error.message : '' }));
  }
}


//GET ONE
const getOneExamTypesRequest = async (payload) => {
  return await axios.get(api.exam_types + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneExamTypes({ payload }) {
  try {
    const ret = yield call(getOneExamTypesRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneExamTypesSuccess({ exam_type: ret.data.exam_type }));

    else yield put(getOneExamTypesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneExamTypesError({ error, message: (error) ? error.message : '' }));
  }
}


//GET EXAMTYPES BY FILTER
const getExamTypesByFilterRequest = async (payload) => {
  return await axios.post(api.exam_types + `/filter/${payload.inst_id}/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getExamTypesByFilter({ payload }) {
  try {
    const ret = yield call(getExamTypesByFilterRequest, payload);


    if (ret && ret.status === 200)
      yield put(getExamTypesByFilterSuccess({ exam_types: ret.data.exam_types, count: ret.data.count || 0, }));

    else yield put(getExamTypesByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getExamTypesByFilterError({ error, message: (error) ? error.message : '' }));
  }
}



//ADD ONE
const addExamTypesRequest = async (payload) => {

  return await axios.post(api.exam_types, payload.data, {
    headers: { Authorization: payload.token },

  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addExamTypes({ payload }) {
  try {
    const ret = yield call(addExamTypesRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/exams/exam-types')
      yield put(addExamTypesSuccess({ exam_type: ret.data.exam_type }));
    }
    else yield put(addExamTypesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addExamTypesError({ error, message: (error) ? error.message : '' }));
  }
}


//EDIT ONE
const editOneExamTypesRequest = async (payload) => {

  return await axios.patch(`${api.exam_types}/edit-one/${payload._id}`, payload.data, {
    headers: { Authorization: payload.token }

  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneExamTypes({ payload }) {
  try {
    const ret = yield call(editOneExamTypesRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/exams/exam-types')
      yield put(editOneExamTypesSuccess({ exam_type: ret.data.exam_type }));
    }
    else yield put(editOneExamTypesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editOneExamTypesError({ error, message: (error) ? error.message : '' }));
  }
}

// DELETE ONE
const deleteOneExamTypesRequest = async (payload) => {
  return await axios.delete(`${api.exam_types}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneExamTypes({ payload }) {
  try {
    const ret = yield call(deleteOneExamTypesRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/exams/exam-types')
      yield put(deleteOneExamTypesSuccess({ index }));
    }
    else yield put(deleteOneExamTypesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneExamTypesError({ error, message: (error) ? error.message : '' }));
  }
}

//DELETE MANY
const deleteManyExamTypesRequest = async (payload) => {
  return await axios.delete(`${api.exam_types}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyExamTypes({ payload }) {
  try {
    const ret = yield call(deleteManyExamTypesRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/app/exams/exam-types')
      yield put(deleteManyExamTypesSuccess({ data }));
    }
    else yield put(deleteManyExamTypesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyExamTypesError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(EXAMTYPES_GET_LIST, getExamTypesList);
}

export function* watchGetOne() {
  yield takeEvery(EXAMTYPES_GET_ONE, getOneExamTypes);
}

export function* watchGetFilter() {
  yield takeEvery(EXAMTYPES_GET_BY_FILTER, getExamTypesByFilter);
}

export function* watchEditOne() {
  yield takeEvery(EXAMTYPES_EDIT_ONE, editOneExamTypes);
}

export function* watchAddOne() {
  yield takeEvery(EXAMTYPES_ADD_ONE, addExamTypes);
}

export function* watchDeleteOne() {
  yield takeEvery(EXAMTYPES_DELETE_ONE, deleteOneExamTypes);
}
export function* watchDeleteMany() {
  yield takeEvery(EXAMTYPES_DELETE_MANY, deleteManyExamTypes);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetFilter),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)

  ]);
}