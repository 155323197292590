import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  TRAINING_GET_LIST, TRAINING_SUBSCRIBE, TRAINING_GET_PARTNER_NETWORK, TRAINING_ADD_ONE, TRAINING_GET_ONE, TRAINING_EDIT_ONE, TRAINING_DELETE_ONE, 
  TRAINING_GET_MY_LIST, TRAINING_ACCEPT, TRAINING_GET_LIVE
} from "../actions";

import {
  subscribeTrainingSuccess,
  subscribeTrainingError,

  getTrainingsListSuccess,
  getTrainingsListError,

  getOneTrainingSuccess,
  getOneTrainingError,

  addTrainingSuccess,
  addTrainingError,

  editTrainingSuccess,
  editTrainingError,

  deleteOneTrainingSuccess,
  deleteOneTrainingError,
  getMyTrainingsListSuccess,
  getMyTrainingsListError,
  acceptTrainingSuccess,
  acceptTrainingError,
  getLiveTrainingsSuccess,
  getLiveTrainingsError
} from "./actions";


//GET LIST
const getTrainingsListRequest = async (payload) => {
  let filters = '?';
  if (typeof payload.filters?.is_free === 'boolean') {
    filters = filters + `&is_free=${payload.filters?.is_free}`
  }
  if (typeof payload.filters?.is_online === 'boolean') {
    filters = filters + `&is_online=${payload.filters?.is_online}`
  }
  if (typeof payload.filters?.is_presential === 'boolean') {
    filters = filters + `&is_presential=${payload.filters?.is_presential}`
  }
  if (typeof payload.filters?.is_hybrid === 'boolean') {
    filters = filters + `&is_hybrid=${payload.filters?.is_hybrid}`
  }
  if (typeof payload.filters?.theme === 'string') {
    filters = filters + `&theme=${payload.filters?.theme}`
  }
  if (typeof payload.filters?.city === 'string') {
    filters = filters + `&city=${payload.filters?.city}`
  }
  if (payload.filters?.date && payload.filters.date != '' ) {
    filters = filters + `&date=${payload.filters?.date}`
  }
  console.log();
  return axios.get(api.trainings + `/list/${payload.inst_id ? payload.inst_id + '/' : ''}${payload.offset}/${payload.limit}${filters}&searchInput=${payload.searchInput || ''}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getTrainingsList({ payload }) {
  try {
    const ret = yield call(getTrainingsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getTrainingsListSuccess({ trainings: ret.data.trainings || [], count: ret.data.count || 0 }));

    else yield put(getTrainingsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getTrainingsListError({ error, message: (error) ? error.message : '' }));
  }
}

//GET LIST
const getMyTrainingsListRequest = async (payload) => {

  return axios.get(api.trainings + `/me/${payload.offset}/${payload.limit}?institution=${payload?.inst_id || ''}&searchInput=${payload.searchInput || ''}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getMyTrainingsList({ payload }) {
  try {
    const ret = yield call(getMyTrainingsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getMyTrainingsListSuccess({ trainings: ret.data.trainings || [], count: ret.data.count || 0 }));

    else yield put(getMyTrainingsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getMyTrainingsListError({ error, message: (error) ? error.message : '' }));
  }
}


//PARTNER NETWORK TRAININGS
const getTrainingsListPartnerRequest = async (payload) => {
  let filters = '';
  if (typeof payload.filters?.is_free === 'boolean') {
    filters = filters + `&is_free=${payload.filters?.is_free}`
  }
  if (typeof payload.filters?.theme === 'string') {
    filters = filters + `&theme=${payload.filters?.theme}`
  }
  return axios.get(api.trainings +
    `/partner-network/${payload.partner_id}/${payload.offset}/${payload.limit}?searchInput=${payload.searchInput || ''}${filters}`,
    { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getTrainingsListPartner({ payload }) {
  try {
    const ret = yield call(getTrainingsListPartnerRequest, payload);

    if (ret && ret.status === 200)
      yield put(getTrainingsListSuccess({ trainings: ret.data.trainings || [], count: ret.data.count || 0 }));

    else yield put(getTrainingsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getTrainingsListError({ error, message: (error) ? error.message : '' }));
  }
}




//GET ONE
const getOneTrainingsRequest = async (payload) => {
  return await axios.get(api.trainings + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneTraining({ payload }) {
  try {
    const ret = yield call(getOneTrainingsRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneTrainingSuccess({ training: ret.data.training }));

    else yield put(getOneTrainingError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneTrainingError({ error, message: (error) ? error.message : '' }));
  }
}






//SUBSCRIBE
const subscribeTrainingRequest = async (payload) => {
  return await axios.post(api.trainings + `/subscribe/${payload.training_id}`, {
    partner: payload.partner
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* subscribeTraining({ payload }) {
  try {
    const ret = yield call(subscribeTrainingRequest, payload);

    if (ret && ret.status === 200) {
      const trainings = payload.trainings
      const training = ret.data.training
      if (trainings && trainings[payload.index]) trainings[payload.index] = training
      yield put(subscribeTrainingSuccess({ index: payload.index, trainings, training }));
    }

    else yield put(subscribeTrainingError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(subscribeTrainingError({ error, message: (error) ? error.message : '' }));
  }
}






//ADD ONE
const addTrainingRequest = async (payload) => {
  return await axios.post(api.trainings, payload.data,{
    headers: {
      Authorization: payload.token, 'Content-Type': 'multipart/form-data',
    }
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addTraining({ payload }) {
  try {
    const ret = yield call(addTrainingRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.goBack()
      yield put(addTrainingSuccess({ training: ret.data.training }));
    }
    else yield put(addTrainingError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addTrainingError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneTrainingRequest = async (payload) => {
  return await axios.patch(`${api.trainings}/edit-one/${payload._id}`, payload.data,{
    headers: {
      Authorization: payload.token, 'Content-Type': 'multipart/form-data',
    }
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneTraining({ payload }) {
  try {
    const ret = yield call(editOneTrainingRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/institution/trainings')
      yield put(editTrainingSuccess({ training: ret.data.training }));
    }
    else yield put(editTrainingError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editTrainingError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneTrainingRequest = async (payload) => {
  return await axios.delete(`${api.trainings}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneTraining({ payload }) {
  try {
    const ret = yield call(deleteOneTrainingRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/my-institutions/trainings')
      yield put(deleteOneTrainingSuccess({ index }));
    }
    else yield put(deleteOneTrainingError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneTrainingError({ error, message: (error) ? error.message : '' }));
  }
}

// HANDLE ACCEPT SUBSCRIPTION
const acceptTrainingRequest = async (payload) => {
  return await axios.post(`${api.trainings}/change-status/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* acceptTraining({ payload }) {
  try {
    const ret = yield call(acceptTrainingRequest, payload);

    if (ret && ret.status === 200) {

      yield put(acceptTrainingSuccess({ training: ret.data.training }));
    }
    else yield put(acceptTrainingError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(acceptTrainingError({ error, message: (error) ? error.message : '' }));
  }

}

//GET LIVE
const getLiveTrainingsRequest = async (payload) => {

  return axios.get(api.trainings + `/live`,{
    headers: {
      Authorization: payload.token
    }
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getLiveTrainings({ payload }) {
  try {
    const ret = yield call(getLiveTrainingsRequest, payload);

    if (ret && ret.status === 200)
      yield put(getLiveTrainingsSuccess({ trainings: ret.data.trainings || [], count: ret.data.count || 0 }));

    else yield put(getLiveTrainingsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getLiveTrainingsError({ error, message: (error) ? error.message : '' }));
  }
}

//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(TRAINING_GET_LIST, getTrainingsList);
}
export function* watchGetMyList() {
  yield takeEvery(TRAINING_GET_MY_LIST, getMyTrainingsList);
}
export function* watchGetLive() {
  yield takeEvery(TRAINING_GET_LIVE, getLiveTrainings);
}

export function* watchSubscribe() {
  yield takeEvery(TRAINING_SUBSCRIBE, subscribeTraining);
}

export function* watchGetListPartner() {
  yield takeEvery(TRAINING_GET_PARTNER_NETWORK, getTrainingsListPartner);
}

export function* watchGetOne() {
  yield takeEvery(TRAINING_GET_ONE, getOneTraining);
}

export function* watchEditOne() {
  yield takeEvery(TRAINING_EDIT_ONE, editOneTraining);
}

export function* watchAddOne() {
  yield takeEvery(TRAINING_ADD_ONE, addTraining);
}

export function* watchDeleteOne() {
  yield takeEvery(TRAINING_DELETE_ONE, deleteOneTraining);
}

export function* watchAccept() {
  yield takeEvery(TRAINING_ACCEPT, acceptTraining);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetMyList),
    fork(watchGetLive),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchGetListPartner),
    fork(watchSubscribe),
    fork(watchAccept)
  ]);
}
