import {
  INSTITUTION_GET_LIST,
  INSTITUTION_GET_LIST_SUCCESS,
  INSTITUTION_GET_LIST_ERROR,

  INSTITUTION_GET_MY_LIST,
  INSTITUTION_GET_MY_LIST_SUCCESS,
  INSTITUTION_GET_MY_LIST_ERROR,

  INSTITUTION_GET_BY_FILTER,
  INSTITUTION_GET_BY_FILTER_SUCCESS,
  INSTITUTION_GET_BY_FILTER_ERROR,

  INSTITUTION_GET_LIST_WITH_FILTER,
  INSTITUTION_GET_LIST_WITH_ORDER,
  INSTITUTION_GET_LIST_SEARCH,

  INSTITUTION_ADD_ITEM,
  INSTITUTION_ADD_ITEM_SUCCESS,
  INSTITUTION_ADD_ITEM_ERROR,

  INSTITUTION_SELECTED_ITEMS_CHANGE,

  INSTITUTION_GET_ITEM,
  INSTITUTION_GET_ITEM_SUCCESS,
  INSTITUTION_GET_ITEM_ERROR,

  INSTITUTION_SELECT_MY_INST,
  POST_INSTITUTION_SELECT_MY_INST,
  INSTITUTION_CHANGE_SECTION,

  INSTITUTION_ACTIVATE_ITEM,
  INSTITUTION_ACTIVATE_ITEM_SUCCESS,
  INSTITUTION_ACTIVATE_ITEM_ERROR,

  INSTITUTION_DELETE_ONE,
  INSTITUTION_DELETE_ONE_SUCCESS,
  INSTITUTION_DELETE_ONE_ERROR,

  INSTITUTION_EDIT_ONE,
  INSTITUTION_EDIT_ONE_SUCCESS,
  INSTITUTION_EDIT_ONE_ERROR,

  INSTITUTION_SUBSCRIBE,
  INSTITUTION_SUBSCRIBE_SUCCESS,
  INSTITUTION_SUBSCRIBE_ERROR,

  INSTITUTION_RECOMMAND,
  INSTITUTION_RECOMMAND_SUCCESS,
  INSTITUTION_RECOMMAND_ERROR


} from './actionTypes';


// GET LIST
export const getInstitutionList = (payload) => ({ //{ offset, limit, token }
  type: INSTITUTION_GET_LIST,
  payload
});
export const getInstitutionListSuccess = (items) => ({
  type: INSTITUTION_GET_LIST_SUCCESS,
  payload: items
});
export const getInstitutionListError = (error) => ({
  type: INSTITUTION_GET_LIST_ERROR,
  payload: error
});

//  GET MY INSTITUTIONS ( institutions that i can manage )
export const getMyInstitutions = (payload) => ({
  type: INSTITUTION_GET_MY_LIST,
  payload
});
export const getMyInstitutionsSuccess = (payload) => ({
  type: INSTITUTION_GET_MY_LIST_SUCCESS,
  payload
});
export const getMyInstitutionsError = (payload) => ({
  type: INSTITUTION_GET_MY_LIST_ERROR,
  payload
});

//GET BY FILTER

export const getInstitutionByFilter = (payload) => ({
  type: INSTITUTION_GET_BY_FILTER,
  payload
});
export const getInstitutionByFilterSuccess = (payload) => ({
  type: INSTITUTION_GET_BY_FILTER_SUCCESS,
  payload
});
export const getInstitutionByFilterError = (payload) => ({
  type: INSTITUTION_GET_BY_FILTER_ERROR,
  payload
});


// GET ONE
export const getInstitution = (payload) => ({ //{ _id, token }
  type: INSTITUTION_GET_ITEM,
  payload
});
export const getInstitutionSuccess = (payload) => ({
  type: INSTITUTION_GET_ITEM_SUCCESS,
  payload
});
export const getInstitutionError = (payload) => ({
  type: INSTITUTION_GET_ITEM_ERROR,
  payload
});




// SELECT INST
export const selectMyInstitution = (payload) => ({
  type: INSTITUTION_SELECT_MY_INST,
  payload
});

// SELECT POST INST
export const selectMyInstitutionPost = (payload) => ({
  type: POST_INSTITUTION_SELECT_MY_INST,
  payload
});

// CHANGE SECTION
export const changeInstSection = (payload) => ({
  type: INSTITUTION_CHANGE_SECTION,
  payload
});



//ACTIVATE ONE
export const activateInstitute = (payload) => ({
  type: INSTITUTION_ACTIVATE_ITEM,
  payload
});
export const activateInstituteSuccess = (payload) => ({
  type: INSTITUTION_ACTIVATE_ITEM_SUCCESS,
  payload
});
export const activateInstituteError = (payload) => ({
  type: INSTITUTION_ACTIVATE_ITEM_ERROR,
  payload
});



//ADD ONE
export const addInstitution = (payload) => ({
  type: INSTITUTION_ADD_ITEM,
  payload
})
export const addInstitutionSuccess = (items) => ({
  type: INSTITUTION_ADD_ITEM_SUCCESS,
  payload: items
})
export const addInstitutionError = (error) => ({
  type: INSTITUTION_ADD_ITEM_ERROR,
  payload: error
});

// EDIT ONE
export const editInstitution = (payload) => ({
  type: INSTITUTION_EDIT_ONE,
  payload
});
export const editInstitutionSuccess = (payload) => ({
  type: INSTITUTION_EDIT_ONE_SUCCESS,
  payload
});
export const editInstitutionError = (payload) => ({
  type: INSTITUTION_EDIT_ONE_ERROR,
  payload
});



// FILTING
export const getInstitutionListWithFilter = (column, value) => ({
  type: INSTITUTION_GET_LIST_WITH_FILTER,
  payload: { column, value }
});

export const getInstitutionListWithOrder = (column) => ({
  type: INSTITUTION_GET_LIST_WITH_ORDER,
  payload: column
});

export const getInstitutionListSearch = (keyword) => ({
  type: INSTITUTION_GET_LIST_SEARCH,
  payload: keyword
});

export const selectedInstitutionItemsChange = (selectedItems) => ({
  type: INSTITUTION_SELECTED_ITEMS_CHANGE,
  payload: selectedItems
});


// DELETE ONE
export const deleteOneInstitution = (payload) => ({
  type: INSTITUTION_DELETE_ONE,
  payload
});
export const deleteOneInstitutionSuccess = (payload) => ({
  type: INSTITUTION_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneInstitutionError = (payload) => ({
  type: INSTITUTION_DELETE_ONE_ERROR,
  payload
});


// TOGGLE SUBSCRIBE
export const subscribeInstitution = (payload) => ({
  type: INSTITUTION_SUBSCRIBE,
  payload
});
export const subscribeInstitutionSuccess = (payload) => ({
  type: INSTITUTION_SUBSCRIBE_SUCCESS,
  payload
});
export const subscribeInstitutionError = (payload) => ({
  type: INSTITUTION_SUBSCRIBE_ERROR,
  payload
});


// RECOMMAND

export const recommendInstitution = (payload) => ({
  type: INSTITUTION_RECOMMAND,
  payload
});
export const recommendInstitutionSuccess = (payload) => ({
  type: INSTITUTION_RECOMMAND_SUCCESS,
  payload
});
export const recommendInstitutionError = (payload) => ({
  type: INSTITUTION_RECOMMAND_ERROR,
  payload
});

