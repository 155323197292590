import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { api } from "../../constants/defaultValues";
import axios from "axios";
import {
  EXAMSESSIONS_GET_LIST, EXAMSESSIONS_GET_ONE, EXAMSESSIONS_GET_BY_FILTER, EXAMSESSIONS_ADD_ONE, EXAMSESSIONS_EDIT_ONE, EXAMSESSIONS_DELETE_ONE, EXAMSESSIONS_DELETE_MANY
} from "../actions";
import {
  getExamSessionsListSuccess,
  getExamSessionsListError,

  getOneExamSessionsSuccess,
  getOneExamSessionsError,

  getExamSessionsByFilterSuccess,
  getExamSessionsByFilterError,


  addExamSessionsSuccess,
  addExamSessionsError,


  editOneExamSessionsSuccess,
  editOneExamSessionsError,

  deleteOneExamSessionsSuccess,
  deleteOneExamSessionsError,

  deleteManyExamSessionsSuccess,
  deleteManyExamSessionsError,


} from "./actions";

//GET LIST
const getExamSessionsListRequest = async (payload) => {
  return await axios.get(api.exam_sessions + `/list/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getExamSessionsList({ payload }) {
  try {
    const ret = yield call(getExamSessionsListRequest, payload);

    if (ret && ret.status === 200)

      yield put(getExamSessionsListSuccess({ exam_sessions: ret.data.exam_sessions || [], count: ret.data.count || 0 }));


    else yield put(getExamSessionsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getExamSessionsListError({ error, message: (error) ? error.message : '' }));
  }
}


//GET ONE
const getOneExamSessionsRequest = async (payload) => {
  return await axios.get(api.exam_sessions + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneExamSessions({ payload }) {
  try {
    const ret = yield call(getOneExamSessionsRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneExamSessionsSuccess({ exam_session: ret.data.exam_session }));

    else yield put(getOneExamSessionsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneExamSessionsError({ error, message: (error) ? error.message : '' }));
  }
}


//GET EXAM SESSIONS BY FILTER
const getExamSessionsByFilterRequest = async (payload) => {
  return await axios.post(api.exam_sessions + `/filter/${payload.inst_id}/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getExamSessionsByFilter({ payload }) {
  try {
    const ret = yield call(getExamSessionsByFilterRequest, payload);


    if (ret && ret.status === 200)
      yield put(getExamSessionsByFilterSuccess({ exam_sessions: ret.data.exam_sessions, count: ret.data.count || 0 }));

    else yield put(getExamSessionsByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getExamSessionsByFilterError({ error, message: (error) ? error.message : '' }));
  }
}

//ADD ONE
const addExamSessionsRequest = async (payload) => {

  return await axios.post(api.exam_sessions, payload.data, {
    headers: { Authorization: payload.token },

  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addExamSessions({ payload }) {
  try {
    const ret = yield call(addExamSessionsRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/exams/exam-sessions')
      yield put(addExamSessionsSuccess({ exam_session: ret.data.exam_session }));
    }
    else yield put(addExamSessionsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addExamSessionsError({ error, message: (error) ? error.message : '' }));
  }
}

//EDIT ONE
const editOneExamSessionsRequest = async (payload) => {

  return await axios.patch(`${api.exam_sessions}/edit-one/${payload._id}`, payload.data, {
    headers: { Authorization: payload.token }

  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneExamSessions({ payload }) {
  try {
    const ret = yield call(editOneExamSessionsRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/exams/exam-sessions/list')
      yield put(editOneExamSessionsSuccess({ exam_session: ret.data.exam_session }));
    }
    else yield put(editOneExamSessionsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editOneExamSessionsError({ error, message: (error) ? error.message : '' }));
  }
}

// DELETE ONE
const deleteOneExamSessionsRequest = async (payload) => {
  return await axios.delete(`${api.exam_sessions}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneExamSessions({ payload }) {
  try {
    const ret = yield call(deleteOneExamSessionsRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/exams/exam-sessions')
      yield put(deleteOneExamSessionsSuccess({ index }));
    }
    else yield put(deleteOneExamSessionsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneExamSessionsError({ error, message: (error) ? error.message : '' }));
  }
}
//DELETE MANY
const deleteManyExamSessionsRequest = async (payload) => {
  return await axios.delete(`${api.exam_sessions}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyExamSessions({ payload }) {
  try {
    const ret = yield call(deleteManyExamSessionsRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/app/exams/exam-sessions')
      yield put(deleteManyExamSessionsSuccess({ data }));
    }
    else yield put(deleteManyExamSessionsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyExamSessionsError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(EXAMSESSIONS_GET_LIST, getExamSessionsList);
}

export function* watchGetOne() {
  yield takeEvery(EXAMSESSIONS_GET_ONE, getOneExamSessions);
}

export function* watchGetFilter() {
  yield takeEvery(EXAMSESSIONS_GET_BY_FILTER, getExamSessionsByFilter);
}

export function* watchEditOne() {
  yield takeEvery(EXAMSESSIONS_EDIT_ONE, editOneExamSessions);
}

export function* watchAddOne() {
  yield takeEvery(EXAMSESSIONS_ADD_ONE, addExamSessions);
}

export function* watchDeleteOne() {
  yield takeEvery(EXAMSESSIONS_DELETE_ONE, deleteOneExamSessions);
}
export function* watchDeleteMany() {
  yield takeEvery(EXAMSESSIONS_DELETE_MANY, deleteManyExamSessions);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetFilter),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)

  ]);
}