import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { api } from "../../../constants/defaultValues";
import axios from "axios";

import {
  CONFIGS_SCHOOL_LEVEL_GET_LIST, 
  CONFIGS_SCHOOL_LEVEL_GET_ONE,
  CONFIGS_SCHOOL_LEVEL_ADD_ONE,
  CONFIGS_SCHOOL_LEVEL_EDIT_ONE,  
  CONFIGS_SCHOOL_LEVEL_DELETE_ONE
} from "../../actions";
import {
  getConfigsSchoolLevelListSuccess,
  getConfigsSchoolLevelListError,


  getOneConfigsSchoollevelSuccess,
  getOneConfigsSchoollevelError,

  addConfigsSchoolLevelSuccess,
  addConfigsSchoolLevelError,

  editOneConfigsSchoolLevelSuccess,
  editOneConfigsSchoolLevelError,

  deleteOneConfigsSchoolLevelSuccess,
  deleteOneConfigsSchoolLevelError,
} from "./actions";


//GET LIST
const getConfigsLevelListRequest = async (payload) => {
  return await axios.get(api.configs + `/schoolLevels/list/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getConfigsLevelList({ payload }) {
  try {
    const ret = yield call(getConfigsLevelListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getConfigsSchoolLevelListSuccess({ levels: ret.data.levels || [], count: ret.data.count || 0 }));


    else yield put(getConfigsSchoolLevelListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {

    yield put(getConfigsSchoolLevelListError({ error, message: (error) ? error.message : '' }));
  }
}


//GET ONE
const getOneConfigslevelRequest = async (payload) => {
  return await axios.get(api.configs + `/schoolLevels/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneConfigslevel({ payload }) {
  try {
    const ret = yield call(getOneConfigslevelRequest, payload);

    if (ret && ret.status === 200) yield put(getOneConfigsSchoollevelSuccess({ level: ret.data.level }));
    else yield put(getOneConfigsSchoollevelError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(getOneConfigsSchoollevelError({ error, message: (error) ? error.message : '' }));
  }
}

//ADD ONE
const addConfigsSchoolLevelRequest = async (payload) => {
  return await axios.post(api.configs + `/schoolLevels`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addConfigsSchoolLevel({ payload }) {
  try {
    const ret = yield call(addConfigsSchoolLevelRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/admin/settings/school-levels')
      yield put(addConfigsSchoolLevelSuccess({ level: ret.data.level }));
    }
    else yield put(addConfigsSchoolLevelError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addConfigsSchoolLevelError({ error, message: (error) ? error.message : '' }));
  }
}

//EDIT ONE
const editOneConfigsLevelRequest = async (payload) => {
  return await axios.patch(`${api.configs}/schoolLevels/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneConfigsLevel({ payload }) {
  try {
    const ret = yield call(editOneConfigsLevelRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/admin/settings/school-levels')
      yield put(editOneConfigsSchoolLevelSuccess({ level: ret.data.level }));
    }
    else yield put(editOneConfigsSchoolLevelError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editOneConfigsSchoolLevelError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneConfigsLevelRequest = async (payload) => {
  return await axios.delete(`${api.configs}/schoolLevels/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneConfigsLevel({ payload }) {
  try {
    const ret = yield call(deleteOneConfigsLevelRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/admin/settings/school-levels')
      yield put(deleteOneConfigsSchoolLevelSuccess({ index }));
    }
    else yield put(deleteOneConfigsSchoolLevelError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneConfigsSchoolLevelError({ error, message: (error) ? error.message : '' }));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(CONFIGS_SCHOOL_LEVEL_GET_LIST, getConfigsLevelList);
}


export function* watchGetOne() {
  yield takeEvery(CONFIGS_SCHOOL_LEVEL_GET_ONE, getOneConfigslevel);
}

export function* watchEditOne() {
  yield takeEvery(CONFIGS_SCHOOL_LEVEL_EDIT_ONE, editOneConfigsLevel);
}

export function* watchAddOne() {
  yield takeEvery(CONFIGS_SCHOOL_LEVEL_ADD_ONE, addConfigsSchoolLevel);
}

export function* watchDeleteOne() {
  yield takeEvery(CONFIGS_SCHOOL_LEVEL_DELETE_ONE, deleteOneConfigsLevel);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}