export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const CHAT_LOGIN_USER = "CHAT_LOGIN_USER";
export const CHAT_LOGIN_USER_SUCCESS = "CHAT_LOGIN_USER_SUCCESS";
export const CHAT_LOGIN_USER_ERROR = "CHAT_LOGIN_USER_ERROR";
export const GET_CHATROOM_ID = "  GET_CHATROOM_ID"
export const GET_CHATROOM_ID_SUCCESS = "  GET_CHATROOM_ID_SUCCESS"
export const GET_CHATROOM_ID_ERROR = "  GET_CHATROOM_ID_ERROR"

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

export const USER_DELETE = "USER_DELETE";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_ERROR = "USER_DELETE_ERROR";


export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const USER_GET_LIST = "USER_GET_LIST";
export const USER_GET_LIST_SUCCESS = "USER_GET_LIST_SUCCESS";
export const USER_GET_LIST_ERROR = "USER_GET_LIST_ERROR";

export const USER_CHANGE_STATE = "USER_CHANGE_STATE";
export const USER_CHANGE_STATE_SUCCESS = "USER_CHANGE_STATE_SUCCESS";
export const USER_CHANGE_STATE_ERROR = "USER_CHANGE_STATE_ERROR";

export const USER_CHANGE_BOX_VIEW = "USER_CHANGE_BOX_VIEW";


export const USER_GET_BY_FILTER = "USER_GET_BY_FILTER";
export const USER_GET_BY_FILTER_SUCCESS = "USER_GET_BY_FILTER_SUCCESS";
export const USER_GET_BY_FILTER_ERROR = "USER_GET_BY_FILTER_ERROR";

export const SELECT_USER = "SELECT_USER ";