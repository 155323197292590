import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../../constants/defaultValues";
import axios from "axios";

import {
  CLASS_CONFIG_GET_LIST,
  CLASS_CONFIG_GET_BY_TEACHER,
  CLASS_CONFIG_ADD_ONE,
  CLASS_CONFIG_GET_ONE,
  CLASS_CONFIG_EDIT_ONE,
  CLASS_CONFIG_DELETE_ONE,
  CLASS_CONFIG_GET_BY_FILTER,
  CLASSES_CONFIG_DELETE_MANY
} from "../../actions";

import {
  getConfigClassesListSuccess,
  getConfigClassesListError,

  getOneClassConfigSuccess,
  getOneClassConfigError,

  getClassesConfigByFilterSuccess,
  getClassesConfigByFilterError,

  addClassConfigSuccess,
  addClassConfigError,

  editClassConfigSuccess,
  editClassConfigError,
  deleteOneClassConfigSuccess,
  deleteOneClassConfigError,

  deleteManyClassesConfigSuccess,
  deleteManyClassesConfigError
} from "./actions";


//GET LIST
const getClassesListRequest = async (payload) => {
  return await axios.get(api.classes + `/list-admin/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getConfigClassesList({ payload }) {
  try {
    const ret = yield call(getClassesListRequest, payload);
    console.log('ret',ret);

    if (ret && ret.status === 200)
      yield put(getConfigClassesListSuccess({ classes: ret.data.classes || [], count: ret.data.count || 0 }));

    else yield put(getConfigClassesListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getConfigClassesListError({ error, message: (error) ? error.message : '' }));
  }
}


//GET LIST BY TEACHER
const getClassesByTeacherRequest = async (payload) => {
  return await axios.post(api.classes + `/teacher/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getClassesByTeacher({ payload }) {
  try {
    const ret = yield call(getClassesByTeacherRequest, payload);

    if (ret && ret.status === 200)
      yield put(getConfigClassesListSuccess({ classes: ret.data.classes || [], count: ret.data.count || 0 }));

    else yield put(getConfigClassesListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getConfigClassesListError({ error, message: (error) ? error.message : '' }));
  }
}


//GET ONE
const getOneClassesRequest = async (payload) => {
  return await axios.get(api.classes + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneClassConfig({ payload }) {
  try {
    const ret = yield call(getOneClassesRequest, payload);

    if (ret && ret.status === 200) yield put(getOneClassConfigSuccess({ class: ret.data.one_class }));
    else yield put(getOneClassConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(getOneClassConfigError({ error, message: (error) ? error.message : '' }));
  }
}



//GET CLASS BY FILTER
const getClassesbyFilterRequest = async (payload) => {
  return await axios.post(api.classes + `/filters/admin/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getClassesConfigByFilterr({ payload }) {
  try {
    const ret = yield call(getClassesbyFilterRequest, payload);
    if (ret && ret.status === 200)
      yield put(getClassesConfigByFilterSuccess({ classes: ret.data.classes, count: ret.data.count || 0 }));

    else yield put(getClassesConfigByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getClassesConfigByFilterError({ error, message: (error) ? error.message : '' }));
  }
}



//ADD ONE
const addClassRequest = async (payload) => {
  return await axios.post(api.classes, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addClassConfig({ payload }) {
  try {
    const ret = yield call(addClassRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/admin/settings/classes/list')
      yield put(addClassConfigSuccess({ class: ret.data.class }));
    }
    else yield put(addClassConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addClassConfigError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneClassRequest = async (payload) => {
  return await axios.patch(`${api.classes}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneClass({ payload }) {
  try {
    const ret = yield call(editOneClassRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/admin/settings/classes/list')
      yield put(editClassConfigSuccess({ class: ret.data.class }));
    }
    else yield put(editClassConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editClassConfigError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneClassRequest = async (payload) => {
  return await axios.delete(`${api.classes}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneClassConfig({ payload }) {
  try {
    const ret = yield call(deleteOneClassRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/admin/settings/classes/list')
      yield put(deleteOneClassConfigSuccess({ index }));
    }
    else yield put(deleteOneClassConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneClassConfigError({ error, message: (error) ? error.message : '' }));
  }
}
//DELETE MANY

const deleteManyClassesRequest = async (payload) => {
  return await axios.delete(`${api.classes}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyClassesConfig({ payload }) {
  try {
    const ret = yield call(deleteManyClassesRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/admin/settings/classes/list')
      yield put(deleteManyClassesConfigSuccess({ data }));
    }
    else yield put(deleteManyClassesConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyClassesConfigError({ error, message: (error) ? error.message : '' }));
  }
}

//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(CLASS_CONFIG_GET_LIST, getConfigClassesList);
}

export function* watchGetByTeacher() {
  yield takeEvery(CLASS_CONFIG_GET_BY_TEACHER, getClassesByTeacher);
}

export function* watchGetFilter() {
  yield takeEvery(CLASS_CONFIG_GET_BY_FILTER, getClassesConfigByFilterr);
}

export function* watchGetOne() {
  yield takeEvery(CLASS_CONFIG_GET_ONE, getOneClassConfig);
}

export function* watchEditOne() {
  yield takeEvery(CLASS_CONFIG_EDIT_ONE, editOneClass);
}

export function* watchAddOne() {
  yield takeEvery(CLASS_CONFIG_ADD_ONE, addClassConfig);
}

export function* watchDeleteOne() {
  yield takeEvery(CLASS_CONFIG_DELETE_ONE, deleteOneClassConfig);
}
export function* watchDeleteMany() {
  yield takeEvery(CLASSES_CONFIG_DELETE_MANY, deleteManyClassesConfig);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetByTeacher),
    fork(watchGetFilter),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)
  ]);
}
