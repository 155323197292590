import {
  SCHOOLLEVEL_GET_LIST,
  SCHOOLLEVEL_GET_LIST_SUCCESS,
  SCHOOLLEVEL_GET_LIST_ERROR,

  SCHOOLLEVEL_GET_ONE,
  SCHOOLLEVEL_GET_ONE_SUCCESS,
  SCHOOLLEVEL_GET_ONE_ERROR,

  SCHOOLLEVEL_GET_BY_FILTER,
  SCHOOLLEVEL_GET_BY_FILTER_SUCCESS,
  SCHOOLLEVEL_GET_BY_FILTER_ERROR,

  SCHOOLLEVEL_ADD_ONE,
  SCHOOLLEVEL_ADD_ONE_SUCCESS,
  SCHOOLLEVEL_ADD_ONE_ERROR,

  SCHOOLLEVEL_EDIT_ONE,
  SCHOOLLEVEL_EDIT_ONE_SUCCESS,
  SCHOOLLEVEL_EDIT_ONE_ERROR,

  SCHOOLLEVEL_DELETE_ONE,
  SCHOOLLEVEL_DELETE_ONE_SUCCESS,
  SCHOOLLEVEL_DELETE_ONE_ERROR,

  SCHOOLLEVELS_DELETE_MANY,
  SCHOOLLEVELS_DELETE_MANY_SUCCESS,
  SCHOOLLEVELS_DELETE_MANY_ERROR,
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'
const INIT_STATE = {
  school_levels: [],
  count: 0,
  school_level: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
  switch (action.type) {
    case SCHOOLLEVEL_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case SCHOOLLEVEL_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        school_levels: action.payload.school_levels || [],
        count: action.payload.count || 0
      };
    case SCHOOLLEVEL_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






    // GET ONE
    case SCHOOLLEVEL_GET_ONE:
      return { ...state, loading: true, error: '' };
    case SCHOOLLEVEL_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        school_level: action.payload.school_level
      };
    case SCHOOLLEVEL_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };




    // GET BY FILTER
    case SCHOOLLEVEL_GET_BY_FILTER:

      return { ...state, loading: true, error: '' };
    case SCHOOLLEVEL_GET_BY_FILTER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        school_levels: action.payload.school_levels || [], count: action.payload.count || 0
      };
    case SCHOOLLEVEL_GET_BY_FILTER_ERROR:

      return { ...state, loading: false, error: action.payload.message };







    // ADD ONE
    case SCHOOLLEVEL_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case SCHOOLLEVEL_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        school_levels: [action.payload.school_level, ...state.school_levels]
      };
    case SCHOOLLEVEL_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // EDIT ONE
    case SCHOOLLEVEL_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case SCHOOLLEVEL_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        school_level: action.payload.school_level
      };
    case SCHOOLLEVEL_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // DELETE ONE
    case SCHOOLLEVEL_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case SCHOOLLEVEL_DELETE_ONE_SUCCESS:
      const school_levels = state.school_levels
      if (action.payload.index !== null && action.payload.index !== undefined)
        school_levels.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        school_levels
      };
    case SCHOOLLEVEL_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    // DELETE MANY
    case SCHOOLLEVELS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case SCHOOLLEVELS_DELETE_MANY_SUCCESS:
      const school_levels_list = state.school_levels


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_school_level = -1
          for (let j = 0; j < school_levels_list.length; j++) {
            if (school_levels_list[j]._id === action.payload.data.ids[i]) {
              index_school_level = j; break;
            }
          }
          if (index_school_level > -1) school_levels_list.splice(index_school_level, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        school_levels: school_levels_list
      };
    case SCHOOLLEVELS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
    default: return { ...state };
  }
}
