import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  INVITATION_GET_LIST, INVITATION_GET_LIST_BY_PARTNER, INVITATION_ADD_ONE, INVITATION_GET_ONE,
  INVITATION_EDIT_ONE, INVITATION_DELETE_ONE, INVITATION_GET_IDS, INVITATION_ACCEPT
} from "../actions";

import {
  getInvitationsListSuccess,
  getInvitationsListError,

  getInvitationsListIdsSuccess,
  getInvitationsListIdsError,

  getOneInvitationSuccess,
  getOneInvitationError,

  addInvitationSuccess,
  addInvitationError,

  editInvitationSuccess,
  editInvitationError,
  deleteOneInvitationSuccess,
  deleteOneInvitationError,
  getInvitationsListByPartnerSuccess,
  getInvitationsListByPartnerError,
} from "./actions";


//GET LIST
const getInvitationsListRequest = async (payload) => {
  return axios.get(api.invitations + `/list/${payload.event_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getInvitationsList({ payload }) {
  try {
    const ret = yield call(getInvitationsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getInvitationsListSuccess({ invitations: ret.data.invitations || [], count: ret.data.count || 0 }));

    else yield put(getInvitationsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getInvitationsListError({ error, message: (error) ? error.message : '' }));
  }
}


//GET PARTNER INVITATIONS
const getInvitationsListByPartnerRequest = async (payload) => {
  return axios.get(api.invitations + `/list/partner/${payload.partner_id}/${payload.offset}/${payload.limit}?${payload.type ? 'type=' + payload.type : ''}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getInvitationsListByPartner({ payload }) {
  try {
    const ret = yield call(getInvitationsListByPartnerRequest, payload);

    if (ret && ret.status === 200)
      yield put(getInvitationsListByPartnerSuccess({ invitations: ret.data.invitations || [], count: ret.data.count || 0 }));

    else yield put(getInvitationsListByPartnerError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getInvitationsListByPartnerError({ error, message: (error) ? error.message : '' }));
  }
}



// GET EVENT INVITATIONS IDS (all)
const getInvitationsListIdsRequest = async (payload) => {
  return axios.get(api.invitations + `/list/${payload.event_id}/get-ids`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getInvitationsListIds({ payload }) {
  try {
    const ret = yield call(getInvitationsListIdsRequest, payload);

    if (ret && ret.status === 200)
      yield put(getInvitationsListIdsSuccess({ invitations_ids: ret.data.invitations_ids || [] }));

    else yield put(getInvitationsListIdsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getInvitationsListIdsError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOneInvitationsRequest = async (payload) => {
  return axios.get(api.invitations + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneInvitation({ payload }) {
  try {
    const ret = yield call(getOneInvitationsRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneInvitationSuccess({ invitation: ret.data.invitation }));

    else yield put(getOneInvitationError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneInvitationError({ error, message: (error) ? error.message : '' }));
  }
}




//ADD ONE
const addInvitationRequest = async (payload) => {
  return axios.post(api.invitations, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addInvitation({ payload }) {
  try {
    const ret = yield call(addInvitationRequest, payload);

    if (ret && ret.status === 201) {
      yield put(addInvitationSuccess({ invitation: ret.data.invitation, is_list: payload.is_list }));
    }
    else yield put(addInvitationError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addInvitationError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneInvitationRequest = async (payload) => {
  return axios.patch(`${api.invitations}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneInvitation({ payload }) {
  try {
    const ret = yield call(editOneInvitationRequest, payload);

    if (ret && ret.status === 200) {
      yield put(editInvitationSuccess({ invitation: ret.data.invitation }));
    }
    else yield put(editInvitationError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editInvitationError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneInvitationRequest = async (payload) => {
  return axios.delete(`${api.invitations}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneInvitation({ payload }) {
  try {
    const ret = yield call(deleteOneInvitationRequest, payload);

    if (ret && ret.status === 202) {
      const { index } = payload;
      yield put(deleteOneInvitationSuccess({ index }));
    }
    else yield put(deleteOneInvitationError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneInvitationError({ error, message: (error) ? error.message : '' }));
  }
}

// ACCEPT ONE
const acceptInvitationRequest = async (payload) => {
  return axios.get(`${api.invitations}/accept/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* acceptInvitation({ payload }) {
  try {
    const ret = yield call(acceptInvitationRequest, payload);

    if (ret && ret.status === 202) {
      const { index, history } = payload;
      if (history) history.go(0)
      yield put(deleteOneInvitationSuccess({ index }));
    }
    else yield put(deleteOneInvitationError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneInvitationError({ error, message: (error) ? error.message : '' }));
  }
}



//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(INVITATION_GET_LIST, getInvitationsList);
}

export function* watchGetListByPartner() {
  yield takeEvery(INVITATION_GET_LIST_BY_PARTNER, getInvitationsListByPartner);
}

export function* watchGetListIds() {
  yield takeEvery(INVITATION_GET_IDS, getInvitationsListIds);
}

export function* watchGetOne() {
  yield takeEvery(INVITATION_GET_ONE, getOneInvitation);
}

export function* watchEditOne() {
  yield takeEvery(INVITATION_EDIT_ONE, editOneInvitation);
}

export function* watchAddOne() {
  yield takeEvery(INVITATION_ADD_ONE, addInvitation);
}

export function* watchAccept() {
  yield takeEvery(INVITATION_ACCEPT, acceptInvitation);
}

export function* watchDeleteOne() {
  yield takeEvery(INVITATION_DELETE_ONE, deleteOneInvitation);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetListByPartner),
    fork(watchGetListIds),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchAccept),
    fork(watchDeleteOne)
  ]);
}
