import SchoolYearSrv from '../../../services/SchoolYearSrv';
import {
  SCHOOLYEAR_CONFIG_GET_LIST,
  SCHOOLYEAR_CONFIG_GET_LIST_SUCCESS,
  SCHOOLYEAR_CONFIG_GET_LIST_ERROR,
  SCHOOLYEAR_CONFIG_GET_ONE,
  SCHOOLYEAR_CONFIG_GET_ONE_SUCCESS,
  SCHOOLYEAR_CONFIG_GET_ONE_ERROR,
  SCHOOLYEAR_CONFIG_GET_BY_FILTER,
  SCHOOLYEAR_CONFIG_GET_BY_FILTER_SUCCESS,
  SCHOOLYEAR_CONFIG_GET_BY_FILTER_ERROR,
  SCHOOLYEAR_CONFIG_ADD_ONE,
  SCHOOLYEAR_CONFIG_ADD_ONE_SUCCESS,
  SCHOOLYEAR_CONFIG_ADD_ONE_ERROR,
  SCHOOLYEAR_CONFIG_EDIT_ONE,
  SCHOOLYEAR_CONFIG_EDIT_ONE_SUCCESS,
  SCHOOLYEAR_CONFIG_EDIT_ONE_ERROR,
  SCHOOLYEAR_CONFIG_DELETE_ONE,
  SCHOOLYEAR_CONFIG_DELETE_ONE_SUCCESS,
  SCHOOLYEAR_CONFIG_DELETE_ONE_ERROR,
  SCHOOLYEAR_CONFIG_DELETE_MANY,
  SCHOOLYEAR_CONFIG_DELETE_MANY_SUCCESS,
  SCHOOLYEAR_CONFIG_DELETE_MANY_ERROR,
} from '../../actions';

// GET LIST
export const getSchoolYearsConfigList = (payload) => {
  return (dispatch) => {
    console.log('ttt');
  dispatch({
    type: SCHOOLYEAR_CONFIG_GET_LIST,
    payload
  });
}
};
export const getSchoolYearsConfigListSuccess = (payload) => ({
  type: SCHOOLYEAR_CONFIG_GET_LIST_SUCCESS,
  payload
});
export const getSchoolYearsConfigListError = (payload) => ({
  type: SCHOOLYEAR_CONFIG_GET_LIST_ERROR,
  payload
});







// GET ONE
export const getOneSchoolYearConfig = (payload) => ({
  type: SCHOOLYEAR_CONFIG_GET_ONE,
  payload
});
export const getOneSchoolYearConfigSuccess = (payload) => ({
  type: SCHOOLYEAR_CONFIG_GET_ONE_SUCCESS,
  payload
});
export const getOneSchoolYearConfigError = (payload) => ({
  type: SCHOOLYEAR_CONFIG_GET_ONE_ERROR,
  payload
});


// GET SCHOOL YEAR BY FILTER

export const getSchoolYearsConfigByFilter = (payload) => ({
  type: SCHOOLYEAR_CONFIG_GET_BY_FILTER,
  payload
});
export const getSchoolYearsConfigByFilterSuccess = (payload) => ({
  type: SCHOOLYEAR_CONFIG_GET_BY_FILTER_SUCCESS,
  payload
});
export const getSchoolYearsConfigByFilterError = (payload) => ({
  type: SCHOOLYEAR_CONFIG_GET_BY_FILTER_ERROR,
  payload
});
export const getSchoolYearsConfigByFilterCB = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: SCHOOLYEAR_CONFIG_GET_BY_FILTER,
    });

    SchoolYearSrv.getListConfig(payload)
      .then((response) => {
        if (response && response?.data) {
          const resp = {
            school_years: response?.data?.school_years,
            count: response.data.count
          }

          dispatch({
            type: SCHOOLYEAR_CONFIG_GET_BY_FILTER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: SCHOOLYEAR_CONFIG_GET_BY_FILTER_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};





// ADD ONE
export const addSchoolYearConfig = (payload) => ({
  type: SCHOOLYEAR_CONFIG_ADD_ONE,
  payload
});
export const addSchoolYearConfigSuccess = (payload) => ({
  type: SCHOOLYEAR_CONFIG_ADD_ONE_SUCCESS,
  payload
});
export const addSchoolYearConfigError = (payload) => ({
  type: SCHOOLYEAR_CONFIG_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editSchoolYearConfig = (payload) => ({
  type: SCHOOLYEAR_CONFIG_EDIT_ONE,
  payload
});
export const editSchoolYearConfigSuccess = (payload) => ({
  type: SCHOOLYEAR_CONFIG_EDIT_ONE_SUCCESS,
  payload
});
export const editSchoolYearConfigError = (payload) => ({
  type: SCHOOLYEAR_CONFIG_EDIT_ONE_ERROR,
  payload
});


// DELETE ONE
export const deleteOneSchoolYearConfig = (payload) => ({
  type: SCHOOLYEAR_CONFIG_DELETE_ONE,
  payload
});
export const deleteOneSchoolYearConfigSuccess = (payload) => ({
  type: SCHOOLYEAR_CONFIG_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneSchoolYearConfigError = (payload) => ({
  type: SCHOOLYEAR_CONFIG_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManySchoolYearsConfig = (payload) => ({
  type: SCHOOLYEAR_CONFIG_DELETE_MANY,
  payload
});
export const deleteManySchoolYearsConfigSuccess = (payload) => ({
  type: SCHOOLYEAR_CONFIG_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManySchoolYearsConfigError = (payload) => ({
  type: SCHOOLYEAR_CONFIG_DELETE_MANY_ERROR,
  payload
});