export const INSTITUTION_GET_LIST = "INSTITUTION_GET_LIST";
export const INSTITUTION_GET_LIST_SUCCESS = "INSTITUTION_GET_LIST_SUCCESS";
export const INSTITUTION_GET_LIST_ERROR = "INSTITUTION_GET_LIST_ERROR";

export const INSTITUTION_GET_MY_LIST = "INSTITUTION_GET_MY_LIST";
export const INSTITUTION_GET_MY_LIST_SUCCESS = "INSTITUTION_GET_MY_LIST_SUCCESS";
export const INSTITUTION_GET_MY_LIST_ERROR = "INSTITUTION_GET_MY_LIST_ERROR";

export const INSTITUTION_GET_ITEM = "INSTITUTION_GET_ITEM";
export const INSTITUTION_GET_ITEM_SUCCESS = "INSTITUTION_GET_ITEM_SUCCESS";
export const INSTITUTION_GET_ITEM_ERROR = "INSTITUTION_GET_ITEM_ERROR";


export const INSTITUTION_GET_BY_FILTER = "INSTITUTION_GET_BY_FILTER";
export const INSTITUTION_GET_BY_FILTER_SUCCESS = "INSTITUTION_GET_BY_FILTER_SUCCESS";
export const INSTITUTION_GET_BY_FILTER_ERROR = "INSTITUTION_GET_BY_FILTER_ERROR";

export const INSTITUTION_SELECT_MY_INST = "INSTITUTION_SELECT_MY_INST";
export const POST_INSTITUTION_SELECT_MY_INST = "POST_INSTITUTION_SELECT_MY_INST";
export const INSTITUTION_CHANGE_SECTION = "INSTITUTION_CHANGE_SECTION";

export const INSTITUTION_ACTIVATE_ITEM = "INSTITUTION_ACTIVATE_ITEM";
export const INSTITUTION_ACTIVATE_ITEM_SUCCESS = "INSTITUTION_ACTIVATE_ITEM_SUCCESS";
export const INSTITUTION_ACTIVATE_ITEM_ERROR = "INSTITUTION_ACTIVATE_ITEM_ERROR";

export const INSTITUTION_ADD_ITEM = "INSTITUTION_ADD_ITEM";
export const INSTITUTION_ADD_ITEM_SUCCESS = "INSTITUTION_ADD_ITEM_SUCCESS";
export const INSTITUTION_ADD_ITEM_ERROR = "INSTITUTION_ADD_ITEM_ERROR";

export const INSTITUTION_GET_LIST_WITH_FILTER = "INSTITUTION_GET_LIST_WITH_FILTER";
export const INSTITUTION_GET_LIST_WITH_ORDER = "INSTITUTION_GET_LIST_WITH_ORDER";
export const INSTITUTION_GET_LIST_SEARCH = "INSTITUTION_GET_LIST_SEARCH";

export const INSTITUTION_SELECTED_ITEMS_CHANGE = "INSTITUTION_SELECTED_ITEMS_CHANGE";

export const INSTITUTION_DELETE_ONE = "INSTITUTION_DELETE_ONE";
export const INSTITUTION_DELETE_ONE_SUCCESS = "INSTITUTION_DELETE_ONE_SUCCESS";
export const INSTITUTION_DELETE_ONE_ERROR = "INSTITUTION_DELETE_ONE_ERROR";

export const INSTITUTION_EDIT_ONE = "INSTITUTION_EDIT_ONE";
export const INSTITUTION_EDIT_ONE_SUCCESS = "INSTITUTION_EDIT_ONE_SUCCESS";
export const INSTITUTION_EDIT_ONE_ERROR = "INSTITUTION_EDIT_ONE_ERROR";

export const INSTITUTION_SUBSCRIBE = "INSTITUTION_SUBSCRIBE";
export const INSTITUTION_SUBSCRIBE_SUCCESS = "INSTITUTION_SUBSCRIBE_SUCCESS";
export const INSTITUTION_SUBSCRIBE_ERROR = "INSTITUTION_SUBSCRIBE_ERROR";

export const  INSTITUTION_RECOMMAND="INSTITUTION_RECOMMAND";
export const  INSTITUTION_RECOMMAND_SUCCESS="INSTITUTION_RECOMMAND_SUCCESS";
export const  INSTITUTION_RECOMMAND_ERROR="INSTITUTION_RECOMMAND_ERROR";

