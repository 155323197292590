import {
  GROUP_GET_LIST,
  GROUP_GET_LIST_SUCCESS,
  GROUP_GET_LIST_ERROR,

  GROUP_GET_MY_LIST,
  GROUP_GET_MY_LIST_SUCCESS,
  GROUP_GET_MY_LIST_ERROR,

  GROUP_GET_PARTNER_NETWORK,

  GROUP_SUBSCRIBE,
  GROUP_SUBSCRIBE_SUCCESS,
  GROUP_SUBSCRIBE_ERROR,

  GROUP_GET_ONE,
  GROUP_GET_ONE_SUCCESS,
  GROUP_GET_ONE_ERROR,

  GROUP_ADD_ONE,
  GROUP_ADD_ONE_SUCCESS,
  GROUP_ADD_ONE_ERROR,

  GROUP_EDIT_ONE,
  GROUP_EDIT_ONE_SUCCESS,
  GROUP_EDIT_ONE_ERROR,

  GROUP_DELETE_ONE,
  GROUP_DELETE_ONE_SUCCESS,
  GROUP_DELETE_ONE_ERROR,
  GROUP_GET_JOINED
} from '../actions';

// GET LIST
export const getGroupsList = (payload) => ({
  type: GROUP_GET_LIST,
  payload
});
export const getGroupsListSuccess = (payload) => ({
  type: GROUP_GET_LIST_SUCCESS,
  payload
});
export const getGroupsListError = (payload) => ({
  type: GROUP_GET_LIST_ERROR,
  payload
});

// GET MY GROUPS LIST
export const getMyGroupsList = (payload) => ({
  type: GROUP_GET_MY_LIST,
  payload
});
export const getMyGroupsListSuccess = (payload) => ({
  type: GROUP_GET_MY_LIST_SUCCESS,
  payload
});
export const getMyGroupsListError = (payload) => ({
  type: GROUP_GET_MY_LIST_ERROR,
  payload
});



// GET LIST
export const getGroupsListPartner = (payload) => ({
  type: GROUP_GET_PARTNER_NETWORK,
  payload
});
export const getJoinedGroups = (payload) => ({
  type: GROUP_GET_JOINED,
  payload
});







// GET LIST
export const getOneGroup = (payload) => ({
  type: GROUP_GET_ONE,
  payload
});
export const getOneGroupSuccess = (payload) => ({
  type: GROUP_GET_ONE_SUCCESS,
  payload
});
export const getOneGroupError = (payload) => ({
  type: GROUP_GET_ONE_ERROR,
  payload
});


// TOGGLE SUBSCRIBE
export const subscribeGroup = (payload) => ({
  type: GROUP_SUBSCRIBE,
  payload
});
export const subscribeGroupSuccess = (payload) => ({
  type: GROUP_SUBSCRIBE_SUCCESS,
  payload
});
export const subscribeGroupError = (payload) => ({
  type: GROUP_SUBSCRIBE_ERROR,
  payload
});





// ADD ONE
export const addGroup = (payload) => ({
  type: GROUP_ADD_ONE,
  payload
});
export const addGroupSuccess = (payload) => ({
  type: GROUP_ADD_ONE_SUCCESS,
  payload
});
export const addGroupError = (payload) => ({
  type: GROUP_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editGroup = (payload) => ({
  type: GROUP_EDIT_ONE,
  payload
});
export const editGroupSuccess = (payload) => ({
  type: GROUP_EDIT_ONE_SUCCESS,
  payload
});
export const editGroupError = (payload) => ({
  type: GROUP_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneGroup = (payload) => ({
  type: GROUP_DELETE_ONE,
  payload
});
export const deleteOneGroupSuccess = (payload) => ({
  type: GROUP_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneGroupError = (payload) => ({
  type: GROUP_DELETE_ONE_ERROR,
  payload
});