import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  EVENT_GET_LIST, EVENT_SUBSCRIBE, EVENT_GET_PARTNER_NETWORK, EVENT_GET_SUBSCRIBED, EVENT_ADD_ONE, EVENT_GET_ONE, EVENT_EDIT_ONE, 
  EVENT_DELETE_ONE, EVENT_GET_MY_LIST, SUBSCRIPTION_ACCEPT, EVENT_GET_LIVE
} from "../actions";

import {
  subscribeEventSuccess,
  subscribeEventError,

  getEventsListSuccess,
  getEventsListError,

  getMyEventsListSuccess,
  getMyEventsListError,

  getOneEventSuccess,
  getOneEventError,

  addEventSuccess,
  addEventError,

  editEventSuccess,
  editEventError,
  deleteOneEventSuccess,
  deleteOneEventError,

  acceptSubscriptionSuccess,
  acceptSubscriptionError,

  getLiveEventsSuccess,
  getLiveEventsError

} from "./actions";


//GET LIST
const getEventsListRequest = async (payload) => {
    return axios.get(api.events + `/list/${payload.inst_id ? payload.inst_id + '/' : ''}${payload.offset}/${payload.limit}?searchInput=${payload.searchInput || ''}&date=${payload.date || ''}&city=${payload.city || ''}&${payload.location || ''}=true`,
    {
      headers: {
        Authorization: payload.token
      }
    })
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
};
function* getEventsList({ payload }) {
  try {
    const ret = yield call(getEventsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getEventsListSuccess({ events: ret.data.events || [], count: ret.data.count || 0 }));

    else yield put(getEventsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getEventsListError({ error, message: (error) ? error.message : '' }));
  }
}


const getMyEventsListRequest = async (payload) => {

  return axios.get(api.events + `/me/${payload.offset}/${payload.limit}?partner=${payload.partner || ''}&institution=${payload.institution || ''}&searchInput=${payload.searchInput || ''}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getMyEvents({ payload }) {
  try {
    const ret = yield call(getMyEventsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getMyEventsListSuccess({ events: ret.data.events || [], count: ret.data.count || 0 }));

    else yield put(getMyEventsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getMyEventsListError({ error, message: (error) ? error.message : '' }));
  }
}


//PARTNER NETWORK EVENTS
const getEventsListPartnerRequest = async (payload) => {
  if (payload.partner_id)
    return axios.get(api.events + `/partner-network/${payload.partner_id ? payload.partner_id + '/' : ''}${payload.offset}/${payload.limit}?searchInput=${payload.searchInput || ''}`)
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
};
function* getEventsListPartner({ payload }) {
  try {
    const ret = yield call(getEventsListPartnerRequest, payload);

    if (ret && ret.status === 200)
      yield put(getEventsListSuccess({ events: ret.data.events || [], count: ret.data.count || 0 }));

    else yield put(getEventsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getEventsListError({ error, message: (error) ? error.message : '' }));
  }
}

//PARTNER NETWORK EVENTS
const getSubscribedEventsRequest = async (payload) => {
  if (payload.partner_id)
    return await axios.get(api.events + `/subscribed/${payload.partner_id}`)
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
};
function* getSubscribedEvents({ payload }) {
  try {
    const ret = yield call(getSubscribedEventsRequest, payload);

    if (ret && ret.status === 200)
      yield put(getEventsListSuccess({ events: ret.data.events || [], count: ret.data.count || 0 }));

    else yield put(getEventsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getEventsListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOneEventsRequest = async (payload) => {
  return await axios.get(api.events + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneEvent({ payload }) {
  try {
    const ret = yield call(getOneEventsRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneEventSuccess({ event: ret.data.event }));

    else yield put(getOneEventError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneEventError({ error, message: (error) ? error.message : '' }));
  }
}



//SUBSCRIBE
const subscribeEventRequest = async (payload) => {
  return await axios.post(api.events + `/subscribe/${payload.event_id}`, {
    partner: payload.partner ? payload.partner : null,
    institution: payload.institution ? payload.institution : null,
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* subscribeEvent({ payload }) {
  try {
    const ret = yield call(subscribeEventRequest, payload);

    if (ret && ret.status === 200) {
      const events = payload.events
      const event = ret.data.event
      if (events && events[payload.index]) events[payload.index] = event
      yield put(subscribeEventSuccess({ index: payload.index, events, event }));
    }

    else yield put(subscribeEventError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(subscribeEventError({ error, message: (error) ? error.message : '' }));
  }
}




//ADD ONE
const addEventRequest = async (payload) => {
  const data = payload.data

  const frm = new FormData();
  frm.append("name", data.name)
  frm.append("cover", data.cover)
  frm.append("type", data.type)
  frm.append("date_start", data.date_start)
  frm.append("date_end", data.date_end)
  frm.append("deadline_subscription", data.deadline_subscription)
  frm.append("program", data.program)
  frm.append("desc", data.desc)
  frm.append("is_public", data.is_public)
  frm.append("is_online", data.is_online)
  frm.append("is_hybrid", data.is_hybrid)
  frm.append("is_presential", data.is_presential)
  if(data.address)frm.append("address", data.address)
  if(data.link)frm.append("link", data.link)

  if (data.user && data.user !== "") frm.append("user", data.user)
  if (data.inst_id && data.inst_id !== "") frm.append("inst_id", data.inst_id)
  if (data.partner && data.partner !== "") frm.append("partner", data.partner)

  return await axios.post(api.events, frm, {
    headers: {
      Authorization: payload.token, 'Content-Type': 'multipart/form-data',
    }
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addEvent({ payload }) {
  try {
    const ret = yield call(addEventRequest, payload);

    if (ret && ret.status === 201) {
      const { history, route } = payload;
      if (history) history.push(route)
      yield put(addEventSuccess({ event: ret.data.event }));
    }
    else yield put(addEventError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addEventError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneEventRequest = async (payload) => {
  const { data } = payload;

  const frm = new FormData();
  frm.append("name", data.name)
  frm.append("cover", data.cover)
  frm.append("type", data.type)
  frm.append("date_start", data.date_start)
  frm.append("date_end", data.date_end)
  frm.append("deadline_subscription", data.deadline_subscription)
  frm.append("program", data.program)
  frm.append("desc", data.desc)
  frm.append("is_public", data.is_public)
  frm.append("is_online", data.is_online)
  frm.append("is_hybrid", data.is_hybrid)
  frm.append("is_presential", data.is_presential)
  if(data.address)frm.append("address", data.address)
  if(data.city)frm.append("city", data.city)
  if(data.link)frm.append("link", data.link)

  if (data.user && data.user !== "") frm.append("user", data.user)
  if (data.inst_id && data.inst_id !== "") frm.append("inst_id", data.inst_id)

  return await axios.patch(`${api.events}/edit-one/${payload._id}`, frm, {
    headers: {
      Authorization: payload.token, 'Content-Type': 'multipart/form-data',
    }
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneEvent({ payload }) {
  try {
    const ret = yield call(editOneEventRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;
      if (history) history.push('/institution/events')
      yield put(editEventSuccess({ event: ret.data.event }));
    }
    else yield put(editEventError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {

    yield put(editEventError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneEventRequest = async (payload) => {
  return await axios.delete(`${api.events}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneEvent({ payload }) {
  try {
    const ret = yield call(deleteOneEventRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/my-institutions/events')
      yield put(deleteOneEventSuccess({ index }));
    }
    else yield put(deleteOneEventError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneEventError({ error, message: (error) ? error.message : '' }));
  }
}

// ACCEPT SUBSCRIPTION
const acceptSubscriptionRequest = async (payload) => {
  return await axios.post(`${api.events}/change-status/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* acceptSubscription({ payload }) {
  try {
    const ret = yield call(acceptSubscriptionRequest, payload);

    if (ret && ret.status === 200) {

      yield put(acceptSubscriptionSuccess({ event: ret.data.event }));
    }
    else yield put(acceptSubscriptionError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(acceptSubscriptionError({ error, message: (error) ? error.message : '' }));
  }

}

//GET LIVE EVENTS
const getLiveEventsRequest = async (payload) => {
  return axios.get(api.events + `/live`,{
    headers: {
      Authorization: payload.token
    }
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getLiveEvents({ payload }) {
try {
  const ret = yield call(getLiveEventsRequest, payload);

  if (ret && ret.status === 200)
    yield put(getLiveEventsSuccess({ events: ret.data.events || []}));

  else yield put(getLiveEventsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

} catch (error) {
  yield put(getLiveEventsError({ error, message: (error) ? error.message : '' }));
}
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(EVENT_GET_LIST, getEventsList);
}

export function* watchgetMyEvents() {
  yield takeEvery(EVENT_GET_MY_LIST, getMyEvents);
}

export function* watchgetLiveEvents() {
  yield takeEvery(EVENT_GET_LIVE, getLiveEvents);
}

export function* watchSubscribe() {
  yield takeEvery(EVENT_SUBSCRIBE, subscribeEvent);
}

export function* watchAccept() {
  yield takeEvery(SUBSCRIPTION_ACCEPT, acceptSubscription);
}

export function* watchGetListPartner() {
  yield takeEvery(EVENT_GET_PARTNER_NETWORK, getEventsListPartner);
}
export function* watchGetSubscribedEvents() {
  yield takeEvery(EVENT_GET_SUBSCRIBED, getSubscribedEvents);
}

export function* watchGetOne() {
  yield takeEvery(EVENT_GET_ONE, getOneEvent);
}

export function* watchEditOne() {
  yield takeEvery(EVENT_EDIT_ONE, editOneEvent);
}

export function* watchAddOne() {
  yield takeEvery(EVENT_ADD_ONE, addEvent);
}

export function* watchDeleteOne() {
  yield takeEvery(EVENT_DELETE_ONE, deleteOneEvent);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchgetMyEvents),
    fork(watchSubscribe),
    fork(watchGetListPartner),
    fork(watchGetSubscribedEvents),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchAccept),
    fork(watchgetLiveEvents)
  ]);
}
