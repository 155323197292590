import {
  EXAMPERIODS_GET_LIST,
  EXAMPERIODS_GET_LIST_SUCCESS,
  EXAMPERIODS_GET_LIST_ERROR,

  EXAMPERIODS_GET_ONE,
  EXAMPERIODS_GET_ONE_SUCCESS,
  EXAMPERIODS_GET_ONE_ERROR,

  EXAMPERIODS_GET_BY_FILTER,
  EXAMPERIODS_GET_BY_FILTER_SUCCESS,
  EXAMPERIODS_GET_BY_FILTER_ERROR,

  EXAMPERIODS_ADD_ONE,
  EXAMPERIODS_ADD_ONE_SUCCESS,
  EXAMPERIODS_ADD_ONE_ERROR,

  EXAMPERIODS_EDIT_ONE,
  EXAMPERIODS_EDIT_ONE_SUCCESS,
  EXAMPERIODS_EDIT_ONE_ERROR,

  EXAMPERIODS_DELETE_ONE,
  EXAMPERIODS_DELETE_ONE_SUCCESS,
  EXAMPERIODS_DELETE_ONE_ERROR,

  EXAMPERIODS_DELETE_MANY,
  EXAMPERIODS_DELETE_MANY_SUCCESS,
  EXAMPERIODS_DELETE_MANY_ERROR,



} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'

const INIT_STATE = {
  exam_periods: [],
  count: 0,
  exam_period: null,
  error: '',
};
export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

  switch (action.type) {
    //GET LIST
    case EXAMPERIODS_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case EXAMPERIODS_GET_LIST_SUCCESS:

      return {
        ...state, loading: false,
        exam_periods: action.payload.exam_periods,
        count: action.payload.count || 0

      };

    case EXAMPERIODS_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET ONE
    case EXAMPERIODS_GET_ONE:
      return { ...state, loading: true, error: '' };
    case EXAMPERIODS_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        exam_period: action.payload.exam_period
      };
    case EXAMPERIODS_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET BY FILTER
    case EXAMPERIODS_GET_BY_FILTER:

      return { ...state, loading: true, error: '' };
    case EXAMPERIODS_GET_BY_FILTER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        exam_periods: action.payload.exam_periods || [], count: action.payload.count || 0
      };
    case EXAMPERIODS_GET_BY_FILTER_ERROR:

      return { ...state, loading: false, error: action.payload.message };


    // ADD ONE
    case EXAMPERIODS_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case EXAMPERIODS_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        exam_periods: [action.payload.exam_period, ...state.exam_periods]
      };
    case EXAMPERIODS_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // EDIT ONE
    case EXAMPERIODS_EDIT_ONE:
      return { ...state, loading: true, edit_sucess: false, error: '' };
    case EXAMPERIODS_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, edit_sucess: true, error: '',
        exam_period: action.payload.exam_period
      };
    case EXAMPERIODS_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, edit_sucess: false, error: action.payload.message };


    // DELETE ONE
    case EXAMPERIODS_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case EXAMPERIODS_DELETE_ONE_SUCCESS:
      const exam_periods = state.exam_periods
      if (action.payload.index !== null && action.payload.index !== undefined)
        exam_periods.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        exam_periods
      };
    case EXAMPERIODS_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // DELETE MANY
    case EXAMPERIODS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case EXAMPERIODS_DELETE_MANY_SUCCESS:
      const exam_periods_list = state.exam_periods


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_exam_period = -1
          for (let j = 0; j < exam_periods_list.length; j++) {
            if (exam_periods_list[j]._id === action.payload.data.ids[i]) {
              index_exam_period = j; break;
            }
          }
          if (index_exam_period > -1) exam_periods_list.splice(index_exam_period, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        exam_periods: exam_periods_list
      };
    case EXAMPERIODS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    default: return { ...state };






  }
}