
import {
    NOTIFICATION_GET_LIST,
    NOTIFICATION_GET_LIST_ERROR,
    NOTIFICATION_GET_LIST_SUCCESS,
    NOTIFICATION_GET_ONE,
    NOTIFICATION_GET_ONE_ERROR,
    NOTIFICATION_GET_ONE_SUCCESS
} from './actionTypes'




// GET LIST
export const getNotificationsList = (payload) => ({
    type: NOTIFICATION_GET_LIST,
    payload
  });
  export const getNotificationsListSuccess = (payload) => ({
    type: NOTIFICATION_GET_LIST_SUCCESS,
    payload
  });
  export const getNotificationsListError = (payload) => ({
    type: NOTIFICATION_GET_LIST_ERROR,
    payload
  });
  
  
  
  
  
  
  
  // GET ONE
  export const getOneNotification = (payload) => ({
    type: NOTIFICATION_GET_ONE,
    payload
  });
  export const getOneNotificationSuccess = (payload) => ({
    type: NOTIFICATION_GET_ONE_SUCCESS,
    payload
  });
  export const getOneNotificationError = (payload) => ({
    type: NOTIFICATION_GET_ONE_ERROR,
    payload
  });
  
  