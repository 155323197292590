import {
  TAX_GET_LIST,
  TAX_GET_LIST_SUCCESS,
  TAX_GET_LIST_ERROR,

  TAX_GET_ONE,
  TAX_GET_ONE_SUCCESS,
  TAX_GET_ONE_ERROR,

  TAX_GET_BY_FILTER,
  TAX_GET_BY_FILTER_SUCCESS,
  TAX_GET_BY_FILTER_ERROR,

  TAX_ADD_ONE,
  TAX_ADD_ONE_SUCCESS,
  TAX_ADD_ONE_ERROR,

  TAX_EDIT_ONE,
  TAX_EDIT_ONE_SUCCESS,
  TAX_EDIT_ONE_ERROR,

  TAX_DELETE_ONE,
  TAX_DELETE_ONE_SUCCESS,
  TAX_DELETE_ONE_ERROR,

  TAXES_DELETE_MANY,
  TAXES_DELETE_MANY_SUCCESS,
  TAXES_DELETE_MANY_ERROR
} from '../../actions';

// GET LIST
export const getTaxesList = (payload) => ({
  type: TAX_GET_LIST,
  payload
});
export const getTaxesListSuccess = (payload) => ({
  type: TAX_GET_LIST_SUCCESS,
  payload
});
export const getTaxesListError = (payload) => ({
  type: TAX_GET_LIST_ERROR,
  payload
});




// GET ONE
export const getOneTax = (payload) => ({
  type: TAX_GET_ONE,
  payload
});
export const getOneTaxSuccess = (payload) => ({
  type: TAX_GET_ONE_SUCCESS,
  payload
});
export const getOneTaxError = (payload) => ({
  type: TAX_GET_ONE_ERROR,
  payload
});

// GET bY Filter
export const getTaxesByFilter = (payload) => ({
  type: TAX_GET_BY_FILTER,
  payload
});
export const getTaxesByFilterSuccess = (payload) => ({
  type: TAX_GET_BY_FILTER_SUCCESS,
  payload
});
export const getTaxesByFilterError = (payload) => ({
  type: TAX_GET_BY_FILTER_ERROR,
  payload
});

// ADD ONE
export const addTax = (payload) => ({
  type: TAX_ADD_ONE,
  payload
});
export const addTaxSuccess = (payload) => ({
  type: TAX_ADD_ONE_SUCCESS,
  payload
});
export const addTaxError = (payload) => ({
  type: TAX_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editTax = (payload) => ({
  type: TAX_EDIT_ONE,
  payload
});
export const editTaxSuccess = (payload) => ({
  type: TAX_EDIT_ONE_SUCCESS,
  payload
});
export const editTaxError = (payload) => ({
  type: TAX_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneTax = (payload) => ({
  type: TAX_DELETE_ONE,
  payload
});
export const deleteOneTaxSuccess = (payload) => ({
  type: TAX_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneTaxError = (payload) => ({
  type: TAX_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyTaxes = (payload) => ({
  type: TAXES_DELETE_MANY,
  payload
});
export const deleteManyTaxesSuccess = (payload) => ({
  type: TAXES_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyTaxesError = (payload) => ({
  type: TAXES_DELETE_MANY_ERROR,
  payload
});