import ClassSrv from '../../../services/ClassSrv';
import {
  CLASS_CONFIG_GET_LIST,
  CLASS_CONFIG_GET_LIST_SUCCESS,
  CLASS_CONFIG_GET_LIST_ERROR,

  CLASS_CONFIG_GET_ONE,
  CLASS_CONFIG_GET_ONE_SUCCESS,
  CLASS_CONFIG_GET_ONE_ERROR,

  CLASS_CONFIG_GET_BY_FILTER,
  CLASS_CONFIG_GET_BY_FILTER_SUCCESS,
  CLASS_CONFIG_GET_BY_FILTER_ERROR,

  CLASS_CONFIG_ADD_ONE,
  CLASS_CONFIG_ADD_ONE_SUCCESS,
  CLASS_CONFIG_ADD_ONE_ERROR,

  CLASS_CONFIG_EDIT_ONE,
  CLASS_CONFIG_EDIT_ONE_SUCCESS,
  CLASS_CONFIG_EDIT_ONE_ERROR,

  CLASS_CONFIG_DELETE_ONE,
  CLASS_CONFIG_DELETE_ONE_SUCCESS,
  CLASS_CONFIG_DELETE_ONE_ERROR,

  CLASSES_CONFIG_DELETE_MANY,
  CLASSES_CONFIG_DELETE_MANY_SUCCESS,
  CLASSES_CONFIG_DELETE_MANY_ERROR,

} from '../../actions';

// GET LIST
export const getConfigClassesList = (payload) => ({
  type: CLASS_CONFIG_GET_LIST,
  payload
});
export const getConfigClassesListSuccess = (payload) => ({
  type: CLASS_CONFIG_GET_LIST_SUCCESS,
  payload
});
export const getConfigClassesListError = (payload) => ({
  type: CLASS_CONFIG_GET_LIST_ERROR,
  payload
});


// GET ONE
export const getOneClassConfig = (payload) => ({
  type: CLASS_CONFIG_GET_ONE,
  payload
});
export const getOneClassConfigSuccess = (payload) => ({
  type: CLASS_CONFIG_GET_ONE_SUCCESS,
  payload
});
export const getOneClassConfigError = (payload) => ({
  type: CLASS_CONFIG_GET_ONE_ERROR,
  payload
});




// GET CLASS BY FILTER
export const getClassesConfigByFilter = (payload) => ({
  type: CLASS_CONFIG_GET_BY_FILTER,
  payload
});
export const getClassesConfigByFilterSuccess = (payload) => ({
  type: CLASS_CONFIG_GET_BY_FILTER_SUCCESS,
  payload
});
export const getClassesConfigByFilterError = (payload) => ({
  type: CLASS_CONFIG_GET_BY_FILTER_ERROR,
  payload
});
export const getClassesConfigByFilterCB = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CLASS_CONFIG_GET_BY_FILTER,
    });

    ClassSrv.getListConfig(payload)
      .then((response) => {
        if (response && response?.data) {
          const resp = {
            classes: response?.data?.classes,
            count: response.data.count
          }

          dispatch({
            type: CLASS_CONFIG_GET_BY_FILTER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CLASS_CONFIG_GET_BY_FILTER_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};





// ADD ONE
export const addClassConfig = (payload) => ({
  type: CLASS_CONFIG_ADD_ONE,
  payload
});
export const addClassConfigSuccess = (payload) => ({
  type: CLASS_CONFIG_ADD_ONE_SUCCESS,
  payload
});
export const addClassConfigError = (payload) => ({
  type: CLASS_CONFIG_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editClassConfig = (payload) => ({
  type: CLASS_CONFIG_EDIT_ONE,
  payload
});
export const editClassConfigSuccess = (payload) => ({
  type: CLASS_CONFIG_EDIT_ONE_SUCCESS,
  payload
});
export const editClassConfigError = (payload) => ({
  type: CLASS_CONFIG_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneClassConfig = (payload) => ({
  type: CLASS_CONFIG_DELETE_ONE,
  payload
});
export const deleteOneClassConfigSuccess = (payload) => ({
  type: CLASS_CONFIG_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneClassConfigError = (payload) => ({
  type: CLASS_CONFIG_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyClassesConfig = (payload) => ({
  type: CLASSES_CONFIG_DELETE_MANY,
  payload
});
export const deleteManyClassesConfigSuccess = (payload) => ({
  type: CLASSES_CONFIG_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyClassesConfigError = (payload) => ({
  type: CLASSES_CONFIG_DELETE_MANY_ERROR,
  payload
});