// const { default: axiosApi } = require("helpers/axiosConfig")

import { api } from "../constants/defaultValues";
import axiosApi from "../helpers/axiosConfig";
import { filtersLink } from "../helpers/Utils";

const SubjectSrv = {
  getList: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(
        `${api.subjects}/${payload.inst_id}/filter/${payload.offset}/${payload.limit}?${filtersLink(payload?.params || {})}`,
        payload.filter
      )
        .then(resp => { resolve(resp); })
        .catch(e => reject(e))
    })
  },
}

export default SubjectSrv