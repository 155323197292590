import ClassSrv from '../../services/ClassSrv';
import {
  CLASS_GET_LIST,
  CLASS_GET_LIST_SUCCESS,
  CLASS_GET_LIST_ERROR,

  CLASS_GET_ONE,
  CLASS_GET_ONE_SUCCESS,
  CLASS_GET_ONE_ERROR,

  CLASS_GET_BY_FILTER,
  CLASS_GET_BY_FILTER_SUCCESS,
  CLASS_GET_BY_FILTER_ERROR,

  CLASS_ADD_ONE,
  CLASS_ADD_ONE_SUCCESS,
  CLASS_ADD_ONE_ERROR,

  CLASS_EDIT_ONE,
  CLASS_EDIT_ONE_SUCCESS,
  CLASS_EDIT_ONE_ERROR,

  CLASS_DELETE_ONE,
  CLASS_DELETE_ONE_SUCCESS,
  CLASS_DELETE_ONE_ERROR,

  CLASSES_DELETE_MANY,
  CLASSES_DELETE_MANY_SUCCESS,
  CLASSES_DELETE_MANY_ERROR,

  CLASS_GET_BY_TEACHER,
} from '../actions';

// GET LIST
export const getClassesList = (payload) => ({
  type: CLASS_GET_LIST,
  payload
});
export const getClassesListSuccess = (payload) => ({
  type: CLASS_GET_LIST_SUCCESS,
  payload
});
export const getClassesListError = (payload) => ({
  type: CLASS_GET_LIST_ERROR,
  payload
});


// GET CLASSES BY TEACHER (teacher casses))
export const getClassesByTeacher = (payload) => ({
  type: CLASS_GET_BY_TEACHER,
  payload
});



// GET ONE
export const getOneClass = (payload) => ({
  type: CLASS_GET_ONE,
  payload
});
export const getOneClassSuccess = (payload) => ({
  type: CLASS_GET_ONE_SUCCESS,
  payload
});
export const getOneClassError = (payload) => ({
  type: CLASS_GET_ONE_ERROR,
  payload
});




// GET CLASS BY FILTER
export const getClassesByFilter = (payload) => ({
  type: CLASS_GET_BY_FILTER,
  payload
});
export const getClassesByFilterSuccess = (payload) => ({
  type: CLASS_GET_BY_FILTER_SUCCESS,
  payload
});
export const getClassesByFilterError = (payload) => ({
  type: CLASS_GET_BY_FILTER_ERROR,
  payload
});
export const getClassesByFilterCB = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CLASS_GET_BY_FILTER,
    });

    ClassSrv.getList(payload)
      .then((response) => {
        if (response && response?.data) {
          const resp = {
            classes: response?.data?.classes,
            count: response.data.count
          }

          dispatch({
            type: CLASS_GET_BY_FILTER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CLASS_GET_BY_FILTER_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};





// ADD ONE
export const addClass = (payload) => ({
  type: CLASS_ADD_ONE,
  payload
});
export const addClassSuccess = (payload) => ({
  type: CLASS_ADD_ONE_SUCCESS,
  payload
});
export const addClassError = (payload) => ({
  type: CLASS_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editClass = (payload) => ({
  type: CLASS_EDIT_ONE,
  payload
});
export const editClassSuccess = (payload) => ({
  type: CLASS_EDIT_ONE_SUCCESS,
  payload
});
export const editClassError = (payload) => ({
  type: CLASS_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneClass = (payload) => ({
  type: CLASS_DELETE_ONE,
  payload
});
export const deleteOneClassSuccess = (payload) => ({
  type: CLASS_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneClassError = (payload) => ({
  type: CLASS_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyClasses = (payload) => ({
  type: CLASSES_DELETE_MANY,
  payload
});
export const deleteManyClassesSuccess = (payload) => ({
  type: CLASSES_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyClassesError = (payload) => ({
  type: CLASSES_DELETE_MANY_ERROR,
  payload
});