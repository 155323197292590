import {
  BILL_GET_LIST,
  BILL_GET_LIST_SUCCESS,
  BILL_GET_LIST_ERROR,
  BILL_GET_ONE,
  BILL_GET_ONE_SUCCESS,
  BILL_GET_ONE_ERROR,

  BILL_GET_BY_FILTER,
  BILL_GET_BY_FILTER_SUCCESS,
  BILL_GET_BY_FILTER_ERROR,

  BILL_ADD_ONE,
  BILL_ADD_ONE_SUCCESS,
  BILL_ADD_ONE_ERROR,
  BILL_EDIT_ONE,
  BILL_EDIT_ONE_SUCCESS,
  BILL_EDIT_ONE_ERROR,
  BILL_PRINT,
  BILL_PRINT_SUCCESS,
  BILL_PRINT_ERROR,
  BILL_DELETE_ONE,
  BILL_DELETE_ONE_SUCCESS,
  BILL_DELETE_ONE_ERROR,

  BILLS_DELETE_MANY,
  BILLS_DELETE_MANY_SUCCESS,
  BILLS_DELETE_MANY_ERROR,
} from '../../actions';
import { NotificationManager } from '../../../components/common/react-notifications';
import notificationMessages from '../../../lang/locales/notificationMsg'

const INIT_STATE = {
  bills: [],
  count: 0,
  bill: null,
  error: '',
  print_success: null
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
  switch (action.type) {
    case BILL_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case BILL_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        bills: action.payload.bills || [],
        count: action.payload.count || 0
      };
    case BILL_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    // GET ONE
    case BILL_GET_ONE:
      return { ...state, loading: true, error: '', bill: null };
    case BILL_GET_ONE_SUCCESS:


      return {
        ...state, loading: false, error: '',
        bill: action.payload.bill
      };
    case BILL_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    // GET BY FILTER
    case BILL_GET_BY_FILTER:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case BILL_GET_BY_FILTER_SUCCESS:
      return {
        ...state, loading: false,
        bills: action.payload.bills || [],
        count: action.payload.count || 0
      };
    case BILL_GET_BY_FILTER_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    // ADD ONE
    case BILL_ADD_ONE:
      //console.log('reducer add one');

      return { ...state, loading: true, error: '' };
    case BILL_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        bills: [action.payload.bill, ...state.bills]
      };
    case BILL_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // EDIT ONE
    case BILL_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case BILL_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        bill: action.payload.bill
      };
    case BILL_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // PRINT ONE
    case BILL_PRINT:
      return { ...state, loading: true, print_success: false, error: '' };
    case BILL_PRINT_SUCCESS:
      NotificationManager.success(messages["Print"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '', print_success: true,
        path: action.payload.path
      };
    case BILL_PRINT_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message, print_success: false, };






    // DELETE ONE
    case BILL_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case BILL_DELETE_ONE_SUCCESS:
      const bills = state.bills
      if (action.payload.index !== null && action.payload.index !== undefined)
        bills.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        bills
      };
    case BILL_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    // DELETE MANY
    case BILLS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case BILLS_DELETE_MANY_SUCCESS:
      const bills_list = state.bills


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_bill = -1
          for (let j = 0; j < bills_list.length; j++) {
            if (bills_list[j]._id === action.payload.data.ids[i]) {
              index_bill = j; break;
            }
          }
          if (index_bill > -1) bills_list.splice(index_bill, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        bills: bills_list
      };
    case BILLS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    default: return { ...state };
  }
}
