import {
  APPOINTEMENTS_GET_LIST,
  APPOINTEMENTS_GET_LIST_SUCCESS,
  APPOINTEMENTS_GET_LIST_ERROR,

  APPOINTEMENT_GET_ONE,
  APPOINTEMENT_GET_ONE_SUCCESS,
  APPOINTEMENT_GET_ONE_ERROR,

  APPOINTEMENTS_GET_BY_FILTER,
  APPOINTEMENTS_GET_BY_FILTER_SUCCESS,
  APPOINTEMENTS_GET_BY_FILTER_ERROR,

  APPOINTEMENT_ADD_ONE,
  APPOINTEMENT_ADD_ONE_SUCCESS,
  APPOINTEMENT_ADD_ONE_ERROR,

  APPOINTEMENT_EDIT_ONE,
  APPOINTEMENT_EDIT_ONE_SUCCESS,
  APPOINTEMENT_EDIT_ONE_ERROR,

  APPOINTEMENT_DELETE_ONE,
  APPOINTEMENT_DELETE_ONE_SUCCESS,
  APPOINTEMENT_DELETE_ONE_ERROR,

  APPOINTEMENTS_DELETE_MANY,
  APPOINTEMENTS_DELETE_MANY_SUCCESS,
  APPOINTEMENTS_DELETE_MANY_ERROR,

} from '../actions';

//GET LIST
export const getAppoitementsList = (payload) => ({
  type: APPOINTEMENTS_GET_LIST,
  payload
});
export const getAppoitementsListSuccess = (payload) => ({
  type: APPOINTEMENTS_GET_LIST_SUCCESS,
  payload
});
export const getAppoitementsListError = (payload) => ({
  type: APPOINTEMENTS_GET_LIST_ERROR,
  payload
});

// GET One
export const getOneAppointement = (payload) => ({
  type: APPOINTEMENT_GET_ONE,
  payload
});
export const getOneAppointementSuccess = (payload) => ({
  type: APPOINTEMENT_GET_ONE_SUCCESS,
  payload
});
export const getOneAppointementError = (payload) => ({
  type: APPOINTEMENT_GET_ONE_ERROR,
  payload
});


// GET APPOINTEMENTS BY FILTER

export const getAppointementsByFilter = (payload) => ({
  type: APPOINTEMENTS_GET_BY_FILTER,
  payload
});
export const getAppointementsByFilterSuccess = (payload) => ({
  type: APPOINTEMENTS_GET_BY_FILTER_SUCCESS,
  payload
});
export const getAppointementsByFilterError = (payload) => ({
  type: APPOINTEMENTS_GET_BY_FILTER_ERROR,
  payload
});

// ADD ONE
export const addAppointement = (payload) => ({
  type: APPOINTEMENT_ADD_ONE,
  payload
});
export const addAppointementSuccess = (payload) => ({
  type: APPOINTEMENT_ADD_ONE_SUCCESS,
  payload
});
export const addAppointementError = (payload) => ({
  type: APPOINTEMENT_ADD_ONE_ERROR,
  payload
});

// EDIT ONE
export const editOneAppointement = (payload) => ({
  type: APPOINTEMENT_EDIT_ONE,
  payload
});
export const editOneAppointementSuccess = (payload) => ({
  type: APPOINTEMENT_EDIT_ONE_SUCCESS,
  payload
});
export const editOneAppointementError = (payload) => ({
  type: APPOINTEMENT_EDIT_ONE_ERROR,
  payload
});

// DELETE ONE
export const deleteOneAppointement = (payload) => ({
  type: APPOINTEMENT_DELETE_ONE,
  payload
});
export const deleteOneAppointementSuccess = (payload) => ({
  type: APPOINTEMENT_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneAppointementError = (payload) => ({
  type: APPOINTEMENT_DELETE_ONE_ERROR,
  payload
});
// DELETE MANY
export const deleteManyAppointements = (payload) => ({
  type: APPOINTEMENTS_DELETE_MANY,
  payload
});
export const deleteManyAppointementsSuccess = (payload) => ({
  type: APPOINTEMENTS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyAppointementsError = (payload) => ({
  type: APPOINTEMENTS_DELETE_MANY_ERROR,
  payload
});