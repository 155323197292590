import {
  CONFIGS_SUBJECT_GET_LIST,
  CONFIGS_SUBJECT_LIST_SUCCESS,
  CONFIGS_SUBJECT_LIST_ERROR,

  CONFIGS_SUBJECT_GET_ONE,
  CONFIGS_SUBJECT_GET_ONE_SUCCESS,
  CONFIGS_SUBJECT_GET_ONE_ERROR,

  SUBJECT_GET_BY_FILTER,
  SUBJECT_GET_BY_FILTER_SUCCESS,
  SUBJECT_GET_BY_FILTER_ERROR,

  CONFIGS_SUBJECT_ADD_ONE,
  CONFIGS_SUBJECT_ADD_ONE_SUCCESS,
  CONFIGS_SUBJECT_ADD_ONE_ERROR,

  CONFIGS_SUBJECT_EDIT_ONE,
  CONFIGS_SUBJECT_EDIT_ONE_SUCCESS,
  CONFIGS_SUBJECT_EDIT_ONE_ERROR,

  CONFIGS_SUBJECT_DELETE_ONE,
  CONFIGS_SUBJECT_DELETE_ONE_SUCCESS,
  CONFIGS_SUBJECT_DELETE_ONE_ERROR,
} from '../../actions';

// GET LIST
export const getConfigsSubjectList = (payload) => ({
  type: CONFIGS_SUBJECT_GET_LIST,
  payload
});
export const getConfigsSubjectListSuccess = (payload) => ({
  type: CONFIGS_SUBJECT_LIST_SUCCESS,
  payload
});
export const getConfigsSubjectListError = (payload) => ({
  type: CONFIGS_SUBJECT_LIST_ERROR,
  payload
});


// GET ONE
export const getOneConfigsSubject = (payload) => ({
  type: CONFIGS_SUBJECT_GET_ONE,
  payload
});
export const getOneConfigsSubjectSuccess = (payload) => ({
  type: CONFIGS_SUBJECT_GET_ONE_SUCCESS,
  payload
});
export const getOneConfigsSubjectError = (payload) => ({
  type: CONFIGS_SUBJECT_GET_ONE_ERROR,
  payload
});



// GET CLASS BY FILTER

export const getSubjectsByFilter = (payload) => ({
  type: SUBJECT_GET_BY_FILTER,
  payload
});
export const getSubjectsByFilterSuccess = (payload) => ({
  type: SUBJECT_GET_BY_FILTER_SUCCESS,
  payload
});
export const getSubjectsByFilterError = (payload) => ({
  type: SUBJECT_GET_BY_FILTER_ERROR,
  payload
});


// ADD ONE
export const addConfigsSubject = (payload) => ({
  type: CONFIGS_SUBJECT_ADD_ONE,
  payload
});
export const addConfigsSubjectSuccess = (payload) => ({
  type: CONFIGS_SUBJECT_ADD_ONE_SUCCESS,
  payload
});
export const addConfigsSubjectError = (payload) => ({
  type: CONFIGS_SUBJECT_ADD_ONE_ERROR,
  payload
});

// EDIT ONE
export const editOneConfigsSubject = (payload) => ({
  type: CONFIGS_SUBJECT_EDIT_ONE,
  payload
});
export const editOneConfigsSubjectSuccess = (payload) => ({
  type: CONFIGS_SUBJECT_EDIT_ONE_SUCCESS,
  payload
});
export const editOneConfigsSubjectError = (payload) => ({
  type: CONFIGS_SUBJECT_EDIT_ONE_ERROR,
  payload
});


// DELETE ONE
export const deleteOneConfigsSubject = (payload) => ({
  type: CONFIGS_SUBJECT_DELETE_ONE,
  payload
});
export const deleteOneConfigsSubjectSuccess = (payload) => ({
  type: CONFIGS_SUBJECT_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneConfigsSubjectError = (payload) => ({
  type: CONFIGS_SUBJECT_DELETE_ONE_ERROR,
  payload
});