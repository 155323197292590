import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  ACCOUNT_GET_ONE, SMS_GET_LIST, CHECK_ACCOUNT, ACCOUNT_ADD_ONE, ACCOUNTS_GET_LIST, ACCOUNT_DELETE_ONE,
  ACCOUNT_EDIT_ONE, SYNCHRONIZE_ACCOUNT, SEND_SMS
} from "../actions";

import {

  getAccountSuccess,
  getAccountError,

  checkAccountSuccess,
  checkAccountError,

  getSmsListSuccess,
  getSmsListError,

  addAccountSuccess,
  addAccountError,

  editAccountSuccess,
  editAccountError,

  getAccountsListSuccess,
  getAccountsListError,

  deleteOneAccountSuccess,
  deleteOneAccountError,

  synchronizeAccountSuccess,
  synchronizeAccountError,

  sendSmsError,
  sendSmsSuccess,

} from "./actions";


//GET ACCOUNT LIST 
const getListAccountsRequest = async (payload) => {
  return await axios.get(`${api.root}/sms/accounts/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getAccountsList({ payload }) {
  try {
    const ret = yield call(getListAccountsRequest, payload);
    //console.log("get account : ",ret);

    if (ret && ret.status === 200)

      yield put(getAccountsListSuccess({ accounts: ret.data.accounts }));

    else yield put(getAccountsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getAccountsListError({ error, message: (error) ? error.message : '' }));
  }
}


//DELETE ACCOUNT
const deleteAccountRequest = async (payload) => {
  return await axios.delete(`${api.root}/sms/accounts/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteAccount({ payload }) {
  try {
    const ret = yield call(deleteAccountRequest, payload);

    if (ret && ret.status === 202) {
      yield put(deleteOneAccountSuccess({ index: ret.data }));
    }

    else yield put(deleteOneAccountError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(deleteOneAccountError({ error, message: (error) ? error.message : '' }));
  }
}


//EDIT ACCOUNT
const editAccountRequest = async (payload) => {

  return await axios.patch(`${api.root}/sms/accounts/edit-one/${payload.account_id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editAccount({ payload }) {

  try {

    const ret = yield call(editAccountRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;
      if (history) history.push('/app/community/sms/accounts')
      yield put(editAccountSuccess({ account: ret.data.account }));
    }

    else yield put(editAccountError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(editAccountError({ error, message: (error) ? error.message : '' }));
  }
}


//  SYNCHRONIZE ACCOUNT
const synchronizeAccountRequest = async (payload) => {
  return await axios.get(`${api.root}/sms/accounts/sync-sms/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* synchronizeAccount({ payload }) {
  try {
    const ret = yield call(synchronizeAccountRequest, payload);

    if (ret && ret.status === 200) yield put(synchronizeAccountSuccess({ account: ret.data.account, msgs_count: ret.data.count, messages: ret.data.messages }));

    else yield put(synchronizeAccountError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('error', error);

    yield put(synchronizeAccountError({ error, message: (error) ? error.message : '' }));
  }
}


//ADD ACCOUNT
const addAccountRequest = async (payload) => {
  return await axios.post(`${api.root}/sms/accounts`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addAccount({ payload }) {
  try {
    const ret = yield call(addAccountRequest, payload);
    // console.log("add account : ",ret);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/community/sms/accounts')
      yield put(addAccountSuccess({ account: ret.data.account }));
    }

    else yield put(addAccountError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(addAccountError({ error, message: (error) ? error.message : '' }));
  }
}

//CHECK ACCOUNT
const checkAccountRequest = async (payload) => {
  return await axios.post(`${api.sms_server}/api/signin`, payload)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* checkAccount({ payload }) {
  try {
    const ret = yield call(checkAccountRequest, payload);
    console.log("check account : ", ret);

    if (ret && ret.status === 200) {
      if (ret.data.data.active === 1) {
        yield put(checkAccountSuccess({ account: ret.data.data }));
      }
    }


    //yield put(getAccountSuccess({account: ret.data}));

    else yield put(checkAccountError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(checkAccountError({ error, message: (error) ? error.message : '' }));


  }
}

//GET ONE
const getOneAccountRequest = async (payload) => {
  return await axios.get(`${api.root}/sms/accounts/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneAccount({ payload }) {
  try {
    const ret = yield call(getOneAccountRequest, payload);
    //console.log("get account : ",ret);

    if (ret && ret.status === 200)

      yield put(getAccountSuccess({ account_data: ret.data.account }));

    else yield put(getAccountError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getAccountError({ error, message: (error) ? error.message : '' }));
  }
}


//GET SMS LIST
const getSmsListRequest = async (payload) => {
  return await axios.get(`${api.sms_server}/api/list-sms?user_id=${payload.user_id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getSmsList({ payload }) {
  try {
    const ret = yield call(getSmsListRequest, payload);
    //console.log("get sms list : ",ret.data);

    if (ret && ret.status === 200)

      yield put(getSmsListSuccess({ sms_list: ret.data.list_sms }));

    else yield put(getSmsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))



  } catch (error) {
    yield put(getSmsListError({ error, message: (error) ? error.message : '' }));
  }
}


//SEND SMS
const sendSmsRequest = async (payload) => {

  return await axios.post(`${api.root}/api/sms/send`, payload.data, { headers: { Authorization: payload.token }})
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* sendSms({ payload }) {
  try {
    const ret = yield call(sendSmsRequest, payload);

    if (ret && ret.status === 200)

      yield put(sendSmsSuccess({ data: ret.data }));

    else yield put(sendSmsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))



  } catch (error) {
    yield put(sendSmsError({ error, message: (error) ? error.message : '' }));
  }
}


export function* watchAddAccount() {
  yield takeEvery(ACCOUNT_ADD_ONE, addAccount);
}
export function* watchGetListAccount() {
  yield takeEvery(ACCOUNTS_GET_LIST, getAccountsList);
}
export function* watchGetOneAccount() {
  yield takeEvery(ACCOUNT_GET_ONE, getOneAccount);
}
export function* watchEditAccount() {

  yield takeEvery(ACCOUNT_EDIT_ONE, editAccount);
}
export function* watchSynchronizeAccount() {
  yield takeEvery(SYNCHRONIZE_ACCOUNT, synchronizeAccount);
}
export function* watchGetSmsList() {
  yield takeEvery(SMS_GET_LIST, getSmsList);
}

export function* watchSendSms() {
  yield takeEvery(SEND_SMS, sendSms);
}

export function* watchCheckAccount() {
  yield takeEvery(CHECK_ACCOUNT, checkAccount);
}
export function* watchDeleteAccount() {
  yield takeEvery(ACCOUNT_DELETE_ONE, deleteAccount);
}


export default function* rootSaga() {
  yield all([
    fork(watchGetListAccount),
    fork(watchAddAccount),
    fork(watchEditAccount),
    fork(watchSynchronizeAccount),
    fork(watchCheckAccount),
    fork(watchGetOneAccount),
    fork(watchGetSmsList),
    fork(watchSendSms),
    fork(watchDeleteAccount),
  ]);
}
