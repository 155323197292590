import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../../constants/defaultValues";
import axios from "axios";

import {
  FEE_COMPOSITION_GET_LIST, FEE_COMPOSITION_ADD_ONE, FEE_COMPOSITION_GET_ONE, FEE_COMPOSITION_EDIT_ONE, FEE_COMPOSITION_DELETE_ONE
} from "../../actions";

import {
  getFeeCompositionsListSuccess,
  getFeeCompositionsListError,

  getOneFeeCompositionSuccess,
  getOneFeeCompositionError,

  addFeeCompositionSuccess,
  addFeeCompositionError,

  editFeeCompositionSuccess,
  editFeeCompositionError,

  deleteOneFeeCompositionSuccess,
  deleteOneFeeCompositionError,
} from "./actions";


//GET LIST
const getFeeCompositionsListRequest = async (payload) => {
  return await axios.get(api.fee_compositions + `/list/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* getFeeCompositionsList({ payload }) {
  try {
    const ret = yield call(getFeeCompositionsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getFeeCompositionsListSuccess({ fee_compositions: ret.data.fee_compositions || [], count: ret.data.count || 0 }));

    else yield put(getFeeCompositionsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getFeeCompositionsListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOneFeeCompositionRequest = async (payload) => {
  return await axios.get(api.fee_compositions + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneFeeComposition({ payload }) {
  try {
    const ret = yield call(getOneFeeCompositionRequest, payload);

    if (ret && ret.status === 200) yield put(getOneFeeCompositionSuccess({ fee_composition: ret.data.fee_composition }));
    else yield put(getOneFeeCompositionError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(getOneFeeCompositionError({ error, message: (error) ? error.message : '' }));
  }
}


//ADD ONE
const addFeeCompositionRequest = async (payload) => {

  return await axios.post(api.fee_compositions, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addFeeComposition({ payload }) {
  //console.log('saga add fee-composition',payload);

  try {
    const ret = yield call(addFeeCompositionRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/billing/fee-compositions')
      yield put(addFeeCompositionSuccess({ fee_composition: ret.data.fee_composition }));
    }
    else yield put(addFeeCompositionError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(addFeeCompositionError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneFeeCompositionRequest = async (payload) => {
  return await axios.patch(`${api.fee_compositions}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneFeeComposition({ payload }) {
  try {
    const ret = yield call(editOneFeeCompositionRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/billing/fee-compositions')
      yield put(editFeeCompositionSuccess({ fee_composition: ret.data.fee_composition }));
    }
    else yield put(editFeeCompositionError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(editFeeCompositionError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneFeeCompositionRequest = async (payload) => {
  return await axios.delete(`${api.fee_compositions}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneFeeComposition({ payload }) {
  try {
    const ret = yield call(deleteOneFeeCompositionRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/billing/fee-compositions')
      yield put(deleteOneFeeCompositionSuccess({ index }));
    }
    else yield put(deleteOneFeeCompositionError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(deleteOneFeeCompositionError({ error, message: (error) ? error.message : '' }));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(FEE_COMPOSITION_GET_LIST, getFeeCompositionsList);
}

export function* watchGetOne() {
  yield takeEvery(FEE_COMPOSITION_GET_ONE, getOneFeeComposition);
}

export function* watchEditOne() {
  yield takeEvery(FEE_COMPOSITION_EDIT_ONE, editOneFeeComposition);
}

export function* watchAddOne() {
  yield takeEvery(FEE_COMPOSITION_ADD_ONE, addFeeComposition);
}

export function* watchDeleteOne() {
  yield takeEvery(FEE_COMPOSITION_DELETE_ONE, deleteOneFeeComposition);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
