import {
  SKILL_CONFIG_GET_LIST,
  SKILL_CONFIG_GET_LIST_SUCCESS,
  SKILL_CONFIG_GET_LIST_ERROR,
  SKILL_CONFIG_GET_ONE,
  SKILL_CONFIG_GET_ONE_SUCCESS,
  SKILL_CONFIG_GET_ONE_ERROR,
  SKILL_CONFIG_ADD_ONE,
  SKILL_CONFIG_ADD_ONE_SUCCESS,
  SKILL_CONFIG_ADD_ONE_ERROR,
  SKILL_CONFIG_EDIT_ONE,
  SKILL_CONFIG_EDIT_ONE_SUCCESS,
  SKILL_CONFIG_EDIT_ONE_ERROR,
  SKILL_CONFIG_DELETE_ONE,
  SKILL_CONFIG_DELETE_ONE_SUCCESS,
  SKILL_CONFIG_DELETE_ONE_ERROR
} from '../../actions';

// GET LIST
export const getSkillsConfigList = (payload) => ({
  type: SKILL_CONFIG_GET_LIST,
  payload
});
export const getSkillsConfigListSuccess = (payload) => ({
  type: SKILL_CONFIG_GET_LIST_SUCCESS,
  payload
});
export const getSkillsConfigListError = (payload) => ({
  type: SKILL_CONFIG_GET_LIST_ERROR,
  payload
});







// GET LIST
export const getOneSkillConfig = (payload) => ({
  type: SKILL_CONFIG_GET_ONE,
  payload
});
export const getOneSkillConfigSuccess = (payload) => ({
  type: SKILL_CONFIG_GET_ONE_SUCCESS,
  payload
});
export const getOneSkillConfigError = (payload) => ({
  type: SKILL_CONFIG_GET_ONE_ERROR,
  payload
});








// ADD ONE
export const addSkillConfig = (payload) => ({
  type: SKILL_CONFIG_ADD_ONE,
  payload
});
export const addSkillConfigSuccess = (payload) => ({
  type: SKILL_CONFIG_ADD_ONE_SUCCESS,
  payload
});
export const addSkillConfigError = (payload) => ({
  type: SKILL_CONFIG_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editSkillConfig = (payload) => ({
  type: SKILL_CONFIG_EDIT_ONE,
  payload
});
export const editSkillConfigSuccess = (payload) => ({
  type: SKILL_CONFIG_EDIT_ONE_SUCCESS,
  payload
});
export const editSkillConfigError = (payload) => ({
  type: SKILL_CONFIG_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneSkillConfig = (payload) => ({
  type: SKILL_CONFIG_DELETE_ONE,
  payload
});
export const deleteOneSkillConfigSuccess = (payload) => ({
  type: SKILL_CONFIG_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneSkillConfigError = (payload) => ({
  type: SKILL_CONFIG_DELETE_ONE_ERROR,
  payload
});