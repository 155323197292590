import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../../constants/defaultValues";
import axios from "axios";

import {
  PERIODTYPE_CONFIG_GET_LIST,
  PERIODTYPE_CONFIG_ADD_ONE,
  PERIODTYPE_CONFIG_GET_ONE,
  PERIODTYPE_CONFIG_GET_ACTUEL,
  PERIODTYPE_CONFIG_EDIT_ONE,
  PERIODTYPE_CONFIG_DELETE_ONE, 
  PERIODTYPES_CONFIG_DELETE_MANY
} from "../../actions";

import {
  getPeriodTypesConfigListSuccess,
  getPeriodTypesConfigListError,

  getOnePeriodTypeConfigSuccess,
  getOnePeriodTypeConfigError,

  getPeriodTypeActuelConfigSuccess,
  getPeriodTypeActuelConfigError,

  addPeriodTypeConfigSuccess,
  addPeriodTypeConfigError,

  editPeriodTypeConfigSuccess,
  editPeriodTypeConfigError,
  deleteOnePeriodTypeConfigSuccess,
  deleteOnePeriodTypeConfigError,
  deleteManyPeriodTypesConfigSuccess,
  deleteManyPeriodTypesConfigError
} from "./actions";


//GET LIST
const getPeriodTypesListRequest = async (payload) => {
  return await axios.get(api.period_types + `/list-admin/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getPeriodTypesConfigList({ payload }) {
  try {
    const ret = yield call(getPeriodTypesListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getPeriodTypesConfigListSuccess({ period_types: ret.data.period_types || [], count: ret.data.count || 0 }));

    else yield put(getPeriodTypesConfigListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getPeriodTypesConfigListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOnePeriodTypesRequest = async (payload) => {
  return await axios.get(api.period_types + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOnePeriodTypeConfig({ payload }) {
  try {
    const ret = yield call(getOnePeriodTypesRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOnePeriodTypeConfigSuccess({ period_type: ret.data.period_type }));

    else yield put(getOnePeriodTypeConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOnePeriodTypeConfigError({ error, message: (error) ? error.message : '' }));
  }
}

//GET ACTUEL
const getPeriodTypeActuelRequest = async (payload) => {

  return await axios.get(api.period_types + `/${payload.inst_id}/actual`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getPeriodTypeActuelConfig({ payload }) {
  try {
    const ret = yield call(getPeriodTypeActuelRequest, payload);

    if (ret && ret.status === 200) {
      yield put(getPeriodTypeActuelConfigSuccess({ period_type_actuel: ret.data.period_type, actual_period: ret.data.actual_period }));

    }
    else yield put(getPeriodTypeActuelConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {

    yield put(getPeriodTypeActuelConfigError({ error, message: (error) ? error.message : '' }));
  }
}




//ADD ONE
const addPeriodTypeRequest = async (payload) => {
  return await axios.post(api.period_types, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addPeriodTypeConfig({ payload }) {
  try {
    const ret = yield call(addPeriodTypeRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/admin/settings/period-types/list')
      yield put(addPeriodTypeConfigSuccess({ period_type: ret.data.period_type }));
    }
    else yield put(addPeriodTypeConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addPeriodTypeConfigError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOnePeriodTypeRequest = async (payload) => {
  return await axios.patch(`${api.period_types}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOnePeriodType({ payload }) {
  try {
    const ret = yield call(editOnePeriodTypeRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/admin/settings/period-types/list')
      yield put(editPeriodTypeConfigSuccess({ period_type: ret.data.period_type }));
    }
    else yield put(editPeriodTypeConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editPeriodTypeConfigError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOnePeriodTypeRequest = async (payload) => {
  return await axios.delete(`${api.period_types}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOnePeriodTypeConfig({ payload }) {
  try {
    const ret = yield call(deleteOnePeriodTypeRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/admin/settings/period-types/list')
      yield put(deleteOnePeriodTypeConfigSuccess({ index }));
    }
    else yield put(deleteOnePeriodTypeConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOnePeriodTypeConfigError({ error, message: (error) ? error.message : '' }));
  }
}

//DELETE MANY

const deleteManyPeriodTypesRequest = async (payload) => {
  return await axios.delete(`${api.period_types}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyPeriodTypesConfig({ payload }) {
  try {
    const ret = yield call(deleteManyPeriodTypesRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/admin/settings/period-types/list')
      yield put(deleteManyPeriodTypesConfigSuccess({ data }));
    }
    else yield put(deleteManyPeriodTypesConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyPeriodTypesConfigError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(PERIODTYPE_CONFIG_GET_LIST, getPeriodTypesConfigList);
}

export function* watchGetOne() {
  yield takeEvery(PERIODTYPE_CONFIG_GET_ONE, getOnePeriodTypeConfig);
}

export function* watchGetActuel() {
  yield takeEvery(PERIODTYPE_CONFIG_GET_ACTUEL, getPeriodTypeActuelConfig);
}

export function* watchEditOne() {
  yield takeEvery(PERIODTYPE_CONFIG_EDIT_ONE, editOnePeriodType);
}

export function* watchAddOne() {
  yield takeEvery(PERIODTYPE_CONFIG_ADD_ONE, addPeriodTypeConfig);
}

export function* watchDeleteOne() {
  yield takeEvery(PERIODTYPE_CONFIG_DELETE_ONE, deleteOnePeriodTypeConfig);
}
export function* watchDeleteMany() {
  yield takeEvery(PERIODTYPES_CONFIG_DELETE_MANY, deleteManyPeriodTypesConfig);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetActuel),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)
  ]);
}
