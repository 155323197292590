import {
  DIPLOMA_CONFIG_GET_LIST,
  DIPLOMA_CONFIG_GET_LIST_SUCCESS,
  DIPLOMA_CONFIG_GET_LIST_ERROR,

  DIPLOMA_CONFIG_GET_ONE,
  DIPLOMA_CONFIG_GET_ONE_SUCCESS,
  DIPLOMA_CONFIG_GET_ONE_ERROR,

  DIPLOMA_CONFIG_GET_BY_FILTER,
  DIPLOMA_CONFIG_GET_BY_FILTER_SUCCESS,
  DIPLOMA_CONFIG_GET_BY_FILTER_ERROR,

  DIPLOMA_CONFIG_ADD_ONE,
  DIPLOMA_CONFIG_ADD_ONE_SUCCESS,
  DIPLOMA_CONFIG_ADD_ONE_ERROR,

  DIPLOMA_CONFIG_EDIT_ONE,
  DIPLOMA_CONFIG_EDIT_ONE_SUCCESS,
  DIPLOMA_CONFIG_EDIT_ONE_ERROR,

  DIPLOMA_CONFIG_DELETE_ONE,
  DIPLOMA_CONFIG_DELETE_ONE_SUCCESS,
  DIPLOMA_CONFIG_DELETE_ONE_ERROR,

  DIPLOMAS_CONFIG_DELETE_MANY,
  DIPLOMAS_CONFIG_DELETE_MANY_SUCCESS,
  DIPLOMAS_CONFIG_DELETE_MANY_ERROR
  
} from '../../actions';
import { NotificationManager } from '../../../components/common/react-notifications';
import notificationMessages from '../../../lang/locales/notificationMsg'
import { DIPLOMA_GET_BY_FILTER_SUCCESS } from '../../actions';
const INIT_STATE = {
  diplomas: [],
  count: 0,
  diploma: null,
  error: '',
};

export default (state = INIT_STATE, action) => {

  const messages = notificationMessages()
  switch (action.type) {
    case DIPLOMA_CONFIG_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case DIPLOMA_CONFIG_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        diplomas: action.payload.diplomas || [],
        count: action.payload.count || 0
      };
    case DIPLOMA_CONFIG_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






    // GET ONE
    case DIPLOMA_CONFIG_GET_ONE:
      return { ...state, loading: true, error: '' };
    case DIPLOMA_CONFIG_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        diploma: action.payload.diploma
      };
    case DIPLOMA_CONFIG_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };





    // GET BY FILTER
    case DIPLOMA_CONFIG_GET_BY_FILTER:

      return { ...state, loading: true, error: '' };
    case DIPLOMA_CONFIG_GET_BY_FILTER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        diplomas: action.payload.diplomas || [], count: action.payload.count || 0
      };
    case DIPLOMA_CONFIG_GET_BY_FILTER_ERROR:

      return { ...state, loading: false, error: action.payload.message };




    // ADD ONE
    case DIPLOMA_CONFIG_ADD_ONE:
      console.log('actionnn 111',action);

      return { ...state, loading: true, error: '' };
    case DIPLOMA_CONFIG_ADD_ONE_SUCCESS:
      console.log('actionnn 222',action);

      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        diplomas: [action.payload.diploma, ...state.diplomas]
      };
    case DIPLOMA_CONFIG_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // EDIT ONE
    case DIPLOMA_CONFIG_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case DIPLOMA_CONFIG_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        diploma: action.payload.diploma
      };
    case DIPLOMA_CONFIG_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // DELETE ONE
    case DIPLOMA_CONFIG_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case DIPLOMA_CONFIG_DELETE_ONE_SUCCESS:
      const diplomas = state.diplomas
      if (action.payload.index !== null && action.payload.index !== undefined)
        diplomas.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        diplomas
      };
    case DIPLOMA_CONFIG_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    // DELETE MANY
    case DIPLOMAS_CONFIG_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case DIPLOMAS_CONFIG_DELETE_MANY_SUCCESS:
      const diplomas_list = state.diplomas


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_diploma = -1
          for (let j = 0; j < diplomas_list.length; j++) {
            if (diplomas_list[j]._id === action.payload.data.ids[i]) {
              index_diploma = j; break;
            }
          }
          if (index_diploma > -1) diplomas_list.splice(index_diploma, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        diplomas: diplomas_list
      };
    case DIPLOMAS_CONFIG_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    default: return { ...state };
  }
}
