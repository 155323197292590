import SchoolYearSrv from '../../services/SchoolYearSrv';
import {
  SCOOLYEAR_GET_LIST,
  SCOOLYEAR_GET_LIST_SUCCESS,
  SCOOLYEAR_GET_LIST_ERROR,

  SCOOLYEAR_GET_ONE,
  SCOOLYEAR_GET_ONE_SUCCESS,
  SCOOLYEAR_GET_ONE_ERROR,

  SCOOLYEAR_GET_BY_FILTER,
  SCOOLYEAR_GET_BY_FILTER_SUCCESS,
  SCOOLYEAR_GET_BY_FILTER_ERROR,

  SCOOLYEAR_ADD_ONE,
  SCOOLYEAR_ADD_ONE_SUCCESS,
  SCOOLYEAR_ADD_ONE_ERROR,

  SCOOLYEAR_EDIT_ONE,
  SCOOLYEAR_EDIT_ONE_SUCCESS,
  SCOOLYEAR_EDIT_ONE_ERROR,

  SCHOOLYEAR_DELETE_ONE,
  SCHOOLYEAR_DELETE_ONE_SUCCESS,
  SCHOOLYEAR_DELETE_ONE_ERROR,

  SCHOOLYEARS_DELETE_MANY,
  SCHOOLYEARS_DELETE_MANY_SUCCESS,
  SCHOOLYEARS_DELETE_MANY_ERROR,
} from '../actions';

// GET LIST
export const getSchoolYearsList = (payload) => ({
  type: SCOOLYEAR_GET_LIST,
  payload
});
export const getSchoolYearsListSuccess = (payload) => ({
  type: SCOOLYEAR_GET_LIST_SUCCESS,
  payload
});
export const getSchoolYearsListError = (payload) => ({
  type: SCOOLYEAR_GET_LIST_ERROR,
  payload
});







// GET ONE
export const getOneSchoolYear = (payload) => ({
  type: SCOOLYEAR_GET_ONE,
  payload
});
export const getOneSchoolYearSuccess = (payload) => ({
  type: SCOOLYEAR_GET_ONE_SUCCESS,
  payload
});
export const getOneSchoolYearError = (payload) => ({
  type: SCOOLYEAR_GET_ONE_ERROR,
  payload
});


// GET SCHOOL YEAR BY FILTER

export const getSchoolYearsByFilter = (payload) => ({
  type: SCOOLYEAR_GET_BY_FILTER,
  payload
});
export const getSchoolYearsByFilterSuccess = (payload) => ({
  type: SCOOLYEAR_GET_BY_FILTER_SUCCESS,
  payload
});
export const getSchoolYearsByFilterError = (payload) => ({
  type: SCOOLYEAR_GET_BY_FILTER_ERROR,
  payload
});
export const getSchoolYearsByFilterCB = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: SCOOLYEAR_GET_BY_FILTER,
    });

    SchoolYearSrv.getList(payload)
      .then((response) => {
        if (response && response?.data) {
          const resp = {
            school_years: response?.data?.school_years,
            count: response.data.count
          }

          dispatch({
            type: SCOOLYEAR_GET_BY_FILTER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: SCOOLYEAR_GET_BY_FILTER_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};





// ADD ONE
export const addSchoolYear = (payload) => ({
  type: SCOOLYEAR_ADD_ONE,
  payload
});
export const addSchoolYearSuccess = (payload) => ({
  type: SCOOLYEAR_ADD_ONE_SUCCESS,
  payload
});
export const addSchoolYearError = (payload) => ({
  type: SCOOLYEAR_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editSchoolYear = (payload) => ({
  type: SCOOLYEAR_EDIT_ONE,
  payload
});
export const editSchoolYearSuccess = (payload) => ({
  type: SCOOLYEAR_EDIT_ONE_SUCCESS,
  payload
});
export const editSchoolYearError = (payload) => ({
  type: SCOOLYEAR_EDIT_ONE_ERROR,
  payload
});


// DELETE ONE
export const deleteOneSchoolYear = (payload) => ({
  type: SCHOOLYEAR_DELETE_ONE,
  payload
});
export const deleteOneSchoolYearSuccess = (payload) => ({
  type: SCHOOLYEAR_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneSchoolYearError = (payload) => ({
  type: SCHOOLYEAR_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManySchoolYears = (payload) => ({
  type: SCHOOLYEARS_DELETE_MANY,
  payload
});
export const deleteManySchoolYearsSuccess = (payload) => ({
  type: SCHOOLYEARS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManySchoolYearsError = (payload) => ({
  type: SCHOOLYEARS_DELETE_MANY_ERROR,
  payload
});