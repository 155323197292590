import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  GET_PARTNER_CONTACTS_LIST
} from "../actions";

import {
  getPartnerContactsListSuccess,
  getPartnerContactsListError,

} from "./actions";


// GET USER PARTNERS
const getPartnerContactsListRequest = async (payload) => {
  return axios.get(api.partners + `/${payload.partnerId}/contacts`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getPartnerContactsList({ payload }) {
  try {
    const ret = yield call(getPartnerContactsListRequest, payload);
    const { students, teachers,partnerInstitution } = ret.data;
    if (ret && ret.status === 200)
      yield put(getPartnerContactsListSuccess({ students, teachers, partnerInstitution, count: ret.data.count || 0 }));

    else yield put(getPartnerContactsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getPartnerContactsListError({ error, message: (error) ? error.message : '' }));
  }
}




//////// WATCHERS
export function* watchGetPartnerContacts() {
  yield takeEvery(GET_PARTNER_CONTACTS_LIST, getPartnerContactsList);
}



export default function* rootSaga() {
  yield all([
    fork(watchGetPartnerContacts),
  ]);
}
