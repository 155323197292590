// const { default: axiosApi } = require("helpers/axiosConfig")

import { api } from "../constants/defaultValues";
import axiosApi from "../helpers/axiosConfig";
import { filtersLink } from "../helpers/Utils";

const ParentSrv = {
  getList: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(
        `${api.parents}/filters/${payload.inst_id}/${payload.offset}/${payload.limit}?${filtersLink(payload?.params || {})}`,
        payload.filter
      )
        .then(resp => { resolve(resp); })
        .catch(e => reject(e))
    })
  },
  getOne: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(
        `${api.parents}/${payload.id}/`,
        payload.filter
      )
        .then(resp => { resolve(resp); })
        .catch(e => reject(e))
    })
  },
}

export default ParentSrv