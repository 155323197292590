import {
  PARENTS_SCHOOL_GET_LIST,
  PARENTS_SCHOOL_GET_LIST_SUCCESS,
  PARENTS_SCHOOL_GET_LIST_ERROR,

  PARENTS_SCHOOL_GET_LIST_BY_FILTER,
  PARENTS_SCHOOL_GET_LIST_BY_FILTER_SUCCESS,
  PARENTS_SCHOOL_GET_LIST_BY_FILTER_ERROR,

  PARENTS_SCHOOL_GET_ONE,
  PARENTS_SCHOOL_GET_ONE_SUCCESS,
  PARENTS_SCHOOL_GET_ONE_ERROR,

  PARENTS_SCHOOL_ADD_ONE,
  PARENTS_SCHOOL_ADD_ONE_SUCCESS,
  PARENTS_SCHOOL_ADD_ONE_ERROR,

  PARENTS_SCHOOL_EDIT_ONE,
  PARENTS_SCHOOL_EDIT_ONE_SUCCESS,
  PARENTS_SCHOOL_EDIT_ONE_ERROR,

  PARENTS_SCHOOL_DELETE_ONE,
  PARENTS_SCHOOL_DELETE_ONE_SUCCESS,
  PARENTS_SCHOOL_DELETE_ONE_ERROR,

  PARENTS_SCHOOL_DELETE_MANY,
  PARENTS_SCHOOL_DELETE_MANY_SUCCESS,
  PARENTS_SCHOOL_DELETE_MANY_ERROR,
} from '../actions';

//GET LIST
export const getParentsSchoolList = (payload) => ({
  type: PARENTS_SCHOOL_GET_LIST,
  payload
});
export const getParentsSchoolListSuccess = (payload) => ({
  type: PARENTS_SCHOOL_GET_LIST_SUCCESS,
  payload
});
export const getParentsSchoolListError = (payload) => ({
  type: PARENTS_SCHOOL_GET_LIST_ERROR,
  payload
});


//GET LIST BY FILTER
export const getParentsSchoolByFilter = (payload) => ({
  type: PARENTS_SCHOOL_GET_LIST_BY_FILTER,
  payload
});
export const getParentsSchoolByFilterSuccess = (payload) => ({
  type: PARENTS_SCHOOL_GET_LIST_BY_FILTER_SUCCESS,
  payload
});
export const getParentsSchoolByFilterError = (payload) => ({
  type: PARENTS_SCHOOL_GET_LIST_BY_FILTER_ERROR,
  payload
});


// GET One
export const getOneParentsSchool = (payload) => ({
  type: PARENTS_SCHOOL_GET_ONE,
  payload
});
export const getOneParentsSchoolSuccess = (payload) => ({
  type: PARENTS_SCHOOL_GET_ONE_SUCCESS,
  payload
});
export const getOneParentsSchoolError = (payload) => ({
  type: PARENTS_SCHOOL_GET_ONE_ERROR,
  payload
});


// ADD ONE
export const addParentsSchool = (payload) => ({
  type: PARENTS_SCHOOL_ADD_ONE,
  payload
});
export const addParentsSchoolSuccess = (payload) => ({
  type: PARENTS_SCHOOL_ADD_ONE_SUCCESS,
  payload
});
export const addParentsSchoolError = (payload) => ({
  type: PARENTS_SCHOOL_ADD_ONE_ERROR,
  payload
});

// EDIT ONE
export const editOneParentsSchool = (payload) => ({
  type: PARENTS_SCHOOL_EDIT_ONE,
  payload
});
export const editOneParentsSchoolSuccess = (payload) => ({
  type: PARENTS_SCHOOL_EDIT_ONE_SUCCESS,
  payload
});
export const editOneParentsSchoolError = (payload) => ({
  type: PARENTS_SCHOOL_EDIT_ONE_ERROR,
  payload
});

// DELETE ONE
export const deleteOneParentsSchool = (payload) => ({
  type: PARENTS_SCHOOL_DELETE_ONE,
  payload
});
export const deleteOneParentsSchoolSuccess = (payload) => ({
  type: PARENTS_SCHOOL_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneParentsSchoolError = (payload) => ({
  type: PARENTS_SCHOOL_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyParentsSchool = (payload) => ({
  type: PARENTS_SCHOOL_DELETE_MANY,
  payload
});
export const deleteManyParentsSchoolSuccess = (payload) => ({
  type: PARENTS_SCHOOL_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyParentsSchoolError = (payload) => ({
  type: PARENTS_SCHOOL_DELETE_MANY_ERROR,
  payload
});