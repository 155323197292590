import {
  EXEMPTION_GET_LIST,
  EXEMPTION_GET_LIST_SUCCESS,
  EXEMPTION_GET_LIST_ERROR,


  EXEMPTION_GET_ONE,
  EXEMPTION_GET_ONE_SUCCESS,
  EXEMPTION_GET_ONE_ERROR,

  EXEMPTION_GET_BY_FILTER,
  EXEMPTION_GET_BY_FILTER_SUCCESS,
  EXEMPTION_GET_BY_FILTER_ERROR,

  EXEMPTION_ADD_ONE,
  EXEMPTION_ADD_ONE_SUCCESS,
  EXEMPTION_ADD_ONE_ERROR,

  EXEMPTION_EDIT_ONE,
  EXEMPTION_EDIT_ONE_SUCCESS,
  EXEMPTION_EDIT_ONE_ERROR,

  EXEMPTION_DELETE_ONE,
  EXEMPTION_DELETE_ONE_SUCCESS,
  EXEMPTION_DELETE_ONE_ERROR,

  EXEMPTIONS_DELETE_MANY,
  EXEMPTIONS_DELETE_MANY_SUCCESS,
  EXEMPTIONS_DELETE_MANY_ERROR

} from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg';
const INIT_STATE = {
  exemptions: [],
  count: 0,
  exemption: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

  switch (action.type) {

    //GET LIST 
    case EXEMPTION_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case EXEMPTION_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        exemptions: action.payload.exemptions || [],
        count: action.payload.count || 0
      };
    case EXEMPTION_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET ONE
    case EXEMPTION_GET_ONE:
      return {
        ...state, loading: true, error: ''
      };

    case EXEMPTION_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        exemption: action.payload.exemption

      };
    case EXEMPTION_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    // GET BY FILTER
    case EXEMPTION_GET_BY_FILTER:

      return { ...state, loading: true, error: '' };
    case EXEMPTION_GET_BY_FILTER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        exemptions: action.payload.exemptions || [],
        count: action.payload.count || 0
      };
    case EXEMPTION_GET_BY_FILTER_ERROR:

      return { ...state, loading: false, error: action.payload.message };

    // ADD ONE
    case EXEMPTION_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case EXEMPTION_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');

      return {
        ...state, loading: false,
        exemptions: [action.payload.exemption, ...state.exemptions]
      };
    case EXEMPTION_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // EDIT ONE
    case EXEMPTION_EDIT_ONE:
      return { ...state, loading: true, edit_sucess: false, error: '' };
    case EXEMPTION_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, edit_sucess: true, error: '',
        exemption: action.payload.exemption
      };
    case EXEMPTION_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, edit_sucess: false, error: action.payload.message };



    // DELETE ONE
    case EXEMPTION_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case EXEMPTION_DELETE_ONE_SUCCESS:
      const exemptions = state.exemptions
      if (action.payload.index !== null && action.payload.index !== undefined)
        exemptions.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        exemptions
      };
    case EXEMPTION_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // DELETE MANY
    case EXEMPTIONS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case EXEMPTIONS_DELETE_MANY_SUCCESS:
      const exemptions_list = state.exemptions


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_exemption = -1
          for (let j = 0; j < exemptions_list.length; j++) {
            if (exemptions_list[j]._id === action.payload.data.ids[i]) {
              index_exemption = j; break;
            }
          }
          if (index_exemption > -1) exemptions_list.splice(index_exemption, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        exemptions: exemptions_list
      };
    case EXEMPTIONS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



    default: return { ...state };

















  }
}