import {
    SIGNALS_GET_LIST,
    SIGNALS_GET_LIST_ERROR,
    SIGNALS_GET_LIST_SUCCESS,

    SIGNALS_GET_ITEM,
    SIGNALS_GET_ITEM_ERROR,
    SIGNALS_GET_ITEM_SUCCESS,

    SIGNALS_ADD,
    SIGNALS_ADD_SUCCESS,
    SIGNALS_ADD_ERROR,

    SIGNALS_DELETE_ONE,
    SIGNALS_DELETE_ONE_SUCCESS,
    SIGNALS_DELETE_ONE_ERROR
} from './actionTypes'

// GET ONE
export const getSignal = (payload) => ({
    type: SIGNALS_GET_ITEM,
    payload
  });
  export const getSignalSuccess = (payload) => ({
    type: SIGNALS_GET_ITEM_SUCCESS,
    payload
  });
  export const getSignalError = (payload) => ({
    type: SIGNALS_GET_ITEM_ERROR,
    payload
});
  

// GET LIST
export const getSignalsList = (payload) => ({
    type: SIGNALS_GET_LIST,
    payload
  });
  export const getSignalsListSuccess = (items) => ({
    type: SIGNALS_GET_LIST_SUCCESS,
    payload: items
  });
  export const getSignalsListError = (error) => ({
    type: SIGNALS_GET_LIST_ERROR,
    payload: error
});
  

//ADD ONE
export const addSignal = (payload) => ({
    type: SIGNALS_ADD,
    payload
  })
  export const addSignalSuccess = (items) => ({
    type: SIGNALS_ADD_SUCCESS,
    payload: items
  })
  export const addSignalError = (error) => ({
    type: SIGNALS_ADD_ERROR,
    payload: error
});

// DELETE ONE
export const deleteOneSignal = (payload) => ({
    type: SIGNALS_DELETE_ONE,
    payload
  });
  export const deleteOneSignalSuccess = (payload) => ({
    type: SIGNALS_DELETE_ONE_SUCCESS,
    payload
  });
  export const deleteOneSignalError = (payload) => ({
    type: SIGNALS_DELETE_ONE_ERROR,
    payload
  });