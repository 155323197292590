import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../../constants/defaultValues";
import axios from "axios";

import {
  DIPLOMA_CONFIG_GET_LIST,
  DIPLOMA_CONFIG_ADD_ONE,
  DIPLOMA_CONFIG_GET_ONE,
  DIPLOMA_CONFIG_EDIT_ONE,
  DIPLOMA_CONFIG_DELETE_ONE,
  DIPLOMAS_CONFIG_DELETE_MANY,
  DIPLOMA_CONFIG_GET_BY_FILTER
} from "../../actions";

import {
  getDiplomasConfigListSuccess,
  getDiplomasConfigListError,

  getOneDiplomaConfigSuccess,
  getOneDiplomaConfigError,
  getDiplomasConfigByFilterSuccess,
  getDiplomasConfigByFilterError,
  addDiplomaConfigSuccess,
  addDiplomaConfigError,

  editDiplomaConfigSuccess,
  editDiplomaConfigError,
  deleteOneDiplomaConfigSuccess,
  deleteOneDiplomaConfigError,

  deleteManyDiplomasConfigSuccess,
  deleteManyDiplomasConfigError,
} from "../../actions";


//GET LIST
const getDiplomasListRequest = async (payload) => {
  return await axios.get(api.diplomas + `/list-admin/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getDiplomasConfigList({ payload }) {
  try {
    const ret = yield call(getDiplomasListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getDiplomasConfigListSuccess({ diplomas: ret.data.diplomas || [], count: ret.data.count || 0 }));

    else yield put(getDiplomasConfigListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getDiplomasConfigListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOneDiplomasRequest = async (payload) => {
  return await axios.get(api.diplomas + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneDiplomaConfig({ payload }) {
  try {
    const ret = yield call(getOneDiplomasRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneDiplomaConfigSuccess({ diploma: ret.data.diploma }));

    else yield put(getOneDiplomaConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneDiplomaConfigError({ error, message: (error) ? error.message : '' }));
  }
}


//GET BY FILTER
const getDiplomasByFilterRequest = async (payload) => {
  return await axios.post(api.diplomas + `/filters/${payload.inst_id}/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getDiplomasConfigByFilter({ payload }) {
  try {
    const ret = yield call(getDiplomasByFilterRequest, payload);


    if (ret && ret.status === 200)
      yield put(getDiplomasConfigByFilterSuccess({ diplomas: ret.data.diplomas, count: ret.data.count || 0 }));

    else yield put(getDiplomasConfigByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getDiplomasConfigByFilterError({ error, message: (error) ? error.message : '' }));
  }
}


//ADD ONE
const addDiplomaRequest = async (payload) => {
  const frm = new FormData();
  frm.append('name', payload.data.name)
  frm.append('desc', payload.data.desc);
  frm.append('type', payload.data.type);

  if (payload.data.model) frm.append('model', payload.data.model)
  return await axios.post(api.diplomas, frm, {
    headers: {
      Authorization: payload.token, //'content-type': 'multipart/form-data'
    }
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addDiplomaConfig({ payload }) {
  try {console.log('____SAGA______');

    const ret = yield call(addDiplomaRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/admin/settings/diplomas/list')
      yield put(addDiplomaConfigSuccess({ diploma: ret.data.diploma }));
    }
    else yield put(addDiplomaConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addDiplomaConfigError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneDiplomaRequest = async (payload) => {
  const frm = new FormData();
  frm.append('name', payload.data.name)
  frm.append('desc', payload.data.desc);
  if (payload.data.model) frm.append('model', payload.data.model)
  frm.append('type', payload.data.type)

  return await axios.patch(`${api.diplomas}/edit-one/${payload._id}`, frm)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneDiploma({ payload }) {
  try {
    const ret = yield call(editOneDiplomaRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/admin/settings/diplomas/list')
      yield put(editDiplomaConfigSuccess({ diploma: ret.data.diploma }));
    }
    else yield put(editDiplomaConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editDiplomaConfigError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneDiplomaRequest = async (payload) => {
  return await axios.delete(`${api.diplomas}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneDiplomaConfig({ payload }) {
  try {
    const ret = yield call(deleteOneDiplomaRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/admin/settings/diplomas/list')
      yield put(deleteOneDiplomaConfigSuccess({ index }));
    }
    else yield put(deleteOneDiplomaConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneDiplomaConfigError({ error, message: (error) ? error.message : '' }));
  }
}

//DELETE MANY

const deleteManyDiplomasRequest = async (payload) => {
  return await axios.delete(`${api.diplomas}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyDiplomasConfig({ payload }) {
  try {
    const ret = yield call(deleteManyDiplomasRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/admin/settings/diplomas/list')
      yield put(deleteManyDiplomasConfigSuccess({ data }));
    }
    else yield put(deleteManyDiplomasConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyDiplomasConfigError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(DIPLOMA_CONFIG_GET_LIST, getDiplomasConfigList);
}

export function* watchGetOne() {
  yield takeEvery(DIPLOMA_CONFIG_GET_ONE, getOneDiplomaConfig);
}
export function* watchGetFilter() {
  yield takeEvery(DIPLOMA_CONFIG_GET_BY_FILTER, getDiplomasConfigByFilter);
}
export function* watchEditOne() {
  yield takeEvery(DIPLOMA_CONFIG_EDIT_ONE, editOneDiploma);
}

export function* watchAddOne() {
  yield takeEvery(DIPLOMA_CONFIG_ADD_ONE, addDiplomaConfig);
}

export function* watchDeleteOne() {
  yield takeEvery(DIPLOMA_CONFIG_DELETE_ONE, deleteOneDiplomaConfig);
}
export function* watchDeleteMany() {
  yield takeEvery(DIPLOMAS_CONFIG_DELETE_MANY, deleteManyDiplomasConfig);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetFilter),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)
  ]);
}

