import {
  SPECIALITY_CONFIG_GET_LIST,
  SPECIALITY_CONFIG_GET_LIST_SUCCESS,
  SPECIALITY_CONFIG_GET_LIST_ERROR,

  SPECIALITY_CONFIG_GET_ONE,
  SPECIALITY_CONFIG_GET_ONE_SUCCESS,
  SPECIALITY_CONFIG_GET_ONE_ERROR,

  SPECIALITY_CONFIG_GET_BY_FILTER,
  SPECIALITY_CONFIG_GET_BY_FILTER_SUCCESS,
  SPECIALITY_CONFIG_GET_BY_FILTER_ERROR,

  SPECIALITY_CONFIG_ADD_ONE,
  SPECIALITY_CONFIG_ADD_ONE_SUCCESS,
  SPECIALITY_CONFIG_ADD_ONE_ERROR,

  SPECIALITY_CONFIG_EDIT_ONE,
  SPECIALITY_CONFIG_EDIT_ONE_SUCCESS,
  SPECIALITY_CONFIG_EDIT_ONE_ERROR,

  SPECIALITY_CONFIG_DELETE_ONE,
  SPECIALITY_CONFIG_DELETE_ONE_SUCCESS,
  SPECIALITY_CONFIG_DELETE_ONE_ERROR,

  SPECIALITY_CONFIG_DELETE_MANY,
  SPECIALITY_CONFIG_DELETE_MANY_SUCCESS,
  SPECIALITY_CONFIG_DELETE_MANY_ERROR
} from '../../actions';

// GET LIST
export const getSpecialityConfigList = (payload) => ({
  type: SPECIALITY_CONFIG_GET_LIST,
  payload
});
export const getSpecialityConfigListSuccess = (payload) => ({
  type: SPECIALITY_CONFIG_GET_LIST_SUCCESS,
  payload
});
export const getSpecialityConfigListError = (payload) => ({
  type: SPECIALITY_CONFIG_GET_LIST_ERROR,
  payload
});







// GET LIST
export const getOneSpecialityConfig = (payload) => ({
  type: SPECIALITY_CONFIG_GET_ONE,
  payload
});
export const getOneSpecialityConfigSuccess = (payload) => ({
  type: SPECIALITY_CONFIG_GET_ONE_SUCCESS,
  payload
});
export const getOneSpecialityConfigError = (payload) => ({
  type: SPECIALITY_CONFIG_GET_ONE_ERROR,
  payload
});




// GET BY FILTER

export const getSpecialitiesConfigByFilter = (payload) => ({
  type: SPECIALITY_CONFIG_GET_BY_FILTER,
  payload
});
export const getSpecialitiesConfigByFilterSuccess = (payload) => ({
  type: SPECIALITY_CONFIG_GET_BY_FILTER_SUCCESS,
  payload
});
export const getSpecialitiesConfigByFilterError = (payload) => ({
  type: SPECIALITY_CONFIG_GET_BY_FILTER_ERROR,
  payload
});



// ADD ONE
export const addSpecialityConfig = (payload) => ({
  type: SPECIALITY_CONFIG_ADD_ONE,
  payload
});
export const addSpecialityConfigSuccess = (payload) => ({
  type: SPECIALITY_CONFIG_ADD_ONE_SUCCESS,
  payload
});
export const addSpecialityConfigError = (payload) => ({
  type: SPECIALITY_CONFIG_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editSpecialityConfig = (payload) => ({
  type: SPECIALITY_CONFIG_EDIT_ONE,
  payload
});
export const editSpecialityConfigSuccess = (payload) => ({
  type: SPECIALITY_CONFIG_EDIT_ONE_SUCCESS,
  payload
});
export const editSpecialityConfigError = (payload) => ({
  type: SPECIALITY_CONFIG_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneSpecialityConfig = (payload) => ({
  type: SPECIALITY_CONFIG_DELETE_ONE,
  payload
});
export const deleteOneSpecialityConfigSuccess = (payload) => ({
  type: SPECIALITY_CONFIG_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneSpecialityConfigError = (payload) => ({
  type: SPECIALITY_CONFIG_DELETE_ONE_ERROR,
  payload
});


// DELETE MANY
export const deleteManySpecialityConfig = (payload) => ({
  type: SPECIALITY_CONFIG_DELETE_MANY,
  payload
});
export const deleteManySpecialityConfigSuccess = (payload) => ({
  type: SPECIALITY_CONFIG_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManySpecialityConfigError = (payload) => ({
  type: SPECIALITY_CONFIG_DELETE_MANY_ERROR,
  payload
});