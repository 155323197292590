import {
  MEALTYPES_GET_LIST,
  MEALTYPES_GET_LIST_SUCCESS,
  MEALTYPES_GET_LIST_ERROR,

  MEALTYPE_GET_ONE,
  MEALTYPE_GET_ONE_SUCCESS,
  MEALTYPE_GET_ONE_ERROR,

  MEALTYPES_GET_BY_FILTER,
  MEALTYPES_GET_BY_FILTER_SUCCESS,
  MEALTYPES_GET_BY_FILTER_ERROR,

  MEALTYPE_ADD_ONE,
  MEALTYPE_ADD_ONE_SUCCESS,
  MEALTYPE_ADD_ONE_ERROR,

  MEALTYPE_EDIT_ONE,
  MEALTYPE_EDIT_ONE_SUCCESS,
  MEALTYPE_EDIT_ONE_ERROR,

  MEALTYPE_DELETE_ONE,
  MEALTYPE_DELETE_ONE_SUCCESS,
  MEALTYPE_DELETE_ONE_ERROR,

  MEALTYPES_DELETE_MANY,
  MEALTYPES_DELETE_MANY_SUCCESS,
  MEALTYPES_DELETE_MANY_ERROR



} from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'
const INIT_STATE = {
  meal_types: [],
  count: 0,
  meal_type: null,
  error: '',
};
export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

  switch (action.type) {
    case MEALTYPES_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case MEALTYPES_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        meal_types: action.payload.meal_types || [],
        count: action.payload.count || 0
      };
    case MEALTYPES_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    // GET ONE
    case MEALTYPE_GET_ONE:
      return { ...state, loading: true, error: '' };
    case MEALTYPE_GET_ONE_SUCCESS:

      return {
        ...state, loading: false, error: '',
        meal_type: action.payload.meal_type

      };
    case MEALTYPE_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    // GET BY FILTER
    case MEALTYPES_GET_BY_FILTER:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case MEALTYPES_GET_BY_FILTER_SUCCESS:
      return {
        ...state, loading: false,
        meal_types: action.payload.meal_types || [],
        count: action.payload.count || 0
      };
    case MEALTYPES_GET_BY_FILTER_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    // ADD ONE
    case MEALTYPE_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case MEALTYPE_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        meal_types: [action.payload.meal_type, ...state.meal_types]
      };
    case MEALTYPE_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




    // EDIT ONE
    case MEALTYPE_EDIT_ONE:
      return { ...state, loading: true, edit_sucess: false, error: '' };
    case MEALTYPE_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, edit_sucess: true, error: '',
        meal_type: action.payload.meal_type
      };
    case MEALTYPE_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, edit_sucess: false, error: action.payload.message };


    // DELETE ONE
    case MEALTYPE_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case MEALTYPE_DELETE_ONE_SUCCESS:
      const meal_types = state.meal_types
      if (action.payload.index !== null && action.payload.index !== undefined)
        meal_types.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        meal_types
      };
    case MEALTYPE_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    // DELETE MANY
    case MEALTYPES_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case MEALTYPES_DELETE_MANY_SUCCESS:
      const meal_types_list = state.meal_types


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_meal_type = -1
          for (let j = 0; j < meal_types_list.length; j++) {
            if (meal_types_list[j]._id === action.payload.data.ids[i]) {
              index_meal_type = j; break;
            }
          }
          if (index_meal_type > -1) meal_types_list.splice(index_meal_type, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        meal_types: meal_types_list
      };
    case MEALTYPES_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    default: return { ...state };







  }
}