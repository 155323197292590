import {
  USER_GET_LIST,
  USER_GET_LIST_SUCCESS,
  USER_GET_LIST_ERROR,

  USER_GET_BY_FILTER,
  USER_GET_BY_FILTER_SUCCESS,
  USER_GET_BY_FILTER_ERROR,

  USER_CHANGE_STATE,
  USER_CHANGE_STATE_SUCCESS,
  USER_CHANGE_STATE_ERROR,

  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,


  USER_DELETE,
  USER_DELETE_SUCCESS,
  USER_DELETE_ERROR,

  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,

  CHAT_LOGIN_USER,
  CHAT_LOGIN_USER_SUCCESS,
  CHAT_LOGIN_USER_ERROR,

  GET_CHATROOM_ID,
  GET_CHATROOM_ID_SUCCESS,
  GET_CHATROOM_ID_ERROR,
  SELECT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,

  USER_CHANGE_BOX_VIEW
} from './actionTypes';
import { is_white_mark } from "../../constants/defaultValues";
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'
import { axiosGlobalConfigs } from '../../helpers/axiosConfig';
import configAxios from '../../axiosConfig';

let user = null;
let selected_user = null;
let token = "";

try {
  user = JSON.parse(localStorage.getItem("odesco_user"))
  selected_user = JSON.parse(localStorage.getItem("selected_user"))
  token = localStorage.getItem('authToken')
  axiosGlobalConfigs(token);
  configAxios(token);
} catch (error) { }

const INIT_STATE = {
  users: [],
  count: 0,
  user,
  token,
  selected_user,
  connected: false,
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
  ref_code: null,

  full_box_view: false
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
  switch (action.type) {
    case USER_GET_LIST:
      return { ...state, loading: true, error: '' };


    case USER_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        users: action.payload.users || [],
        count: action.payload.count || 0
      };

    case USER_GET_LIST_ERROR:
      return { ...state, loading: false, users: [], error: action.payload.message };


    // GET BY FILTER
    case USER_GET_BY_FILTER:

      return { ...state, loading: true, error: '' };
    case USER_GET_BY_FILTER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        users: action.payload.users || [],
        count: action.payload.count || 0
      };
    case USER_GET_BY_FILTER_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    case USER_CHANGE_STATE:
      return { ...state, loading: true, error: '', ref_code: null };

    case USER_CHANGE_STATE_SUCCESS:
      return {
        ...state, loading: false,
        users: action.payload.users || [],
        ref_code: action.payload.ref_code || "",
      };

    case USER_CHANGE_STATE_ERROR:
      return { ...state, loading: false, error: action.payload.message, ref_code: null };



    case LOGIN_USER:
      return { ...state, loading: true, connected: false, error: '' };
    case LOGIN_USER_SUCCESS:
      // location.href = '/'
      localStorage.setItem('currentLanguage', 'fr');

      return {
        ...state, loading: false,
        user: action.payload.user,
        token: action.payload.token,
        error: '',
        connected: true
      };
    case LOGIN_USER_ERROR:
      return { ...state, loading: false, user: null, connected: false, error: action.payload.message };

    /*************************************** */
    case CHAT_LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case CHAT_LOGIN_USER_SUCCESS:


      return {
        ...state, loading: false, rocket_user: action.payload, error: '',
      };
    case CHAT_LOGIN_USER_ERROR:
      return { ...state, loading: false, user: null, connected: false, error: action.payload.message };
    /*GET ROOM ID*/
    case GET_CHATROOM_ID:
      return { ...state, loading: true, error: '' };
    case GET_CHATROOM_ID_SUCCESS:


      return {
        ...state, loading: false, rocket_room_id: action.payload, error: '',
      };
    case GET_CHATROOM_ID_ERROR:
      return { ...state, loading: false, user: null, connected: false, error: action.payload.message };


    /*************************************** */

    case EDIT_USER:
      return { ...state, loading: true, error: '' };
    case EDIT_USER_SUCCESS:
      return {
        ...state, loading: false, user: action.payload.user, error: '',

      };
    case EDIT_USER_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    // DELETE ONE
    case USER_DELETE:
      return { ...state, loading: true, error: '' };
    case USER_DELETE_SUCCESS:
      const users = state.users
      if (action.payload.index !== null && action.payload.index !== undefined)
        users.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        users
      };
    case USER_DELETE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    case USER_CHANGE_BOX_VIEW:
      return { ...state, full_box_view: (action.payload.full_box_view) };


    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: '' };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, loading: false, forgotUserMail: action.payload, error: '' };
    case FORGOT_PASSWORD_ERROR:
      return { ...state, loading: false, forgotUserMail: '', error: action.payload.message };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: '' };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, newPassword: action.payload, resetPasswordCode: '', error: '' };
    case RESET_PASSWORD_ERROR:
      return { ...state, loading: false, newPassword: '', resetPasswordCode: '', error: action.payload.message };
    case REGISTER_USER:
      return { ...state, loading: true, error: '' };
    case REGISTER_USER_SUCCESS:
      return {
        ...state, loading: false, error: '',
        connected: (is_white_mark) ? false : true, user: (is_white_mark) ? null : action.payload,
      };
    case REGISTER_USER_ERROR:
      return { ...state, loading: false, user: null, error: action.payload.message };
  
      //SELECT USER
    case SELECT_USER:
      localStorage.setItem("selected_user", JSON.stringify(action.payload.user))
      return {
        ...state, selected_user: action.payload.user,
      }
    case LOGOUT_USER:
      return { ...state, user: null, };
    default: return { ...state };
  }
}

