import {
  FEE_COMPOSITION_GET_LIST,
  FEE_COMPOSITION_GET_LIST_SUCCESS,
  FEE_COMPOSITION_GET_LIST_ERROR,

  FEE_COMPOSITION_GET_ONE,
  FEE_COMPOSITION_GET_ONE_SUCCESS,
  FEE_COMPOSITION_GET_ONE_ERROR,

  FEE_COMPOSITION_ADD_ONE,
  FEE_COMPOSITION_ADD_ONE_SUCCESS,
  FEE_COMPOSITION_ADD_ONE_ERROR,

  FEE_COMPOSITION_EDIT_ONE,
  FEE_COMPOSITION_EDIT_ONE_SUCCESS,
  FEE_COMPOSITION_EDIT_ONE_ERROR,

  FEE_COMPOSITION_DELETE_ONE,
  FEE_COMPOSITION_DELETE_ONE_SUCCESS,
  FEE_COMPOSITION_DELETE_ONE_ERROR
} from '../../actions';

// GET LIST
export const getFeeCompositionsList = (payload) => ({
  type: FEE_COMPOSITION_GET_LIST,
  payload
});
export const getFeeCompositionsListSuccess = (payload) => ({
  type: FEE_COMPOSITION_GET_LIST_SUCCESS,
  payload
});
export const getFeeCompositionsListError = (payload) => ({
  type: FEE_COMPOSITION_GET_LIST_ERROR,
  payload
});




// GET ONE
export const getOneFeeComposition = (payload) => ({
  type: FEE_COMPOSITION_GET_ONE,
  payload
});
export const getOneFeeCompositionSuccess = (payload) => ({
  type: FEE_COMPOSITION_GET_ONE_SUCCESS,
  payload
});
export const getOneFeeCompositionError = (payload) => ({
  type: FEE_COMPOSITION_GET_ONE_ERROR,
  payload
});



// ADD ONE
export const addFeeComposition = (payload) => ({
  type: FEE_COMPOSITION_ADD_ONE,
  payload
});
export const addFeeCompositionSuccess = (payload) => ({
  type: FEE_COMPOSITION_ADD_ONE_SUCCESS,
  payload
});
export const addFeeCompositionError = (payload) => ({
  type: FEE_COMPOSITION_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editFeeComposition = (payload) => ({
  type: FEE_COMPOSITION_EDIT_ONE,
  payload
});
export const editFeeCompositionSuccess = (payload) => ({
  type: FEE_COMPOSITION_EDIT_ONE_SUCCESS,
  payload
});
export const editFeeCompositionError = (payload) => ({
  type: FEE_COMPOSITION_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneFeeComposition = (payload) => ({
  type: FEE_COMPOSITION_DELETE_ONE,
  payload
});
export const deleteOneFeeCompositionSuccess = (payload) => ({
  type: FEE_COMPOSITION_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneFeeCompositionError = (payload) => ({
  type: FEE_COMPOSITION_DELETE_ONE_ERROR,
  payload
});