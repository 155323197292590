import {
  PARENT_GET_LIST,
  PARENT_GET_LIST_SUCCESS,
  PARENT_GET_LIST_ERROR,

  PARENT_GET_LIST_BY_FILTER,
  PARENT_GET_LIST_BY_FILTER_SUCCESS,
  PARENT_GET_LIST_BY_FILTER_ERROR,

  PARENT_GET_ONE,
  PARENT_GET_ONE_SUCCESS,
  PARENT_GET_ONE_ERROR,

  PARENT_ADD_ONE,
  PARENT_ADD_ONE_SUCCESS,
  PARENT_ADD_ONE_ERROR,

  PARENT_EDIT_ONE,
  PARENT_EDIT_ONE_SUCCESS,
  PARENT_EDIT_ONE_ERROR,

  PARENT_DELETE_ONE,
  PARENT_DELETE_ONE_SUCCESS,
  PARENT_DELETE_ONE_ERROR,

  PARENTS_DELETE_MANY,
  PARENTS_DELETE_MANY_SUCCESS,
  PARENTS_DELETE_MANY_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'

const INIT_STATE = {
  parents: [],
  count: 0,
  parent: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
  switch (action.type) {

    case PARENT_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case PARENT_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        parents: action.payload.parents || [],
        count: action.payload.count || 0
      };
    case PARENT_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    case PARENT_GET_LIST_BY_FILTER:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case PARENT_GET_LIST_BY_FILTER_SUCCESS:
      return {
        ...state, loading: false,
        parents: action.payload.parents || [],
        count: action.payload.count || 0
      };
    case PARENT_GET_LIST_BY_FILTER_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    // GET ONE
    case PARENT_GET_ONE:
      return { ...state, loading: true, error: '' };
    case PARENT_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        parent: action.payload.parent
      };
    case PARENT_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









    // ADD ONE
    case PARENT_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case PARENT_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        parents: [action.payload.parent, ...state.parents]
      };
    case PARENT_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // EDIT ONE
    case PARENT_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case PARENT_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        parent: action.payload.parent
      };
    case PARENT_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // DELETE ONE
    case PARENT_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case PARENT_DELETE_ONE_SUCCESS:
      const parents = state.parents
      if (action.payload.index !== null && action.payload.index !== undefined)
        parents.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        parents
      };
    case PARENT_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    // DELETE MANY
    case PARENTS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case PARENTS_DELETE_MANY_SUCCESS:
      const parents_list = state.parents


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_parent = -1
          for (let j = 0; j < parents_list.length; j++) {
            if (parents_list[j]._id === action.payload.data.ids[i]) {
              index_parent = j; break;
            }
          }
          if (index_parent > -1) parents_list.splice(index_parent, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        parents: parents_list
      };
    case PARENTS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    default: return { ...state };
  }
}
