import {
  ROOM_GET_LIST,
  ROOM_GET_LIST_SUCCESS,
  ROOM_GET_LIST_ERROR,

  ROOM_GET_ONE,
  ROOM_GET_ONE_SUCCESS,
  ROOM_GET_ONE_ERROR,

  ROOM_GET_BY_FILTER,
  ROOM_GET_BY_FILTER_SUCCESS,
  ROOM_GET_BY_FILTER_ERROR,

  ROOM_ADD_ONE,
  ROOM_ADD_ONE_SUCCESS,
  ROOM_ADD_ONE_ERROR,

  ROOM_EDIT_ONE,
  ROOM_EDIT_ONE_SUCCESS,
  ROOM_EDIT_ONE_ERROR,

  ROOM_DELETE_ONE,
  ROOM_DELETE_ONE_SUCCESS,
  ROOM_DELETE_ONE_ERROR,

  ROOMS_DELETE_MANY,
  ROOMS_DELETE_MANY_SUCCESS,
  ROOMS_DELETE_MANY_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg';
const INIT_STATE = {
  rooms: [],
  count: 0,
  room: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
  switch (action.type) {
    case ROOM_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case ROOM_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        rooms: action.payload.rooms || [],
        count: action.payload.count || 0
      };
    case ROOM_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






    // GET ONE
    case ROOM_GET_ONE:
      return { ...state, loading: true, error: '' };
    case ROOM_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        room: action.payload.room
      };
    case ROOM_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };




    // GET BY FILTER
    case ROOM_GET_BY_FILTER:

      return { ...state, loading: true, error: '' };
    case ROOM_GET_BY_FILTER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        rooms: action.payload.rooms || [], count: action.payload.count || 0
      };
    case ROOM_GET_BY_FILTER_ERROR:

      return { ...state, loading: false, error: action.payload.message };




    // ADD ONE
    case ROOM_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case ROOM_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        rooms: [action.payload.room, ...state.rooms]
      };
    case ROOM_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // EDIT ONE
    case ROOM_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case ROOM_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        room: action.payload.room
      };
    case ROOM_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // DELETE ONE
    case ROOM_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case ROOM_DELETE_ONE_SUCCESS:
      const rooms = state.rooms
      if (action.payload.index !== null && action.payload.index !== undefined)
        rooms.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        rooms
      };
    case ROOM_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    // DELETE MANY
    case ROOMS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case ROOMS_DELETE_MANY_SUCCESS:
      const rooms_list = state.rooms


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_room = -1
          for (let j = 0; j < rooms_list.length; j++) {
            if (rooms_list[j]._id === action.payload.data.ids[i]) {
              index_room = j; break;
            }
          }
          if (index_room > -1) rooms_list.splice(index_room, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        rooms: rooms_list
      };
    case ROOMS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
    default: return { ...state };
  }
}
