import {
  STUDENT_GET_LIST,
  STUDENT_GET_LIST_SUCCESS,
  STUDENT_GET_LIST_ERROR,

  STUDENT_GET_ONE,
  STUDENT_GET_ONE_SUCCESS,
  STUDENT_GET_ONE_ERROR,


  STUDENT_GET_BY_FILTER,
  STUDENT_GET_BY_FILTER_SUCCESS,
  STUDENT_GET_BY_FILTER_ERROR,

  STUDENT_ADD_ONE,
  STUDENT_ADD_ONE_SUCCESS,
  STUDENT_ADD_ONE_ERROR,

  STUDENT_EDIT_ONE,
  STUDENT_EDIT_ONE_SUCCESS,
  STUDENT_EDIT_ONE_ERROR,

  STUDENT_DELETE_ONE,
  STUDENT_DELETE_ONE_SUCCESS,
  STUDENT_DELETE_ONE_ERROR,

  GET_STUDENT_ASSIGNMENTS,
  GET_STUDENT_ASSIGNMENTS_SUCCESS,
  GET_STUDENT_ASSIGNMENTS_ERROR,

  STUDENT_ASSIGNMENT,
  STUDENT_ASSIGNMENT_SUCCESS,
  STUDENT_ASSIGNMENT_ERROR,


  STUDENTS_DELETE_MANY,
  STUDENTS_DELETE_MANY_SUCCESS,
  STUDENTS_DELETE_MANY_ERROR,
  SCHOOL_STUDENTS_GET_LIST,
  SCHOOL_STUDENTS_GET_LIST_SUCCESS,
  SCHOOL_STUDENTS_GET_LIST_ERROR,
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'

const INIT_STATE = {
  students: [],
  schoolStudents: [],
  count: 0,
  student: null,
  error: '',
  student_assignments: []
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
  switch (action.type) {
    case STUDENT_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case STUDENT_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        students: action.payload.students || [],
        count: action.payload.count || 0
      };
    case STUDENT_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    // GET_STUDENT_ASSIGNMENTS

    case GET_STUDENT_ASSIGNMENTS:
      return { ...state, loading: true, error: '' };
    case GET_STUDENT_ASSIGNMENTS_SUCCESS:
      return {
        ...state, loading: false, error: '',
        student_assignments: action.payload.student_assignments
      };
    case GET_STUDENT_ASSIGNMENTS_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    // STUDENT_ASSIGNMENT

    case STUDENT_ASSIGNMENT:
      console.log("STUDENT_ASSIGNMENT", action);
      return { ...state, loading: true, error: '' };
    case STUDENT_ASSIGNMENT_SUCCESS:
      NotificationManager.success("Affected", "Success", 3000, null, null, '');
      console.log("STUDENT_ASSIGNMENT_SUCCESS", action);
      return {
        ...state, loading: false,
        student_assignments: action.payload.assigned
      };
    case STUDENT_ASSIGNMENT_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET ONE
    case STUDENT_GET_ONE:
      return { ...state, loading: true, error: '',student: null };
    case STUDENT_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        student: action.payload.student
      };
    case STUDENT_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    // GET BY FILTER
    case STUDENT_GET_BY_FILTER:

      return { ...state, loading: true, error: '' };
    case STUDENT_GET_BY_FILTER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        students: action.payload.students || [],
        count: action.payload.count || 0
      };
    case STUDENT_GET_BY_FILTER_ERROR:
      return { ...state, loading: false, error: action.payload.message };




    case SCHOOL_STUDENTS_GET_LIST:
      return {
        ...state, loading: true, error: '', count: 0
      };
    case SCHOOL_STUDENTS_GET_LIST_SUCCESS:
      const oldStudentsList = action?.payload?.clear ? [] : state.schoolStudents
      return {
        ...state, loading: false,
        schoolStudents: [...oldStudentsList, ...action.payload.schoolStudents || []],
        count: action.payload.count || 0
      };
    case SCHOOL_STUDENTS_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    /******************************************************* */



    // ADD ONE
    case STUDENT_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case STUDENT_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        students: [action.payload.student, ...state.students]
      };
    case STUDENT_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // EDIT ONE
    case STUDENT_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case STUDENT_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        student: action.payload.student
      };
    case STUDENT_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // DELETE ONE
    case STUDENT_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case STUDENT_DELETE_ONE_SUCCESS:
      const students = state.students
      if (action.payload.index !== null && action.payload.index !== undefined)
        students.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        students
      };
    case STUDENT_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    // DELETE MANY
    case STUDENTS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case STUDENTS_DELETE_MANY_SUCCESS:
      const students_list = state.students


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_student = -1
          for (let j = 0; j < students_list.length; j++) {
            if (students_list[j]._id === action.payload.data.ids[i]) {
              index_student = j; break;
            }
          }
          if (index_student > -1) students_list.splice(index_student, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        students: students_list
      };
    case STUDENTS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    default: return { ...state };
  }
}
