import {
  GET_PARTNER_CONTACTS_LIST_SUCCESS,
  GET_PARTNER_CONTACTS_LIST_ERROR,
  GET_PARTNER_CONTACTS_LIST,
} from "../actions";

// GET USER CONTACTS
export const getPartnerContacts = (payload) => ({
  type: GET_PARTNER_CONTACTS_LIST,
  payload,
});

export const getPartnerContactsListSuccess = (payload) => ({
  type: GET_PARTNER_CONTACTS_LIST_SUCCESS,
  payload,
});

export const getPartnerContactsListError = (payload) => ({
  type: GET_PARTNER_CONTACTS_LIST_ERROR,
  payload,
});
