import {
  TUTORING_GET_LIST,
  TUTORING_GET_LIST_SUCCESS,
  TUTORING_GET_LIST_ERROR,

  TUTORING_GET_BY_TEACHER,
  TUTORING_GET_BY_TEACHER_SUCCESS,
  TUTORING_GET_BY_TEACHER_ERROR,

  TUTORING_GET_BY_INSTUTITION_SUCCESS,
  TUTORING_GET_BY_INSTUTITION_ERROR,
  TUTORING_GET_BY_INSTUTITION,

  TUTORING_GET_ONE,
  TUTORING_GET_ONE_SUCCESS,
  TUTORING_GET_ONE_ERROR,

  TUTORING_ADD_ONE,
  TUTORING_ADD_ONE_SUCCESS,
  TUTORING_ADD_ONE_ERROR,

  TUTORING_EDIT_ONE,
  TUTORING_EDIT_ONE_SUCCESS,
  TUTORING_EDIT_ONE_ERROR,

  TUTORING_DELETE_ONE,
  TUTORING_DELETE_ONE_SUCCESS,
  TUTORING_DELETE_ONE_ERROR,

  TUTORING_SUBSCRIBE,
  TUTORING_SUBSCRIBE_SUCCESS,
  TUTORING_SUBSCRIBE_ERROR,

  TUTORING_ACCEPT,
  TUTORING_ACCEPT_SUCCESS,
  TUTORING_ACCEPT_ERROR,

  TUTORING_UNSUBSCRIBE_SUCCESS,
  TUTORING_UNSUBSCRIBE_ERROR,
  TUTORING_UNSUBSCRIBE,


} from '../actions';


import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg';

const INIT_STATE = {
  tutorings: [],
  count: 0,
  tutoring: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
  switch (action.type) {


    case TUTORING_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case TUTORING_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        tutorings: action.payload.tutorings || [],
        count: action.payload.count || 0
      };
    case TUTORING_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };




    // GET BY Inst
    case TUTORING_GET_BY_INSTUTITION:

      return { ...state, loading: true, error: '' };
    case TUTORING_GET_BY_INSTUTITION_SUCCESS:

      return {
        ...state, loading: false, error: '',
        tutorings: action.payload.tutorings || [],
      };
    case TUTORING_GET_BY_INSTUTITION_ERROR:

      return { ...state, loading: false, error: action.payload.message };


    // GET BY TEACHER
    case TUTORING_GET_BY_TEACHER:

      return { ...state, loading: true, error: '' };
    case TUTORING_GET_BY_TEACHER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        tutorings: action.payload.tutorings || [],
      };
    case TUTORING_GET_BY_TEACHER_ERROR:

      return { ...state, loading: false, error: action.payload.message };


    // GET ONE
    case TUTORING_GET_ONE:
      return { ...state, loading: true, error: '' };
    case TUTORING_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        tutoring: action.payload.tutoring
      };
    case TUTORING_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };




    // ADD ONE
    case TUTORING_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case TUTORING_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        tutorings: [action.payload.tutoring, ...state.tutorings]
      };
    case TUTORING_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




    // EDIT ONE
    case TUTORING_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case TUTORING_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        tutoring: action.payload.tutoring
      };
    case TUTORING_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




    // DELETE ONE
    case TUTORING_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case TUTORING_DELETE_ONE_SUCCESS:
      const tutorings = state.tutorings
      if (action.payload.index !== null && action.payload.index !== undefined)
        tutorings.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        tutorings
      };
    case TUTORING_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




    // TOGGLE SUBSCRIBE
    case TUTORING_SUBSCRIBE:
      return { ...state, loading: true, error: '' };
    case TUTORING_SUBSCRIBE_SUCCESS:
      // NotificationManager.success("Subscribe", "Success",3000, null, null, '');
      const obj = { tutoring: action.payload.tutoring }
      if (action.payload.tutorings) obj['tutorings'] = action.payload.tutorings
      return {
        ...state, loading: false, error: '',
        ...obj
      };
    case TUTORING_SUBSCRIBE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



    // ACCEPT ONE
    case TUTORING_ACCEPT:
      return { ...state, loading: true, error: '' };
    case TUTORING_ACCEPT_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        tutoring: action.payload.tutoring
      };
    case TUTORING_ACCEPT_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    // UNSUBSCRIPTION
    case TUTORING_UNSUBSCRIBE:
      return { ...state, loading: true, error: '' };
    case TUTORING_UNSUBSCRIBE_SUCCESS:
      // NotificationManager.success("Subscribe", "Success",3000, null, null, '');
      const objj = { tutoring: action.payload.tutoring }
      if (action.payload.tutorings) objj['tutorings'] = action.payload.tutorings || []
      return {
        ...state, loading: false, error: '',
        ...objj
      };
    case TUTORING_UNSUBSCRIBE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
    default: return { ...state };

  }
}