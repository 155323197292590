import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
    GET_LIST_QUESTIONS,
    EDIT_QUESTION,
    DELETE_QUESTION
} from "../actions";

import {
    getQuestionsListSuccess,
    getQuestionsListError,

    deleteQuestionSuccess,
    deleteQuestionError,

    editQuestionSuccess,
    editQuestionError
} from "./actions";

// import { appendFormData } from "../../helpers/Utils";

//GET LIST
const getQuestionsListRequest = async (payload) => {
  return await axios.get(api.quiz + `/list-questions/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getQuestionsList({ payload }) {
  try {
    const ret = yield call(getQuestionsListRequest, payload);
    if (ret && ret.status === 200)
      yield put(getQuestionsListSuccess({ quizzes: ret.data.quizzes || [], count: ret.data.count || 0 }));
    else yield put(getQuestionsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))
  } catch (error) {
    yield put(getQuestionsListError({ error, message: (error) ? error.message : '' }));
  }
}



//EDIT ONE
const editQuestionRequest = async (payload) => {
  return await axios.patch(`${api.quiz}/edit-question/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editQuestion({ payload }) {
  try {
    const ret = yield call(editQuestionRequest, payload);

    if (ret && ret.status === 200) {
      // const { history } = payload;

      // if (history) history.push('/app/my-institutions/posts')
      yield put(editQuestionSuccess({ question: ret.data.question }));
    }
    else yield put(editQuestionError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editQuestionError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteQuestionRequest = async (payload) => {
  return await axios.delete(`${api.quiz}/delete-question/${payload?.data?._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteQuestion({ payload }) {
  try { 
    const ret = yield call(deleteQuestionRequest, payload);

    if (ret && ret.status === 202) {
      yield put(deleteQuestionSuccess({ index: payload?.data?.index }));
    }
    else yield put(deleteQuestionError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteQuestionError({ error, message: (error) ? error.message : '' }));
  }
}



//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(GET_LIST_QUESTIONS, getQuestionsList);
}



export function* watchEditOne() {
  yield takeEvery(EDIT_QUESTION, editQuestion);
}


export function* watchDeleteOne() {
  yield takeEvery(DELETE_QUESTION, deleteQuestion);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchEditOne),
    fork(watchDeleteOne),

  ]);
}
