import chatServices from '../../services/ChatServices';
import {
  GET_MY_CHATROOMS,
  GET_MY_CHATROOMS_SUCCESS,
  GET_MY_CHATROOMS_ERROR,

  GET_CHATROOM_CONVERSATION,
  GET_CHATROOM_CONVERSATION_SUCCESS,
  GET_CHATROOM_CONVERSATION_ERROR,

  CREATE_NEW_CHATROOM,
  CREATE_NEW_CHATROOM_SUCCESS,
  CREATE_NEW_CHATROOM_ERROR,

  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR,
} from '../actions';


export const getMyChatRoomsList = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_MY_CHATROOMS,
    });

    chatServices.getMyRooms(payload)
      .then((response) => {
        if (response?.data) {
          const resp = {
            chatRooms: response?.data?.data?.chatRooms,
          }

          dispatch({
            type: GET_MY_CHATROOMS_SUCCESS,
            payload: resp,
          });

          callback && callback(resp);
        }

        callbackError && callbackError({ error: response?.data.error });

      })
      .catch(e => {
        dispatch({
          type: GET_MY_CHATROOMS_ERROR,
          payload: { error: {} }
        });
        callbackError && callbackError(e?.response?.data?.error);
      });
  };
};
export const getRoomConversationList = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_CHATROOM_CONVERSATION,
    });

    chatServices.getRoomConversation(payload)
      .then((response) => {
        if (response?.data) {
          const resp = {
            conversation: response?.data?.data?.conversation,
            count: response?.data?.data?.count,
          }

          dispatch({
            type: GET_CHATROOM_CONVERSATION_SUCCESS,
            payload: resp,
          });

          callback && callback(resp);
        }

        callbackError && callbackError({ error: response?.data.error });

      })
      .catch(e => {
        dispatch({
          type: GET_CHATROOM_CONVERSATION_ERROR,
          payload: { error: {} }
        });
        callbackError && callbackError(e?.response?.data?.error);
      });
  };
};
export const sendMessageAction = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: SEND_MESSAGE,
    });

    chatServices.sendMessage(payload)
      .then((response) => {
        if (response?.data) {
          const resp = {
            conversation: response?.data?.data?.conversation,
          }

          dispatch({
            type: SEND_MESSAGE_SUCCESS,
            payload: resp,
          });

          callback && callback(resp);
        }

        callbackError && callbackError({ error: response?.data.error });

      })
      .catch(e => {
        dispatch({
          type: SEND_MESSAGE_ERROR,
          payload: { error: {} }
        });
        callbackError && callbackError(e?.response?.data?.error);
      });
  };
};
export const markConversationAsRead = (payload, callback) => {

  return () => {
    chatServices.markAsRead(payload)
      .then((response) => {
        if (response?.data) {
          const resp = response?.data?.data;
          callback(resp);
        }
      })
      .catch();
  };
};
export const createNewChatRoom = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_NEW_CHATROOM,
    });

    chatServices.initiateChatRoom(payload)
      .then((response) => {
        if (response?.data) {
          const resp = response?.data?.data;

          dispatch({
            type: CREATE_NEW_CHATROOM_SUCCESS,
            payload: resp,
          });

          callback && callback(resp);
        }

        callbackError && callbackError({ error: response?.data.error });

      })
      .catch(e => {
        dispatch({
          type: CREATE_NEW_CHATROOM_ERROR,
          payload: { error: {} }
        });
        callbackError && callbackError(e?.response?.data?.error);
      });
  };
};

