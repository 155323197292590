import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  SKILL_GET_LIST, SKILL_ADD_ONE, SKILL_GET_ONE, SKILL_EDIT_ONE, SKILL_DELETE_ONE
} from "../actions";

import {
  getSkillsListSuccess,
  getSkillsListError,

  getOneSkillSuccess,
  getOneSkillError,

  addSkillSuccess,
  addSkillError,

  editSkillSuccess,
  editSkillError,
  deleteOneSkillSuccess,
  deleteOneSkillError,
} from "./actions";


//GET LIST
const getSkillsListRequest = async (payload) => {
  return await axios.get(`${api.skills}/list/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getSkillsList({ payload }) {
  try {
    const ret = yield call(getSkillsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getSkillsListSuccess({ skills: ret.data.skills || [], count: ret.data.count || 0 }));

    else yield put(getSkillsListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getSkillsListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOneSkillsRequest = async (payload) => {
  return await axios.get(`${api.skills}/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneSkill({ payload }) {
  try {
    const ret = yield call(getOneSkillsRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneSkillSuccess({ skill: ret.data.skill }));

    else yield put(getOneSkillError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneSkillError({ error, message: (error) ? error.message : '' }));
  }
}




//ADD ONE
const addSkillRequest = async (payload) => {
  return await axios.post(api.skills, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addSkill({ payload }) {
  try {
    const ret = yield call(addSkillRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/my-institutions/skills')
      yield put(addSkillSuccess({ skill: ret.data.skill }));
    }
    else yield put(addSkillError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addSkillError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneSkillRequest = async (payload) => {
  return await axios.patch(`${api.skills}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneSkill({ payload }) {
  try {
    const ret = yield call(editOneSkillRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/my-institutions/skills')
      yield put(editSkillSuccess({ skill: ret.data.skill }));
    }
    else yield put(editSkillError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editSkillError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneSkillRequest = async (payload) => {
  return await axios.delete(`${api.skills}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneSkill({ payload }) {
  try {
    const ret = yield call(deleteOneSkillRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/my-institutions/skills')
      yield put(deleteOneSkillSuccess({ index }));
    }
    else yield put(deleteOneSkillError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneSkillError({ error, message: (error) ? error.message : '' }));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(SKILL_GET_LIST, getSkillsList);
}

export function* watchGetOne() {
  yield takeEvery(SKILL_GET_ONE, getOneSkill);
}

export function* watchEditOne() {
  yield takeEvery(SKILL_EDIT_ONE, editOneSkill);
}

export function* watchAddOne() {
  yield takeEvery(SKILL_ADD_ONE, addSkill);
}

export function* watchDeleteOne() {
  yield takeEvery(SKILL_DELETE_ONE, deleteOneSkill);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
