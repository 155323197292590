import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  SANCTION_TYPES_GET_LIST, SANCTION_TYPES_GET_ONE, SANCTION_TYPES_ADD_ONE, SANCTION_TYPES_EDIT_ONE, SANCTION_TYPES_DELETE_ONE, SANCTION_TYPES_DELETE_MANY, SANCTION_TYPES_GET_BY_FILTER
} from "../actions";

import {
  getSanctionTypesListSuccess,
  getSanctionTypesListError,

  getOneSanctionTypesSuccess,
  getOneSanctionTypesError,

  addSanctionTypesSuccess,
  addSanctionTypesError,


  editSanctionTypesSuccess,
  editSanctionTypesError,

  deleteOneSanctionTypesSuccess,
  deleteOneSanctionTypesError,

  deleteManySanctionTypesSuccess,
  deleteManySanctionTypesError,

  getSanctionTypesByFilterSuccess,
  getSanctionTypesByFilterError
} from "./actions";


//GET LIST
const getSanctionTypesListRequest = async (payload) => {
  return await axios.get(api.sanction_types + `/list/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getSanctionTypesList({ payload }) {
  try {
    const ret = yield call(getSanctionTypesListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getSanctionTypesListSuccess({ sanction_types: ret.data.sanction_types || [], count: ret.data.count || 0 }));

    else yield put(getSanctionTypesListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getSanctionTypesListError({ error, message: (error) ? error.message : '' }));
  }
}


//GET ONE
const getOneSanctionTypesRequest = async (payload) => {
  return await axios.get(api.sanction_types + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneSanctionTypes({ payload }) {
  try {
    const ret = yield call(getOneSanctionTypesRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneSanctionTypesSuccess({ sanction_type: ret.data.sanction_type }));

    else yield put(getOneSanctionTypesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneSanctionTypesError({ error, message: (error) ? error.message : '' }));
  }
}


//GET LIST BY FILTER

const getSanctionTypesByFilterRequest = async (payload) => {
  return await axios.post(api.sanction_types + `/filters/${payload.inst_id}/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* getSanctionTypesByFilter({ payload }) {
  try {
    const ret = yield call(getSanctionTypesByFilterRequest, payload);

    if (ret && ret.status === 200)
      yield put(getSanctionTypesByFilterSuccess({ sanction_types: ret.data.sanction_types || [], count: ret.data.count || 0 }));

    else yield put(getSanctionTypesByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getSanctionTypesByFilterError({ error, message: (error) ? error.message : '' }));
  }
}

//ADD ONE
const addSanctionTypesRequest = async (payload) => {


  return await axios.post(api.sanction_types, payload.data, {
    headers: { Authorization: payload.token },
    //'content-type': 'multipart/form-data' 
  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addSanctionTypes({ payload }) {
  try {
    const ret = yield call(addSanctionTypesRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/schooling/sanctions/sanction-types')
      yield put(addSanctionTypesSuccess({ sanction_type: ret.data.sanction_type }));
    }
    else yield put(addSanctionTypesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addSanctionTypesError({ error, message: (error) ? error.message : '' }));
  }
}


//EDIT ONE
const editSanctionTypesRequest = async (payload) => {
  return await axios.patch(`${api.sanction_types}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editSanctionTypes({ payload }) {
  try {
    const ret = yield call(editSanctionTypesRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/app/schooling/sanctions/sanction-types')
      yield put(editSanctionTypesSuccess({ sanction_type: ret.data.sanction_type }));
    }
    else yield put(editSanctionTypesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editSanctionTypesError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneSanctionTypesRequest = async (payload) => {
  return await axios.delete(`${api.sanction_types}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneSanctionTypes({ payload }) {
  try {
    const ret = yield call(deleteOneSanctionTypesRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/schooling/sanctions/sanction-types')
      yield put(deleteOneSanctionTypesSuccess({ index }));
    }
    else yield put(deleteOneSanctionTypesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneSanctionTypesError({ error, message: (error) ? error.message : '' }));
  }
}

//delete many
const deleteManySanctionTypesRequest = async (payload) => {
  return await axios.delete(`${api.sanction_types}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManySanctionTypes({ payload }) {
  try {
    const ret = yield call(deleteManySanctionTypesRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/app/schooling/sanctions/sanction-types')
      yield put(deleteManySanctionTypesSuccess({ data }));
    }
    else yield put(deleteManySanctionTypesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManySanctionTypesError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(SANCTION_TYPES_GET_LIST, getSanctionTypesList);
}

export function* watchGetOne() {
  yield takeEvery(SANCTION_TYPES_GET_ONE, getOneSanctionTypes);
}
export function* watchGetListByFilter() {
  yield takeEvery(SANCTION_TYPES_GET_BY_FILTER, getSanctionTypesByFilter);
}
export function* watchEditOne() {
  yield takeEvery(SANCTION_TYPES_EDIT_ONE, editSanctionTypes);
}

export function* watchAddOne() {
  yield takeEvery(SANCTION_TYPES_ADD_ONE, addSanctionTypes);
}

export function* watchDeleteOne() {
  yield takeEvery(SANCTION_TYPES_DELETE_ONE, deleteOneSanctionTypes);
}
export function* watchDeleteMany() {
  yield takeEvery(SANCTION_TYPES_DELETE_MANY, deleteManySanctionTypes);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetListByFilter),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)

  ]);
}