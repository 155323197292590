import PeriodTypeSrv from '../../../services/PeriodTypeSrv';
import {
  PERIODTYPE_CONFIG_GET_LIST,
  PERIODTYPE_CONFIG_GET_LIST_SUCCESS,
  PERIODTYPE_CONFIG_GET_LIST_ERROR,

  PERIODTYPE_CONFIG_GET_LIST_FILTER,
  PERIODTYPE_CONFIG_GET_LIST_FILTER_SUCCESS,
  PERIODTYPE_CONFIG_GET_LIST_FILTER_ERROR,

  PERIODTYPE_CONFIG_GET_ONE,
  PERIODTYPE_CONFIG_GET_ONE_SUCCESS,
  PERIODTYPE_CONFIG_GET_ONE_ERROR,

  PERIODTYPE_CONFIG_GET_ACTUEL,
  PERIODTYPE_CONFIG_GET_ACTUEL_SUCCESS,
  PERIODTYPE_CONFIG_GET_ACTUEL_ERROR,

  PERIODTYPE_CONFIG_SELECT_PERIOD,

  PERIODTYPE_CONFIG_ADD_ONE,
  PERIODTYPE_CONFIG_ADD_ONE_SUCCESS,
  PERIODTYPE_CONFIG_ADD_ONE_ERROR,

  PERIODTYPE_CONFIG_EDIT_ONE,
  PERIODTYPE_CONFIG_EDIT_ONE_SUCCESS,
  PERIODTYPE_CONFIG_EDIT_ONE_ERROR,

  PERIODTYPE_CONFIG_DELETE_ONE,
  PERIODTYPE_CONFIG_DELETE_ONE_SUCCESS,
  PERIODTYPE_CONFIG_DELETE_ONE_ERROR,

  PERIODTYPES_CONFIG_DELETE_MANY,
  PERIODTYPES_CONFIG_DELETE_MANY_SUCCESS,
  PERIODTYPES_CONFIG_DELETE_MANY_ERROR
} from '../../actions';

// GET LIST
export const getPeriodTypesConfigList = (payload) => ({
  type: PERIODTYPE_CONFIG_GET_LIST,
  payload
});
export const getPeriodTypesConfigListSuccess = (payload) => ({
  type: PERIODTYPE_CONFIG_GET_LIST_SUCCESS,
  payload
});
export const getPeriodTypesConfigListError = (payload) => ({
  type: PERIODTYPE_CONFIG_GET_LIST_ERROR,
  payload
});
export const getPeriodTypesConfigByFilterCB = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PERIODTYPE_CONFIG_GET_LIST_FILTER,
    });

    PeriodTypeSrv.getListConfig(payload)
      .then((response) => {
        if (response && response?.data) {
          const resp = {
            period_types: response?.data?.period_types,
            count: response.data.count
          }

          dispatch({
            type: PERIODTYPE_CONFIG_GET_LIST_FILTER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PERIODTYPE_CONFIG_GET_LIST_FILTER_ERROR,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// GET SELECTED PERIOD 
export const getSelectedPeriodConfig = (payload) => ({
  type: PERIODTYPE_CONFIG_SELECT_PERIOD,
  payload
});




// GET ONE
export const getOnePeriodTypeConfig = (payload) => ({
  type: PERIODTYPE_CONFIG_GET_ONE,
  payload
});
export const getOnePeriodTypeConfigSuccess = (payload) => ({
  type: PERIODTYPE_CONFIG_GET_ONE_SUCCESS,
  payload
});
export const getOnePeriodTypeConfigError = (payload) => ({
  type: PERIODTYPE_CONFIG_GET_ONE_ERROR,
  payload
});

//GET ACTUAL
export const getPeriodTypeActuelConfig = (payload) => ({
  type: PERIODTYPE_CONFIG_GET_ACTUEL,
  payload
});
export const getPeriodTypeActuelConfigSuccess = (payload) => ({
  type: PERIODTYPE_CONFIG_GET_ACTUEL_SUCCESS,
  payload
});
export const getPeriodTypeActuelConfigError = (payload) => ({
  type: PERIODTYPE_CONFIG_GET_ACTUEL_ERROR,
  payload
});








// ADD ONE
export const addPeriodTypeConfig = (payload) => ({
  type: PERIODTYPE_CONFIG_ADD_ONE,
  payload
});
export const addPeriodTypeConfigSuccess = (payload) => ({
  type: PERIODTYPE_CONFIG_ADD_ONE_SUCCESS,
  payload
});
export const addPeriodTypeConfigError = (payload) => ({
  type: PERIODTYPE_CONFIG_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editPeriodTypeConfig = (payload) => ({
  type: PERIODTYPE_CONFIG_EDIT_ONE,
  payload
});
export const editPeriodTypeConfigSuccess = (payload) => ({
  type: PERIODTYPE_CONFIG_EDIT_ONE_SUCCESS,
  payload
});
export const editPeriodTypeConfigError = (payload) => ({
  type: PERIODTYPE_CONFIG_EDIT_ONE_ERROR,
  payload
});




// EDIT ONE
export const deleteOnePeriodTypeConfig = (payload) => ({
  type: PERIODTYPE_CONFIG_DELETE_ONE,
  payload
});
export const deleteOnePeriodTypeConfigSuccess = (payload) => ({
  type: PERIODTYPE_CONFIG_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOnePeriodTypeConfigError = (payload) => ({
  type: PERIODTYPE_CONFIG_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyPeriodTypesConfig = (payload) => ({
  type: PERIODTYPES_CONFIG_DELETE_MANY,
  payload
});
export const deleteManyPeriodTypesConfigSuccess = (payload) => ({
  type: PERIODTYPES_CONFIG_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyPeriodTypesConfigError = (payload) => ({
  type: PERIODTYPES_CONFIG_DELETE_MANY_ERROR,
  payload
});