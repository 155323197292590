import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../../constants/defaultValues";
import axios from "axios";

import {
  SPECIALITY_CONFIG_GET_LIST,
  SPECIALITY_CONFIG_ADD_ONE,
  SPECIALITY_CONFIG_GET_ONE,
  SPECIALITY_CONFIG_EDIT_ONE,
  SPECIALITY_CONFIG_DELETE_ONE,
  SPECIALITY_CONFIG_DELETE_MANY,
  SPECIALITY_CONFIG_GET_BY_FILTER
} from "../../actions";

import {
  getSpecialityConfigListSuccess,
  getSpecialityConfigListError,

  getOneSpecialityConfigSuccess,
  getOneSpecialityConfigError,

  getSpecialitiesConfigByFilterSuccess,
  getSpecialitiesConfigByFilterError,

  addSpecialityConfigSuccess,
  addSpecialityConfigError,

  editSpecialityConfigSuccess,
  editSpecialityConfigError,

  deleteOneSpecialityConfigSuccess,
  deleteOneSpecialityConfigError,

  deleteManySpecialityConfigSuccess,
  deleteManySpecialityConfigError
  
} from "./actions";


//GET LIST
const getSpecialitysListRequest = async (payload) => {
  return await axios.get(api.specialities + `/list-admin/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getSpecialityConfigList({ payload }) {
  try {
    const ret = yield call(getSpecialitysListRequest, payload);

    // console.log("RET", ret);


    if (ret && ret.status === 200)
      yield put(getSpecialityConfigListSuccess({ specialities: ret.data.specialities || [], count: ret.data.count || 0 }));

    else yield put(getSpecialityConfigListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getSpecialityConfigListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOneSpecialitysRequest = async (payload) => {
  return await axios.get(api.specialities + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneSpecialityConfig({ payload }) {
  try {
    const ret = yield call(getOneSpecialitysRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneSpecialityConfigSuccess({ speciality: ret.data.speciality }));

    else yield put(getOneSpecialityConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneSpecialityConfigError({ error, message: (error) ? error.message : '' }));
  }
}


//GET BY FILTER
const getSpecialitiesByFilterRequest = async (payload) => {
  return await axios.post(api.specialities + `/filters/${payload.inst_id}/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getSpecialitiesConfigByFilter({ payload }) {
  try {
    const ret = yield call(getSpecialitiesByFilterRequest, payload);


    if (ret && ret.status === 200)
      yield put(getSpecialitiesConfigByFilterSuccess({ specialities: ret.data.specialities, count: ret.data.count || 0 }));

    else yield put(getSpecialitiesConfigByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getSpecialitiesConfigByFilterError({ error, message: (error) ? error.message : '' }));
  }
}

//ADD ONE
const addSpecialityRequest = async (payload) => {
  return await axios.post(api.specialities, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addSpecialityConfig({ payload }) {
  try {
    const ret = yield call(addSpecialityRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/admin/settings/specialities/list')
      yield put(addSpecialityConfigSuccess({ speciality: ret.data.speciality }));
    }
    else yield put(addSpecialityConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addSpecialityConfigError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneSpecialityRequest = async (payload) => {
  return await axios.patch(`${api.specialities}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneSpeciality({ payload }) {
  try {
    const ret = yield call(editOneSpecialityRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/admin/settings/specialities/list')
      yield put(editSpecialityConfigSuccess({ speciality: ret.data.speciality }));
    }
    else yield put(editSpecialityConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editSpecialityConfigError({ error, message: (error) ? error.message : '' }));
  }
}


// DELETE ONE
const deleteOneSpecialityRequest = async (payload) => {
  return await axios.delete(`${api.specialities}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneSpecialityConfig({ payload }) {
  try {
    const ret = yield call(deleteOneSpecialityRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/admin/settings/specialities/list')
      yield put(deleteOneSpecialityConfigSuccess({ index }));
    }
    else yield put(deleteOneSpecialityConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneSpecialityConfigError({ error, message: (error) ? error.message : '' }));
  }
}
//DELETE MANY

const deleteManySpecialitiesRequest = async (payload) => {
  return await axios.delete(`${api.specialities}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManySpecialityConfig({ payload }) {
  try {
    const ret = yield call(deleteManySpecialitiesRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/admin/settings/specialities/list')
      yield put(deleteManySpecialityConfigSuccess({ data }));
    }
    else yield put(deleteManySpecialityConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManySpecialityConfigError({ error, message: (error) ? error.message : '' }));
  }
}

//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(SPECIALITY_CONFIG_GET_LIST, getSpecialityConfigList);
}

export function* watchGetOne() {
  yield takeEvery(SPECIALITY_CONFIG_GET_ONE, getOneSpecialityConfig);
}
export function* watchGetFilter() {
  yield takeEvery(SPECIALITY_CONFIG_GET_BY_FILTER, getSpecialitiesConfigByFilter);
}
export function* watchEditOne() {
  yield takeEvery(SPECIALITY_CONFIG_EDIT_ONE, editOneSpeciality);
}

export function* watchAddOne() {
  yield takeEvery(SPECIALITY_CONFIG_ADD_ONE, addSpecialityConfig);
}

export function* watchDeleteOne() {
  yield takeEvery(SPECIALITY_CONFIG_DELETE_ONE, deleteOneSpecialityConfig);
}
export function* watchDeleteMany() {
  yield takeEvery(SPECIALITY_CONFIG_DELETE_MANY, deleteManySpecialityConfig);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetFilter),

    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany),
  ]);
}
