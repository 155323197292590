import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../../constants/defaultValues";
import axios from "axios";

import {
  SCHOOLYEAR_CONFIG_GET_LIST,
  SCHOOLYEAR_CONFIG_ADD_ONE,
  SCHOOLYEAR_CONFIG_GET_ONE,
  SCHOOLYEAR_CONFIG_EDIT_ONE,
  SCHOOLYEAR_CONFIG_DELETE_ONE,
  SCHOOLYEAR_CONFIG_DELETE_MANY,
  SCHOOLYEAR_CONFIG_GET_BY_FILTER
} from "../../actions";

import {
  getSchoolYearsConfigListSuccess,
  getSchoolYearsConfigListError,

  getOneSchoolYearConfigSuccess,
  getOneSchoolYearConfigError,

  getSchoolYearsConfigByFilterSuccess,
  getSchoolYearsConfigByFilterError,

  addSchoolYearConfigSuccess,
  addSchoolYearConfigError,

  editSchoolYearConfigSuccess,
  editSchoolYearConfigError,
  deleteOneSchoolYearConfigError,
  deleteOneSchoolYearConfigSuccess,

  deleteManySchoolYearsConfigSuccess,
  deleteManySchoolYearsConfigError,
} from "./actions";


//GET LIST
const getSchoolYearsListRequest = async (payload) => {
  return await axios.get(api.school_years + `/list-admin/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getSchoolYearsConfigList({ payload }) {
  try {
    const ret = yield call(getSchoolYearsListRequest, payload);

    if (ret && ret.status === 200)
      yield put(getSchoolYearsConfigListSuccess({ school_years: ret.data.school_years || [], count: ret.data.count || 0 }));

    else yield put(getSchoolYearsConfigListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getSchoolYearsConfigListError({ error, message: (error) ? error.message : '' }));
  }
}



//GET ONE
const getOneSchoolYearsRequest = async (payload) => {
  return await axios.get(api.school_years + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneSchoolYearConfig({ payload }) {
  try {
    const ret = yield call(getOneSchoolYearsRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneSchoolYearConfigSuccess({ school_year: ret.data.school_year }));

    else yield put(getOneSchoolYearConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneSchoolYearConfigError({ error, message: (error) ? error.message : '' }));
  }
}

//GET BY FILTER
const getSchoolYearsByFilterRequest = async (payload) => {
  return await axios.post(api.school_years + `/filters/${payload.inst_id}/${payload.offset}/${payload.limit}`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getSchoolYearsConfigByFilter({ payload }) {
  try {
    const ret = yield call(getSchoolYearsByFilterRequest, payload);


    if (ret && ret.status === 200)
      yield put(getSchoolYearsConfigByFilterSuccess({ school_years: ret.data.school_years, count: ret.data.count || 0 }));

    else yield put(getSchoolYearsConfigByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getSchoolYearsConfigByFilterError({ error, message: (error) ? error.message : '' }));
  }
}


//ADD ONE
const addSchoolYearRequest = async (payload) => {
  return await axios.post(api.school_years, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addSchoolYearConfig({ payload }) {
  try {
    const ret = yield call(addSchoolYearRequest, payload);

    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/admin/settings/school-years/list')
      yield put(addSchoolYearConfigSuccess({ school_year: ret.data.school_year }));
    }
    else yield put(addSchoolYearConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addSchoolYearConfigError({ error, message: (error) ? error.message : '' }));
  }
}





//EDIT ONE
const editOneSchoolYearRequest = async (payload) => {
  return await axios.patch(`${api.school_years}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneSchoolYear({ payload }) {
  try {
    const ret = yield call(editOneSchoolYearRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      if (history) history.push('/admin/settings/school-years/list')
      yield put(editSchoolYearConfigSuccess({ school_year: ret.data.school_year }));
    }
    else yield put(editSchoolYearConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editSchoolYearConfigError({ error, message: (error) ? error.message : '' }));
  }
}






// DELETE ONE
const deleteOneSchoolYearRequest = async (payload) => {
  return await axios.delete(`${api.school_years}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneSchoolYearConfig({ payload }) {
  try {
    const ret = yield call(deleteOneSchoolYearRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/admin/settings/school-years/list')
      yield put(deleteOneSchoolYearConfigSuccess({ index }));
    }
    else yield put(deleteOneSchoolYearConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneSchoolYearConfigError({ error, message: (error) ? error.message : '' }));
  }
}



//DELETE MANY

const deleteManySchoolYearsRequest = async (payload) => {
  return await axios.delete(`${api.school_years}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManySchoolYearsConfig({ payload }) {
  try {
    const ret = yield call(deleteManySchoolYearsRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/app/my-institutions/school-years')
      yield put(deleteManySchoolYearsConfigSuccess({ data }));
    }
    else yield put(deleteManySchoolYearsConfigError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManySchoolYearsConfigError({ error, message: (error) ? error.message : '' }));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(SCHOOLYEAR_CONFIG_GET_LIST, getSchoolYearsConfigList);
}

export function* watchGetOne() {
  yield takeEvery(SCHOOLYEAR_CONFIG_GET_ONE, getOneSchoolYearConfig);
}
export function* watchGetFilter() {
  yield takeEvery(SCHOOLYEAR_CONFIG_GET_BY_FILTER, getSchoolYearsConfigByFilter);
}

export function* watchEditOne() {
  yield takeEvery(SCHOOLYEAR_CONFIG_EDIT_ONE, editOneSchoolYear);
}

export function* watchAddOne() {
  yield takeEvery(SCHOOLYEAR_CONFIG_ADD_ONE, addSchoolYearConfig);
}


export function* watchDeleteOne() {
  yield takeEvery(SCHOOLYEAR_CONFIG_DELETE_ONE, deleteOneSchoolYearConfig);
}
export function* watchDeleteMany() {
  yield takeEvery(SCHOOLYEAR_CONFIG_DELETE_MANY, deleteManySchoolYearsConfig);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetFilter),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany),
  ]);
}
