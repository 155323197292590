import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  PARENTS_SCHOOL_GET_LIST, PARENTS_SCHOOL_GET_ONE, PARENTS_SCHOOL_ADD_ONE, PARENTS_SCHOOL_EDIT_ONE, PARENTS_SCHOOL_DELETE_ONE, PARENTS_SCHOOL_GET_LIST_BY_FILTER
  , PARENTS_SCHOOL_DELETE_MANY
} from "../actions";

import {
  getParentsSchoolListSuccess,
  getParentsSchoolListError,

  getParentsSchoolByFilterSuccess,
  getParentsSchoolByFilterError,

  getOneParentsSchoolSuccess,
  getOneParentsSchoolError,

  addParentsSchoolSuccess,
  addParentsSchoolError,

  editOneParentsSchoolSuccess,
  editOneParentsSchoolError,

  deleteOneParentsSchoolSuccess,
  deleteOneParentsSchoolError,
  deleteManyParentsSchoolSuccess,
  deleteManyParentsSchoolError
} from "./actions";

//GET LIST
const getParentsSchoolListRequest = async (payload) => {
  return await axios.get(api.parents_school + `/list/${payload.inst_id}/${payload.offset}/${payload.limit}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getParentsSchoolList({ payload }) {
  try {
    const ret = yield call(getParentsSchoolListRequest, payload);
    //console.log("////// getParentsSchoolList  :",ret);

    if (ret && ret.status === 200)
      yield put(getParentsSchoolListSuccess({ parentsSchoolList: ret.data.parent_schools || [], count: ret.data.count || 0 }));


  } catch (error) {
    yield put(getParentsSchoolListError({ error, message: (error) ? error.message : '' }));
  }
}

//GET LIST By FILTER
const getParentsSchoolByFilterRequest = async (payload) => {

  return await axios.post(api.parents_school + `/filters`, payload.filter)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getParentsSchoolByFilter({ payload }) {
  try {
    const ret = yield call(getParentsSchoolByFilterRequest, payload);
    //console.log("////// getParentsSchoolByFilter  :",ret);

    if (ret && ret.status === 200)
      yield put(getParentsSchoolByFilterSuccess({ parentsSchoolList: ret.data.parent_schools || [], count: ret.data.count || 0 }));

    else yield put(getParentsSchoolByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getParentsSchoolByFilterError({ error, message: (error) ? error.message : '' }));
  }
}

//GET ONE
const getOneParentsSchoolRequest = async (payload) => {
  return await axios.get(api.parents_school + `/get-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneParentsSchool({ payload }) {
  try {
    const ret = yield call(getOneParentsSchoolRequest, payload);

    if (ret && ret.status === 200)
      yield put(getOneParentsSchoolSuccess({ parentsSchool: ret.data.parent_school }));
    else yield put(getOneParentsSchoolError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(getOneParentsSchoolError({ error, message: (error) ? error.message : '' }));
  }
}


//ADD ONE
const addParentsSchoolRequest = async (payload) => {
  return await axios.post(api.parents_school, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addParentsSchool({ payload }) {
  try {
    const ret = yield call(addParentsSchoolRequest, payload);


    if (ret && ret.status === 201) {
      const { history } = payload;

      if (history) history.push('/app/community/messages-sent')
      yield put(addParentsSchoolSuccess({ parentsSchool: ret.data.parent_school }));
    }
    else yield put(addParentsSchoolError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(addParentsSchoolError({ error, message: (error) ? error.message : '' }));
  }
}

//EDIT ONE
const editOneParentsSchoolRequest = async (payload) => {
  return await axios.patch(`${api.parents_school}/edit-one/${payload._id}`, payload.data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneParentsSchool({ payload }) {
  try {
    const ret = yield call(editOneParentsSchoolRequest, payload);

    if (ret && ret.status === 200) {
      const { history } = payload;

      yield put(editOneParentsSchoolSuccess({ parentsSchool: ret.data.parent_school }));
      if (history) history.goBack()

    }
    else yield put(editOneParentsSchoolError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editOneParentsSchoolError({ error, message: (error) ? error.message : '' }));
  }
}

// DELETE ONE
const deleteOneParentsSchoolRequest = async (payload) => {
  return await axios.delete(`${api.parents_school}/delete-one/${payload._id}`)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneParentsSchool({ payload }) {
  try {
    const ret = yield call(deleteOneParentsSchoolRequest, payload);

    if (ret && ret.status === 202) {
      const { history, index } = payload;

      if (history) history.push('/app/community/parents-school')
      yield put(deleteOneParentsSchoolSuccess({ index }));
    }
    else yield put(deleteOneParentsSchoolError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneParentsSchoolError({ error, message: (error) ? error.message : '' }));
  }
}

//DELETE MANY
const deleteManyParentsSchoolRequest = async (payload) => {
  return await axios.delete(`${api.parents_school}/delete-many`, {data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyParentsSchool({ payload }) {
  try {
    const ret = yield call(deleteManyParentsSchoolRequest, payload);

    if (ret && ret.status === 202) {
      const { history, data } = payload;

      if (history) history.push('/app/community/parents-school')
      yield put(deleteManyParentsSchoolSuccess({ data }));
    }
    else yield put(deleteManyParentsSchoolError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyParentsSchoolError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(PARENTS_SCHOOL_GET_LIST, getParentsSchoolList);
}

export function* watchGetListByFilter() {
  yield takeEvery(PARENTS_SCHOOL_GET_LIST_BY_FILTER, getParentsSchoolByFilter);
}

export function* watchGetOne() {
  yield takeEvery(PARENTS_SCHOOL_GET_ONE, getOneParentsSchool);
}

export function* watchEditOne() {
  yield takeEvery(PARENTS_SCHOOL_EDIT_ONE, editOneParentsSchool);
}

export function* watchAddOne() {
  yield takeEvery(PARENTS_SCHOOL_ADD_ONE, addParentsSchool);
}

export function* watchDeleteOne() {
  yield takeEvery(PARENTS_SCHOOL_DELETE_ONE, deleteOneParentsSchool);
}
export function* watchDeleteMany() {
  yield takeEvery(PARENTS_SCHOOL_DELETE_MANY, deleteManyParentsSchool);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetListByFilter),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)
  ]);
}