import {
  DIPLOMA_CONFIG_GET_LIST,
  DIPLOMA_CONFIG_GET_LIST_SUCCESS,
  DIPLOMA_CONFIG_GET_LIST_ERROR,

  DIPLOMA_CONFIG_GET_ONE,
  DIPLOMA_CONFIG_GET_ONE_SUCCESS,
  DIPLOMA_CONFIG_GET_ONE_ERROR,

  DIPLOMA_CONFIG_GET_BY_FILTER,
  DIPLOMA_CONFIG_GET_BY_FILTER_SUCCESS,
  DIPLOMA_CONFIG_GET_BY_FILTER_ERROR,

  DIPLOMA_CONFIG_ADD_ONE,
  DIPLOMA_CONFIG_ADD_ONE_SUCCESS,
  DIPLOMA_CONFIG_ADD_ONE_ERROR,

  DIPLOMA_CONFIG_EDIT_ONE,
  DIPLOMA_CONFIG_EDIT_ONE_SUCCESS,
  DIPLOMA_CONFIG_EDIT_ONE_ERROR,

  DIPLOMA_CONFIG_DELETE_ONE,
  DIPLOMA_CONFIG_DELETE_ONE_SUCCESS,
  DIPLOMA_CONFIG_DELETE_ONE_ERROR,

  DIPLOMAS_CONFIG_DELETE_MANY,
  DIPLOMAS_CONFIG_DELETE_MANY_SUCCESS,
  DIPLOMAS_CONFIG_DELETE_MANY_ERROR
} from '../../actions';
// GET LIST
export const getDiplomasConfigList = (payload) => ({
  type: DIPLOMA_CONFIG_GET_LIST,
  payload
});
export const getDiplomasConfigListSuccess = (payload) => ({
  type: DIPLOMA_CONFIG_GET_LIST_SUCCESS,
  payload
});
export const getDiplomasConfigListError = (payload) => ({
  type: DIPLOMA_CONFIG_GET_LIST_ERROR,
  payload
});







// GET LIST
export const getOneDiplomaConfig = (payload) => ({
  type: DIPLOMA_CONFIG_GET_ONE,
  payload
});
export const getOneDiplomaConfigSuccess = (payload) => ({
  type: DIPLOMA_CONFIG_GET_ONE_SUCCESS,
  payload
});
export const getOneDiplomaConfigError = (payload) => ({
  type: DIPLOMA_CONFIG_GET_ONE_ERROR,
  payload
});



// GET BY FILTER

export const getDiplomasConfigByFilter = (payload) => ({
  type: DIPLOMA_CONFIG_GET_BY_FILTER,
  payload
});
export const getDiplomasConfigByFilterSuccess = (payload) => ({
  type: DIPLOMA_CONFIG_GET_BY_FILTER_SUCCESS,
  payload
});
export const getDiplomasConfigByFilterError = (payload) => ({
  type: DIPLOMA_CONFIG_GET_BY_FILTER_ERROR,
  payload
});




// ADD ONE
export const addDiplomaConfig = (payload) => ({
  type: DIPLOMA_CONFIG_ADD_ONE,
  payload
});
export const addDiplomaConfigSuccess = (payload) => ({
  type: DIPLOMA_CONFIG_ADD_ONE_SUCCESS,
  payload
});
export const addDiplomaConfigError = (payload) => ({
  type: DIPLOMA_CONFIG_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editDiplomaConfig = (payload) => ({
  type: DIPLOMA_CONFIG_EDIT_ONE,
  payload
});
export const editDiplomaConfigSuccess = (payload) => ({
  type: DIPLOMA_CONFIG_EDIT_ONE_SUCCESS,
  payload
});
export const editDiplomaConfigError = (payload) => ({
  type: DIPLOMA_CONFIG_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneDiplomaConfig = (payload) => ({
  type: DIPLOMA_CONFIG_DELETE_ONE,
  payload
});
export const deleteOneDiplomaConfigSuccess = (payload) => ({
  type: DIPLOMA_CONFIG_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneDiplomaConfigError = (payload) => ({
  type: DIPLOMA_CONFIG_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyDiplomasConfig = (payload) => ({
  type: DIPLOMAS_CONFIG_DELETE_MANY,
  payload
});
export const deleteManyDiplomasConfigSuccess = (payload) => ({
  type: DIPLOMAS_CONFIG_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyDiplomasConfigError = (payload) => ({
  type: DIPLOMAS_CONFIG_DELETE_MANY_ERROR,
  payload
});