import {
  COURSE_GET_LIST,
  COURSE_GET_LIST_SUCCESS,
  COURSE_GET_LIST_ERROR,

  LEARNINGS_GET_LIST,
  LEARNINGS_GET_LIST_SUCCESS,
  LEARNINGS_GET_LIST_ERROR,


  COURSE_GET_PARTNER_NETWORK,

  COURSE_SUBSCRIBE,
  COURSE_SUBSCRIBE_SUCCESS,
  COURSE_SUBSCRIBE_ERROR,

  COURSE_GET_ONE,
  COURSE_GET_ONE_SUCCESS,
  COURSE_GET_ONE_ERROR,

  COURSE_ADD_ONE,
  COURSE_ADD_ONE_SUCCESS,
  COURSE_ADD_ONE_ERROR,

  COURSE_EDIT_ONE,
  COURSE_EDIT_ONE_SUCCESS,
  COURSE_EDIT_ONE_ERROR,

  COURSE_DELETE_ONE,
  COURSE_DELETE_ONE_SUCCESS,
  COURSE_DELETE_ONE_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'

const INIT_STATE = {
  courses: [],
  count: 0,
  course: null,
  error: '',
  learnings : [], 
  learnings_count : 0
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()


  switch (action.type) {
    case COURSE_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case COURSE_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        courses: action.payload.courses || [],
        count: action.payload.count || 0
      };
    case COURSE_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    case LEARNINGS_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case LEARNINGS_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        learnings: action.payload.courses || [],
        learnings_count: action.payload.count || 0
      };
    case LEARNINGS_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    case COURSE_GET_PARTNER_NETWORK:
      return {
        ...state, loading: true, error: '',
        count: 0
      };




    // GET ONE
    case COURSE_GET_ONE:
      return { ...state, loading: true, error: '' };
    case COURSE_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        course: action.payload.course
      };
    case COURSE_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









    // ADD ONE
    case COURSE_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case COURSE_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');

      return {
        ...state, loading: false,
        courses: [action.payload.course, ...state.courses],
        course: action.payload.course

      };
    case COURSE_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      ;
      return { ...state, loading: false, error: action.payload.message };






    // EDIT ONE
    case COURSE_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case COURSE_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      const new_courses = state.courses
      const course = action.payload.course
      for (let i = 0; i < new_courses.length; i++) {
        if (new_courses[i]._id === course._id) {
          new_courses[i] = course; break;
        }
      }

      return { ...state, loading: false, error: '', course, courses: new_courses };
    case COURSE_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    // TOGGLE SUBSCRIBE
    case COURSE_SUBSCRIBE:
      return { ...state, loading: true, error: '' };
    case COURSE_SUBSCRIBE_SUCCESS:
      // NotificationManager.success("Subscribe", "Success",3000, null, null, '');
      const obj = { course: action.payload.course }
      if (action.payload.courses) obj['courses'] = action.payload.courses
      return {
        ...state, loading: false, error: '',
        ...obj
      };
    case COURSE_SUBSCRIBE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    // DELETE ONE
    case COURSE_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case COURSE_DELETE_ONE_SUCCESS:
      const courses = state.courses
      if (action.payload.index !== null && action.payload.index !== undefined)
        courses.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        courses
      };
    case COURSE_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    default: return { ...state };
  }
}
