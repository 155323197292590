import {
  EXAMTYPES_GET_LIST,
  EXAMTYPES_GET_LIST_SUCCESS,
  EXAMTYPES_GET_LIST_ERROR,

  EXAMTYPES_GET_ONE,
  EXAMTYPES_GET_ONE_SUCCESS,
  EXAMTYPES_GET_ONE_ERROR,

  EXAMTYPES_GET_BY_FILTER,
  EXAMTYPES_GET_BY_FILTER_SUCCESS,
  EXAMTYPES_GET_BY_FILTER_ERROR,

  EXAMTYPES_ADD_ONE,
  EXAMTYPES_ADD_ONE_SUCCESS,
  EXAMTYPES_ADD_ONE_ERROR,

  EXAMTYPES_EDIT_ONE,
  EXAMTYPES_EDIT_ONE_SUCCESS,
  EXAMTYPES_EDIT_ONE_ERROR,

  EXAMTYPES_DELETE_ONE,
  EXAMTYPES_DELETE_ONE_SUCCESS,
  EXAMTYPES_DELETE_ONE_ERROR,

  EXAMTYPES_DELETE_MANY,
  EXAMTYPES_DELETE_MANY_SUCCESS,
  EXAMTYPES_DELETE_MANY_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg';

const INIT_STATE = {
  exam_types: [],
  count: 0,
  exam_type: null,
  error: '',
};
export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

  switch (action.type) {
    //GET LIST
    case EXAMTYPES_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case EXAMTYPES_GET_LIST_SUCCESS:

      return {
        ...state, loading: false,
        exam_types: action.payload.exam_types,
        count: action.payload.count || 0

      };

    case EXAMTYPES_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };




    // GET ONE
    case EXAMTYPES_GET_ONE:
      return { ...state, loading: true, error: '' };
    case EXAMTYPES_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        exam_type: action.payload.exam_type
      };
    case EXAMTYPES_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };




    // GET BY FILTER
    case EXAMTYPES_GET_BY_FILTER:

      return { ...state, loading: true, error: '' };
    case EXAMTYPES_GET_BY_FILTER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        exam_types: action.payload.exam_types || [], count: action.payload.count || 0

      };
    case EXAMTYPES_GET_BY_FILTER_ERROR:

      return { ...state, loading: false, error: action.payload.message };


    // ADD ONE
    case EXAMTYPES_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case EXAMTYPES_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');

      return {
        ...state, loading: false,
        exam_types: [action.payload.exam_type, ...state.exam_types]
      };
    case EXAMTYPES_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



    // EDIT ONE
    case EXAMTYPES_EDIT_ONE:
      return { ...state, loading: true, edit_sucess: false, error: '' };
    case EXAMTYPES_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, edit_sucess: true, error: '',
        exam_type: action.payload.exam_type
      };
    case EXAMTYPES_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, edit_sucess: false, error: action.payload.message };



    // DELETE ONE
    case EXAMTYPES_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case EXAMTYPES_DELETE_ONE_SUCCESS:
      const exam_types = state.exam_types
      if (action.payload.index !== null && action.payload.index !== undefined)
        exam_types.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        exam_types
      };
    case EXAMTYPES_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    // DELETE MANY
    case EXAMTYPES_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case EXAMTYPES_DELETE_MANY_SUCCESS:
      const exam_types_list = state.exam_types


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_exam_type = -1
          for (let j = 0; j < exam_types_list.length; j++) {
            if (exam_types_list[j]._id === action.payload.data.ids[i]) {
              index_exam_type = j; break;
            }
          }
          if (index_exam_type > -1) exam_types_list.splice(index_exam_type, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        exam_types: exam_types_list
      };
    case EXAMTYPES_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    default: return { ...state };






  }
}