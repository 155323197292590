import React, { Component, Fragment } from "react";
import IntlMessages from "../helpers/IntlMessages";

const server_api = `${process.env.REACT_APP_API}/api`;
const server = process.env.REACT_APP_API;

const sms_server = process.env.REACT_APP_GTEXT || 'http://51.91.109.142:3000';

export const api = {
  root: server,
  sms_server: sms_server,
  users: `${server_api}/users`,

  //CONFIG
  institutions: `${server_api}/config/institutions`,
  school_years: `${server_api}/config/school-years`,
  period_types: `${server_api}/config/period-types`,
  education_levels: `${server_api}/config/education-levels`,
  school_levels: `${server_api}/config/school-levels`,
  diplomas: `${server_api}/config/diplomas`,
  specialities: `${server_api}/config/specialities`,
  subjects: `${server_api}/config/subjects`,
  skills: `${server_api}/config/skills`,
  classes: `${server_api}/config/classes`,
  modules: `${server_api}/config/modules`,
  rooms: `${server_api}/config/rooms`,
  configs: `${server_api}/config/configs`,

  requestManager:`${server_api}/config/requests`,

  //SCHOOLING
  partners: `${server_api}/schooling/partners`,
  teachers: `${server_api}/schooling/teachers`,
  parents: `${server_api}/schooling/parents`,
  students: `${server_api}/schooling/students`,
  homeworks: `${server_api}/schooling/homeworks`,
  sanctions: `${server_api}/schooling/sanctions`,
  sanction_types: `${server_api}/schooling/sanction-types`,
  exemptions: `${server_api}/schooling/exemptions`,
  absences: `${server_api}/schooling/absences`,
  sessions: `${server_api}/schooling/sessions`,
  timetables: `${server_api}/schooling/timetables`,
  assignments: `${server_api}/schooling/assignments`,
  network: `${server_api}/schooling/network`,


  //EXAMS
  exam_types: `${server_api}/exams/exam-types`,
  exam_periods: `${server_api}/exams/exam-periods`,
  exam_sessions: `${server_api}/exams/exam-sessions`,
  exam_notes: `${server_api}/exams/exam-notes`,
  exam_modules: `${server_api}/exams/exam-modules`,
  exam_results: `${server_api}/exams/exam-results`,
  mark_module: `${server_api}/exams/exam-mark-modules`,
  marks: `${server_api}/exams/exam-marks`,
  mark_note: `${server_api}/exams/exam-mark-notes`,
  teacher_comments: `${server_api}/exams/teacher-comments`,
  exams_training_center: `${server_api}/exams/exams-training-center`,
  exams: `${server_api}/exams`,

  notifications: `${server_api}/notifications`,

  /*billing*/
  taxes: `${server_api}/billing/taxes`,
  fees: `${server_api}/billing/fees`,
  fee_compositions: `${server_api}/billing/fee-compositions`,
  payments: `${server_api}/billing/payments`,
  bills: `${server_api}/billing/bills`,

  /*community*/
  appointements: `${server_api}/appointments`,
  parents_school: `${server_api}/parent-school`,
  contact_msg: `${server_api}/contact-msg`,
  /*cantine*/
  meal_types: `${server_api}/meal-types`,
  meals: `${server_api}/meals`,
  canteen_menus: `${server_api}/canteen-menus`,
  canteen_reservation_types: `${server_api}/canteen-rsvn-types`,
  canteen_reservations: `${server_api}/canteen-rsvns`,

  signals: `${server_api}/signals`,
  posts: `${server_api}/posts`,
  likes: `${server_api}/likes`,
  comments: `${server_api}/comments`,
  events: `${server_api}/events`,
  invitations: `${server_api}/invitations`,
  jobs: `${server_api}/job-offers`,
  trainings: `${server_api}/trainings`,
  courses: `${server_api}/courses`,
  quiz: `${server_api}/quiz`,
  tutorings: `${server_api}/tutorings`,
  groups: `${server_api}/groups`,
  chat: `${server_api}/chat`,
  recommendations:`${server_api}/recommendations`,

  bbb_server: 'https://live.higheredlab.com/api/v1/2d05de42'
}

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "fr";
export const localeOptions = [
  { id: "en", name: "English", direction: "ltr" },
  { id: "fr", name: "Français", direction: "ltr" },
  // { id: "ar", name: "العربية", direction: "rtl" }

  // { id: "es", name: "Español", direction: "ltr" },
  // { id: "enrtl", name: "English - RTL", direction: "rtl" }
];

export const firebaseConfig = {
  apiKey: "AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg",
  authDomain: "gogo-react-login.firebaseapp.com",
  // databaseURL: "https://gogo-react-login.firebaseio.com",
  projectId: "gogo-react-login",
  storageBucket: "gogo-react-login.appspot.com",
  messagingSenderId: "216495999563"
};

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = "light.purple"; //"light.blue"
export const defaultDirection = "ltr";
export const isDarkSwitchActive = true;
export const themeColorStorageKey = "__theme_color";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;

export const is_white_mark = false;

export const jobTypes = [
  { label: <IntlMessages id={"menu.full-time"} />, value: "FULL_TIME", key: 0 },
  { label: <IntlMessages id={"menu.part-time"} />, value: "PART_TIME", key: 1 },
  { label: <IntlMessages id={"menu.contract"} />, value: "CONTRACT", key: 2 },
  { label: <IntlMessages id={"menu.temporary"} />, value: "TEMPORARY", key: 3 },
  { label: <IntlMessages id={"menu.internship-type"} />, value: "INTERNSHIP", key: 4 },
]

export const jobLocationTypes = [
  { label: <IntlMessages id={"menu.remote"} />, value: "REMOTE", key: 0 },
  { label: <IntlMessages id={"menu.on-site"} />, value: "ON_SITE", key: 1 },
  { label: <IntlMessages id={"menu.hybrid"} />, value: "HYBRID", key: 2 },
]

export const icons = {
  attendance: '/assets/img/icons/attendance.png',
  events: '/assets/img/icons/events.png',
  exams: '/assets/img/icons/exams.png',
  homeworks: '/assets/img/icons/homeworks.png',
  inst: '/assets/img/icons/inst.png',
  job: '/assets/img/icons/job.png',
  members: '/assets/img/icons/members.png',
  timetable: '/assets/img/icons/timetable.png',
  training: '/assets/img/icons/training.png',
  tutors: '/assets/img/icons/tutors.png',
  canteen: '/assets/img/icons/canteen.png',
  appointement: '/assets/img/icons/appointement.png',
  user: '/assets/img/no-user.png',
  student_male: '/assets/img/student_male.jpeg',
  student_female: '/assets/img/student_female.png',

  teacher_male: '/assets/img/teacher_male.jpg',
  teacher_female: '/assets/img/teacher_female.jpg',

  father: '/assets/img/father.jpg',
  mother: '/assets/img/mother.png',

  empty_page: '/assets/img/empty_page.png',
  empty_list: '/assets/img/noresult.png',

  event: '/assets/img/events.png',
  cover: '/assets/img/icons/cover_img.png',
  admin: '/assets/img/icons/admin.png',
  payment: '/assets/img/icons/icon-paiement.png',
  envelope: '/assets/img/icons/envelope.png'
}

