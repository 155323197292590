import {
  COURSE_GET_LIST,
  COURSE_GET_LIST_SUCCESS,
  COURSE_GET_LIST_ERROR,

  LEARNINGS_GET_LIST,
  LEARNINGS_GET_LIST_SUCCESS,
  LEARNINGS_GET_LIST_ERROR,

  COURSE_GET_PARTNER_NETWORK,

  COURSE_SUBSCRIBE,
  COURSE_SUBSCRIBE_SUCCESS,
  COURSE_SUBSCRIBE_ERROR,

  COURSE_GET_ONE,
  COURSE_GET_ONE_SUCCESS,
  COURSE_GET_ONE_ERROR,

  COURSE_ADD_ONE,
  COURSE_ADD_ONE_SUCCESS,
  COURSE_ADD_ONE_ERROR,

  COURSE_EDIT_ONE,
  COURSE_EDIT_ONE_SUCCESS,
  COURSE_EDIT_ONE_ERROR,

  COURSE_DELETE_ONE,
  COURSE_DELETE_ONE_SUCCESS,
  COURSE_DELETE_ONE_ERROR
} from '../actions';


export const getMyLearning = (payload) => ({
  type: LEARNINGS_GET_LIST,
  payload
});
export const getMyLearningSuccess = (payload) => ({
  type: LEARNINGS_GET_LIST_SUCCESS,
  payload
});
export const getMyLearningError = (payload) => ({
  type: LEARNINGS_GET_LIST_ERROR,
  payload
});
// GET LIST
export const getCoursesList = (payload) => ({
  type: COURSE_GET_LIST,
  payload
});
export const getCoursesListSuccess = (payload) => ({
  type: COURSE_GET_LIST_SUCCESS,
  payload
});
export const getCoursesListError = (payload) => ({
  type: COURSE_GET_LIST_ERROR,
  payload
});







// GET LIST
export const getOneCourse = (payload) => ({
  type: COURSE_GET_ONE,
  payload
});
export const getOneCourseSuccess = (payload) => ({
  type: COURSE_GET_ONE_SUCCESS,
  payload
});
export const getOneCourseError = (payload) => ({
  type: COURSE_GET_ONE_ERROR,
  payload
});


// GET LIST
export const getCoursesListPartner = (payload) => ({
  type: COURSE_GET_PARTNER_NETWORK,
  payload
});




// TOGGLE SUBSCRIBE
export const subscribeCourse = (payload) => ({
  type: COURSE_SUBSCRIBE,
  payload
});
export const subscribeCourseSuccess = (payload) => ({
  type: COURSE_SUBSCRIBE_SUCCESS,
  payload
});
export const subscribeCourseError = (payload) => ({
  type: COURSE_SUBSCRIBE_ERROR,
  payload
});





// ADD ONE
export const addCourse = (payload) => ({
  type: COURSE_ADD_ONE,
  payload
});
export const addCourseSuccess = (payload) => ({
  type: COURSE_ADD_ONE_SUCCESS,
  payload
});
export const addCourseError = (payload) => ({
  type: COURSE_ADD_ONE_ERROR,
  payload
});




// EDIT ONE
export const editCourse = (payload) => ({
  type: COURSE_EDIT_ONE,
  payload
});
export const editCourseSuccess = (payload) => ({
  type: COURSE_EDIT_ONE_SUCCESS,
  payload
});
export const editCourseError = (payload) => ({
  type: COURSE_EDIT_ONE_ERROR,
  payload
});




// DELETE ONE
export const deleteOneCourse = (payload) => ({
  type: COURSE_DELETE_ONE,
  payload
});
export const deleteOneCourseSuccess = (payload) => ({
  type: COURSE_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneCourseError = (payload) => ({
  type: COURSE_DELETE_ONE_ERROR,
  payload
});