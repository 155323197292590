import {
  EXAMSESSIONS_GET_LIST,
  EXAMSESSIONS_GET_LIST_SUCCESS,
  EXAMSESSIONS_GET_LIST_ERROR,

  EXAMSESSIONS_GET_ONE,
  EXAMSESSIONS_GET_ONE_SUCCESS,
  EXAMSESSIONS_GET_ONE_ERROR,

  EXAMSESSIONS_GET_BY_FILTER,
  EXAMSESSIONS_GET_BY_FILTER_SUCCESS,
  EXAMSESSIONS_GET_BY_FILTER_ERROR,

  EXAMSESSIONS_ADD_ONE,
  EXAMSESSIONS_ADD_ONE_SUCCESS,
  EXAMSESSIONS_ADD_ONE_ERROR,

  EXAMSESSIONS_EDIT_ONE,
  EXAMSESSIONS_EDIT_ONE_SUCCESS,
  EXAMSESSIONS_EDIT_ONE_ERROR,

  EXAMSESSIONS_DELETE_ONE,
  EXAMSESSIONS_DELETE_ONE_SUCCESS,
  EXAMSESSIONS_DELETE_ONE_ERROR,

  EXAMSESSIONS_DELETE_MANY,
  EXAMSESSIONS_DELETE_MANY_SUCCESS,
  EXAMSESSIONS_DELETE_MANY_ERROR,
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'
const INIT_STATE = {
  exam_sessions: [],
  count: 0,
  exam_session: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

  switch (action.type) {

    //GET LIST
    case EXAMSESSIONS_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case EXAMSESSIONS_GET_LIST_SUCCESS:

      return {
        ...state, loading: false,
        exam_sessions: action.payload.exam_sessions,
        count: action.payload.count || 0

      };

    case EXAMSESSIONS_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    // GET ONE
    case EXAMSESSIONS_GET_ONE:
      return { ...state, loading: true, error: '' };
    case EXAMSESSIONS_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        exam_session: action.payload.exam_session
      };
    case EXAMSESSIONS_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET BY FILTER
    case EXAMSESSIONS_GET_BY_FILTER:

      return { ...state, loading: true, error: '' };
    case EXAMSESSIONS_GET_BY_FILTER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        exam_sessions: action.payload.exam_sessions || [], count: action.payload.count || 0
      };
    case EXAMSESSIONS_GET_BY_FILTER_ERROR:

      return { ...state, loading: false, error: action.payload.message };


    // ADD ONE
    case EXAMSESSIONS_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case EXAMSESSIONS_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');

      return {
        ...state, loading: false,
        exam_sessions: [action.payload.exam_session, ...state.exam_sessions]
      };
    case EXAMSESSIONS_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // EDIT ONE
    case EXAMSESSIONS_EDIT_ONE:
      return { ...state, loading: true, edit_sucess: false, error: '' };
    case EXAMSESSIONS_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, edit_sucess: true, error: '',
        exam_session: action.payload.exam_session
      };
    case EXAMSESSIONS_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, edit_sucess: false, error: action.payload.message };


    // DELETE ONE
    case EXAMSESSIONS_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case EXAMSESSIONS_DELETE_ONE_SUCCESS:
      const exam_sessions = state.exam_sessions
      if (action.payload.index !== null && action.payload.index !== undefined)
        exam_sessions.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        exam_sessions
      };
    case EXAMSESSIONS_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // DELETE MANY
    case EXAMSESSIONS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case EXAMSESSIONS_DELETE_MANY_SUCCESS:
      const exam_sessions_list = state.exam_sessions


      if (action.payload.data.ids.length > 0) {

        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_exam_session = -1
          for (let j = 0; j < exam_sessions_list.length; j++) {
            if (exam_sessions_list[j]._id === action.payload.data.ids[i]) {
              index_exam_session = j; break;
            }
          }
          if (index_exam_session > -1) exam_sessions_list.splice(index_exam_session, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        exam_sessions: exam_sessions_list
      };
    case EXAMSESSIONS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    default: return { ...state };
  }
}