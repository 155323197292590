import {
    SIGNALS_GET_LIST,
    SIGNALS_GET_LIST_ERROR,
    SIGNALS_GET_LIST_SUCCESS,

    SIGNALS_GET_ITEM,
    SIGNALS_GET_ITEM_ERROR,
    SIGNALS_GET_ITEM_SUCCESS,

    SIGNALS_DELETE_ONE,
    SIGNALS_DELETE_ONE_SUCCESS,
    SIGNALS_DELETE_ONE_ERROR
} from './actionTypes'

import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from '../../lang/locales/notificationMsg'

const INIT_STATE = {
    signals: [],
    count: 0,
    signal: null,
    error: '',
    loading: false
};


export default (state = INIT_STATE, action) => {
    const messages = notificationMessages()
  
    switch (action.type) {
      case SIGNALS_GET_LIST:
        return {
          ...state, loading: true,
          error: ''
        };
      case SIGNALS_GET_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          signals: action.payload.signals,
          count: action.payload.count
        };
      case SIGNALS_GET_LIST_ERROR:
        return { ...state, loading: false, error: action.payload };

     //GET ONE
      case SIGNALS_GET_ITEM:
        return { ...state, signal: null, loading: true, error: '' };
  
      case SIGNALS_GET_ITEM_SUCCESS:
        return { ...state, loading: false, signal: action.payload.signal };
  
      case SIGNALS_GET_ITEM_ERROR:
        return { ...state, loading: false, signal: null, error: action.payload.message };
 
 

      // DELETE ONE
      case SIGNALS_DELETE_ONE:
        return { ...state, loading: true, error: '' };
      case SIGNALS_DELETE_ONE_SUCCESS:
        const signals = state.signals
        if (action.payload.index !== null && action.payload.index !== undefined)
        signals.splice(action.payload.index, 1)
  
        return {
          ...state, loading: false, error: '',
          signals
        };
      case SIGNALS_DELETE_ONE_ERROR:
        NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };
  
  
  
      default: return { ...state };
  
    }
}  